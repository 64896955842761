/**
 * Google Tag
 * @param ACTION
 * @param category
 * @param label
 * @param value
 * @param rest
 * @returns {void|*}
 */
export const gtagEvent = (ACTION, category, label, value, ...rest) => {
    if (!window.gtag) return;

    return window.gtag('event', ACTION, {
        event_category: category || '',
        event_label: label || '',
        value: value || '',
        ...rest,
    });
};

/**
 * GA4 Analytics Event for Login or Register
 * @param userId
 * @param method
 * @param isRegister
 */
export const loginEvent = (userId, method, isRegister) => {
    if (!window.gtag) return;

    return window.gtag('event', isRegister ? 'REGISTER ' : 'LOGIN', {
        method: method || 'FB',
        userId: userId || null,
    });
};

/**
 * GA4 Analytics Event for PAGE VIEW
 * @param screenName
 * @param screenType
 * @param screenCategory
 */
export const pageViewEvent = (screenName, screenType, screenCategory) => {
    if (!window.gtag) return;

    return window.gtag('event', 'PAGE_VIEW', {
        screen_name: screenName || '',
        screen_type: screenType || '',
        screen_category: screenCategory || '',
    });
};

/**
 * GA4 Analytics Event for Interactions (left sidebar actions, play button, right sidebar actions)
 * @param screenName
 * @param screenSection
 * @param interaction_category
 * @param interaction_object
 * @param interaction_object_type
 */
export const interactionEvent = (
    screenName,
    screenSection,
    interaction_category,
    interaction_object,
    interaction_object_type,
) => {
    if (!window.gtag) return;

    return window.gtag('event', 'INTERACTION', {
        screen_name: screenName || '',
        screen_section: screenSection || '',
        interaction_type: 'click',
        interaction_category: interaction_category || '',
        interaction_object: interaction_object || '',
        interaction_object_type: interaction_object_type || '',
    });
};

/**
 * GA4 Analytics Event for Offers (when looking at the offer, when opens info, after purchase)
 * @param eventName
 * @param item_name
 * @param item_id
 * @param item_category
 * @param quantity
 * @param currency_name
 * @param price
 */
export const offerEventHandlerGA4 = (
    eventName,
    { item_name, item_id, item_category, quantity, currency_name, price },
) => {
    if (!window.gtag) return;

    return window.gtag('event', eventName, {
        item_name: item_name || '',
        item_id: item_id || '',
        price: price || 0,
        item_category: item_category || '',
        quantity: quantity || 0,
        currency_name: currency_name || '',
    });
};
