import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import platform from 'platform';
import { IPlayGameButton } from 'src/@types';

import { globalOp } from '../../../../store/global';
import { localizationSel } from '../../../../store/localization';

import { GAME_DOWNLOAD_URL } from '../../../../constants/api.constants';

import { attributionHook, tracker } from '../../../../configs/titan.config';

import { interactionEvent } from '../../../../helpers/GA4Helper';
import { titanAnalyticsEvents } from '../../../../helpers/titanAnalytics';

import { Box } from '../../../core-ui';

import { AttributionHooks, XSollaDownloadHookAction, XSollaURLBuilder } from '@scopely/web-sdk-ua';

import { Button } from '../../../../stories';
import { PlayGameButtonStyled } from './PlayGameButton.styled';

const PlayGameButton = ({
    setOpenLeftSidebar,
    openLeftSidebar,
    isInBanner,
    mobileVersion,
}: IPlayGameButton) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [url, setUrl] = useState('');
    const localizationLoading = useSelector(localizationSel.loadingSelector);

    const hookAction = useMemo(() => {
        return new XSollaDownloadHookAction(new XSollaURLBuilder(GAME_DOWNLOAD_URL ?? ''));
    }, []);

    const hook = useMemo(() => {
        /**this line has error because of protected variables of Class AttributionHooks(used from ts ignore and linter ignore )**/
        return new AttributionHooks(tracker as any)?.withDownload(hookAction);
    }, [hookAction]);

    useEffect(() => {
        hookAction.generateDownloadURL(hook.getUserLandingId()).then((res) => {
            setUrl(res);
        });
    }, [hook, hookAction]);

    const playGameHandler = () => {
        interactionEvent(pathname, 'left_sidebar', 'game', 'play', 'button');
        if (platform && platform?.os) {
            switch (platform?.os?.family?.toLowerCase()) {
                case 'android':
                    goToLink(url, 'android');
                    return;
                case 'windows':
                    goToLink(url, 'windows');
                    return;
                case 'ios':
                    playOnMac();
                    return;
                case 'os x':
                    playOnMac();
                    return;
                case 'linux':
                    goToLink(url, 'linux');
                    return;
                default:
                    goToLink(url, 'windows');
                    return;
            }
        }
    };

    const playOnMac = () => {
        if (openLeftSidebar && setOpenLeftSidebar) {
            setOpenLeftSidebar(false);
        }

        return dispatch(
            globalOp.handleOpenModal({
                open: true,
                variant: 'download-game',
            }),
        );
    };

    const goToLink = (url: string, os: string) => {
        if (openLeftSidebar && setOpenLeftSidebar) {
            setOpenLeftSidebar(false);
        }

        window.open(url, '_blank');

        switch (os) {
            case 'windows':
            case 'linux':
                titanAnalyticsEvents.downloadPcGameButton();
                break;
            case 'android':
                titanAnalyticsEvents.downloadAndroidGameButton();
                break;
            default:
                titanAnalyticsEvents.downloadPcGameButton();
        }

        attributionHook.download();
        hook.download();
    };

    return (
        <PlayGameButtonStyled>
            <Box
                display="flex"
                alignItems="center"
                px={isInBanner ? 0 : 3}
                width={isInBanner ? [mobileVersion ? 1 : 220] : [1]}
            >
                <Button
                    variant="white"
                    size="large"
                    label={t('play.button.text')}
                    onClick={() => playGameHandler()}
                    isLoading={localizationLoading}
                    fullWidth
                />
            </Box>
        </PlayGameButtonStyled>
    );
};

export default memo(PlayGameButton);
