/**
 *  Main Colors
 */
import { TPalette } from '../../@types';

import { hexToRgba } from '../../helpers/utils';

const white = '#ffffff';

const fbColor = '#1588EA';
const fbColorHover = '#1272c5';
const scopelyColor = '#0c2aa9';
const scopelyColorHover = '#0f35d6';

const purpleA200 = '#141644';
const purpleA100 = '#3c58ca';
const purple900 = '#3728A5';
const purple800 = '#402FB6';
const purple700 = '#5848C5';
const purple600 = '#6C5DD3';
const purple500 = '#8274E3';
const purple400 = '#9487EE';
const purple300 = '#AEA3F6';
const purple200 = '#D5CFFF';
const purple100 = '#E6E2FF';
const purple50 = '#F9F8FF';

const greyA200 = '#1f2333';
const greyA100 = '#343130';
const grey900 = '#192661';
const grey800 = '#3A4676';
const grey700 = '#596490';
const grey600 = '#7882AA';
const grey500 = '#A9B1CF';
const grey400 = '#D7DBEC';
const grey300 = '#E6EAF2';
const grey200 = '#EDF0F5';
const grey100 = '#F4F6FA';
const grey50 = '#F3F7FC';
const grey25 = '#F5F6FA';
const grey10 = hexToRgba(white, 0.1);

const blue900 = '#0F6BB2';
const blue800 = '#177BC8';
const blue700 = '#2992E2';
const blue600 = '#49AAF4';
const blue500 = '#66B9FA';
const blue400 = '#88CBFF';
const blue300 = '#A7D8FF';
const blue200 = '#C2E4FF';
const blue100 = '#E4F3FF';
const blueA100 = '#3E8FCA';
const blueA200 = '#5ca5d8';
const blueA300 = '#a9dcff';
const blueA400 = '#59A4DA';
const blueA500 = '#4695cf';
const blueA600 = '#d38b00';
const blueA700 = '#664bd2';
const blueA800 = '#5da6db';
const blueA900 = '#3e8fca';

const greenA400 = '#AEDB9B';
const greenA300 = '#547547';
const greenA200 = '#384F2E';
const greenA100 = '#63A448';
const green900 = '#3A8C11';
const green800 = '#47A618';
const green700 = '#52AF24';
const green600 = '#59C125';
const green500 = '#6ED03D';
const green400 = '#89DE5E';
const green300 = '#A4E982';
const green200 = '#C8F5B1';
const green100 = '#E4FBD9';

const orangeA500 = '#eeab3a';
const orangeA400 = '#ffce0b';
const orangeA300 = '#ffa600';
const orangeA200 = '#FFD755';
const orangeA100 = '#F8BE56';
const orange900 = '#E09D19';
const orange800 = '#ECA51B';
const orange700 = '#F0AA23';
const orange600 = '#F5B73E';
const orange500 = '#F4C365';
const orange400 = '#F5D492';
const orange300 = '#FFE7BA';
const orange200 = '#FFEFD0';
const orange100 = '#FFF5E3';

const red900 = '#D01818';
const red800 = '#DC2323';
const red700 = '#E53939';
const red600 = '#F05454';
const red500 = '#F57070';
const red400 = '#F58686';
const red300 = '#FBB6B6';
const red200 = '#FDCECE';
const red100 = '#FFE3E3';

const darkBlack800 = '#171718';
const darkBlack700 = '#1C1C1E';
const darkBlack600 = '#1E1E20';
const darkBlack500 = '#242426';
const darkBlack400 = '#2A2A2D';
const darkBlack300 = '#2C2C2F';
const darkBlack200 = '#313134';

export const lightPalette: TPalette = {
    primary: {
        main: purple600,
        light: purple500,
        dark: purple700,
    },
    black: {
        dark: {
            800: darkBlack800,
            700: darkBlack700,
            600: darkBlack600,
            500: darkBlack500,
            400: darkBlack400,
            300: darkBlack300,
            200: darkBlack200,
        },
    },
    secondary: {
        main: grey100,
        light: grey200,
        dark: green300,
    },
    background: {
        10: grey10,
        50: grey50,
        100: grey100,
        200: white,
        300: purple600,
        400: orange800,
        500: grey200,
        600: purple300,
        700: grey25,
        800: grey600,
    },
    text: {
        50: white,
        100: grey600,
        200: grey900,
        300: grey800,
        400: grey500,
        500: grey700,
    },
    border: {
        50: grey300,
        100: grey600,
        200: white,
        300: purple600,
        400: grey200,
        500: orange700,
        600: red900,
        700: grey500,
    },
    common: {
        white: '#ffffff',
        black: '#000000',
    },
    facebook: {
        primary: fbColor,
        hover: fbColorHover,
    },
    scopely: {
        primary: scopelyColor,
        hover: scopelyColorHover,
    },
    purple: {
        A200: purpleA200,
        A100: purpleA100,
        900: purple900,
        800: purple800,
        700: purple700,
        600: purple600,
        500: purple500,
        400: purple400,
        300: purple300,
        200: purple200,
        100: purple100,
        50: purple50,
    },
    grey: {
        A200: greyA200,
        A100: greyA100,
        900: grey900,
        800: grey800,
        700: grey700,
        600: grey600,
        500: grey500,
        400: grey400,
        300: grey300,
        200: grey200,
        100: grey100,
        50: grey50,
    },
    blue: {
        900: blue900,
        800: blue800,
        700: blue700,
        600: blue600,
        500: blue500,
        400: blue400,
        300: blue300,
        200: blue200,
        100: blue100,
        A100: blueA100,
        A200: blueA200,
        A300: blueA300,
        A400: blueA400,
        A500: blueA500,
        A600: blueA600,
        A700: blueA700,
        A800: blueA800,
        A900: blueA900,
    },
    green: {
        900: green900,
        800: green800,
        700: green700,
        600: green600,
        500: green500,
        400: green400,
        300: green300,
        200: green200,
        100: green100,
        A100: greenA100,
        A200: greenA200,
        A300: greenA300,
        A400: greenA400,
    },
    orange: {
        900: orange900,
        800: orange800,
        700: orange700,
        600: orange600,
        500: orange500,
        400: orange400,
        300: orange300,
        200: orange200,
        100: orange100,
        A100: orangeA100,
        A200: orangeA200,
        A300: orangeA300,
        A400: orangeA400,
        A500: orangeA500,
    },
    red: {
        900: red900,
        800: red800,
        700: red700,
        600: red600,
        500: red500,
        400: red400,
        300: red300,
        200: red200,
        100: red100,
    },
};
