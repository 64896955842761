import styled from 'styled-components';

export const NavStyled = styled.nav`
    .nav-link {
        text-decoration: none;
        color: ${({ theme }) => theme.palette.grey[600]};
        &:hover {
            color: ${({ theme }) => theme.palette.purple[600]};
        }
    }
    .active-link {
        //transition: color 200ms ease-in-out;
        background: ${({ theme }) => theme.palette.purple[600]};
        color: ${({ theme }) => theme.palette.common.white};
        border-radius: 12px;
    }

    i {
        margin-right: 12px;
    }
    .WP-Nav-new-label {
        font-weight: 600;
        padding: 4px 12px !important;
    }
`;
