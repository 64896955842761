import {
    TCurrentUser,
    TCurrentUserAvatar,
    IBannerConfig,
    TGlobalConfig,
    TGlobalInitialState,
    TMaintenance,
    TModal,
    TSnackbar,
    TUserBalance,
    TUserCollections,
} from 'src/@types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialGlobalState } from './initialState';

export const globalSlice = createSlice({
    name: 'global',
    initialState: initialGlobalState(),
    reducers: {
        /**
         * LOADING ACTIONS
         */

        setFacebookLoading(state: TGlobalInitialState, action: PayloadAction<boolean>) {
            state.isLoadingFacebook = action.payload;
        },
        setScopelyLoading(state: TGlobalInitialState, action: PayloadAction<boolean>) {
            state.isLoadingScopely = action.payload;
        },
        setDashboardBannerLoading(state: TGlobalInitialState, action: PayloadAction<boolean>) {
            state.dashboardBannerLoading = action.payload;
        },

        /**
         * USER SPECIFIC ACTIONS
         */

        setCurrentUser(state: TGlobalInitialState, action: PayloadAction<TCurrentUser | null>) {
            state.currentUser = action.payload;
        },

        setCurrentUserAvatar(
            state: TGlobalInitialState,
            action: PayloadAction<TCurrentUserAvatar>,
        ) {
            state.currentUser = {
                ...state.currentUser,
                avatar: action.payload,
            };
        },

        setAuthenticated(state: TGlobalInitialState, action: PayloadAction<boolean>) {
            state.authenticated = action.payload;
        },

        setUserBalance(state: TGlobalInitialState, action: PayloadAction<Array<TUserBalance>>) {
            state.userBalance = action.payload;
        },

        setUserCollections(state: TGlobalInitialState, action: PayloadAction<TUserCollections>) {
            state.userCollections = action.payload;
        },

        /**
         * GLOBAL ACTIONS
         */

        setDashboardBanner(state: TGlobalInitialState, action: PayloadAction<IBannerConfig>) {
            state.dashboardBanner = action.payload;
        },

        setSnackbar(state: TGlobalInitialState, action: PayloadAction<TSnackbar>) {
            state.snackbar = action.payload;
        },

        setModal(state: TGlobalInitialState, action: PayloadAction<TModal>) {
            state.modal = action.payload;
        },

        setPortalConfigs(
            state: TGlobalInitialState,
            action: PayloadAction<TGlobalConfig | TMaintenance>,
        ) {
            state.globalConfig = action.payload;
            state.maintenance = action.payload;
        },
    },
});
