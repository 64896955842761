import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@mui/material';

import { specialOffersSel } from '../../../../store/specialOffers';

import ModalContent from '../../OfferModal/components/ModalContent';
import ModalContentError from '../../OfferModal/components/ModalContentError';
import ModalContentMobile from '../../OfferModal/components/ModalContentMobile';
import ModalContentSkeleton from '../../OfferModal/components/ModalContentSkeleton';

import { Box } from '../../../core-ui';

import ConfirmModalBackGroundImage from '../../../../assets/images/modals/ConfirmModalBackgrount.png';
import { Typography, LazyImage } from '../../../../stories';

export interface ISuccessModalProps {
    isBeepcoin?: boolean;
}

const SuccessfulModal: FC<ISuccessModalProps> = ({ isBeepcoin = false }) => {
    const theme = useTheme();
    const [t] = useTranslation();

    const offerModalData = useSelector(specialOffersSel.offerModalDataSelector);
    const offerInfo = useSelector(specialOffersSel.shopItemInfoSelector);
    const isOfferInfoLoading = useSelector(specialOffersSel.shopItemInfoLoaderSelector);

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Box
            pt={[6, 15]}
            pb={[6, 8]}
            px={[6, 8]}
            width={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                className="WP-Modal-Icon"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box pb={3}>
                    <Box display="flex" justifyContent="center" alignItems="center" mb={1}>
                        <Box width={1} display="flex" justifyContent="center">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                bordercolor={theme.palette.purple[600]}
                                border={2}
                                borderRadius="50%"
                                width={120}
                                height={120}
                                py={1}
                                px={1}
                            >
                                <Box
                                    bgimage={ConfirmModalBackGroundImage}
                                    width="100px"
                                    height="100px"
                                    className="background-cover"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <LazyImage
                                            src={offerModalData?.iconPath ?? ''}
                                            alt="avatar"
                                            height="80px"
                                            draggable={false}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box textAlign="center" data-testid="success-payment-modal">
                    <Typography fWeight="bold" variant="h5" color={theme.palette.text[300]}>
                        {!offerModalData?.price
                            ? t('modal.successful.reward.claimed')
                            : t('modal.successful.payment.successful')}
                    </Typography>
                </Box>
            </Box>
            <Box textAlign="center" pt={3} px={[0, 2, 6]} maxWidth={[452, 436]}>
                <Typography fWeight="medium" variant="p16" color={theme.palette.text[100]}>
                    {!offerModalData?.price
                        ? t('modal.successful.claim.close.successful')
                        : isBeepcoin
                        ? t('modal.processing.description')
                        : t('modal.successful.payment.close.successful')}
                </Typography>
            </Box>
            <Box py={8} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="p14" fWeight="semiBold" color={theme.palette.grey[900]}>
                    {t(offerModalData?.name ?? '')}
                </Typography>
            </Box>
            <Box width={1}>
                <Box>
                    {isOfferInfoLoading ? (
                        <ModalContentSkeleton />
                    ) : !offerInfo ? (
                        <ModalContentError />
                    ) : isMobile ? (
                        <ModalContentMobile
                            price={offerModalData?.price || 0}
                            isBeepCoin={isBeepcoin}
                        />
                    ) : (
                        <ModalContent
                            successModal
                            price={offerModalData?.price || 0}
                            isBeepCoin={isBeepcoin}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default SuccessfulModal;
