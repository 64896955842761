import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTheme, useMediaQuery } from '@mui/material';

import { localizationSel } from '../../../store/localization';

import { useEffectOnce } from '../../../hooks/useEffectOnce';

import { Box } from '../../core-ui';

import { Button, Typography } from '../../../stories';
import notFoundImage from './../../../assets/images/404.png';

export default function NotFoundPage() {
    const [t] = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));

    const xl = useMediaQuery(theme.breakpoints.up('xl'));
    const isLoading = useSelector(localizationSel.loadingSelector);

    useEffectOnce(() => {
        (window as any)['DD_RUM']?.addError('404-visit-count', window.history.state?.as || null);
    });

    const detectVariant = () => {
        if (sm) return 'h3';
        if (xl) return 'h1';
        return 'h2';
    };

    return (
        <Box
            textAlign="center"
            height="calc(100vh - 64px)"
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            data-testid="not-found-page"
        >
            <Box
                component="img"
                src={notFoundImage}
                alt="not-found-image"
                width="100%"
                mb={3}
                px={[5, 30, 25, 30, 43]}
            />
            <Box
                fontSize={[
                    theme.typography.size.h3.fontSize,
                    theme.typography.size.h3.fontSize,
                    theme.typography.size.h3.fontSize,
                    theme.typography.size.h2.fontSize,
                    theme.typography.size.h1.fontSize,
                ]}
            >
                <Typography
                    fWeight="bold"
                    color={theme.palette.text[200]}
                    loaderWidth={200}
                    variant={detectVariant()}
                >
                    {t('not.found.page.not.found')}
                </Typography>
            </Box>
            <Box mt={5} fontSize={[16, 20]}>
                <Typography
                    color={theme.palette.text[100]}
                    fWeight="medium"
                    loaderWidth={250}
                    variant={sm ? 'p16' : 'p20'}
                >
                    {t('not.found.description')}
                </Typography>
            </Box>

            <Box display="flex" justifyContent="center" maxWidth="230px" width="100%">
                <Box mt={8} width="100%">
                    <Button
                        variant="primary"
                        size="large"
                        onClick={() => navigate('/')}
                        label={t('not.found.back.button')}
                        isLoading={isLoading}
                        fullWidth
                    />
                </Box>
            </Box>
        </Box>
    );
}
