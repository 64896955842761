import styled from 'styled-components';

export const MilestoneInfoModalContentItemStyled = styled.div`
    .modal-item-content {
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: ${({ theme }) => theme.palette.grey[500]}
            ${({ theme }) => theme.palette.grey[300]};

        &::-webkit-scrollbar {
            width: 4px;
            display: block;
        }

        &::-webkit-scrollbar-track {
            background: ${({ theme }) => theme.palette.grey[300]};
            border-radius: 10px;
            margin-bottom: 16px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${({ theme }) => theme.palette.grey[500]};
            border-radius: 10px;
            border: none;
        }
    }
`;
