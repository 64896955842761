import { createSelector } from 'reselect';
import { TLocalizationState, TLocalization } from 'src/@types';

import { RootState } from '../configureStore';

const localizationStateSelector = (state: RootState) => state.localization;
const loadingSelector = createSelector(
    [localizationStateSelector],
    (localization: TLocalizationState) => localization.isLoading,
);
const localizationSelector = createSelector(
    [localizationStateSelector],
    ({ localization }: { localization: TLocalization | null }) => localization,
);

export const localizationSel = { loadingSelector, localizationSelector };
