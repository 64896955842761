import { TToonGuideState } from 'src/@types';

export function initialToonGuideState(): TToonGuideState {
    return {
        isToonGuidesLoading: true,
        isFilterItemLoading: true,
        isToonLevelUpsLoading: true,
        isTuneup45Loading: true,
        toonGuide: null,
        toonGuideProfileLoader: true,
        toonProfile: null,
        toonLevelUps: null,
        tuneup45: null,
        filterItems: null,
        calculation: null,
        characterStats: null,
        skills: null,
    };
}
