import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material';

import { globalOp, globalSel } from '../../../../store/global';

import { titanAnalyticsEvents } from '../../../../helpers/titanAnalytics';

import { useEffectOnce } from '../../../../hooks/useEffectOnce';

import { Box } from '../../../core-ui';

import currency_beepcoin from '../../../../assets/images/currency_beepcoin.png';
import { Button, Typography } from '../../../../stories';

const NoneEnoughModal = () => {
    const theme = useTheme();
    const [t] = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentUser = useSelector(globalSel.currentUserSelector);

    const userId = currentUser?.playerProfile?.id;

    useEffectOnce(() => {
        titanAnalyticsEvents.notEnoughFundsModal({ 'sys.user_id': userId || '' });
    });

    return (
        <Box width={[320, 450]} height={[440, 420]} pt={[6, 15]} pb={[5, 8]} px={[6, 15]}>
            <Box
                className="WP-Modal-Icon"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    width={120}
                    height={120}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="50%"
                    border={1}
                    borderColor={theme.palette.purple[100]}
                >
                    <Box
                        bgcolor={theme.palette.purple[100]}
                        width={100}
                        height={100}
                        borderRadius="50%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box
                            component="img"
                            alt="not-enough-icon"
                            src={currency_beepcoin}
                            width={60}
                            height={60}
                        />
                    </Box>
                </Box>

                <Box pt={6} pb={3} textAlign="center">
                    <Typography fWeight="bold" variant="h5" color={theme.palette.text[300]}>
                        {t('modal.none.enough.title')}
                    </Typography>
                </Box>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                pb={6}
            >
                <Typography fWeight="regular" variant="p16" color={theme.palette.text[100]}>
                    {t('modal.none.enough.description')}
                </Typography>
            </Box>

            <Box width={1} display="flex" alignItems="center" justifyContent="center">
                <Button
                    variant="primary"
                    onClick={() => {
                        navigate('/special-offers');
                        dispatch(globalOp.handleCloseModal());
                    }}
                    label={t('modal.none.enough.button.go.shop')}
                />
            </Box>
        </Box>
    );
};

export default NoneEnoughModal;
