import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { getTime } from 'date-fns';
import numeral from 'numeral';

import { IOfferReward } from '../@types';

import { GAME_S3_BUCKET_URL } from '../constants/api.constants';

import { getRewardItemName } from './rewards';

export function hexToRgba(hex: string, opacity: number) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    const result: RegExpExecArray | null = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (result && result[1] && result[2] && result[3]) {
        return opacity
            ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
                  result[3],
                  16,
              )}, ${opacity})`
            : `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
                  result[3],
                  16,
              )})`;
    }

    return 'transparent';
}

export function playerSuperGoldStarsPossibleValues(maxPoints: number) {
    let previousValue = 0;

    return new Array(maxPoints).fill('').map((_, index) => {
        const currentValue = maxPoints - index + previousValue + 1;
        previousValue = currentValue;

        return currentValue;
    });
}

/**
 * Description Text Color Generator
 * text {string}
 * @param text
 **/

export const htmlTextGenerator = (text: string) => {
    if (!text) return '';

    const tagOpen = '<color=';
    const tagClose = '</color>';

    function generateHtml(str: string): string {
        const tagIndex = str.indexOf(tagOpen);
        const initialText = str.slice(str.indexOf('['), str.indexOf(']') + 1);

        if (tagIndex >= 0 || initialText) {
            const title = initialText.split('-')[1];
            const convertedTitle = title ? title.charAt(0).toUpperCase() + title.slice(1) : '';
            const color = str.slice(tagIndex + tagOpen.length, tagIndex + tagOpen.length + 7);
            const correctText = str
                .replace(tagClose, '</span>')
                .replace(`<color=${color}>`, `<span style='color: ${color}; font-weight: 600' >`);

            const result =
                initialText &&
                title &&
                initialText
                    .replace(title, convertedTitle)
                    .replace('-', '% ')
                    .replace('[', '')
                    .replace(']', '');

            if (result) {
                return generateHtml(correctText.replace(initialText, result));
            }

            return str;
        } else {
            return str;
        }
    }

    return generateHtml(text);
};
/**
 * Calculate Progress bar percents
 * @param startDate
 * @param endDate
 */
export const generateProgressDateInPercents = (startDate: number, endDate: number) => {
    const now = getTime(new Date());
    const fullProgress = endDate - startDate;
    const currentProgress = now - startDate;

    const progress = (currentProgress * 100) / fullProgress;
    return progress > 0 && progress < 100 ? progress : 'expired';
};

/**
 * Generate Progress bar Date and time
 * @param endDate
 */
export const generateProgressDate = (endDate: number) => {
    /**
     * Calculate duration between date now/startDate and event endDate
     * Format: X Days Y Hours Remaining
     */
    const now = getTime(new Date());
    const diff = endDate - now;

    let days = diff / (24 * 60 * 60 * 1000);
    let hours = (days % 1) * 24;
    let minutes = (hours % 1) * 60;

    [days, hours, minutes] = [Math.floor(days), Math.floor(hours), Math.floor(minutes)];
    if (!minutes || days < 0 || hours < 0) {
        return 'Ended';
    }
    if (days && hours) {
        return `${days} ${days > 1 ? 'days' : 'day'} ${hours} hours remaining`;
    } else if (hours && !days) {
        return `${hours} hours remaining`;
    } else if (!hours && days) {
        return `${days} days remaining`;
    }

    return `${minutes} minutes remaining`;
};

export const thousandsFormatterWithDots = (string: string) => {
    if (string) {
        return string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else return 0;
};

export const thousandsFormatterWithCommas = (string: string | number) => {
    if (string) {
        return string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else return 0;
};

export const buttonPriceFormatter = (price: string | number) => {
    if (price) {
        const number = +price;
        const isInt = Number(number) === number && number % 1 === 0;

        if (isInt) {
            return numeral(number).format('0,0');
        } else {
            return numeral(number).format('0,0.00');
        }
    } else return 0;
};

export const getGuaranteedOneOfTheseItemsDescription = (item: IOfferReward, t: TFunction) => {
    if (item?.odds[0]) {
        if (item.max === item.min) {
            return `${item.min} ${getRewardItemName(item, t)} - ${Math.trunc(
                item.odds[0].chance,
            )}% ${t('chance')}`;
        }

        return `${t('at.least')} ${item.min} ${getRewardItemName(item, t)} (${t(
            'max',
        ).toLowerCase()} ${item.max}) - ${Math.trunc(item.odds?.[0]?.chance)}% ${t('chance')}`;
    }
    return '';
};

export const getOfferButtonText = (
    leftCount: number,
    price: number | string,
    isPromoOffer?: boolean,
) => {
    if (isPromoOffer) {
        return 'use.promo.code';
    }
    if (leftCount === 0) {
        return 'special.offer.ShopCard.purchased.btn';
    } else if (price) {
        return 'buy';
    } else {
        return 'claim';
    }
};

export const getFormattedTime = (time: number, divideByDay = true) => {
    const hour = divideByDay
        ? Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        : Math.floor(time % (1000 * 60 * 60 * 24));

    const hours = hour < 10 ? `0${hour}` : hour;

    const mins = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const minutes = mins < 10 ? `0${mins}` : mins;

    const secs = Math.floor((time % (1000 * 60)) / 1000);
    const seconds = secs < 10 ? `0${secs}` : secs;
    if (`${hours}`?.includes('-') && `${minutes}`?.includes('-') && `${seconds}`?.includes('-')) {
        return 'Ended';
    }
    return `${hours}:${minutes}:${seconds}`;
};

export const startOfferRemainingCount = (
    endDate: string | number,
    stateCallback: Dispatch<SetStateAction<boolean>>,
) => {
    const endTime = typeof endDate === 'string' ? +endDate : endDate;
    const leftTime = endTime - getTime(new Date());

    if (leftTime > 172800000) return; // 2 days - if left time is more than 2 days we don't need to handle it

    const timeout = setTimeout(() => {
        stateCallback(true);
    }, leftTime);

    return () => clearInterval(timeout);
};

export const getAvatarBackgroundImg = (category: string) => {
    const initialUrl = GAME_S3_BUCKET_URL + '/ShopBackgrounds/';

    switch (category) {
        case 'Epic':
            return initialUrl + 'shop_background_purple_01.png';
        case 'Common':
            return initialUrl + 'shop_background_green_02.png';
        case 'Rare':
            return initialUrl + 'shop_background_blue_2.png';
        case 'Legendary':
            return initialUrl + 'shop_background_gold_01.png';
        default:
            return initialUrl + 'shop_background_purple_01.png';
    }
};

export const getBeepcoinCount = (price: number) => {
    return Math.round(price) * 3;
};

/**
 * @desc function generate unique id
 * @param length {Number}
 * @return {string}
 */

export const uuid = (length = 12) => {
    return Math.random().toString(36).substring(3, length);
};

/**
 * @desc offer card types(small/default, large/medium)
 */

export const CARD_TYPES = {
    small: 'small',
    medium: 'medium',
};

export const getCardType = (priority: number) => {
    if (priority > 1 && priority < 6) {
        return CARD_TYPES.medium;
    } else return CARD_TYPES.small;
};

export const REWARD_TYPES = {
    possible: 'possible',
    guaranteed: 'guaranteed',
    guaranteedOneOfThese: 'guaranteedOneOfThese',
};

export const getSearchParams = (search: string) => {
    return new URLSearchParams(search);
};

export const compareMilestoneState = (
    claimDate: string | number,
    endDate: string | number,
): boolean => {
    const claimTime = new Date(claimDate).getTime();
    const endTime = new Date(endDate).getTime();
    const now = new Date().getTime();

    return endTime > now && claimTime < now;
};
