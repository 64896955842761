import { createSelector } from 'reselect';

import { RootState } from '../configureStore';

const allianceProfilesSelector = (state: RootState) => state.alliance;

const isLoadingSelector = createSelector(
    [allianceProfilesSelector],
    (alliance) => alliance.isLoadingSelector,
);

const allianceProfileSelector = createSelector(
    [allianceProfilesSelector],
    (alliance) => alliance.allianceProfile,
);

export const allianceProfileSel = {
    isLoadingSelector,
    allianceProfileSelector,
};
