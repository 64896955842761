import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { globalSel } from '../../../store/global';

import { Box } from '../../core-ui';

import { ConnectionLostStyled } from './ConnectionLost.styled';

function ConnectionLost() {
    const [t] = useTranslation();
    const theme = useSelector(globalSel.themeSelector);

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                position="relative"
                height={[375, 452, 452, 500, 600]}
            >
                <Box
                    component={ConnectionLostStyled}
                    className="WP-ConnectionLostComponent-bg"
                    height={1}
                >
                    <Box
                        position="relative"
                        display="flex"
                        justifyContent="center"
                        alignItems="flex-end"
                        height="100%"
                    >
                        <Box className="WP-ConnectionLost-img" height="100%" width={[160, 336]} />
                    </Box>
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                textAlign="center"
            >
                <Box
                    fontSize={[
                        theme.typography.size.h3.fontSize,
                        theme.typography.size.h2.fontSize,
                    ]}
                    color={theme.palette.text[200]}
                    fontWeight={theme.typography.weight.bold}
                    mb={3}
                    px={10}
                >
                    {t('connection.lost.page.title')}
                </Box>
                <Box
                    fontSize={[
                        theme.typography.size.p16.fontSize,
                        theme.typography.size.h6.fontSize,
                    ]}
                    color={theme.palette.text[500]}
                    fontWeight={theme.typography.weight.medium}
                >
                    {t('connection.lost.page.description')}
                </Box>
            </Box>
        </Box>
    );
}

export default ConnectionLost;
