import React from 'react';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const InfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.elevation[900],
        borderBottom: `1px solid ${theme.palette.common.white}`,
        borderRadius: '12px',
        marginBottom: '8px !important',
        padding: '12px 8px',
    },
    [`& .${tooltipClasses.arrow}`]: {
        backgroundColor: 'transparent',
        color: theme.palette.common.white,
    },
}));

export default InfoTooltip;
