import React, { ElementType } from 'react';

import MUIBox from '@mui/material/Box';

import { TGuaranteedItems } from '../../../@types';

import { ILazyLoadImageSpecificProps } from '../../../stories/components/LazyImage';
import { ISwiperSliderProps } from '../../components/SwiperSlider/SwiperSlider';

import { AllSystemCSSProperties } from '@mui/system/styleFunctionSx/styleFunctionSx';

import { BoxStyled } from './Box.styled';

export interface IBoxProps {
    children?: React.ReactNode | null;
    className?: string;
    bgimage?: string;
    imageUrl?: string;
    bgcolor?: string;
    bordercolor?: string;
    align?: string;
    loaderWidth?: number;
    src?: string;
    alt?: string;
    variant?: string;
    frameBorder?: string;
    fWeight?: string | number;
    onClick?: () => void;
    onError?: () => void;
    component?: ElementType;
    sx?: object;
    rank?: string;
    loading?: string;
    width?: (string | number) | (string | number)[];
    height?: (string | number) | (string | number)[];
    size?: number;
    spacing?: number;
    item?: boolean;
    overflow?: string;
    onScroll?: () => void;
    testId?: string;
    iconPath?: string;
    data?: TGuaranteedItems;
    type?: string;
    bgColor?: string;
}

export const Box: React.FC<
    IBoxProps & AllSystemCSSProperties & ILazyLoadImageSpecificProps & ISwiperSliderProps
> = (props) => {
    return (
        <MUIBox
            component={BoxStyled}
            bgcolor={props.bgcolor}
            bgimage={props.bgimage}
            bordercolor={props.bordercolor}
            {...props}
        >
            {props.children}
        </MUIBox>
    );
};
