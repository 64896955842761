import axios from 'axios';

import { API_URL } from '../constants/api.constants';
import { DEFAULT_HEADERS } from '../constants/defaultHeaders.constant';

import { getStorageItem, removeStorageItem, setStorageItem } from '../helpers/localStorage';

const axiosInstance = axios.create({
    baseURL: API_URL,
});

const fetch = ({ url = API_URL, endPoint = '/', body = {}, method = 'GET', headers = {} }) => {
    let config = {
        method,
        url: `${url}${endPoint}`,
        data: body,
    };

    config.headers = {
        ...DEFAULT_HEADERS,
        ...headers,
    };

    if (method === 'GET') {
        config.params = body;
    }

    return axiosInstance(config);
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

axiosInstance.interceptors.request.use(
    (config) => {
        const token = getStorageItem('accessToken');

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        config.headers['Content-Type'] = 'application/json';
        config.headers['Accept'] = 'application/json';
        return config;
    },
    (error) => {
        void Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const refreshToken = getStorageItem('refreshToken');

        if (error.response.status === 401 && !originalRequest._retry && refreshToken) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axiosInstance.request(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }
            originalRequest._retry = true;
            isRefreshing = true;
            await refreshAccessToken(refreshToken);
            const access_token = getStorageItem('refreshToken');
            processQueue(null, access_token);
            isRefreshing = false;
            return axiosInstance(originalRequest);
        }

        if (error.response) {
            error.data =
                error.response.status === 500
                    ? { message: 'Something went wrong, please try again.' }
                    : error.response.data;
        } else {
            error.data = {
                message: 'Something went wrong, please try again.',
            };
        }
        return Promise.reject(error.response);
    },
);

async function refreshAccessToken(refreshToken) {
    try {
        const { data } = await axios.post(`${API_URL}/v2/auth/refresh-token`, { refreshToken });

        setStorageItem('accessToken', data.accessToken);
        setStorageItem('refreshToken', data.refreshToken);
        processQueue(null, data.accessToken);
        return data.accessToken;
    } catch (e) {
        processQueue(e, null);
        removeStorageItem('accessToken');
        removeStorageItem('refreshToken');
    } finally {
        isRefreshing = false;
    }
}

export const services = { fetch, axiosInstance };
