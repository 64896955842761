import { IAuthErrorResponse, TAuthError, TResponseError } from 'src/@types';

import { globalSlice } from '../store/global/globalSlice';

import {
    BANNED_ACCOUNT_STATUS_CODE,
    NOT_LINKED_ACCOUNT_STATUS_CODE,
    NETWORK_UNHANDLED_EXCEPTION,
} from '../constants/api.constants';
import { BANNED_ACCOUNT, NO_LINKED_ACCOUNT } from '../constants/purchaseVariants';

import { setSessionStorage } from './localStorage';

export const getAuthError = (
    err: TResponseError | TAuthError | null,
    isScopely: boolean,
): IAuthErrorResponse => {
    const handleError = () => {
        return;
    };
    const defaultValue = { errorCode: 0, errorHandler: handleError };

    if (!err) return defaultValue;

    const { gameServerErrorType } = err;
    const { setModal, setSnackbar } = globalSlice.actions;

    switch (gameServerErrorType) {
        case BANNED_ACCOUNT_STATUS_CODE:
            return {
                errorCode: BANNED_ACCOUNT_STATUS_CODE,
                errorHandler: () => {
                    const handlerData = { open: true, variant: BANNED_ACCOUNT };

                    if (isScopely) {
                        return setSessionStorage('loginErrorHandler', handlerData);
                    }

                    return setModal(handlerData);
                },
            };
        case NOT_LINKED_ACCOUNT_STATUS_CODE:
            return {
                errorCode: NOT_LINKED_ACCOUNT_STATUS_CODE,
                errorHandler: () => {
                    const handlerData = { open: true, variant: NO_LINKED_ACCOUNT };

                    if (isScopely) {
                        return setSessionStorage('loginErrorHandler', handlerData);
                    }

                    return setModal(handlerData);
                },
            };
        case NETWORK_UNHANDLED_EXCEPTION:
            return {
                errorCode: NETWORK_UNHANDLED_EXCEPTION,
                errorHandler: () => {
                    const handlerData = { open: true, variant: 'error', message: 'Network Error' };

                    if (isScopely) {
                        return setSessionStorage('loginErrorHandler', handlerData);
                    }

                    return setSnackbar(handlerData);
                },
            };
        default:
            return defaultValue;
    }
};
