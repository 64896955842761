import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { IMilestoneReward } from '../../../../@types';

import { Box } from '../../../core-ui';

import MilestoneInfoModalContentItem from './MilestoneInfoModalContentItem';

function MilestoneInfoModalContent({
    rewards,
    isStory,
}: {
    rewards: IMilestoneReward[];
    isStory?: boolean;
}) {
    const [t] = useTranslation();

    return (
        <Box
            width={1}
            minWidth={[1, '460px']}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
        >
            <Box width="100%">
                <MilestoneInfoModalContentItem
                    title={t('offers.card.guaranteedItems')}
                    rewards={rewards}
                    isStory={isStory}
                />
            </Box>
        </Box>
    );
}

export default memo(MilestoneInfoModalContent);
