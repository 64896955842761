import platform from 'platform';
import { v4 as uuidv4 } from 'uuid';

import { Titan, tracker } from '../configs/titan.config';

// Helpers

const pagesPath = {
    '/': 'dashboard',
    '/special-offers': 'offers',
    '/beepcoin-shop': 'store',
    '/news': 'news',
    '/calendar': 'calendar',
    '/toon-guide': 'toonGuide',
    '/toon-profile/:toonId': 'toonProfile',
    '/leaderboard': 'leaderboard',
    '/leaderboard/top/:id': 'leaderboardTop',
    '/alliance/:allianceId': 'alliance',
    '/player/:playerId': 'playerProfile',
};

const defaultTitanUIParameters = (otherParameters = {}) => {
    const { pathname } = window.location;

    return {
        screen_name: pagesPath[pathname],
        index: 1,
        'sys.platform': 'web',
        'event.source': 'client',
        'sys.store': 'Xsolla',
        'sys.os': platform.os.family || '',
        'sys.os_version': platform.os.version || '',
        'sys.model': platform.name || '',
        device_token: tracker?.getDeviceToken() || '',
        ...otherParameters,
    };
};

// Main events

export const titanAnalyticsEvents = {
    // UI events
    appOpen: (user) => {
        const is_warm_start = window.history.length === 1;

        Titan.SendEvent({
            name: 'sys.app_open',
            parameters: {
                ...defaultTitanUIParameters(user),
                is_warm_start,
            },
        });
        window?.DDRUM?.addAction('titan-events-count');
    },
    authModal: () => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `auth_modal_${uuidv4()}`,
                screen_type: 'pop-up',
                screen_category: 'authorization',
                interaction_type: 'load',
                interaction_object: 'authorization_modal',
            },
        });
        window?.DDRUM?.addAction('titan-events-count');
    },
    authErrorModal: (type = '') => {
        // Could be archived, not found
        // Game service unavailable -> maintenance
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `auth_failed_modal_${uuidv4()}_type_${type
                    .trim()
                    .replace(' ', '_')
                    .toLowerCase()}`,
                screen_type: 'pop-up',
                screen_category: 'authorization_failed',
                interaction_type: 'load',
                interaction_object: 'authorization_failed_modal',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    notEnoughFundsModal: (user_id) => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `not_enough_funds_modal_${uuidv4()}`,
                screen_type: 'pop-up',
                screen_category: 'purchase',
                interaction_type: 'load',
                interaction_object: 'not_enough_funds_modal',
                'sys.user_id': user_id,
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    notSupportedPlatformModal: () => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `not_supported_platform_modal_${uuidv4()}`,
                screen_type: 'pop-up',
                screen_category: 'game',
                interaction_type: 'load',
                interaction_object: 'not_supported_platform_modal',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    paymentSuccessModal: () => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `payment_success_modal_${uuidv4()}`,
                screen_type: 'pop-up',
                screen_category: 'payment',
                interaction_type: 'load',
                interaction_object: 'payment_success_modal',
            },
        });
        window?.DDRUM?.addAction('titan-events-count');
    },
    noConnectedModal: () => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `no_connected_modal_${uuidv4()}`,
                screen_type: 'pop-up',
                screen_category: 'authorization',
                interaction_type: 'load',
                interaction_object: 'no_connected_modal',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    suspendedAccountModal: () => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `suspended_account_modal_${uuidv4()}`,
                screen_type: 'pop-up',
                screen_category: 'authorization',
                interaction_type: 'load',
                interaction_object: 'suspended_account_modal',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    xsollaPopUp: (user) => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(user),
                screen_id: `xsolla_pop_up_${uuidv4()}`,
                screen_type: 'pop-up',
                screen_category: 'shop',
                interaction_type: 'transaction',
                interaction_object: 'xsolla_transaction_pop_up',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    snackBarPopUp: (type, message) => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `notification_pop_up_${uuidv4()}_type_${type}_message_${message}`,
                screen_type: 'pop-up',
                screen_category: 'notification',
                interaction_type: 'load',
                interaction_object: `notification_${type}_pop_up`,
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    generatePageLoadEvent: (pageName, user) => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(user),
                screen_id: `page_${pageName}_${uuidv4()}`,
                screen_type: 'screen',
                screen_category: 'page',
                interaction_type: 'load',
                interaction_object: `${pageName}_page`,
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    loadMoreNewsButton: () => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `load_more_news_${uuidv4()}`,
                screen_type: 'screen',
                screen_category: 'news',
                interaction_type: 'tap',
                interaction_object: 'load_more_news_button',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    downloadPcGameButton: () => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `download_the_pc_game_${uuidv4()}`,
                screen_type: 'screen',
                screen_category: 'game',
                interaction_type: 'tap',
                interaction_object: 'download_the_pc_game',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },

    downloadiOSGameButton: () => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `download_the_ios_game_${uuidv4()}`,
                screen_type: 'screen',
                screen_category: 'game',
                interaction_type: 'tap',
                interaction_object: 'download_the_ios_game',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    downloadAndroidGameButton: () => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `download_the_android_game_${uuidv4()}`,
                screen_type: 'screen',
                screen_category: 'game',
                interaction_type: 'tap',
                interaction_object: 'download_the_android_game',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    signInButton: () => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `sign_in_button_${uuidv4()}`,
                screen_type: 'screen',
                screen_category: 'authorization',
                interaction_type: 'tap',
                interaction_object: 'sign_in_button',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    signOutButton: () => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `sign_out_button_${uuidv4()}`,
                screen_type: 'screen',
                screen_category: 'authorization',
                interaction_type: 'tap',
                interaction_object: 'sign_out_button',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    navigationButton: (user) => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(user),
                screen_id: `navigation_button_${uuidv4()}`,
                screen_type: 'screen',
                screen_category: 'navigation',
                interaction_type: 'tap',
                interaction_object: 'navigation_button',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    newsButton: () => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `news_category_button_${uuidv4()}`,
                screen_type: 'screen',
                screen_category: 'sorting',
                interaction_type: 'tap',
                interaction_object: 'news_category_button',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    offerCarouselButton: () => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `special_offers_slider_button_${uuidv4()}`,
                screen_type: 'screen',
                screen_category: 'slider',
                interaction_type: 'tap',
                interaction_object: 'special_offers_slider_button',
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },
    sideBarButton: (direction = 'left', user) => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(user),
                screen_id: `${direction}_side_bar_button_${uuidv4()}`,
                screen_type: 'screen',
                screen_category: 'sidebar',
                interaction_type: 'tap',
                interaction_object: `${direction}_side_bar_button`,
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },

    // TODO use this event for other events
    generateButtonEvent: ({ screen_id, screen_category, interaction_object }) => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `${screen_id}_button_${uuidv4()}`,
                screen_type: 'screen',
                screen_category,
                interaction_type: 'tap',
                interaction_object,
            },
        });

        window?.DDRUM?.addAction('titan-events-count');
    },

    // TODO
    generateModalEvent: ({ screen_id, screen_category, interaction_object }) => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `${screen_id}_modal_${uuidv4()}`,
                screen_type: 'pop-up',
                screen_category,
                interaction_type: 'load',
                interaction_object,
            },
        });
    },

    // Xsolla events
    xsolla_payment: ({
        error,
        amount_us,
        amount_local_currency,
        local_currency_type,
        store_sku,
        special_id,
        user_id,
    }) => {
        const parameters = {
            amount_us,
            amount_local_currency,
            local_currency_type,
            store_sku,
            special_id,
            'sys.user_id': user_id,
            success: true,
        };

        if (error) {
            parameters.error = error;
            parameters.success = false;
        }

        Titan.SendEvent({
            name: 'sys_payment',
            parameters,
        });
        window?.DDRUM?.addAction('titan-events-count');
    },
    xsolla_transaction: ({ context, item_id, quantity }) => {
        Titan.SendEvent({
            name: 'sys_payment',
            parameters: {
                trans_type: 'web_portal_iap',
                context,
                item_id,
                quantity,
            },
        });
        window?.DDRUM?.addAction('titan-events-count');
    },
};
