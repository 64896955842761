import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { avatarPLayerOp, avatarPlayerSel } from '../../../store/avatarPlayer';
import { globalOp, globalSel } from '../../../store/global';

import { PORTAL_S3_BUCKET_URL } from '../../../constants/api.constants';

import { interactionEvent } from '../../../helpers/GA4Helper';
import { titanAnalyticsEvents } from '../../../helpers/titanAnalytics';

import { Box, Icon } from '../../core-ui';

import AvatarModal from '../AvatarModal/AvatarModal';
import { Menu } from '../Menu/Menu';
import { UserImageStyled } from './UserImage.styled';

interface IUserImageProps {
    imageSrc: string;
    mainBorderColor: string;
    secondaryBorderColor: string;
    setOpenRightSidebar: (open: boolean) => void;
}

const UserImage = ({
    imageSrc,
    mainBorderColor,
    secondaryBorderColor,
    setOpenRightSidebar,
}: IUserImageProps) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector(globalSel.currentUserSelector);
    const [openAvatar, setOpenAvatar] = useState(false);

    const currentAvatar = useSelector(avatarPlayerSel.currentAvatarSelector);

    const theme = useSelector(globalSel.themeSelector);
    const [infoDropdownOpen, setInfoDropdownOpen] = useState(false);

    const handleAvatar = () => {
        setOpenAvatar(true);
        setOpenRightSidebar(false);
        setInfoDropdownOpen(false);
        dispatch(avatarPLayerOp.getAvatars());
    };

    const handleClose = () => {
        setTimeout(() => {
            if (infoDropdownOpen) {
                setInfoDropdownOpen(false);
            }
        }, 0);
    };
    // const handlePlayerProfile = () => {
    //     handleClose();
    //     setOpenRightSidebar(false);
    //     history.push(`/player/${user?.playerProfile?.id}`);
    // };

    // const handlePlayerAlliance = () => {
    //     handleClose();
    //     setOpenRightSidebar(false);
    //     history.push(`/alliance/${user?.playerProfile?.id}`);
    // };

    const handleLogout = () => {
        dispatch(globalOp.logout());
        setOpenRightSidebar(false);

        titanAnalyticsEvents.signOutButton();
        interactionEvent(
            location.pathname,
            'right_sidebar',
            'logout_button',
            'confirm_exit',
            'button',
        );
    };

    const handleSetInfoDropdown = () => {
        setInfoDropdownOpen((prevState) => !prevState);
    };
    const menuList = [
        {
            content: t('avatar.menu.change.avatar'),
            icon: 'icon-user-single-select',
            callback: () => handleAvatar(),
            testId: 'change-avatar',
        },
        // {
        //     content: t('avatar.menu.view.player.profile'),
        //     icon: 'icon-user-profile-square',
        //     callback: () => handlePlayerProfile(),
        // },
        // {
        //     content: t('avatar.menu.view.alliance.profile'),
        //     icon: 'icon-user-profile',
        //     callback: () => handlePlayerAlliance(),
        // },
        {
            content: t('avatar.menu.logout'),
            icon: 'icon-login-enter',
            callback: () => handleLogout(),
            testId: 'logout',
        },
    ];

    const getCurrentImage = () => {
        if (user?.avatar?.path) {
            if (!user?.avatar?.path.includes('data:')) {
                return `${PORTAL_S3_BUCKET_URL}${user?.avatar?.path}`;
            }
            return `${user?.avatar?.path}`;
        }
        return null;
    };
    const getCurrentSessionImg = () => {
        if (currentAvatar?.path) {
            if (!currentAvatar?.path.includes('data:')) {
                return `${PORTAL_S3_BUCKET_URL}${currentAvatar?.path}`;
            }
            return `${currentAvatar?.path}`;
        }
        return null;
    };

    return (
        <Box
            component={UserImageStyled}
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            {openAvatar && <AvatarModal open={openAvatar} handleModal={setOpenAvatar} />}
            <Box
                position="relative"
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={2}
                border={3}
                borderRadius="100px"
                bordercolor={mainBorderColor}
            >
                {user ? (
                    <Box
                        component="img"
                        width="78px"
                        height="78px"
                        borderRadius="50px"
                        src={getCurrentSessionImg() || getCurrentImage() || imageSrc}
                        alt="Player"
                        className="cursor-pointer"
                        onClick={handleSetInfoDropdown}
                    />
                ) : (
                    <Box width={100} height={100} borderRadius="50px" className="cursor-pointer" />
                )}
                <Box
                    position="absolute"
                    zIndex={9}
                    bgcolor={mainBorderColor}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bottom={1}
                    right={-3}
                    className="cursor-pointer"
                    border={3}
                    p={1}
                    bordercolor={secondaryBorderColor}
                    borderRadius="50px"
                    width={36}
                    height={36}
                    onClick={handleSetInfoDropdown}
                    data-testid="user-stetting-button"
                >
                    <Icon
                        className="icon-playerSettings"
                        size={14}
                        color={theme.palette.common.white}
                    />
                </Box>
            </Box>
            {infoDropdownOpen && (
                <Box position="absolute" width="100%" top="100%" minWidth={200} zIndex={9}>
                    <Menu
                        bgcolor={theme.palette.background[200]}
                        menuContent={menuList}
                        handleClose={handleClose}
                        variant="bottom"
                    />
                </Box>
            )}
        </Box>
    );
};

export default UserImage;
