import styled from 'styled-components';

import maintenance_effect from '../../../assets/images/MaintenancePage/MainteanceEffect.png';

export const BrowserSupportStyled = styled.div`
    width: 100%;

    background-image: url(${maintenance_effect});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-color: ${({ theme }) => theme.palette.purple[700]};

    mix-blend-mode: multiply;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
    padding-bottom: 120px;

    &::before {
        width: 100%;
        height: 10%;

        position: absolute;
        bottom: -1px;
        left: 0;
        content: '';

        display: block;
        border-radius: 100% 100% 0 0;
        background-color: ${({ theme }) => theme.palette.background[200]};
    }

    .WP-BrowserSupport-items {
        box-shadow: inset 0 0 25px rgba(243, 247, 252, 0.5);
    }
}
`;
