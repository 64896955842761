import React from 'react';
import { useSelector } from 'react-redux';

import { globalSel } from '../../../store/global';

import { Box } from '../../core-ui';

import Chrome from '../../../assets/images/BrowserSupport/browser-chrome.png';
import Edge from '../../../assets/images/BrowserSupport/browser-edge.png';
import Mozilla from '../../../assets/images/BrowserSupport/browser-mozilla.png';
import Safari from '../../../assets/images/BrowserSupport/browser-safari.png';
import { Typography } from '../../../stories';
import { BrowserSupportStyled } from './BrowserSupport.styled';

function BrowserSupport() {
    const theme = useSelector(globalSel.themeSelector);

    const BrowserTypes = [
        {
            image: Chrome,
            text: 'Google Chrome',
        },
        {
            image: Mozilla,
            text: 'Mozilla Firefox',
        },
        {
            image: Safari,
            text: 'Safari',
        },
        {
            image: Edge,
            text: 'Microsoft Edge',
        },
    ];

    return (
        <Box>
            <Box
                component={BrowserSupportStyled}
                position="relative"
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                pt={[15, 55]}
                pb={[15, 45]}
            >
                <Box height={1}>
                    <Box position="relative" display="flex" justifyContent="center">
                        <Box height="100%" width={[260, 300]}>
                            {BrowserTypes.map((btn) => {
                                return (
                                    <Box
                                        className="WP-BrowserSupport-items"
                                        display="flex"
                                        alignItems="center"
                                        key={btn.text}
                                        py={4}
                                        px={5}
                                        mb={5}
                                        borderRadius="20px"
                                        bgcolor={theme.palette.background[600]}
                                    >
                                        <Box
                                            component="img"
                                            alt={btn.text}
                                            src={btn.image}
                                            width={40}
                                            height={40}
                                            mr={3}
                                        />
                                        <Box mx={3}>
                                            <Typography
                                                variant="p20"
                                                fWeight="medium"
                                                color={theme.palette.common.white}
                                            >
                                                {btn.text}
                                            </Typography>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                textAlign="center"
            >
                <Box
                    fontSize={[
                        theme.typography.size.h3.fontSize,
                        theme.typography.size.h2.fontSize,
                    ]}
                    color={theme.palette.text[200]}
                    fontWeight={theme.typography.weight.bold}
                    mb={3}
                    px={4}
                >
                    The Website Available On
                </Box>
            </Box>
        </Box>
    );
}

export default BrowserSupport;
