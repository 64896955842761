import { createSlice } from '@reduxjs/toolkit';

import { initialEventsState } from './initialState';

export const eventsRBESlice = createSlice({
    name: 'eventsRBE',
    initialState: initialEventsState,
    reducers: {
        setClaimLoading(state, action) {
            state.isClaimLoading = action.payload;
        },
        setEventsLoading(state, action) {
            state.isEventsLoading = action.payload;
        },
        setActiveEvents(state, action) {
            state.activeEvents = action.payload;
        },
    },
});
