import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import { getStorageItem } from './helpers/localStorage';

import translation from './locales/global/translations.json';

const resources = {
    en: {
        translation,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: getStorageItem('language') || 'en',
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });
