import { TAllianceProfile } from 'src/@types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAllianceProfileState } from './initialState';

export const allianceProfileSlice = createSlice({
    name: 'allianceProfile',
    initialState: initialAllianceProfileState(),
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoadingSelector = action.payload;
        },
        setAllianceProfile(state, action: PayloadAction<Array<TAllianceProfile>>) {
            state.allianceProfile = action.payload;
        },
    },
});
