import { services } from '../../services/fetch.service';
import { AppDispatch } from '../configureStore';
import { calendarSlice } from './calendarSlice';

const getEvents = () => {
    const { setEvents, setLoading } = calendarSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setLoading(true));
        try {
            const { data } = await services.fetch({
                endPoint: `/v2/calendars/events`,
                method: 'GET',
            });

            dispatch(setEvents(data));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on get events');
        }

        dispatch(setLoading(false));
    };
};

export const calendarOp = {
    getEvents,
};
