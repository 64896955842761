import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';

import { Box } from '../../../core-ui';

import { Typography } from '../../../../stories';

const BannedAccountModal: React.FC = () => {
    const [t] = useTranslation();
    const theme = useTheme();

    return (
        <>
            <Box
                className="WP-Modal-Icon"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                pt={15}
                px={[5, 18]}
                pb={[9, 18]}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border={1}
                    borderRadius="50%"
                    borderColor={theme.palette.purple[100]}
                    mb={6}
                    width={120}
                    height={120}
                >
                    <Box
                        width={100}
                        height={100}
                        className="WP-Modal-Modal-BannedAccount WP-Modal-Icon-Background"
                    />
                </Box>

                <Box mb={3}>
                    <Typography fWeight="bold" variant="h5" color={theme.palette.text[300]}>
                        {t('modal.banned.account.title')}
                    </Typography>
                </Box>
                <Box pb={1} textAlign="center" maxWidth={400}>
                    <Typography variant="p16" color={theme.palette.text[100]}>
                        {t('modal.banned.account.description')}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default BannedAccountModal;
