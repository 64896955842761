import { AxiosResponse } from 'axios';
import { TAuthError, TUserBalance, TUserCollections } from 'src/@types';
import { TBody, TCurrentUserAvatar, TSnackbar, TModal, TResponseError } from 'src/@types';

import { LOGIN_TYPES } from '../../constants/api.constants';

import { scopelyId } from '../../configs/titan.config';

import { getAuthError } from '../../helpers/auth';
import { loginEvent } from '../../helpers/GA4Helper';
import { getStorageItem, removeStorageItem, setStorageItem } from '../../helpers/localStorage';
import { titanAnalyticsEvents } from '../../helpers/titanAnalytics';

import { services } from '../../services/fetch.service';
import { beepCoinShopSlice } from '../beepCoinShop/beepCoinShopSlice';
import { AppDispatch } from '../configureStore';
import { specialOffers } from '../specialOffers/specialOffers';
import { globalSlice } from './globalSlice';

/**
 * USER SPECIFIC OPERATIONS
 */

export const loginAsync = (body: TBody, deviceToken: string) => {
    const { setCurrentUser, setFacebookLoading, setAuthenticated } = globalSlice.actions;
    const { setStore } = specialOffers.actions;
    const { setBeepCoinShopData, setAvatarOffers } = beepCoinShopSlice.actions;

    const loggedInType = getStorageItem('loginProcessing');
    const isScopelyLogin = loggedInType === LOGIN_TYPES.scopely;

    return async (dispatch: AppDispatch) => {
        dispatch(setFacebookLoading(true));

        try {
            const { data } = await services.fetch({
                endPoint: '/v2/auth',
                method: 'POST',
                body: body,
                headers: {
                    'device-token': deviceToken,
                },
            });

            const user = await services.fetch({
                endPoint: '/v1/players/profile',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${data.accessToken}`,
                    'device-token': deviceToken,
                },
            });

            if (user?.data) {
                dispatch(setBeepCoinShopData(null));
                dispatch(setAvatarOffers(null));
                dispatch(setStore(null));
                dispatch(setCurrentUser(user.data));
                dispatch(setAuthenticated(true));

                setStorageItem('accessToken', data.accessToken);
                setStorageItem('refreshToken', data.refreshToken);

                dispatch(getUserBalance());

                titanAnalyticsEvents.signInButton();

                loginEvent(
                    user.data.playerProfile.id,
                    isScopelyLogin ? 'ScopelyID' : 'FB',
                    !!user.data.firstTime,
                );
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            const error = (err?.data as TResponseError | TAuthError | null) || null;

            const { errorCode, errorHandler } = getAuthError(error, isScopelyLogin);
            if (errorCode) {
                if (isScopelyLogin) {
                    await errorHandler();
                    await scopelyId.logout();
                } else {
                    dispatch(errorHandler());
                }
            }

            dispatch(logout());
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any)['DD_RUM']?.addError('AuthError', { errorCode, requestBody: body });
        }

        dispatch(setFacebookLoading(false));
    };
};

export const authMe = (accessToken: string, refreshToken: string) => {
    const { setCurrentUser, setFacebookLoading, setAuthenticated } = globalSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setFacebookLoading(true));

        try {
            const user = await services.fetch({
                endPoint: '/v1/players/profile',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (user?.data) {
                dispatch(setCurrentUser(user.data));
                dispatch(setAuthenticated(true));
                setStorageItem('accessToken', accessToken);
                setStorageItem('refreshToken', refreshToken);
                dispatch(getUserBalance());
            }
        } catch (err) {
            removeStorageItem('fbData');
            removeStorageItem('refreshToken');
            removeStorageItem('accessToken');
            removeStorageItem('loginProcessing');
        }

        dispatch(setFacebookLoading(false));
    };
};

const logout = () => (dispatch: AppDispatch) => {
    const { setCurrentUser, setAuthenticated } = globalSlice.actions;
    const { setStore } = specialOffers.actions;
    const { setBeepCoinShopData, setAvatarOffers } = beepCoinShopSlice.actions;

    dispatch(setBeepCoinShopData(null));
    dispatch(setAvatarOffers(null));
    dispatch(setStore(null));
    dispatch(setCurrentUser(null));
    dispatch(setAuthenticated(false));

    removeStorageItem('fbData');
    removeStorageItem('refreshToken');
    removeStorageItem('accessToken');
    removeStorageItem('loginProcessing');
    scopelyId.logout();
};

const getUserBalance = () => {
    const { setUserBalance, setUserCollections } = globalSlice.actions;

    return async (dispatch: AppDispatch) => {
        try {
            const [data, collection]: [
                AxiosResponse<Array<TUserBalance>>,
                AxiosResponse<TUserCollections>,
            ] = await Promise.all([
                services.fetch({
                    endPoint: `/v1/players/inventory`,
                    method: 'GET',
                }),
                services.fetch({
                    endPoint: `/v1/players/collection`,
                    method: 'GET',
                }),
            ]);
            dispatch(setUserCollections(collection?.data));
            dispatch(setUserBalance(data?.data));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error, 'error on get user balance');
        }
    };
};

const setCurrentUserAvatar = (data: TCurrentUserAvatar) => (dispatch: AppDispatch) => {
    const { setCurrentUserAvatar } = globalSlice.actions;

    dispatch(setCurrentUserAvatar(data));
};

/**
 * GLOBAL OPERATIONS
 */
const handleOpenSnackbar = (data: TSnackbar) => (dispatch: AppDispatch) => {
    const { setSnackbar } = globalSlice.actions;

    dispatch(setSnackbar(data));
};

const handleCloseSnackbar = () => (dispatch: AppDispatch) => {
    const { setSnackbar } = globalSlice.actions;

    dispatch(
        setSnackbar({
            open: false,
            variant: '',
        }),
    );
};

const handleOpenModal = (data: TModal) => (dispatch: AppDispatch) => {
    const { setModal } = globalSlice.actions;

    dispatch(setModal(data));
};

const handleScopelyLoading = (state: boolean) => {
    const { setScopelyLoading } = globalSlice.actions;
    return (dispatch: AppDispatch) => dispatch(setScopelyLoading(state));
};

const handleCloseModal = () => (dispatch: AppDispatch) => {
    const { setModal } = globalSlice.actions;

    dispatch(
        setModal({
            open: false,
            variant: '',
        }),
    );
};

const getGlobalConfigs = () => {
    const { setPortalConfigs } = globalSlice.actions;

    return async (dispatch: AppDispatch) => {
        try {
            const { data } = await services.fetch({
                endPoint: `/v2/configs/killSwitch`,
                method: 'GET',
            });

            dispatch(setPortalConfigs(data));
        } catch (error) {
            return error;
        }
    };
};

const getDashboardBannerConfigs = () => {
    const { setDashboardBanner, setDashboardBannerLoading } = globalSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setDashboardBannerLoading(true));
        try {
            const { data } = await services.fetch({
                endPoint: `/v2/configs/banner`,
                method: 'GET',
            });

            dispatch(setDashboardBanner(data));
        } catch (error) {
            return error;
        }

        dispatch(setDashboardBannerLoading(false));
    };
};

export const globalOp = {
    loginAsync,
    handleCloseSnackbar,
    handleOpenSnackbar,
    handleOpenModal,
    handleCloseModal,
    logout,
    authMe,
    setCurrentUserAvatar,
    getGlobalConfigs,
    getUserBalance,
    getDashboardBannerConfigs,
    handleScopelyLoading,
};
