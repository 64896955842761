import styled from 'styled-components';

export const LeftSidebarFooterStyled = styled.div`
    p {
        transition: color 200ms ease-out;
    }

    .WP-LeftSideBarFooter-social-links {
        a {
            margin-right: 20px;
            color: ${({ theme }) => theme.palette.grey[500]};
            transition: color 200ms ease-out;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                color: ${({ theme }) => theme.palette.grey[600]};
            }
        }
    }

    .WP-LeftSideBarFooter-download-links {
        border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
        border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};

        img {
            padding: 10px 0;
        }
    }

    .discord-button {
        padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
        text-align: left;
        text-transform: none;

        i {
            margin-right: 16px;
        }
    }
`;
