import { TCalculation, TSkills } from 'src/@types';

import { services } from '../../services/fetch.service';
import { AppDispatch } from '../configureStore';
import { toonGuidesSlice } from './toonGuidesSlice';

const getToons = () => {
    const { setLoading, setToonGuides, setToonGuidesFilterItems } = toonGuidesSlice.actions;

    return async (dispatch: AppDispatch) => {
        setLoading(true);

        try {
            const { data } = await services.fetch({
                endPoint: `/v1/players/characters`,
            });

            dispatch(setToonGuidesFilterItems(data.filters));
            dispatch(setToonGuides(data));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on get toons');
        }

        dispatch(setLoading(false));
    };
};

const getToonGuideProfile = (toonId: string) => {
    const { setToonGuideProfileLoader, setToonProfile } = toonGuidesSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setToonGuideProfileLoader(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v1/players/characters/${toonId}`,
                method: 'GET',
            });

            dispatch(setToonProfile(data));

            dispatch(getToonLevelUps());
            dispatch(getTuneup45());
        } catch (err) {
            return err;
        }

        dispatch(setToonGuideProfileLoader(false));
    };
};

const getToonLevelUps = () => {
    const { setToonLevelUpsLoader, setToonLevelUps } = toonGuidesSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setToonLevelUpsLoader(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v1/exportable/curve`,
                method: 'GET',
            });

            const dataMapped = data.data.map((item: { [key: string]: number | string }) => {
                return {
                    curveName: item.curveName,
                    ...item,
                };
            });

            dispatch(
                setToonLevelUps({
                    data: dataMapped,
                }),
            );
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on getToonLevelUps');
        }

        dispatch(setToonLevelUpsLoader(false));
    };
};

const getTuneup45 = () => {
    const { setTuneup45Loader, setTuneup45 } = toonGuidesSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setTuneup45Loader(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v1/exportable/tuneup45`,
                method: 'GET',
            });

            dispatch(setTuneup45(data));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on getTuneup45');
        }

        dispatch(setTuneup45Loader(false));
    };
};
// Todo: check any
const updateCharacterStats = (stats: any) => {
    const { setCharacterStats } = toonGuidesSlice.actions;

    return (dispatch: AppDispatch) => {
        dispatch(setCharacterStats(stats));
    };
};
// Todo: check any
const updateCalculation = (stats: TCalculation) => {
    const { setCalculation } = toonGuidesSlice.actions;

    return (dispatch: AppDispatch) => {
        dispatch(setCalculation(stats));
    };
};

const updateSkills = (skills: Array<TSkills>) => {
    const { setSkills } = toonGuidesSlice.actions;

    return (dispatch: AppDispatch) => {
        dispatch(setSkills(skills));
    };
};

export const toonGuideOp = {
    getToons,
    getToonGuideProfile,
    getToonLevelUps,
    getTuneup45,
    updateCharacterStats,
    updateCalculation,
    updateSkills,
};
