export const sizes = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

export const variants = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    DANGER: 'danger',
    WARNING: 'warning',
    SUCCESS: 'success',
    INFO: 'info',
    SOLID: 'solid',
    WHITE: 'white',
};
export const labelVariants = {
    PURPLE: 'purple',
    GREY: 'grey',
    RED: 'red',
    ORANGE: 'orange',
    GREEN: 'green',
    BLUE: 'blue',
    SOLID: 'solid',
    TRANSPARENT: 'transparent',
};
