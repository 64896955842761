/**
 * Special Offers Tab
 * @type {string}
 */
export const featuredOfferIndex = '8';
export const specialOfferIndex = '9';
export const essentialOfferIndex = '10';
export const defaultOfferIndex = '11';
export const eventOfferIndex = '14';

/**
 * Beepcoin Offers Tab
 * @type {string}
 */
export const dailyExchangeOfferIndex = '17';
export const energyOfferIndex = '18';
export const avatarOfferIndex = '16';
export const miscOfferIndex = '15';

export const BEEPCOIN_OFFERS_TYPES = {
    misc: 'miscOffers',
    daily: 'dailyExchangeOffers',
    energy: 'energyOffers',
};

export const SPECIAL_OFFERS_TYPES = {
    promo: 'promoCodeOffers',
    featured: 'featuredOffers',
    special: 'specialOffers',
    events: 'eventOffers',
    essential: 'essentialOffers',
    default: 'defaultOffers',
};
