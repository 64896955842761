import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, useTheme } from '@mui/material';

import { DOWNLOAD_LINKS } from '../../../../constants/links.constants';

import { Box } from '../../../core-ui';

import AppStore from '../../../../assets/images/appstore.svg';
import PlayMarket from '../../../../assets/images/google.svg';
import { Typography } from '../../../../stories';

const NoLinkedAccountModal: FC = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    return (
        <Box width={[1, 540]} pt={[7, 15]} pb={[7, 11]} px={[5, 15]}>
            <Box
                width={1}
                className="WP-Modal-Icon"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border={1}
                    borderRadius="50%"
                    borderColor={theme.palette.purple[100]}
                    width={120}
                    height={120}
                >
                    <Box
                        width={100}
                        height={100}
                        className="WP-Modal-No_Linked_Account WP-Modal-Icon-Background"
                    />
                </Box>

                <Box pt={6} textAlign="center">
                    <Typography fWeight="bold" variant="h5" color={theme.palette.text[300]}>
                        {t('modal.no.linked.account.title')}
                    </Typography>
                </Box>
                <Box pt={3} pb={6} textAlign="center">
                    <Typography variant="p16" color={theme.palette.text[100]}>
                        {t('modal.no.linked.account.description')}
                    </Typography>
                </Box>
            </Box>

            <Box width={1} display={['block', 'flex']} textAlign="center">
                <Box mr={[0, 3]}>
                    <Link href={DOWNLOAD_LINKS.PLAY_STORE} underline="none" target="_blank">
                        <img
                            height="46px"
                            width={200}
                            src={PlayMarket}
                            draggable="false"
                            alt="PlayMarket"
                        />
                    </Link>
                </Box>

                <Link href={DOWNLOAD_LINKS.APP_STORE} underline="none" target="_blank">
                    <img
                        height="46px"
                        width={200}
                        src={AppStore}
                        draggable="false"
                        alt="AppStore"
                    />
                </Link>
            </Box>
        </Box>
    );
};

export default NoLinkedAccountModal;
