import styled from 'styled-components';

import { IProgressBarProps } from '../../../@types';

export const ProgressBarWrapperStyled = styled.div<IProgressBarProps>`
    min-width: 100%;
    position: relative;
    color: ${({ color }) => color};

    .progress-text-dashed {
        ${({ theme }) => theme.breakpoints.down('sm')} {
            margin-right: 4px;
            text-align: center;
        }
    }

    .dashed-variant {
        font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
        font-weight: ${({ theme }) => theme.typography.weight.bold};
    }

    .lined-variant {
        font-size: ${({ theme }) => theme.typography.size.p12.fontSize};
        line-height: ${({ theme }) => theme.typography.size.p12.lineHeight};
    }
`;

export const ProgressBarStyled = styled.div<IProgressBarProps>`
    .WP-ProgressBar-filler {
        border-radius: inherit;
        transition: width 0.2s ease-in;
        position: relative;
        height: ${({ height }) => height + 'px' || '8px'};
        background: ${({ color, isMax }) => (isMax ? '#A4E982' : color || 'red')};
        opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

        &.dashed {
            &:before {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                animation: stripes-move ${({ isDisabled }) => (isDisabled ? 0 : 1.4)}s linear
                    infinite;
                border: 2px solid hsla(0, 0%, 100%, 0.5);
                border-radius: 10px;
                background-size: 22px 22px;
                background-image: linear-gradient(
                    135deg,
                    hsla(0, 0%, 100%, 0.15) 25%,
                    transparent 0,
                    transparent 50%,
                    hsla(0, 0%, 100%, 0.15) 0,
                    hsla(0, 0%, 100%, 0.15) 75%,
                    transparent 0,
                    transparent
                );
            }
        }

        @-webkit-keyframes stripes-move {
            0% {
                background-position: 22px 22px;
            }
            100% {
                background-position: 0 0;
            }
        }
        @keyframes stripes-move {
            0% {
                background-position: 22px 22px;
            }
            100% {
                background-position: 0 0;
            }
        }

        .WP-ProgressBar-Max {
            font-size: ${({ theme }) => theme.typography.size.p14.fontSize};
            font-weight: ${({ theme }) => theme.typography.weight.semiBold};
            color: ${({ theme }) => theme.palette.green[900]};
        }
    }
`;
