import { TAvatarPlayer } from 'src/@types';

import { services } from '../../services/fetch.service';
import { AppDispatch } from '../configureStore';
import { globalOp } from '../global';
import { avatarPlayerSlice } from './avatarPlayerSlice';

const setAvatarImage = (avatar: TAvatarPlayer) => {
    const { setCurrentAvatars } = avatarPlayerSlice.actions;
    const { setCurrentUserAvatar } = globalOp;

    return async (dispatch: AppDispatch) => {
        try {
            const { status } = await services.fetch({
                endPoint: `/v2/avatars/${avatar?.uuid}`,
                method: `${avatar?.uuid === 'reset' ? 'GET' : 'PUT'}`,
            });

            if (status === 200) {
                dispatch(setCurrentAvatars(avatar));
                dispatch(setCurrentUserAvatar(avatar));
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on set avatar image');
        }
    };
};

const getAvatars = () => {
    const { setAvatars, setAvatarsLoading } = avatarPlayerSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setAvatarsLoading(true));
        try {
            const { data } = await services.fetch({
                endPoint: `/v2/avatars`,
                method: 'GET',
            });

            dispatch(setAvatars(data));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on getting avatars');
        }
        dispatch(setAvatarsLoading(false));
    };
};

export const avatarPLayerOp = {
    setAvatarImage,
    getAvatars,
};
