import React, { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useTheme } from '@mui/material';

import { globalOp } from '../../../../store/global';

import { GAME_DOWNLOAD_URL } from '../../../../constants/api.constants';

import { attributionHook, tracker } from '../../../../configs/titan.config';

import { titanAnalyticsEvents } from '../../../../helpers/titanAnalytics';

import { Box } from '../../../core-ui';

import { AttributionHooks, XSollaDownloadHookAction, XSollaURLBuilder } from '@scopely/web-sdk-ua';

import { Button, Typography } from '../../../../stories';

const DownloadGameModal: FC = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const hookAction = useMemo(() => {
        return new XSollaDownloadHookAction(new XSollaURLBuilder(GAME_DOWNLOAD_URL ?? ''));
    }, []);

    const hook = useMemo(() => {
        /**this line has error because of protected variables of Class AttributionHooks(used from ts ignore and linter ignore )**/
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return new AttributionHooks(tracker as any).withDownload(hookAction);
    }, [hookAction]);

    const goToLink = (url = '') => {
        dispatch(globalOp.handleCloseModal());
        window.location.href = url;
        titanAnalyticsEvents.downloadiOSGameButton();
        attributionHook.download();
        hook.download();
    };

    return (
        <Box width={[1, 540]} pt={[7, 15]} pb={[7, 13]} px={[5, 8]}>
            <Box
                className="WP-Modal-Icon"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border={1}
                    borderRadius="50%"
                    borderColor={theme.palette.purple[100]}
                    width={120}
                    height={120}
                >
                    <Box
                        width={100}
                        height={100}
                        className="WP-Modal-DownLoadLink WP-Modal-Icon-Background"
                    />
                </Box>

                <Box textAlign="center" pt={6} pb={3}>
                    <Typography color={theme.palette.text[300]} fWeight="bold" variant="h5">
                        {t('modal.download.game.title')}
                    </Typography>
                </Box>
                <Box px={12} textAlign="center">
                    <Typography variant="p16" color={theme.palette.text[100]}>
                        {t('modal.download.game.description')}
                    </Typography>
                </Box>
            </Box>
            <Box pt={7} display={['block', 'flex']} justifyContent="center">
                <Box
                    display="flex"
                    alignItems="center"
                    flexDirection={['column', 'row']}
                    justifyContent="center"
                    mb={[3, 0]}
                    gap={3}
                >
                    <Button
                        variant="secondary"
                        onClick={() => dispatch(globalOp.handleCloseModal())}
                        label={t('modal.download.game.button.no')}
                    />

                    <Button
                        variant="primary"
                        onClick={() => goToLink(GAME_DOWNLOAD_URL)}
                        label={t('modal.download.game.button.yes')}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default DownloadGameModal;
