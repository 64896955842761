import { createSelector } from 'reselect';

import { RootState } from '../configureStore';

const calendarSelector = (state: RootState) => state.calendar;

const eventSelector = createSelector([calendarSelector], ({ events }) => events);
const loaderSelector = createSelector([calendarSelector], ({ isLoading }) => isLoading);

export const calendarSel = {
    eventSelector,
    loaderSelector,
};
