import { lazyWithRetry } from './helpers/lazyWithRetry';

const Dashboard = lazyWithRetry(() =>
    import(/* webpackChunkName: "pages/dashboard" */ './views/pages/Dashboard/Dashboard').then(
        ({ default: Dashboard }) => ({
            default: Dashboard,
        }),
    ),
);

const LeaderboardPage = lazyWithRetry(() =>
    import(
        /* webpackChunkName: "pages/leaderboard-page" */ './views/pages/Leaderboard/LeaderboardPage'
    ).then(({ default: LeaderboardPage }) => ({
        default: LeaderboardPage,
    })),
);

const LeaderboardTop500 = lazyWithRetry(() =>
    import(
        /* webpackChunkName: "pages/leaderboard-top-500" */ './views/pages/LeaderboardTop500/LeaderboardTop500'
    ).then(({ default: LeaderboardTop500 }) => ({
        default: LeaderboardTop500,
    })),
);

const SpecialOffers = lazyWithRetry(() =>
    import(
        /* webpackChunkName: "pages/special-offers" */ './views/pages/SpecialOffers/SpecialOffers'
    ).then(({ default: SpecialOffers }) => ({
        default: SpecialOffers,
    })),
);

const BeepCoinShop = lazyWithRetry(() =>
    import(
        /* webpackChunkName: "pages/beepcoin-offers" */ './views/pages/BeepcoinShop/BeepCoinShop'
    ).then(({ default: BeepCoinShop }) => ({
        default: BeepCoinShop,
    })),
);

const Events = lazyWithRetry(() =>
    import(/* webpackChunkName: "pages/events" */ './views/pages/Events/Events').then(
        ({ default: Events }) => ({
            default: Events,
        }),
    ),
);

const Calendar = lazyWithRetry(() =>
    import(/* webpackChunkName: "pages/calendar" */ './views/pages/Calendar/CalendarWrapper').then(
        ({ default: Calendar }) => ({
            default: Calendar,
        }),
    ),
);

const News = lazyWithRetry(() =>
    import(/* webpackChunkName: "pages/news" */ './views/pages/News/News').then(
        ({ default: News }) => ({
            default: News,
        }),
    ),
);

const NewsInPage = lazyWithRetry(() =>
    import(
        /* webpackChunkName: "pages/news-in-page" */ './views/pages/News/NewsInPage/NewsInPage'
    ).then(({ default: NewsInPage }) => ({
        default: NewsInPage,
    })),
);

const ToonGuide = lazyWithRetry(() =>
    import(
        /* webpackChunkName: "pages/toon-guide" */ './views/pages/ToonGuide/ToonGuideWrapper'
    ).then(({ default: ToonGuide }) => ({
        default: ToonGuide,
    })),
);

const ToonProfile = lazyWithRetry(() =>
    import(
        /* webpackChunkName: "pages/toon-profile" */ './views/pages/ToonProfile/ToonGuideProfileWrapper'
    ).then(({ default: ToonProfile }) => ({
        default: ToonProfile,
    })),
);

const APP_ROUTES = [
    {
        path: '/',
        component: Dashboard,
        exact: true,
    },
    {
        path: '/leaderboard',
        component: LeaderboardPage,
        exact: true,
    },
    {
        path: '/leaderboard/top/:id',
        component: LeaderboardTop500,
        exact: true,
    },
    {
        path: '/special-offers',
        component: SpecialOffers,
        exact: true,
    },
    {
        path: '/beepcoin-shop',
        component: BeepCoinShop,
        exact: true,
    },
    {
        path: '/events',
        component: Events,
        exact: true,
    },
    {
        path: '/calendar',
        component: Calendar,
        exact: true,
    },
    {
        path: '/news',
        component: News,
        exact: true,
    },
    {
        path: '/news/:year/:month/:day/:newsId',
        component: NewsInPage,
        exact: false,
    },
    {
        path: '/news/category/:id',
        component: NewsInPage,
        exact: false,
    },
    {
        path: '/calendar/:year/:month/:day/:calendarEventId',
        component: NewsInPage,
        exact: false,
    },
    {
        path: '/toon-guide',
        component: ToonGuide,
        exact: true,
    },
    {
        path: '/toon-profile/:toonId',
        component: ToonProfile,
        exact: true,
    },
    // {
    //     path: '/player/:playerId',
    //     component: ProfileWrapper,
    //     exact: true,
    // },
    // {
    //     path: '/alliance/:allianceId',
    //     component: AllianceWrapper,
    //     exact: true,
    // }
];

export { APP_ROUTES };
