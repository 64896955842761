export const LOGIN = 'login';
export const REWARD_PROCESS = 'reward-process';
export const PAYMENT_PROCESS = 'payment-process';
export const REWARD_SUCCESS = 'reward-success';
export const MILESTONE_REWARD_SUCCESS = 'milestone-reward-success';
export const PAYMENT_SUCCESS = 'payment-success';
export const CLAIM_BEEPCOIN_SUCCESS = 'payment-success';
export const PAYMENT_PROCESS_BEEPCOIN = 'payment-success-beepcoin';
export const NOT_ENOUGH_COIN = 'not-enough-coin';
export const NO_LINKED_ACCOUNT = 'no-linked-account';
export const DOWNLOAD_GAME = 'download-game';
export const BANNED_ACCOUNT = 'banned-account';
export const OFFER_NOT_AVAILABLE = 'offer-not-available';
export const AVATAR_PAYMENT_SUCCESS = 'avatar-payment-success';
export const PROMO_CODE_MODAL = 'promo-code-modal';
