import styled from 'styled-components';

export const HeaderStyled = styled.header`
    position: fixed;
    top: 0;
    right: 280px;
    left: 240px;
    padding: 12px 32px;
    z-index: ${({ theme }) => theme.zIndex.appBar};
    height: 62px;
    background-color: ${({ theme }) => theme.palette.background[200]};
    box-shadow: 0 0 8px ${({ theme }) => theme.palette.grey[900]}50;

    ${({ theme }) => theme.breakpoints.down('md')} {
        left: 0;
        right: 0;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        height: 60px;
        padding: 0 20px;
    }

    .global-search-container-wrapper {
        ${({ theme }) => theme.breakpoints.down('md')} {
            flex-grow: 1;
        }

        ${({ theme }) => theme.breakpoints.down('sm')} {
            flex-grow: 0;
        }
        .global-search-container {
            //position: absolute;
            ${({ theme }) => theme.breakpoints.down('md')} {
                &.show {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                }
            }
            ${({ theme }) => theme.breakpoints.down('sm')} {
                display: none;
                &.show {
                    top: 10px;
                }
            }

            .global-search-input {
                border-radius: 12px;
                width: 625px;
                background-color: ${({ theme }) => theme.palette.background[200]};
                padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(10)}
                    ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(12)};
                font-size: 14px;
                color: ${({ theme }) => theme.palette.grey[600]};
                background-color: ${({ theme }) => theme.palette.background[100]};

                ${({ theme }) => theme.breakpoints.down('xl')} {
                    width: 470px;
                }
                ${({ theme }) => theme.breakpoints.down('md')} {
                    width: 100%;
                }

                & + i {
                    color: ${({ theme }) => theme.palette.grey[600]};
                }

                &:focus {
                    color: ${({ theme }) => theme.palette.grey[600]};

                    & + i {
                        color: ${({ theme }) => theme.palette.grey[600]};
                    }
                }

                &::placeholder {
                    color: ${({ theme }) => theme.palette.grey[600]};
                    font-weight: ${({ theme }) => theme.typography.weight.regular};
                }
            }

            .backdrop-index {
                z-index: 1;
            }

            .make-empty-field {
                &:hover {
                    color: ${({ theme }) => theme.palette.grey[600]};
                }
            }

            .overlay {
                background-color: ${({ theme }) => theme.palette.grey['A200']};
                opacity: 0.2;
            }

            .global-search-result {
                position: absolute;
                top: 12px;
                left: 0;
                padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(5)}`};
                width: 625px;
                max-height: 500px;
                background-color: ${({ theme }) => theme.palette.background[200]};
                border-radius: 12px;

                ${({ theme }) => theme.breakpoints.down('xl')} {
                    width: 470px;
                }
                ${({ theme }) => theme.breakpoints.down('md')} {
                    width: 100%;
                }

                .global-search-result-wrapper {
                    margin-bottom: ${({ theme }) => theme.spacing(7)};
                    .global-search-result-title {
                        font-weight: 600;
                        padding-bottom: ${({ theme }) => theme.spacing(3)};
                        margin-bottom: ${({ theme }) => theme.spacing(6)};
                        border-bottom: 1px solid ${({ theme }) => theme.palette.border['400']};
                        text-decoration: none;
                    }
                    .global-search-result-list-container {
                        margin: 0;
                        padding: 0;

                        .global-search-result-item {
                            padding: 5px 20px;
                            margin: 4px -20px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            transition: background-color 200ms ease-out;
                            text-decoration: none;

                            &:hover {
                            }
                        }
                        .global-search-result-item-tag {
                            text-align: center;
                            width: 90px;
                            padding: 3px 8px;
                        }
                    }

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        z-index: -1;
                        height: 100%;
                    }
                    &:after {
                        transform: rotate(180deg);
                        top: auto;
                        bottom: 0;
                    }
                }
            }
        }
    }
`;
