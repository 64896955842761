import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { globalSlice } from '../store/global/globalSlice';

import { scopelyId } from '../configs/titan.config';

import { getSearchParams } from '../helpers/utils';

import { useEffectOnce } from './useEffectOnce';

export function useLogoutHook() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = getSearchParams(window.location.search);
    const { setModal } = globalSlice.actions;

    const handleLogout = async () => {
        if (params.get('state') && !params.get('code')) {
            const currentLocation = window.location.href;

            if (scopelyId.isLogoutUri(currentLocation)) {
                const previousLocation = scopelyId.previousLocation();

                if (previousLocation) navigate(previousLocation.pathname, { replace: true });

                const logoutResult = await scopelyId.logoutResult(currentLocation);

                if (!logoutResult.isSuccessful()) {
                    dispatch(
                        setModal({
                            open: true,
                            variant: 'error',
                            message: 'Logout error',
                        }),
                    );
                }
            }
            params.delete('state');

            navigate(
                {
                    search: params.toString(),
                },
                { replace: true },
            );
        }
    };

    useEffectOnce(() => {
        handleLogout();
    });
}
