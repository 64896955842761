import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { globalSel } from '../../../store/global';
import { localizationSel } from '../../../store/localization';

import { MAILCHIMP_API } from '../../../constants/api.constants';
import { SCOPELY_URLS } from '../../../constants/links.constants';

import { Box } from '../../core-ui';

import { Button, Typography } from '../../../stories';
import CustomCheckbox from './CustomCheckbox/CustomCheckbox';
import { MailChimpStyled } from './MailChimp.styled';

// const EMAIL_REGEX = /^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,3})+$/;

function Subscribe() {
    const [t] = useTranslation();
    const theme = useSelector(globalSel.themeSelector);
    const localizationLoading = useSelector(localizationSel.loadingSelector);

    const [emailInput, setEmailInput] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [emailCheckboxSelected, setEmailCheckboxSelected] = useState(false);

    const handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        if (name && name === 'EMAIL') {
            setEmailInput(value.trimStart());
        }
    };

    const setDefaultValues = () => {
        setSubmitted(true);
    };

    useEffect(() => {
        if (submitted && emailInput !== '') {
            setEmailInput('');
            setEmailCheckboxSelected(false);
            setSubmitted(false);
        }
        // eslint-disable-next-line
    }, [submitted]);

    const isDisable = emailInput && emailInput.length && emailCheckboxSelected;

    return (
        <MailChimpStyled>
            <Box id="mc_embed_signup" pb={4} pt={5} px={4} mb={15}>
                <form
                    action={MAILCHIMP_API.MAILCHIMP_URL}
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    onSubmit={setDefaultValues}
                    target="_blank"
                    noValidate
                >
                    <Box id="mergeRow-gdpr">
                        <Box py={1}>
                            <Typography
                                variant="p14"
                                fWeight="semiBold"
                                color={theme.palette.grey[900]}
                                loaderWidth={120}
                            >
                                {t('right.bar.mailchimp.confirm.your.subscription')}
                            </Typography>
                        </Box>

                        <Box py={1}>
                            <Typography
                                variant="p12"
                                fWeight="regular"
                                color={theme.palette.grey[600]}
                                loaderWidth={220}
                            >
                                {t('right.bar.mailchimp.sign.up.for.updates')}
                            </Typography>
                        </Box>
                        <Box py={1}>
                            <Box
                                component="input"
                                type="text"
                                id="mce-EMAIL"
                                name="EMAIL"
                                width="100%"
                                height={48}
                                border={1}
                                borderColor={theme.palette.border[50]}
                                borderRadius="12px"
                                px={3}
                                py={5}
                                value={emailInput}
                                className="WP-MailChimp-input"
                                placeholder={
                                    localizationLoading
                                        ? ''
                                        : t('right.bar.mailchimp.mail.input.placeholder')
                                }
                                autoComplete="off"
                                onChange={handleInputChange}
                            />
                        </Box>

                        <Box py={1}>
                            <Typography
                                variant="p12"
                                fWeight="regular"
                                color={theme.palette.grey[600]}
                                loaderWidth={220}
                            >
                                {t('right.bar.mailchimp.confirm.description')}
                            </Typography>

                            <Box py={5}>
                                <CustomCheckbox
                                    id={MAILCHIMP_API.CHECKBOX_ID}
                                    name={MAILCHIMP_API.CHECKBOX_NAME}
                                    checked={emailCheckboxSelected}
                                    checkboxName={t('right.bar.mailchimp.checkbox.label')}
                                    callback={() => {
                                        setEmailCheckboxSelected(!emailCheckboxSelected);
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box py={1}>
                        <Button
                            fullWidth
                            size="large"
                            variant="secondary"
                            label={t('right.bar.mailchimp.subscribe.button')}
                            isDisabled={!isDisable}
                            isLoading={localizationLoading}
                        />
                    </Box>
                    <Box py={1}>
                        <Typography
                            variant="p12"
                            fWeight="regular"
                            color={theme.palette.grey[600]}
                            loaderWidth={220}
                        >
                            {t('right.bar.mailchimp.unsubscribe.text')}{' '}
                            <a href={SCOPELY_URLS.PRIVACY_POLICY}>
                                {t('right.bar.mailchimp.privacy.policy')}
                            </a>{' '}
                            {t('right.bar.mailchimp.our')}{' '}
                            <a href={SCOPELY_URLS.TERMS}>{t('right.bar.mailchimp.terms')}</a>{' '}
                            {t('right.bar.mailchimp.of.service')}
                        </Typography>
                    </Box>
                </form>
            </Box>
        </MailChimpStyled>
    );
}

export default memo(Subscribe);
