import React from 'react';

import styled from 'styled-components';

interface ILeftSidebarStyled {
    className: string;
    ref: React.RefObject<HTMLDivElement>;
}
export const LeftSidebarStyled = styled.aside<ILeftSidebarStyled>`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 240px;
    overflow: auto;
    z-index: ${({ theme }) => theme.zIndex.drawer};
    transition: transform 200ms ease-out;
    background: ${({ theme }) => theme.palette.common.white};
    scrollbar-width: none;

    ${({ theme }) => theme.breakpoints.down('md')} {
        transform: translateX(-240px);
        background-color: ${({ theme }) => theme.palette.background[200]};
        &.show {
            transform: translateX(0);
        }
    }

    .WP-LeftBar-Game-Btn {
        background: ${({ theme }) => theme.palette.common.white};
        position: sticky;
        top: 0;
    }

    .app-logo {
        p {
            color: ${({ theme }) => theme.palette.grey[800]};
        }
    }

    a {
        color: ${({ theme }) => theme.palette.text[100]};
        border: unset;
        background-color: unset;
        padding: unset;
        font-size: 14px;
        font-family: Inter, serif;
        text-align: left !important;
        text-decoration: none;

        &:hover {
            background-color: unset;
            color: ${({ theme }) => theme.palette.text[200]};
        }
    }
`;
