import { createTheme } from '@mui/material';

import { lightPalette } from './palette/lightPalette';
import { typography } from './typography/typography';

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1280,
            lg: 1440,
            xl: 1920,
        },
    },
    palette: lightPalette,
    typography,
    spacing: 4,
    elevation: {
        100: '0 10px 20px rgba(20, 22, 68, 0.02)',
        200: '0 12px 24px rgba(20, 22, 68, 0.02)',
        300: '0 20px 30px rgba(20, 22, 68, 0.04)',
        400: '0 20px 30px rgba(20, 22, 68, 0.06)',
        500: '0 30px 40px -6px rgba(20, 22, 68, 0.04)',
        600: '0 30px 40px -10px rgba(20, 22, 68, 0.04)',
        700: '0 40px 40px -10px rgba(20, 22, 68, 0.04)',
        800: '0 40px 40px -10px rgba(20, 22, 68, 0.08)',
        900: '0 8px 8px rgba(0, 0, 0, 0.25)',
    },
    zIndex: {
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
});
