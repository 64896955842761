import React from 'react';
import { useSelector } from 'react-redux';

import Skeleton from '@mui/material/Skeleton';

import { globalSel } from '../../../../../store/global';

import { Box } from '../../../../core-ui';

import { Label } from '../../../../../stories';
import { EventItemStyled } from '../../EventItem/EventItem.styled';

function EventsListSkeleton() {
    const theme = useSelector(globalSel.themeSelector);

    return (
        <Box my={5} px={5} display="grid">
            <Box display="flex" justifyContent="space-between" pb={2}>
                <Box width={120} height={24}>
                    <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                </Box>
                <Label size="small" variant="orange" rounded label="--" />
            </Box>

            <EventItemStyled>
                <Box py={5} display="flex" borderBottom={1} bordercolor={theme.palette.grey[300]}>
                    <Box
                        borderRadius="4px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height={32}
                        width={32}
                    >
                        <Skeleton variant="circular" animation="wave" height={24} width={24} />
                    </Box>

                    <Box className="card-content">
                        <Box width={120} height={20}>
                            <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                        </Box>
                        <Box width={70} height={20}>
                            <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                        </Box>
                    </Box>
                </Box>
            </EventItemStyled>
        </Box>
    );
}

export default EventsListSkeleton;
