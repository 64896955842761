import React, { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ClickAwayListener } from '@mui/material';

import { globalSel } from '../../../store/global';
import { localizationSel } from '../../../store/localization';

import LoginWithFacebook from '../../components/CustomButtons/LoginWithFacebookButton/LoginWithFacebook';

import { Box } from '../../core-ui';

import { Button } from '../../../stories';
import LoginWithScopelyId from '../CustomButtons/LoginWithScopelyButton/LoginWithScopelyId';
import { LoginArrowStyled, LoginStyled, LoginButtonStyled } from './styles';

function Login() {
    const [isLoginModalOpened, setIsLoginModalOpen] = useState(false);
    const localizationLoading = useSelector(localizationSel.loadingSelector);
    const isLoadingScopely = useSelector(globalSel.isLoadingScopelySelector);
    const isLoadingFacebook = useSelector(globalSel.isLoadingFacebookSelector);

    const ref = useRef<HTMLDivElement | null>(null);
    const [t] = useTranslation();

    return (
        <ClickAwayListener onClickAway={() => setIsLoginModalOpen(false)}>
            <div>
                <Box mx={4} mt={5} position="relative">
                    <LoginButtonStyled>
                        <Button
                            variant="primary"
                            size="large"
                            onClick={() => setIsLoginModalOpen((prevState) => !prevState)}
                            fullWidth
                            isLoading={localizationLoading}
                            isProcessing={isLoadingScopely || isLoadingFacebook}
                            label={t('sign.in')}
                            className="loginBtn"
                            data-testid="login-btn"
                        />
                    </LoginButtonStyled>
                    {isLoginModalOpened ? (
                        <Box position="absolute">
                            <div ref={ref}>
                                <LoginStyled>
                                    <LoginArrowStyled />
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        flexDirection="column"
                                        justifyContent="center"
                                    >
                                        <Box width={240} pt={3} px={3}>
                                            <LoginWithScopelyId />
                                        </Box>
                                        <Box width={240} px={3} my={3}>
                                            <LoginWithFacebook />
                                        </Box>
                                    </Box>
                                </LoginStyled>
                            </div>
                        </Box>
                    ) : null}
                </Box>
            </div>
        </ClickAwayListener>
    );
}

export default memo(Login);
