import { IEventsResponse, IMilestoneReward } from '../../@types';

import { MILESTONE_REWARD_SUCCESS } from '../../constants/purchaseVariants';

import { services } from '../../services/fetch.service';
import { AppDispatch } from '../configureStore';
import { globalOp } from '../global';
import { eventsRBESlice } from './eventsRBESlice';

const setClaimLoading = (isLoading: boolean) => {
    const { setClaimLoading } = eventsRBESlice.actions;

    return (dispatch: AppDispatch) => dispatch(setClaimLoading(isLoading));
};

const getMilestones = () => {
    const { setEventsLoading, setActiveEvents } = eventsRBESlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setEventsLoading(true));

        try {
            const { data }: { data: IEventsResponse } = await services.fetch({
                endPoint: `/v2/events`,
                method: 'GET',
            });

            if (data) {
                dispatch(setActiveEvents(data));
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on getMilestones');
        }

        dispatch(setEventsLoading(false));
    };
};

const claimMilestone = (
    questId: string,
    milestone: {
        title: string;
        image?: string | null;
        rewards: IMilestoneReward[];
    },
) => {
    const { setClaimLoading } = eventsRBESlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setClaimLoading(questId));

        try {
            const { status } = await services.fetch({
                endPoint: `/v2/events/quests/claim/${questId}`,
                method: 'GET',
            });

            if (status === 200) {
                dispatch(
                    globalOp.handleOpenModal({
                        open: true,
                        variant: MILESTONE_REWARD_SUCCESS,
                        content: {
                            title: milestone.title,
                            rewards: milestone.rewards,
                            image: milestone.image,
                        },
                    }),
                );
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on milestoneClaim');

            dispatch(
                globalOp.handleOpenSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'Please try again later...',
                }),
            );
        }

        dispatch(setClaimLoading(''));
        dispatch(getMilestones());
    };
};

export const eventsRBEOp = { setClaimLoading, claimMilestone, getMilestones };
