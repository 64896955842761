import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { globalSel } from '../../../../../store/global';

import { Typography } from '../../../../../stories';

export interface IPlayerInfoProps {
    image: string;
    title: string;
    value: number | string;
    hasAmount?: boolean;
    isBeepcoin?: boolean;
}

function PlayerInfo({ image, title, value, hasAmount, isBeepcoin }: IPlayerInfoProps) {
    const theme = useSelector(globalSel.themeSelector);
    const imageSize = isBeepcoin ? 24 : 40;

    return (
        <Box display="flex" alignItems="center" pl={3}>
            <Box
                height={40}
                width={40}
                pt={2}
                borderRadius="50px"
                bgcolor={theme.palette.background[200]}
            >
                <Box component="img" src={image} width={imageSize} height={imageSize} alt="img" />

                {hasAmount && (
                    <Box
                        color={theme.palette.common.white}
                        fontWeight="bold"
                        position="relative"
                        fontSize="9px"
                        bottom="37px"
                    >
                        {value}
                    </Box>
                )}
            </Box>
            <Box display="block" pl={2} textAlign="start">
                <Typography
                    loaderWidth={50}
                    variant="p12"
                    fWeight="regular"
                    color={theme.palette.text[100]}
                >
                    {title}
                </Typography>
                <Typography
                    loaderWidth={50}
                    variant="p12"
                    fWeight="semiBold"
                    color={theme.palette.text[200]}
                >
                    {value}
                </Typography>
            </Box>
        </Box>
    );
}

export default PlayerInfo;
