import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { generateProgressDate, getFormattedTime } from '../../../helpers/utils';

import { Typography } from '../../../stories';
import { Box, Icon } from '../index';

export function Timer({ endDate, color, divideByDay = true }) {
    const [leftTime, setLeftTime] = useState(0);

    useEffect(() => {
        const calculateLeftTime = setInterval(() => {
            const now = new Date();
            const diff = endDate - now;

            setLeftTime(diff);
        }, 1000);

        return () => clearInterval(calculateLeftTime);
    }, [endDate]);

    const formattedTime = useMemo(() => {
        return !divideByDay
            ? generateProgressDate(endDate)
            : getFormattedTime(leftTime, divideByDay);
    }, [divideByDay, endDate, leftTime]);

    return formattedTime ? (
        <Box display="flex" alignItems="center" justifyContent="flex-end" width={1}>
            <Icon size={20} color={color} className="icon-interface-essential-clock" />
            <Box pl={1} width={1}>
                <Typography variant="p12" color={color}>
                    {formattedTime}
                </Typography>
            </Box>
        </Box>
    ) : null;
}

Timer.propTypes = {
    endDate: PropTypes.object,
    color: PropTypes.string,
    divideByDay: PropTypes.bool,
};
