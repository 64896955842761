/**
 * RRULE JS SDK
 * https://github.com/jakubroztocil/rrule
 */
import { rrulestr } from 'rrule';

export const formatDaysLeftRRule = (rule: string, startedDuration: number) => {
    if (!rule) {
        return {
            progressStartDate: 0,
            progressEndDate: 0,
        };
    }

    const rrule = rrulestr(rule);

    /**
     * Offers end date should be the last from startDates array (rrule.all())
     */
    let endDate;

    if (rrule.origOptions.until || rrule.origOptions.count) {
        const startDates = rrule.all();
        const lastStartDate = startDates[startDates.length - 1];

        if (lastStartDate) {
            endDate = startDates?.length ? lastStartDate.getTime() + startedDuration : 0;
        }
    } else {
        endDate = rrule.after(new Date(), true);
    }

    return {
        progressStartDate: rrule?.options?.dtstart.getTime() || 0,
        progressEndDate: endDate ? +endDate : 0,
    };
};
