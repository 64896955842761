import {
    API_ENV,
    CLIENT_ID,
    IDENTITY_PROVIDER_URL,
    TITAN_API_KEY,
    VOLUUM_OFFER_ID,
    VOLUUM_ORGANIC_CAMPAIGN_ID,
    APP_REDIRECT_URL,
} from '../constants/api.constants';

import {
    Settings,
    BrowserTracker,
    webRegistrationWithRetrocompatibility,
    BuildType,
} from '@scopely/web-sdk-analytics';
import { Config, OktaProviderConfig, ScopelyId } from '@scopely/web-sdk-scopelyid';
import { AttributionHooks, VoluumLandingHookAction, VoluumSettings } from '@scopely/web-sdk-ua';

import { getAnalyticsId } from '../bridge/analyticsId.bridge';

const debug = API_ENV !== 'prod';
const buildType = API_ENV === 'prod' ? BuildType.Production : BuildType.Debug;

const config = new Settings(TITAN_API_KEY, buildType)
    .withDebug(debug)
    .withFlushIntervalMillis(5000);

const retrocompatibleRegistration = webRegistrationWithRetrocompatibility(config, getAnalyticsId());
const tracker = new BrowserTracker(config);

tracker
    .register(retrocompatibleRegistration)
    .then((res) => {
        // eslint-disable-next-line no-console
        console.log(res, 'titan registered mmg mock release');
        window.trackerError = {
            error: false,
            message: '',
        };
    })
    .catch((e) => {
        window.trackerError = {
            error: true,
            message: e,
        };
    });

const Titan = {};

Titan.SendEvent = function (event) {
    if (event.name && event.parameters) {
        tracker.track(event.name, event.parameters);
    } else {
        // eslint-disable-next-line no-console
        console.error('Trying to send malformed event: ' + JSON.stringify(event));
    }
};

const attributionHook = new AttributionHooks(tracker).withUserLanding(
    new VoluumLandingHookAction(new VoluumSettings(VOLUUM_ORGANIC_CAMPAIGN_ID, VOLUUM_OFFER_ID)),
);

const scopelyIdConfig = new Config(
    new OktaProviderConfig(IDENTITY_PROVIDER_URL, CLIENT_ID, APP_REDIRECT_URL, APP_REDIRECT_URL),
    'https://heimdall.aprod.scopely.io',
);

const scopelyId = new ScopelyId(scopelyIdConfig, tracker);

export { Titan, tracker, scopelyId, attributionHook };
