import React, { memo, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { globalSel } from '../../../../store/global';
import { specialOffersSel } from '../../../../store/specialOffers';

import { getRewardItemName, getRewardsCount, getRewardsIcons } from '../../../../helpers/rewards';
import {
    getBeepcoinCount,
    getGuaranteedOneOfTheseItemsDescription,
    REWARD_TYPES,
} from '../../../../helpers/utils';

import { Box, Icon } from '../../../core-ui';

import beepCoinLogo from '../../../../assets/images/currency_beepcoin.png';
import { Typography, LazyImage } from '../../../../stories';
import InfoTooltip from './InfoTooltip';
import ModalContentInfo from './ModalContentInfo';
import { ModalContentItemStyled } from './ModalContentItem.styled';

interface IModalContentMobileProps {
    price: number;
    isBeepCoin: boolean;
}
function ModalContentMobile({ price, isBeepCoin }: IModalContentMobileProps) {
    const [t] = useTranslation();
    const theme = useSelector(globalSel.themeSelector);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const offerInfo = useSelector(specialOffersSel.shopItemInfoSelector);
    const dashboardBannerSettings = useSelector(globalSel.dashboardBannerSelector);

    const info = [
        {
            title: 'offers.card.guaranteedItems',
            type: REWARD_TYPES.guaranteed,
            data: offerInfo?.info?.guaranteedItems?.length ? offerInfo.info.guaranteedItems : null,
        },
        {
            title: 'offers.card.possibleItems',
            type: REWARD_TYPES.possible,
            data: offerInfo?.info?.possibleItems?.length ? offerInfo.info.possibleItems : null,
        },
        {
            title: 'offers.card.guaranteedOneOfTheseItems',
            type: REWARD_TYPES.guaranteedOneOfThese,
            data: offerInfo?.info?.guaranteedOneOfTheseItems?.length
                ? offerInfo.info.guaranteedOneOfTheseItems
                : null,
        },
    ];

    const handleClose = () => {
        if (isTooltipOpen) {
            setIsTooltipOpen(false);
        }
    };

    const showConversionRewards = useMemo(() => {
        return !isBeepCoin && dashboardBannerSettings?.beepcoinConversionRate;
    }, [dashboardBannerSettings?.beepcoinConversionRate, isBeepCoin]);

    return (
        <ModalContentItemStyled>
            <Box
                width={1}
                bgcolor={theme.palette.grey[100]}
                borderRadius="10px"
                pt={4}
                pl={5}
                pr={2}
            >
                <Box
                    width={1}
                    height={148}
                    pb={1}
                    onScroll={handleClose}
                    className="modal-item-content"
                >
                    {info.map((item) => {
                        if (!item.data) {
                            return null;
                        } else {
                            return (
                                <Box key={item.title} width={1}>
                                    <Box display="flex" alignItems="center">
                                        <Box pr={2}>
                                            <Typography
                                                variant="p14"
                                                fWeight="semiBold"
                                                color={theme.palette.grey[900]}
                                            >
                                                {t(item.title)}
                                            </Typography>
                                        </Box>
                                        {item.type !== REWARD_TYPES.guaranteed ? (
                                            <InfoTooltip
                                                arrow
                                                open={isTooltipOpen}
                                                leaveTouchDelay={7000}
                                                onClose={handleClose}
                                                PopperProps={{ disablePortal: true }}
                                                placement="top"
                                                title={
                                                    <ModalContentInfo
                                                        data={item.data?.map((infoElement) =>
                                                            getGuaranteedOneOfTheseItemsDescription(
                                                                infoElement,
                                                                t,
                                                            ),
                                                        )}
                                                    />
                                                }
                                            >
                                                <div>
                                                    <Box
                                                        component={Icon}
                                                        onClick={() =>
                                                            setIsTooltipOpen((prev) => !prev)
                                                        }
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="icon-info cursor-pointer"
                                                        bgcolor={theme.palette.grey[400]}
                                                        color={theme.palette.grey[500]}
                                                        borderRadius="50%"
                                                        width={18}
                                                        height={18}
                                                        size={8}
                                                    />
                                                </div>
                                            </InfoTooltip>
                                        ) : null}
                                    </Box>

                                    <Box width={1} pt={3}>
                                        {item.data.map((reward) => {
                                            const icon = getRewardsIcons(reward);
                                            const name = getRewardItemName(reward, t);
                                            const rewardsAmount = getRewardsCount(reward);

                                            return (
                                                <Box
                                                    key={reward.gameItemId}
                                                    width={1}
                                                    pb={3}
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    {icon ? (
                                                        <Box
                                                            component="img"
                                                            height={32}
                                                            mr={3}
                                                            src={icon}
                                                            alt="Icon"
                                                            draggable={false}
                                                        />
                                                    ) : null}

                                                    <Typography
                                                        variant="p12"
                                                        fWeight="semiBold"
                                                        color={theme.palette.grey[800]}
                                                    >
                                                        {`${rewardsAmount} ${name}`}
                                                    </Typography>
                                                </Box>
                                            );
                                        })}
                                    </Box>

                                    {showConversionRewards &&
                                    item.type === REWARD_TYPES.guaranteed &&
                                    price ? (
                                        <Box width={1} pb={3} display="flex" alignItems="center">
                                            <Box mr={3}>
                                                <LazyImage
                                                    height="32px"
                                                    src={beepCoinLogo}
                                                    alt="offer-reward-icon"
                                                    draggable={false}
                                                />
                                            </Box>

                                            <Typography
                                                variant="p12"
                                                fWeight="semiBold"
                                                color={theme.palette.grey[800]}
                                            >
                                                {`x${getBeepcoinCount(price)} Beepcoin`}
                                            </Typography>
                                        </Box>
                                    ) : null}
                                </Box>
                            );
                        }
                    })}
                </Box>
            </Box>
        </ModalContentItemStyled>
    );
}

export default memo(ModalContentMobile);
