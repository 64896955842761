import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { Typography } from '../../../../stories';
import { CustomCheckboxStyled } from './CustomCheckbox.styled';

function CustomCheckbox({ checkboxName, checked, callback, id, name }) {
    return (
        <CustomCheckboxStyled>
            <input
                id={id}
                name={name}
                type="checkbox"
                className="styled-checkbox"
                onChange={callback}
                checked={checked}
            />
            <label htmlFor={id} />
            <Typography loaderWidth={80} variant="p14">
                {checkboxName}
            </Typography>
        </CustomCheckboxStyled>
    );
}

CustomCheckbox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    checked: PropTypes.bool,
    checkboxName: PropTypes.string,
    callback: PropTypes.func,
};

export default memo(CustomCheckbox);
