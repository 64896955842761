import { TLeaderboardsInitialState } from 'src/@types';

export function initialSettingsState(): TLeaderboardsInitialState {
    return {
        /**
         ** DASHBOARD LEADERBOARDS
         */
        isGlobalLoading: false,
        globalPlayerLeaderboard: [],
        globalAllianceLeaderboard: [],
        widgetsLeaderboard: [],

        /**
         ** LEADERBOARDS TOP 500
         */
        top500Leaderboard: [],
        top500LeaderboardLoading: false,

        /**
         ** LEADERBOARDS PAGE
         */
        isTopPlayersLoading: true,
        topPlayersLeaderboard: null,

        isTopAllianceLoading: true,
        topAllianceLeaderboard: null,

        isTopEventsLoading: true,
        topEventsLeaderboard: null,

        topPlayersDate: '',
        topAllianceDate: '',
        topEventsDate: '',
    };
}
