export const ordinalSuffixOf = (i) => {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + 'st';
    }
    if (j === 2 && k !== 12) {
        return i + 'nd';
    }
    if (j === 3 && k !== 13) {
        return i + 'rd';
    }
    return i + 'th';
};

const toFixedWithoutRound = (num, round) => {
    const dotIndex = num.toString().indexOf('.');

    if (dotIndex === -1) {
        return num;
    } else {
        const i = dotIndex + 1;
        /**
         * (+ 1) for including dot position
         */
        return i ? Number(num.toString().substring(0, i + round)) : Number(num.toString());
    }
};

export const convertPriceUsingK = (value) => {
    const price = +value;
    switch (true) {
        case price >= 1000000:
            return toFixedWithoutRound(price / 1000000, 2) + 'mln';
        case price >= 1000:
            return toFixedWithoutRound(price / 1000, 1) + 'k';
        default:
            return price;
    }
};

export const discountPercentageCounter = (newPrice, oldPrice) => {
    const discountPercentage = 100 - (newPrice * 100) / oldPrice;
    return `${Math.floor(discountPercentage)}% OFF`;
};

export const isDiscountPercentageAvailable = (newPrice, oldPrice) => {
    if (!oldPrice) {
        return false;
    }
    const discountPercentage = 100 - (newPrice * 100) / oldPrice;
    /**
     * In that case when something goes wrong and the discount percentage show 0%.
     */
    return Math.floor(discountPercentage) !== 0;
};
