import { services } from '../../services/fetch.service';
import { AppDispatch } from '../configureStore';
import { localizationSlice } from './localizationSlice';

const getLocalization = (langID: string) => {
    const { setLocalization, setLoading } = localizationSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setLoading(true));

        try {
            const version = await services.fetch({
                endPoint: `/v2/translations/version/${langID}`,
                method: 'GET',
            });

            const { data } = await services.fetch({
                endPoint: `/v2/translations/${langID}`,
                method: 'GET',
                headers: {
                    'LOCALIZATION-VERSION': version?.data,
                },
            });

            dispatch(setLocalization(data));
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e, 'error on localization getting');
        }

        // Todo: fix the loading of localization with better way
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 140);
    };
};

export const localizationOp = {
    getLocalization,
};
