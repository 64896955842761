import { createSelector } from 'reselect';
import { TShopData, TSpecialOffersState } from 'src/@types';

import { RootState } from '../configureStore';

const specialOffersSelector = (state: RootState) => state.specialOffers;

const changeActiveTabSelector = createSelector(
    [specialOffersSelector],
    (specialOffers: TSpecialOffersState) => specialOffers.activeTab,
);

const shopItemsSelector = createSelector(
    [specialOffersSelector],
    ({ shopItems }: { shopItems: TShopData | null }) => shopItems,
);

const isLoadingSelector = createSelector(
    [specialOffersSelector],
    ({ isLoading }: { isLoading: boolean }) => isLoading,
);

const offerProcessingIdSelector = createSelector(
    [specialOffersSelector],
    ({ offerProcessingId }: { offerProcessingId: string }) => offerProcessingId,
);

const currenciesSelector = createSelector(
    [specialOffersSelector],
    (specialOffers: TSpecialOffersState) => specialOffers.currencies,
);

const shopItemInfoSelector = createSelector(
    [specialOffersSelector],
    (specialOffers: TSpecialOffersState) => specialOffers.shopItemInfo,
);

const offerModalDataSelector = createSelector(
    [specialOffersSelector],
    (specialOffers: TSpecialOffersState) => specialOffers.offerModalData,
);

const shopItemInfoLoaderSelector = createSelector(
    [specialOffersSelector],
    (specialOffers: TSpecialOffersState) => specialOffers.shopItemInfoLoader,
);

const bannerInfoListSelector = createSelector(
    [specialOffersSelector],
    (specialOffers: TSpecialOffersState) => specialOffers.bannerInfoList,
);

const bannerInfoListLoaderSelector = createSelector(
    [specialOffersSelector],
    (specialOffers: TSpecialOffersState) => specialOffers.bannerInfoListLoader,
);

export const specialOffersSel = {
    changeActiveTabSelector,
    specialOffersSelector,
    isLoadingSelector,
    shopItemsSelector,
    currenciesSelector,
    shopItemInfoSelector,
    shopItemInfoLoaderSelector,
    offerProcessingIdSelector,
    bannerInfoListSelector,
    bannerInfoListLoaderSelector,
    offerModalDataSelector,
};
