import React from 'react';

import { TBannerInfoList, TOfferModalData, TShopData } from 'src/@types';

import { PAYMENT_SUCCESS } from '../../constants/purchaseVariants';

import { services } from '../../services/fetch.service';
import { AppDispatch } from '../configureStore';
import { globalOp } from '../global';
import { specialOffers } from './specialOffers';

const getSpecialOffers = () => {
    const { setLoading, setStore } = specialOffers.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setLoading(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v1/offers/store`,
                method: 'GET',
            });

            const promoCodeOffers = await getPromoCodeOffers();

            const dataToSet: TShopData[] = [...data, ...promoCodeOffers];

            dispatch(setStore(dataToSet));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'Error on Offers getting');
        }

        dispatch(setLoading(false));
    };
};

const promoCodePurchase = (
    promoCode: string,
    eventId: string,
    setError: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const { setProcessingId } = specialOffers.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setProcessingId(eventId));

        try {
            await services.fetch({
                endPoint: `/v2/store/promoCodeOffers/claim/${eventId}`,
                method: 'POST',
                body: { promoCode },
            });

            dispatch(
                globalOp.handleOpenModal({
                    open: true,
                    variant: PAYMENT_SUCCESS,
                }),
            );
        } catch (err) {
            setError(true);
            // eslint-disable-next-line no-console
            console.error(err, 'Error on Promo purchase');
        }

        dispatch(setProcessingId(''));
    };
};

const getPromoCodeOffers = async (isLoggedOut = false) => {
    let promoCodeOffers: TShopData[] = [];

    const endPoint = isLoggedOut ? '/v2/store/promoCodeOffers/public' : `/v2/store/promoCodeOffers`;

    const { data, status } = await services.fetch({
        endPoint,
        method: 'GET',
    });

    if (status === 200) {
        promoCodeOffers = data;
    }

    return promoCodeOffers;
};

const getSpecialOffersLoggedOut = () => {
    const { setLoading, setStore } = specialOffers.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setLoading(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v1/offers/store/public`,
                method: 'GET',
            });

            const promoCodeOffers = await getPromoCodeOffers(true);

            const dataToSet: TShopData[] = [...data, ...promoCodeOffers];

            dispatch(setStore(dataToSet));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'Error on logout offers getting');
        }

        dispatch(setLoading(false));
    };
};

// const getCurrencies = () => {
//     //TODO - check the specialOffers.actions | there is no any setter for currency loading and set currencies
//     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//     //@ts-ignore
//     // const { setCurrencies, setCurrencyLoading }: any = specialOffers.actions;
//
//     return async (dispatch: AppDispatch) => {
//         // dispatch(setCurrencyLoading(true));
//
//         try {
//             const { data } = await services.fetch({
//                 endPoint: `/v1/currencies`,
//                 method: 'GET',
//             });
//
//             // dispatch(setCurrencies(data));
//         } catch (err) {
//             // eslint-disable-next-line no-console
//             console.error(err, 'error on currency get');
//         }
//
//         // dispatch(setCurrencyLoading(false));
//     };
// };

const getOfferInfo = (id: string) => {
    const { setOfferInfoLoader, setOfferInfoData } = specialOffers.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setOfferInfoLoader(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v1/product-reward/${id}`,
                method: 'GET',
            });

            dispatch(setOfferInfoData(data));
            dispatch(setOfferInfoLoader(false));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on offer info get');
            dispatch(setOfferInfoLoader(false));
        }
    };
};

const getOfferInfoLoggedOut = (id: string) => {
    const { setOfferInfoLoader, setOfferInfoData } = specialOffers.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setOfferInfoLoader(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v1/product-reward/public/${id}`,
                method: 'GET',
            });

            dispatch(setOfferInfoData(data));
            dispatch(setOfferInfoLoader(false));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on offer info get');
            dispatch(setOfferInfoLoader(false));
        }
    };
};

const getProductInfoList = (id: string) => {
    const { setProductInfoList, setProductInfoListLoader } = specialOffers.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setProductInfoListLoader(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v1/product-reward/${id}`,
                method: 'GET',
            });

            dispatch(setProductInfoList(data));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on offer info get');
        }
        dispatch(setProductInfoListLoader(false));
    };
};

const getProductInfoListLoggedOut = (id: string) => {
    const { setProductInfoList, setProductInfoListLoader } = specialOffers.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setProductInfoListLoader(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v1/product-reward/public/${id}`,
                method: 'GET',
            });

            dispatch(setProductInfoList(data));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on offer info get');
        }
        dispatch(setProductInfoListLoader(false));
    };
};

const getXsollaAccessTokenForOrderAsync = (eventId: string, deviceToken: string) => {
    return services.fetch({
        endPoint: `/v2/purchase/${eventId}`,
        method: 'GET',
        headers: {
            'device-token': deviceToken,
        },
    });
};

const setActiveTab = (position: string) => {
    const { setActiveTab } = specialOffers.actions;

    return (dispatch: AppDispatch) => dispatch(setActiveTab(position));
};

const setProcessingId = (eventId: string) => {
    const { setProcessingId } = specialOffers.actions;

    return (dispatch: AppDispatch) => dispatch(setProcessingId(eventId));
};

const setOfferModalData = (data: TOfferModalData) => {
    const { setOfferModalData } = specialOffers.actions;

    return (dispatch: AppDispatch) => dispatch(setOfferModalData(data));
};

const getMediumCardInfo = (
    id: string,
    isLoggedIn: boolean,
    setOfferInfo: (data: TBannerInfoList | null) => void,
) => {
    return async () => {
        try {
            const { data } = await services.fetch({
                endPoint: isLoggedIn
                    ? `/v1/product-reward/${id}`
                    : `/v1/product-reward/public/${id}`,
                method: 'GET',
            });
            if (data) {
                return setOfferInfo(data);
            }
        } catch (err: unknown) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on offer info get');
        }
    };
};

export const specialOffersOp = {
    getSpecialOffers,
    setActiveTab,
    // getCurrencies,
    getOfferInfo,
    getSpecialOffersLoggedOut,
    getXsollaAccessTokenForOrderAsync,
    setProcessingId,
    getProductInfoList,
    getOfferInfoLoggedOut,
    getProductInfoListLoggedOut,
    setOfferModalData,
    getMediumCardInfo,
    promoCodePurchase,
};
