import { createSlice } from '@reduxjs/toolkit';

import { initialPlayerProfileState } from './initialState';

export const playerProfileSlice = createSlice({
    name: 'playerProfile',
    initialState: initialPlayerProfileState(),
    reducers: {
        setLoading(state, action) {
            state.isLoadingSelector = action.payload;
        },
        setPlayerProfile(state, action) {
            state.playerProfile = action.payload;
        },
    },
});
