import styled from 'styled-components';

const LOGIN_POPUP_STYLES = {
    width: 248,
    arrowWidth: 15,
};

export const LoginButtonStyled = styled.div`
    .loginBtn {
        background-color: ${({ theme }) => theme.palette.scopely.primary};
        border-radius: 0;
    }
    .loginBtn:hover {
        background-color: ${({ theme }) => theme.palette.scopely.hover};
    }
`;

export const LoginStyled = styled.div`
    width: ${LOGIN_POPUP_STYLES.width}px;
    position: absolute;
    top: 15px;
    z-index: 20;
    border: 1px solid ${({ theme }) => theme.palette.grey[200]};
    border-radius: 12px;
    box-shadow: ${({ theme }) => theme.elevation[400]};
    background: ${({ theme }) => theme.palette.common.white};
`;

export const LoginArrowStyled = styled.div`
    width: 15px;
    height: 15px;

    position: absolute;
    right: calc((${LOGIN_POPUP_STYLES.width}px - ${LOGIN_POPUP_STYLES.arrowWidth}px) / 2);
    top: calc(-${LOGIN_POPUP_STYLES.arrowWidth}px / 2);

    transform: rotate(45deg);
    z-index: 30;

    background-color: ${({ theme }) => theme.palette.common.white};
    border-left: 1px solid ${({ theme }) => theme.palette.grey[200]};
    border-top: 1px solid ${({ theme }) => theme.palette.grey[200]};
`;
