import {
    TCalculation,
    TCharacterStats,
    TFilterItems,
    TSkills,
    TToonGuide,
    TToonGuideState,
    TToonLevelUps,
    TToonProfile,
    TTuneup45,
} from 'src/@types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialToonGuideState } from './initialState';

export const toonGuidesSlice = createSlice({
    name: 'toonGuide',
    initialState: initialToonGuideState(),
    reducers: {
        setLoading(state: TToonGuideState, action: PayloadAction<boolean>) {
            state.isToonGuidesLoading = action.payload;
        },
        setToonLevelUpsLoader(state: TToonGuideState, action: PayloadAction<boolean>) {
            state.isToonLevelUpsLoading = action.payload;
        },
        setTuneup45Loader(state: TToonGuideState, action: PayloadAction<boolean>) {
            state.isTuneup45Loading = action.payload;
        },
        setToonGuides(state: TToonGuideState, action: PayloadAction<TToonGuide | null>) {
            state.toonGuide = action.payload;
        },
        setToonProfile(state: TToonGuideState, action: PayloadAction<TToonProfile | null>) {
            state.toonProfile = action.payload;
        },
        setToonGuideProfileLoader(state, action: PayloadAction<boolean>) {
            state.toonGuideProfileLoader = action.payload;
        },
        setFilterLoading(state: TToonGuideState, action: PayloadAction<boolean>) {
            state.isFilterItemLoading = action.payload;
        },
        setToonGuidesFilterItems(
            state: TToonGuideState,
            action: PayloadAction<TFilterItems | null>,
        ) {
            state.filterItems = action.payload;
        },
        setToonLevelUps(state: TToonGuideState, action: PayloadAction<TToonLevelUps | null>) {
            state.toonLevelUps = action.payload;
        },
        setTuneup45(state, action: PayloadAction<TTuneup45 | null>) {
            state.tuneup45 = action.payload;
        },
        setCharacterStats(
            state: TToonGuideState,
            action: PayloadAction<Array<Array<TCharacterStats>> | null>,
        ) {
            state.characterStats = action.payload;
        },
        setCalculation(state: TToonGuideState, action: PayloadAction<TCalculation | null>) {
            state.calculation = action.payload;
        },
        setSkills(state: TToonGuideState, action: PayloadAction<Array<TSkills> | null>) {
            state.skills = action.payload;
        },
    },
});
