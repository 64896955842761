import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import store from './store/configureStore';

import { DatDogEnvKeys } from './constants/api.constants';

import { datadogRum } from '@datadog/browser-rum';

import App from './App';
import * as serviceWorker from './serviceWorker';

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

if (!isLocalhost) {
    datadogRum.init({
        applicationId: DatDogEnvKeys?.applicationId || '',
        clientToken: DatDogEnvKeys?.clientToken || '',
        site: 'datadoghq.com',
        service: DatDogEnvKeys?.service || '',
        env: DatDogEnvKeys?.env || '',
        sampleRate: 100,
        trackInteractions: true,
    });
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);

serviceWorker.register();
