import React from 'react';

import styled from 'styled-components';

import { ITypography } from './index';

const fonts = {
    rubik: 'Rubik',
    inter: 'Inter',
    riffic: 'Riffic',
};
export interface ITypographyStyled extends ITypography {
    as?: React.ElementType;
}

export const TypographyStyled = styled.div<ITypographyStyled>`
    line-height: ${({ lineHeight }) => lineHeight};
    font-size: ${({ fontSize }) => fontSize};
    text-align: ${({ textAlign }) => textAlign};
    color: ${({ color }) => color};
    font-family: ${({ fontFamily }) => fonts[fontFamily ?? 'inter']};

    font-weight: ${({ fWeight }) => fWeight};
`;
