import { useDispatch, useSelector } from 'react-redux';

import { globalOp, globalSel } from '../store/global';
import { specialOffersOp } from '../store/specialOffers';

import { PAYMENT_SUCCESS } from '../constants/purchaseVariants';

import { removeSWCacheStorage } from '../helpers/localStorage';
import { titanAnalyticsEvents } from '../helpers/titanAnalytics';

import { useEffectOnce } from './useEffectOnce';
import { useScrollBlock } from './useScrollBlock.hook';

export function useXsollaLoadHook() {
    const dispatch = useDispatch();
    const [blockScroll, allowScroll] = useScrollBlock();
    const currentUser = useSelector(globalSel.currentUserSelector);

    const handleXsollaLoaded = () => {
        window.XPayStationWidget.on(
            window.XPayStationWidget.eventTypes.STATUS_DONE,
            (event, res) => {
                if (res?.paymentInfo?.status === 'done') {
                    removeSWCacheStorage();

                    dispatch(specialOffersOp.getSpecialOffers());
                    dispatch(globalOp.getUserBalance());

                    dispatch(specialOffersOp.setProcessingId(''));
                    dispatch(
                        globalOp.handleOpenModal({
                            open: true,
                            variant: PAYMENT_SUCCESS,
                        }),
                    );
                }
            },
        );

        window.XPayStationWidget.on(
            window.XPayStationWidget.eventTypes.STATUS_TROUBLED,
            function () {
                dispatch(specialOffersOp.setProcessingId(''));

                titanAnalyticsEvents.xsolla_payment({
                    error: 'status troubled',
                    user_id: currentUser?.playerProfile?.id,
                });
            },
        );

        window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.CLOSE, function () {
            allowScroll();
            dispatch(specialOffersOp.setProcessingId(''));
        });

        window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.OPEN, function () {
            blockScroll();
        });
    };

    useEffectOnce(() => {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = '//cdn.xsolla.net/embed/paystation/1.0.7/widget.min.js';
        s.onload = handleXsollaLoaded;
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(s);
    });
}
