import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material';

import { globalSel } from '../../../../../store/global';

import UserImage from '../../../../components/UserImage/UserImage';

import { Box } from '../../../../core-ui';

import userPhoto from '../../../../../assets/images/UserDefaultAvatar.png';
import { Typography } from '../../../../../stories';
import { IRightSidebarProps } from '../../RightSidebar';

function PlayerAvatar({ setOpenRightSidebar }: IRightSidebarProps) {
    const [t] = useTranslation();
    const theme = useTheme();
    const user = useSelector(globalSel.currentUserSelector);

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="center">
                <Typography
                    variant="p16"
                    loaderWidth={100}
                    fWeight="semiBold"
                    color={theme.palette.text[200]}
                >
                    {t('right.bar.player.profile.title')}
                </Typography>
            </Box>

            <Box width={100} height={100} mx="auto" mt={10} mb={5}>
                <UserImage
                    imageSrc={userPhoto}
                    setOpenRightSidebar={setOpenRightSidebar}
                    mainBorderColor={theme.palette.border[300]}
                    secondaryBorderColor={theme.palette.border[200]}
                />
            </Box>

            <Box display="flex" alignItems="center" justifyContent="center">
                <Typography
                    variant="p16"
                    loaderWidth={180}
                    fWeight="semiBold"
                    color={theme.palette.text[200]}
                >
                    {user?.playerProfile?.name}
                </Typography>
            </Box>
        </>
    );
}

export default PlayerAvatar;
