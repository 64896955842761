import { TNewsState } from 'src/@types';

export function initialNewsState(): TNewsState {
    return {
        isLoading: true,
        loadMoreLoading: true,
        news: [],
        page: 1,
        loadMore: true,
        activeNews: null,
    };
}
