import styled from 'styled-components';

import { formHelperTextClasses, inputLabelClasses, outlinedInputClasses } from '@mui/material';

import { hexToRgba } from '../../../helpers/utils';

import webCoins_notEnough from '../../../assets/images/currency_beepcoin.png';
import reward_progress from '../../../assets/images/medal_popup.svg';
import DownloadLink from '../../../assets/images/modals/DownloadLink.png';
import popup_facebook from '../../../assets/images/modals/login_icon.svg';
import Modal_BannedAccount from '../../../assets/images/modals/Modal_BannedAccount.svg';
import no_linked_account from '../../../assets/images/modals/no_linked_account.svg';
import OfferNotAvailable from '../../../assets/images/modals/OfferNotAvailPNG.png';
import payment_reward_success from '../../../assets/images/party_popup.svg';
import payment_progress from '../../../assets/images/time_popup.svg';

export const ModalStyled = styled.div`
    .WP-Special-Offer-Popup-Header {
        background: linear-gradient(180deg, #8274e3 0%, #9283fa 51.04%, #8274e3 100%);
    }

    .WP-OfferCardInfo-close-button {
        color: ${({ theme }) => theme.palette.grey[600]};
        box-shadow: ${({ theme }) => theme.elevation[400]};
    }

    .WP-Modal-loading {
        circle {
            stroke: ${({ theme }) => theme.palette.purple[600]};
        }
    }

    .WP-Modal-Icon-Background {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.palette.purple[100]};
        background-position: center;
        background-repeat: no-repeat;

        :before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url(${payment_reward_success});
            background-repeat: no-repeat;
            background-position: center;
            filter: drop-shadow(
                0 15px 20px ${({ theme }) => hexToRgba(theme.palette.purple[600], 0.3)}
            );
        }
    }

    .WP-Modal-loading {
        circle {
            stroke-width: 1;
        }
    }

    .WP-Modal-Login-Icon {
        position: relative;

        :before {
            background-image: url(${popup_facebook});
        }
    }
    .WP-Modal-No_Linked_Account {
        position: relative;

        :before {
            background-image: url(${no_linked_account});
        }
    }

    .WP-Modal-Offer-Not-Available {
        position: relative;

        :before {
            background-image: url(${OfferNotAvailable});
        }
    }

    .WP-Modal-Modal-BannedAccount {
        position: relative;

        :before {
            background-image: url(${Modal_BannedAccount});
        }
    }
    .WP-Modal-DownLoadLink {
        position: relative;

        :before {
            top: 10px;
            background-image: url(${DownloadLink});
        }
    }

    .WP-Modal-NotEnough-Icon {
        position: relative;

        :before {
            background-image: url(${webCoins_notEnough});
        }
    }

    .WP-Modal-Reward-Process {
        :before {
            background-image: url(${reward_progress});
        }
    }

    .WP-Modal-Payment-Process {
        :before {
            background-image: url(${payment_progress});
        }
    }

    .WP-Modal-Success {
        :before {
            background-image: url(${payment_reward_success});
        }
    }

    .${outlinedInputClasses.root} {
        border-radius: 12px;

        &:hover {
            .${outlinedInputClasses.notchedOutline} {
                border-color: ${({ theme }) => theme.palette.primary.main};
            }
        }
    }

    .${outlinedInputClasses.notchedOutline} {
        border-color: ${({ theme }) => theme.palette.grey[400]};

        span {
            padding: 0;
        }
    }

    .${formHelperTextClasses.error} {
        margin-left: 0;

        .${outlinedInputClasses.notchedOutline} {
            border-color: #d32f2f !important;
        }

        &.${formHelperTextClasses.root} {
            font-size: 10px;
        }
    }

    .${formHelperTextClasses.focused} {
        .${outlinedInputClasses.notchedOutline} {
            border-color: ${({ theme }) => theme.palette.primary.main};
        }
    }

    .${outlinedInputClasses.input} {
        min-width: 200px;

        padding: 14px 20px;
    }

    .${inputLabelClasses.root} {
        font-size: 12px;
        font-family: 'Inter', serif;
        font-weight: 400;
        line-height: 20px;
        color: ${({ theme }) => theme.palette.grey[500]};
    }
`;
