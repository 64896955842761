import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useMediaQuery, useTheme } from '@mui/material';

import { globalOp, globalSel } from '../../../../store/global';

import { LOGIN_TYPES } from '../../../../constants/api.constants';

import { scopelyId } from '../../../../configs/titan.config';

import { getStorageItem, setStorageItem } from '../../../../helpers/localStorage';
import { uuid } from '../../../../helpers/utils';

import { Box, Icon } from '../../../core-ui';

import { Typography } from '../../../../stories';
import LoadAnimateButton from '../../LoadAnimateButton/LoadAnimateButton';
import { LoginWithScopelyIdStyled } from './LoginWithScopelyId.styled';

const LoginWithScopelyId = () => {
    const theme = useTheme();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const isLoadingScopely = useSelector(globalSel.isLoadingScopelySelector);
    const mobileQuery = useMediaQuery(theme.breakpoints.down('sm'));
    const loggedInType = getStorageItem('loginProcessing');
    const isLoading = useSelector(globalSel.isLoadingFacebookSelector);

    const redirectToLogin = (state: string) => {
        dispatch(globalOp.handleScopelyLoading(true));
        scopelyId.login(state);
    };

    const handleRedirectToLogin = () => {
        setStorageItem('loginProcessing', LOGIN_TYPES.scopely);
        redirectToLogin(uuid());
    };

    return (
        <LoginWithScopelyIdStyled data-testid="Scopely-btn">
            <Box
                width={1}
                className={
                    isLoading && loggedInType === LOGIN_TYPES.facebook
                        ? 'disable-events'
                        : 'cursor-pointer'
                }
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className="WP-LoginWithScopelyID-button cursor-pointer"
                    border="none"
                    color={theme.palette.common.white}
                    height={48}
                    width="100%"
                    onClick={handleRedirectToLogin}
                >
                    {isLoadingScopely && loggedInType === LOGIN_TYPES.scopely ? (
                        <LoadAnimateButton hasText bodySize={mobileQuery ? 'small' : 'medium'} />
                    ) : (
                        <Box width={1} display="flex" alignItems="center" justifyContent="center">
                            <Icon className="icon-Scopely-logo-new" size={24} />
                            <Box pl={2}>
                                <Typography loaderWidth={120} variant="p12" fWeight="semiBold">
                                    {t('login.with.scopely')}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </LoginWithScopelyIdStyled>
    );
};

export default LoginWithScopelyId;
