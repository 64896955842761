import { TFunction } from 'react-i18next';

import { IOfferReward, TRewardCategory } from '../@types';

import { GAME_S3_BUCKET_URL } from '../constants/api.constants';
import { REWARD_CATEGORIES } from '../constants/rewards';

import { convertPriceUsingK } from '../views/components/Alliance/AllianceTopPlayers/PlayerCard/helpers';

export const getRewardImageName = (reward: IOfferReward) => {
    const { characterPiece, token, potion, accountExperience, gadgetDrop } = reward;

    switch (true) {
        case !!characterPiece?.[0]:
            return characterPiece ? characterPiece[0]?.name : '';
        case !!token?.[0]:
            return token[0]?.name ?? '';
        case !!potion?.[0]:
            return potion[0]?.name ?? '';
        case !!accountExperience?.[0]:
            return accountExperience ? accountExperience[0]?.name : '';
        case !!gadgetDrop?.[0]:
            return gadgetDrop ? gadgetDrop[0]?.name : '';
        default:
            return '';
    }
};

export const getRewardsIcons = (reward: IOfferReward) => {
    /**
     * Guaranteed rewards doesn't have an icon and type is array [guaranteedOneOfThese]
     */
    if (Array.isArray(reward)) return '';
    if (!reward) return '';
    const { icon, iconUri } = reward;

    if (icon && iconUri) {
        const splitIconUrl = iconUri.split('/');
        const rewardIconUrl = splitIconUrl[splitIconUrl.length - 1];

        return `${GAME_S3_BUCKET_URL}/MaterialIcon/${rewardIconUrl}`;
    }

    const imageName = getRewardImageName(reward);

    return `${GAME_S3_BUCKET_URL}/MaterialIcon/${imageName}.png`;
};

export const getRewardItemName = (item: IOfferReward, t: TFunction): string => {
    if (!item) return '';
    if (item?.descriptionKey) return t(item?.descriptionKey);
    /**
     * Getting item name, using characterPiece || token[0] || potion[0] etc...
     * it must include localizationKeysMap?.[1]?.[1] for name
     */
    let name = '';
    REWARD_CATEGORIES.forEach((category: TRewardCategory) => {
        const rewardName = item[category]?.[0]?.localizationKeysMap?.['1']?.['1'];

        if (rewardName) name = rewardName;

        return name;
    });

    return t(name);
};

export const getRewardsCount = (reward: IOfferReward) => {
    if (!reward) return '';

    if (reward.max === reward.min) return `x${convertPriceUsingK(reward.max)} `;

    return `x${convertPriceUsingK(reward.min)}-${convertPriceUsingK(reward.max)} `;
};
