import React, { memo, useState } from 'react';

import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { IAvatarNameProps } from '../../../@types';

import { Box, Icon } from '../../../views/core-ui';

import { LazyImage } from '../../index';
import Typography from '../Typography';
import { AvatarNameStyled } from './AvatarName.styled';

const AvatarName: React.FC<IAvatarNameProps> = ({
    name = 'Avatar name',
    subName,
    image = '',
    isPlayer = true,
    borderRadius = '50%',
    iconSize = 34,
    isStory = false,
}) => {
    const theme = useTheme();
    const [error, setError] = useState(false);
    const customXS = useMediaQuery('(min-width:375px)');

    return (
        <AvatarNameStyled>
            <Box display="flex" alignItems="center">
                {!image || error ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor={theme.palette.background[100]}
                        width={34}
                        height={34}
                        mr={3}
                        borderRadius={borderRadius}
                    >
                        <Icon
                            size={iconSize}
                            color={theme.palette.text[100]}
                            className={isPlayer ? 'icon-users-1' : 'icon-user-group'}
                        />
                    </Box>
                ) : (
                    <Box
                        className="WP-AvatarName-avatar"
                        minWidth={34}
                        width={34}
                        height={34}
                        display="inline-block"
                        mr={3}
                        borderRadius={borderRadius}
                        onError={() => setError(true)}
                        alt="avatar"
                    >
                        <LazyImage
                            src={image}
                            width="100%"
                            height="100%"
                            alt="avatar"
                            draggable={false}
                            borderRadius={borderRadius}
                        />
                    </Box>
                )}
                <Box>
                    <Box maxWidth={customXS ? 160 : 90}>
                        <Typography
                            variant="p12"
                            color={theme.palette.grey[900]}
                            loaderWidth={60}
                            className="text-truncate WP-AvatarName-name"
                            isStory={isStory}
                        >
                            {name}
                        </Typography>
                    </Box>

                    {subName && (
                        <Typography
                            variant="p12"
                            fWeight="medium"
                            color={theme.palette.grey[600]}
                            loaderWidth={60}
                            className={`${customXS ? 'WP-AvatarName-subname' : ''}`}
                            isStory={isStory}
                        >
                            {subName}
                        </Typography>
                    )}
                </Box>
            </Box>
        </AvatarNameStyled>
    );
};
export default memo(AvatarName);
