import all from '../assets/images/all.png';
import artist from '../assets/images/artist.png';
import athlete from '../assets/images/athlete.png';
import attacker from '../assets/images/Attacker.png';
import avalooney from '../assets/images/avalooney.png';
import bird from '../assets/images/bird.png';
import calendar from '../assets/images/calendar.png';
import citizen from '../assets/images/citizen.png';
import city from '../assets/images/City.png';
import cop from '../assets/images/cop.png';
import defender from '../assets/images/Defender.png';
import desert from '../assets/images/Desert.png';
import duck from '../assets/images/duck.png';
import explorel from '../assets/images/explorel.png';
import human from '../assets/images/human.png';
import locked from '../assets/images/locked.png';
import medal from '../assets/images/medal.png';
import monster from '../assets/images/monster.png';
import pig from '../assets/images/pig.png';
import rabbit from '../assets/images/rabbit.png';
import space from '../assets/images/Space.png';
import summit from '../assets/images/Summit.png';
import support from '../assets/images/Support.png';
import tasmania from '../assets/images/tasmania.png';
import BugsBunny from '../assets/images/TopFive/BugsBunny.png';
import Eagle from '../assets/images/TopFive/Eagle.png';
import MarvinTheMartian from '../assets/images/TopFive/MarvinTheMartian.png';
import TasmanianDevil from '../assets/images/TopFive/TasmanianDevil.png';
import TweetyBird from '../assets/images/TopFive/TweetyBird.png';
import town from '../assets/images/Town.png';
import AllianceImage from '../assets/images/UserWithinAlliance.png';
import wbStudio from '../assets/images/wb_studio.png';

const _PlayerInfoBannerMock = {
    league: {
        place: 56,
    },
    mainInfo: [
        {
            title: 'Collection Power',
            value: '12.2 M',
            icon: 'icon-hexagon-fill-1',
        },
        {
            title: 'Collection Size',
            value: '3,14 Years',
            icon: 'icon-countdown',
        },
        {
            title: 'Time Playing',
            value: '88',
            icon: 'icon-users-fill-1',
        },

        {
            title: 'Consecutive Login',
            value: '42 Days',
            icon: 'icon-calendar-alt',
        },
    ],
    secondaryInfo: [
        {
            title: 'Crates Stolen (90 Days)',
            value: '1,220',
            icon: 'icon-verified',
        },
        {
            title: '1st place Arena Wins (90 Days)',
            value: '1,380',
            icon: 'icon-medal-fill',
        },
        {
            title: '1st place Champion  Arena Wins  (90 Days)',
            value: '482',
            icon: 'icon-trophy',
        },
        {
            title: 'Arena 1st place Percentage',
            value: '42%',
            icon: 'icon-chart-bar-fill',
        },
        {
            title: 'Days played  (90 Days)',
            value: '4,640',
            icon: 'icon-game-controller-fill',
        },
        {
            title: 'Bombs earned in War (90 Days)',
            value: '12,650',
            icon: 'icon-lightning-fill',
        },
    ],
    profileInfo: {
        name: 'NickybravesOwn_788',
        nickname: 'Blackdesert',
        alliancePower: '210.5M',
        allianceName: 'Newrigafish',
        lastActive: 'Yesterday',
    },
};

const _TopFiveToons = [
    {
        name: 'Bugs Bunny',
        image: BugsBunny,
        level: 35,
        startsCount: 26,
        capabilities: ['Forest', 'Epic Attacker', 'Rabbit', 'Original'],
        power: 920,
    },
    {
        name: 'Daffy Duck',
        image: Eagle,
        level: 27,
        startsCount: 21,
        capabilities: ['Forest', 'Epic Attacker', 'Rabbit', 'Original'],
        power: 740,
    },
    {
        name: 'Elmer Fudd',
        image: MarvinTheMartian,
        level: 21,
        startsCount: 21,
        capabilities: ['Forest', 'Epic Attacker', 'Rabbit', 'Original'],
        power: 630,
    },
    {
        name: 'Scout Foghorn',
        image: TasmanianDevil,
        level: 41,
        startsCount: 33,
        capabilities: ['Forest', 'Epic Attacker', 'Rabbit', 'Original'],
        power: 560,
    },
    {
        name: 'Black Knight Sam',
        image: TweetyBird,
        level: 10,
        startsCount: 17,
        capabilities: ['Forest', 'Epic Attacker', 'Rabbit', 'Original'],
        power: 450,
    },
];

const _AllianceInfoBannerMock = {
    mainInfo: [
        {
            title: 'Brawl Stars Contibuted',
            value: '125',
            icon: 'icon-trophy',
        },
        {
            title: 'Days in Alliance',
            value: '2.16 Years',
            icon: 'icon-countdown',
        },
        {
            title: 'War Coins Donated',
            value: '274',
            icon: 'icon-coin-sack-1',
        },
        {
            title: 'Punch-In Milestones',
            value: '1.486',
            icon: 'icon-verified',
        },
    ],
    allianceInfo: {
        name: 'NickybravesOwn_788',
        alliancePower: '210.5M',
        icon: AllianceImage,
    },
};

const _CollectionBreakdownData = [
    {
        title: 'Toons by Star Level',
        type: 'default',
        data: [
            {
                starWorth: 7,
                starsCount: 16,
            },
            {
                starWorth: 6,
                starsCount: 25,
            },
            {
                starWorth: 5,
                starsCount: 42,
            },
            {
                starWorth: 4,
                starsCount: 35,
            },
            {
                starWorth: 3,
                starsCount: 27,
            },
            {
                starWorth: 2,
                starsCount: 24,
            },
            {
                starWorth: 1,
                starsCount: 21,
            },
        ],
    },
    {
        title: 'Toons by Cosmic Star Level',
        type: 'cosmic',
        data: [
            {
                starWorth: 7,
                starsCount: 24,
            },
            {
                starWorth: 6,
                starsCount: 12,
            },
            {
                starWorth: 5,
                starsCount: 32,
            },
            {
                starWorth: 4,
                starsCount: 16,
            },
            {
                starWorth: 3,
                starsCount: 18,
            },
            {
                starWorth: 2,
                starsCount: 22,
            },
            {
                starWorth: 1,
                starsCount: 24,
            },
        ],
    },
];

const _TopEventsMock = [
    {
        rank: 1,
        title: 'A Chorus For Horus & Divine Team Spotlight',
        date: new Date(),
    },
    {
        rank: 2,
        title: 'Haeat Treatment & Foreman Longhorn Hype Tournement',
        date: new Date(),
    },
    {
        rank: 3,
        title: 'Team Spothlight : Granny’s House Party Space Event',
        date: new Date(),
    },
    {
        rank: 125,
        title: 'Team Spothlight : Imperials & Desert Event',
        date: new Date(),
    },
    {
        rank: 420,
        title: 'Fan Vote : Legendary and Silence Team Spothlight',
        date: new Date(),
    },
];

const _LeaderboardProfileRegion = {
    title: '480',
    titleType: 'toon.guide.filter.left.panel.region',
    infoLeaderboard: [
        {
            img: desert,
            name: 'Forest',
            count: 24,
        },
        {
            img: desert,
            name: 'Desert',
            count: 12,
        },
        {
            img: desert,
            name: 'Farm',
            count: 35,
        },
        {
            img: space,
            name: 'Space',
            count: 42,
        },
        {
            img: city,
            name: 'City',
            count: 27,
        },
        {
            img: all,
            name: 'All',
            count: 10,
        },
        {
            img: avalooney,
            name: 'Avalooney',
            count: 24,
        },
        {
            img: locked,
            name: 'Locked',
            count: 45,
        },
        {
            img: summit,
            name: 'Summit',
            count: 29,
        },
        {
            img: tasmania,
            name: 'Tasmania',
            count: 27,
        },
        {
            img: town,
            name: 'Town',
            count: 69,
        },
        {
            img: wbStudio,
            name: 'wb studio',
            count: 38,
        },
    ],
};

const _LeaderboardProfileTheme = {
    title: '270',
    titleType: 'toon.guide.filter.left.panel.theme',
    infoLeaderboard: [
        {
            img: artist,
            name: 'Artist',
            count: 24,
        },
        {
            img: citizen,
            name: 'Citizen',
            count: 12,
        },
        {
            img: cop,
            name: 'Cop',
            count: 35,
        },
        {
            img: explorel,
            name: 'Explorel',
            count: 42,
        },
        {
            img: desert,
            name: 'Farmer',
            count: 27,
        },
        {
            img: medal,
            name: 'Medal',
            count: 10,
        },
        {
            img: calendar,
            name: 'Calendar',
            count: 24,
        },
        {
            img: athlete,
            name: 'Athlete',
            count: 45,
        },
    ],
};

const _LeaderboardProfileRace = {
    title: '160',
    titleType: 'toon.guide.filter.left.panel.race',
    infoLeaderboard: [
        {
            img: rabbit,
            name: 'Rabbit',
            count: 24,
        },
        {
            img: duck,
            name: 'Duck',
            count: 12,
        },
        {
            img: bird,
            name: 'Bird',
            count: 35,
        },
        {
            img: human,
            name: 'Human',
            count: 42,
        },
        {
            img: monster,
            name: 'Monster',
            count: 27,
        },
        {
            img: pig,
            name: 'Pig',
            count: 10,
        },
    ],
};

const _LeaderboardProfileArchetype = {
    title: '220',
    titleType: 'toon.guide.filter.left.panel.archetype',
    infoLeaderboard: [
        {
            img: attacker,
            name: 'Attacker',
            count: 24,
        },
        {
            img: defender,
            name: 'Defender',
            count: 12,
        },
        {
            img: support,
            name: 'Support',
            count: 35,
        },
    ],
};

export const _mock_data = {
    _PlayerInfoBannerMock,
    _TopFiveToons,
    _AllianceInfoBannerMock,
    _CollectionBreakdownData,
    _TopEventsMock,
    _LeaderboardProfileRegion,
    _LeaderboardProfileTheme,
    _LeaderboardProfileRace,
    _LeaderboardProfileArchetype,
};
