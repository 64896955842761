import { IEventsResponse2 } from '../../@types';

export interface IInitialEventsState {
    isClaimLoading: number | string;
    isEventsLoading: boolean;
    activeEvents: IEventsResponse2 | null;
}

export const initialEventsState: IInitialEventsState = {
    isClaimLoading: '',
    isEventsLoading: true,
    activeEvents: null,
};
