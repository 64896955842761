import styled from 'styled-components';

export const AvatarNameStyled = styled.div`
    .WP-AvatarName-name,
    .WP-AvatarName-subname {
        max-width: 90px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        ${({ theme }) => theme.breakpoints.down(375)} {
            max-width: 66px;
        }
    }
`;
