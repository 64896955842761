export const icons = `
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?81t2ly');
  src:  url('fonts/icomoon.eot?81t2ly#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?81t2ly') format('truetype'),
    url('fonts/icomoon.woff?81t2ly') format('woff'),
    url('fonts/icomoon.svg?81t2ly#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon-add-fill:before {
  content: "\\e900";
}
.icon-apple-fill:before {
  content: "\\e901";
}
.icon-arrow-left-s-line:before {
  content: "\\e902";
}
.icon-arrow-right-line:before {
  content: "\\e903";
}
.icon-bag-circle-essential-interface-shopping-ui-1:before {
  content: "\\e904";
}
.icon-business-products:before {
  content: "\\e905";
}
.icon-calculator:before {
  content: "\\e906";
}
.icon-calendar-alt:before {
  content: "\\e907";
}
.icon-Calendar-empty:before {
  content: "\\e908";
}
.icon-calendar-schedule-31-1:before {
  content: "\\e909";
}
.icon-calendar-schedule-star:before {
  content: "\\e90a";
}
.icon-calendar-tab-check:before {
  content: "\\e90b";
}
.icon-chart-bar-fill:before {
  content: "\\e90c";
}
.icon-chart-pie-fill-2:before {
  content: "\\e90d";
}
.icon-chart-pie-slice-fill-1:before {
  content: "\\e90e";
}
.icon-chat-messages-bubble-circle:before {
  content: "\\e90f";
}
.icon-circles-three-fill:before {
  content: "\\e910";
}
.icon-clock-1:before {
  content: "\\e911";
}
.icon-close-fill:before {
  content: "\\e912";
}
.icon-coin-sack-1:before {
  content: "\\e913";
}
.icon-computers-devices-electronics:before {
  content: "\\e914";
}
.icon-countdown:before {
  content: "\\e915";
}
.icon-crown-1:before {
  content: "\\e916";
}
.icon-crown-outline:before {
  content: "\\e917";
}
.icon-crown:before {
  content: "\\e918";
}
.icon-crypto-currency-bitcoin-ethereum:before {
  content: "\\e919";
}
.icon-discord:before {
  content: "\\e91a";
}
.icon-download-arrows-loading:before {
  content: "\\e91b";
}
.icon-DuableArrow:before {
  content: "\\e91c";
}
.icon-energy_icon:before {
  content: "\\e91d";
}
.icon-essential:before {
  content: "\\e91e";
}
.icon-facebook-2:before {
  content: "\\e91f";
}
.icon-facebook-black:before {
  content: "\\e920";
}
.icon-fb-logo-new:before {
  content: "\\e921";
}
.icon-filter:before {
  content: "\\e922";
}
.icon-fire-fill:before {
  content: "\\e923";
}
.icon-game-controller-fill:before {
  content: "\\e924";
}
.icon-giftIcon:before {
  content: "\\e925";
}
.icon-google-play-fill:before {
  content: "\\e926";
}
.icon-hexagon-fill-1:before {
  content: "\\e927";
}
.icon-home-house-line:before {
  content: "\\e928";
}
.icon-infinity:before {
  content: "\\e929";
}
.icon-info:before {
  content: "\\e92a";
}
.icon-instagram:before {
  content: "\\e92b";
}
.icon-interface-essential-book:before {
  content: "\\e92c";
}
.icon-interface-essential-clock:before {
  content: "\\e92d";
}
.icon-lightning-fill:before {
  content: "\\e92e";
}
.icon-location-arrow-circle:before {
  content: "\\e92f";
}
.icon-login-enter:before {
  content: "\\e930";
}
.icon-medal-fill:before {
  content: "\\e931";
}
.icon-menu-burger-square:before {
  content: "\\e932";
}
.icon-money-dollar-circle-fill:before {
  content: "\\e933";
}
.icon-playerAvatarIcon:before {
  content: "\\e934";
}
.icon-playerSettings:before {
  content: "\\e935";
}
.icon-promo:before {
  content: "\\e936";
}
.icon-Scopely-logo-new:before {
  content: "\\e937";
}
.icon-search-2-line:before {
  content: "\\e938";
}
.icon-shield-star:before {
  content: "\\e939";
}
.icon-social-medias-rewards-rating-1:before {
  content: "\\e93a";
}
.icon-social-medias-rewards-rating-2:before {
  content: "\\e93b";
}
.icon-social-medias-rewards-rating-3:before {
  content: "\\e93c";
}
.icon-social-medias-rewards-rating-4:before {
  content: "\\e93d";
}
.icon-social-medias-rewards-rating-5:before {
  content: "\\e93e";
}
.icon-social-medias-rewards-rating-6:before {
  content: "\\e93f";
}
.icon-social-medias-rewards-rating:before {
  content: "\\e940";
}
.icon-solid-cup:before {
  content: "\\e941";
}
.icon-star-reward:before {
  content: "\\e942";
}
.icon-star-sertificate:before {
  content: "\\e943";
}
.icon-swords:before {
  content: "\\e944";
}
.icon-trophy:before {
  content: "\\e945";
}
.icon-twitter:before {
  content: "\\e946";
}
.icon-usd-dollar:before {
  content: "\\e947";
}
.icon-user-profile-square:before {
  content: "\\e948";
}
.icon-user-profile:before {
  content: "\\e949";
}
.icon-user-single-select:before {
  content: "\\e94a";
}
.icon-users-1:before {
  content: "\\e94b";
}
.icon-users-2:before {
  content: "\\e94c";
}
.icon-users-fill-1:before {
  content: "\\e94d";
}
.icon-vector:before {
  content: "\\e94e";
}
.icon-verified:before {
  content: "\\e94f";
}
.icon-youtube:before {
  content: "\\e950";
}`;
