/**
 * Check if passed date belongs to the range of 2 dates
 * @param from
 * @param to
 * @param check
 * @returns {boolean}
 */
export const isBetweenDates = (from: Date, to: Date, check: Date): boolean => {
    return check.getTime() <= to.getTime() && check.getTime() >= from.getTime();
};

/**
 * @param date
 * @returns {Date}
 */
export const getNextMonday = (date: Date = new Date()): Date => {
    const dateCopy = new Date(date.getTime());

    const nextMonday = new Date(
        dateCopy.setDate(dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7)),
    );

    return nextMonday;
};
/**
 * @param date
 * @returns {Date}
 */
export const getNextSunday = (date: Date = new Date()): Date => {
    const dateCopy = new Date(date.getTime());

    const nextSunday = new Date(
        dateCopy.setDate(dateCopy.getDate() + (((6 - dateCopy.getDay() + 7) % 7) + 1)),
    );

    return nextSunday;
};
