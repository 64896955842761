import { TFunction } from 'i18next';

import { IEventsResponse, MilestonesEnum, IBannerConfig } from '../@types';

import { GAME_S3_BUCKET_URL_FOR_ICONS, PORTAL_S3_BUCKET_URL } from '../constants/api.constants';

export interface ICardType {
    title: string;
    claimed: boolean;
    disabled: boolean;
}

interface IActiveEventInfo {
    title: string;
    description: string;
    eventRules: string[];
    currentPoints: number;
    totalPoints: number;
    bannerRelativePath: string;
    secondaryLink: boolean;
    endDate: number | string;
    claimDate: number | string;
}

export const getMilestoneCardType = (
    status: string,
    currentPoints: number,
    requiredPoints: number,
): ICardType => {
    switch (true) {
        case status === MilestonesEnum.COMPLETED:
            return {
                title: 'claimed',
                claimed: true,
                disabled: true,
            };
        case status === MilestonesEnum.NOT_READY:
        case status === MilestonesEnum.CLAIMABLE && currentPoints < requiredPoints:
            return {
                title: 'not.ready',
                claimed: false,
                disabled: true,
            };
        case currentPoints >= requiredPoints:
            return {
                title: 'claim',
                claimed: false,
                disabled: false,
            };
        default:
            return {
                title: 'not.ready',
                claimed: false,
                disabled: true,
            };
    }
};

export const getEventInfoData = (
    activeEvent: IEventsResponse,
    dashboardConfig: IBannerConfig | null,
    t: TFunction,
): IActiveEventInfo => {
    const dashboardBannerLink = PORTAL_S3_BUCKET_URL
        ? PORTAL_S3_BUCKET_URL + dashboardConfig?.banner
        : '';

    if (!activeEvent?.event) {
        return {
            title: '',
            description: '',
            eventRules: [],
            currentPoints: 0,
            totalPoints: 100,
            bannerRelativePath: dashboardBannerLink,
            secondaryLink: false,
            endDate: 0,
            claimDate: 0,
        };
    }

    const {
        title,
        description,
        eventRules,
        bannerRelativePath,
        eventProgress: { current, total },
        secondaryLink,
        endDate,
        claimDate,
    } = activeEvent.event;

    return {
        title: t(title),
        description: t(description),
        eventRules,
        currentPoints: current,
        totalPoints: total,
        secondaryLink,
        bannerRelativePath: bannerRelativePath
            ? GAME_S3_BUCKET_URL_FOR_ICONS + '/' + bannerRelativePath
            : dashboardBannerLink,
        endDate: new Date(endDate).getTime(),
        claimDate: new Date(claimDate).getTime(),
    };
};
