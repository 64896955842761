export const DOWNLOAD_LINKS = {
    APP_STORE: `https://apps.apple.com/us/app/looney-tunes-world-of-mayhem/id1158042933`,
    PLAY_STORE: `https://play.google.com/store/apps/details?id=com.aqupepgames.projectpepe&hl=en`,
};

export const SCOPELY_URLS = {
    PRIVACY_POLICY: `https://scopely.com/privacy/`,
    TERMS: `https://scopely.com/tos/`,
};

export const BLOG_URL = 'https://looneytuneswom.scopely.com/';
