import {
    TAllianceProfile,
    TAllianceCollectionStatsData,
    TAllianceBannerMockedData,
    TAlliancePerformanceData,
    TAllianceWarPerformanceData,
    TAllianceMockLeaderboard,
    TAllianceTopEventsMock,
    TmockAlliance,
} from 'src/@types';

import { Theme } from '@mui/material';

import avatar from '../assets/images/avatar.png';
import rank1 from '../assets/images/rank-1.svg';
import rank2 from '../assets/images/rank-2.svg';
import rank3 from '../assets/images/rank-3.svg';

export const __AllianceActivePlayersList: TAllianceProfile[] = [
    {
        id: 1,
        name: 'Poxos',
        role: 'gamer',
        rank: rank1,
        rankText: 'rankText',
        point: ' 15',
        pointText: 'Battle Wins',
        avatar: avatar,
    },
    {
        id: 2,
        name: 'Petros',
        role: 'programmer',
        rank: rank2,
        rankText: 'rankText',
        point: ' 15.855',
        pointText: 'Battle Wins',
        avatar: avatar,
    },
    {
        id: 3,
        name: 'Martiros',
        role: 'player',
        rank: rank3,
        rankText: 'rankText',
        point: '15.998.77',
        pointText: 'Battle Wins',
        avatar: avatar,
    },
    {
        id: 4,
        name: 'Vardanush',
        role: 'driver',
        rank: 4,
        rankText: 'rankText',
        point: '15.258.77',
        pointText: 'Battle Wins',
        avatar: avatar,
    },
    {
        id: 5,
        name: 'Haykanush',
        role: 'teacher',
        rank: 5,
        rankText: 'rankText',
        point: '35.555.75',
        pointText: 'Battle Wins',
        avatar: avatar,
    },
];

export const __AllianceCollectionStatsData: Array<TAllianceCollectionStatsData> = [
    {
        title: 'Toons by Star Level',
        type: 'default',
        data: [
            {
                starWorth: 7,
                starsCount: 16,
                name: 'Total in Alliance',
                average: {
                    averagePoint: 12.5,
                    averageTitle: 'Average Per member',
                },
            },
            {
                starWorth: 6,
                starsCount: 25,
                name: 'Total in Alliance',
                average: {
                    averagePoint: 4.2,
                    averageTitle: 'Average Per member',
                },
            },
            {
                starWorth: 5,
                starsCount: 42,
                name: 'Total in Alliance',
                average: {
                    averagePoint: 37.5,
                    averageTitle: 'Average Per member',
                },
            },
            {
                starWorth: 4,
                starsCount: 35,
                name: 'Total in Alliance',
                average: {
                    averagePoint: 42.2,
                    averageTitle: 'Average Per member',
                },
            },
            {
                starWorth: 3,
                starsCount: 27,
                name: 'Total in Alliance',
                average: {
                    averagePoint: 16.7,
                    averageTitle: 'Average Per member',
                },
            },
            {
                starWorth: 2,
                starsCount: 24,
                name: 'Total in Alliance',
                average: {
                    averagePoint: 32.1,
                    averageTitle: 'Average Per member',
                },
            },
            {
                starWorth: 1,
                starsCount: 21,
                name: 'Total in Alliance',
                average: {
                    averagePoint: 12.4,
                    averageTitle: 'Average Per member',
                },
            },
        ],
    },
];

export const __AllianceBannerMockedData: TAllianceBannerMockedData = {
    league: {
        place: 56,
    },
    mainInfo: [
        {
            title: 'Alliance Total Power',
            value: '12.2 M',
            icon: 'icon-hexagon-fill-1',
        },
        {
            title: 'Brawls Per Player',
            value: '420 (30 Days)',
            icon: 'icon-medal-fill',
        },
        {
            title: 'Alliance Total Toons',
            value: '88',
            icon: 'icon-coin-sack-1',
        },
        {
            title: 'Total Trophies',
            value: '42 Days',
            icon: 'icon-Solid-cup',
        },
    ],
    secondaryInfo: [
        {
            title: '3 Star Brawl Wins % (90 Days)',
            value: '95.5%',
            icon: 'icon-chart-pie-slice-fill-1',
        },
        {
            title: '1st place War Wins % (90 Days)',
            value: '97.60%',
            icon: 'icon-chart-pie-fill-2',
        },
        {
            title: '3 Star Brawl Wins (90 Days)',
            value: '12,550',
            icon: 'icon-circles-three-fill',
        },
        {
            title: 'Total Brawls (90 Days)',
            value: '1,450',
            icon: 'icon-chart-bar-fill',
        },
        {
            title: '1st Place War Wins (90 Days)',
            value: '4,640',
            icon: 'icon-game-controller-fill',
        },
        {
            title: 'Wars Fought (90 Days)',
            value: '12,650',
            icon: 'icon-fire-fill',
        },
    ],
    allianceInfo: {
        name: 'NickybravesOwn_788',
        description: 'Its Just Too Sweet',
        content: [
            {
                id: 1,
                title: 'Active Members',
                point: '435',
                additionalText: '',
                icon: 'icon-users-fill-1',
            },
            {
                id: 2,
                title: 'Alliance Rank',
                additionalText: '(by power)',
                point: '240',
                icon: 'icon-trophy',
            },
            {
                id: 3,
                title: 'Alliance Created',
                point: '12/10/2020',
                additionalText: '',
                icon: 'icon-countdown',
            },
        ],
    },
};

export const __AlliancePerformanceData = (theme: Theme): Array<TAlliancePerformanceData> => {
    return [
        {
            id: 1,
            color: theme.palette.purple[600],
            chartTitle: {
                title: 'Bomb Wins',
                subTitle: 'Successful Attacks For Alliance',
                iconName: 'icon-medal-fill',
                last30Days: '544',
                allTime: '2,442',
            },
        },
        {
            id: 2,
            color: theme.palette.orange[700],

            chartTitle: {
                title: 'Bomb Losses',
                subTitle: 'Successful Attacks Against Alliance',
                iconName: 'icon-shield-star',
                last30Days: '850',
                allTime: '3,840',
            },
        },
        {
            id: 3,
            color: theme.palette.blue[600],

            chartTitle: {
                title: 'Alliance Structures Destroyed',
                subTitle: 'Ships and Towers',
                iconName: 'icon-lightning-fill',
                last30Days: '1,520',
                allTime: '12,860',
            },
        },
        {
            id: 4,
            color: theme.palette.green[600],

            chartTitle: {
                title: 'Alliance Structures Lost',
                subTitle: 'Ships and Towers',
                iconName: 'icon-location-arrow-circle',
                last30Days: '2,940',
                allTime: '16,650',
            },
        },
        {
            id: 5,
            color: theme.palette.red[600],

            chartTitle: {
                title: 'Alliance War Donations',
                subTitle: 'Donations of Alliance',
                iconName: 'icon-coin-sack-1',
                last30Days: '4,560',
                allTime: '23,840',
            },
        },
    ];
};

export const __AllianceWarPerformanceData: Array<TAllianceWarPerformanceData> = [
    {
        id: 3722836,
        faction_id: 'f5cf2bf3fbf148cfa0ebd9f26f8e9e7f',
        snapshot_month: '2020-09-01T00:00:00.000Z',
        faction_missions: 13417,
        faction_token_spent: 24655,
        faction_help_sent: 52550,
        feud_battles: 62304,
        battle_wins: 266204,
        battle_losses: 12685,
        faction_name: 'The Haven',
    },
    {
        id: 3723850,
        faction_id: 'f5cf2bf3fbf148cfa0ebd9f26f8e9e7f',
        snapshot_month: '2020-10-01T00:00:00.000Z',
        faction_missions: 14851,
        faction_token_spent: 29532,
        faction_help_sent: 75072,
        feud_battles: 54634,
        battle_wins: 257250,
        battle_losses: 90552,
        faction_name: 'The Haven',
    },
    {
        id: 3724739,
        faction_id: 'f5cf2bf3fbf148cfa0ebd9f26f8e9e7f',
        snapshot_month: '2020-11-01T00:00:00.000Z',
        faction_missions: 14140,
        faction_token_spent: 35415,
        faction_help_sent: 95899,
        feud_battles: 57439,
        battle_wins: 233676,
        battle_losses: 84563,
        faction_name: 'The Haven',
    },
    {
        id: 3725802,
        faction_id: 'f5cf2bf3fbf148cfa0ebd9f26f8e9e7f',
        snapshot_month: '2020-12-01T00:00:00.000Z',
        faction_missions: 17389,
        faction_token_spent: 20543,
        faction_help_sent: 26664,
        feud_battles: 56458,
        battle_wins: 213076,
        battle_losses: 92943,
        faction_name: 'The Haven',
    },
    {
        id: 3726486,
        faction_id: 'f5cf2bf3fbf148cfa0ebd9f26f8e9e7f',
        snapshot_month: '2021-01-01T00:00:00.000Z',
        faction_missions: 13984,
        faction_token_spent: 18387,
        faction_help_sent: 25207,
        feud_battles: 59612,
        battle_wins: 271959,
        battle_losses: 87843,
        faction_name: 'The Haven',
    },
    {
        id: 3727083,
        faction_id: 'f5cf2bf3fbf148cfa0ebd9f26f8e9e7f',
        snapshot_month: '2021-02-01T00:00:00.000Z',
        faction_missions: 17469,
        faction_token_spent: 11738,
        faction_help_sent: 14607,
        feud_battles: 55587,
        battle_wins: 233538,
        battle_losses: 67263,
        faction_name: 'The Haven',
    },
    {
        id: 3727565,
        faction_id: 'f5cf2bf3fbf148cfa0ebd9f26f8e9e7f',
        snapshot_month: '2021-03-01T00:00:00.000Z',
        faction_missions: 13545,
        faction_token_spent: 9824,
        faction_help_sent: 11887,
        feud_battles: 58755,
        battle_wins: 283984,
        battle_losses: 79293,
        faction_name: 'The Haven',
    },
    {
        id: 3721288,
        faction_id: 'f5cf2bf3fbf148cfa0ebd9f26f8e9e7f',
        snapshot_month: '2021-04-01T00:00:00.000Z',
        faction_missions: 15327,
        faction_token_spent: 8901,
        faction_help_sent: 10921,
        feud_battles: 47675,
        battle_wins: 274287,
        battle_losses: 116672,
        faction_name: 'The Haven',
    },
    {
        id: 3722569,
        faction_id: 'f5cf2bf3fbf148cfa0ebd9f26f8e9e7f',
        snapshot_month: '2021-05-01T00:00:00.000Z',
        faction_missions: 14591,
        faction_token_spent: 9142,
        faction_help_sent: 11490,
        feud_battles: 48944,
        battle_wins: 283811,
        battle_losses: 108114,
        faction_name: 'The Haven',
    },
    {
        id: 3723162,
        faction_id: 'f5cf2bf3fbf148cfa0ebd9f26f8e9e7f',
        snapshot_month: '2021-06-01T00:00:00.000Z',
        faction_missions: 15178,
        faction_token_spent: 10042,
        faction_help_sent: 11784,
        feud_battles: 53471,
        battle_wins: 244968,
        battle_losses: 6386,
        faction_name: 'The Haven',
    },
    {
        id: 3724278,
        faction_id: 'f5cf2bf3fbf148cfa0ebd9f26f8e9e7f',
        snapshot_month: '2021-07-01T00:00:00.000Z',
        faction_missions: 15970,
        faction_token_spent: 6953,
        faction_help_sent: 7566,
        feud_battles: 51859,
        battle_wins: 260747,
        battle_losses: 50963,
        faction_name: 'The Haven',
    },
    {
        id: 3725572,
        faction_id: 'f5cf2bf3fbf148cfa0ebd9f26f8e9e7f',
        snapshot_month: '2021-08-01T00:00:00.000Z',
        faction_missions: 921,
        faction_token_spent: 471,
        faction_help_sent: 528,
        feud_battles: 0,
        battle_wins: 154521,
        battle_losses: 42211,
        faction_name: 'The Haven',
    },
];

export const __AllianceMockLeaderboard: Array<TAllianceMockLeaderboard> = [
    {
        id: 1,
        rank: 6,
        name: 'Fan Vote : Legendary',
        endDate: '03/06/2021',
        image: avatar,
        points: 180540250,
    },
    {
        id: 2,
        rank: 9,
        name: 'Starry Knight',
        endDate: '03/06/2021',
        image: avatar,
        points: 175120356,
    },
    {
        id: 3,
        rank: 3,
        name: 'Team Spothlight : Imperials & Desert Event',
        endDate: '03/06/2021',
        image: avatar,
        points: 162195931,
    },
    {
        id: 4,
        rank: 5,
        name: 'The Dark Side of The Toon',
        endDate: '03/06/2021',
        image: avatar,
        points: 162195931,
    },
    {
        id: 5,
        rank: 1,
        name: 'A Chorus For Horus & Divine Team Spotlight',
        endDate: '03/06/2021',
        image: avatar,
        points: 162195931,
    },
    {
        id: 6,
        rank: 3,
        name: 'Chungabunga & Extravaganza!',
        endDate: '03/06/2021',
        image: avatar,
        points: 162195931,
    },
    {
        id: 7,
        rank: 4,
        name: 'Olympic Strategist',
        endDate: '03/06/2021',
        image: avatar,
        points: 162195931,
    },
    {
        id: 8,
        rank: 8,
        name: 'Team Spothlight : Imperials and City',
        endDate: '03/06/2021',
        image: avatar,
        points: 162195931,
    },
    {
        id: 9,
        rank: 10,
        name: 'Regional Event : WB Studios',
        endDate: '03/06/2021',
        image: avatar,
        points: 162195931,
    },
    {
        id: 3,
        rank: 1,
        name: 'Regional Event : WB Studios',
        endDate: '03/06/2021',
        image: avatar,
        points: 162195931,
    },
];

export const __AllianceTopEventsMock: Array<TAllianceTopEventsMock> = [
    {
        rank: 1,
        title: 'A Chorus For Horus & Divine Team Spotlight',
        date: new Date(),
    },
    {
        rank: 2,
        title: 'Haeat Treatment & Foreman Longhorn Hype Tournement',
        date: new Date(),
    },
    {
        rank: 3,
        title: 'Team Spothlight : Granny’s House Party Space Event',
        date: new Date(),
    },
    {
        rank: 125,
        title: 'Team Spothlight : Imperials & Desert Event',
        date: new Date(),
    },
    {
        rank: 420,
        title: 'Fan Vote : Legendary and Silence Team Spothlight',
        date: new Date(),
    },
];

export const _mockAlliance: TmockAlliance = {
    __AllianceActivePlayersList,
    __AllianceCollectionStatsData,
    __AllianceBannerMockedData,
    __AlliancePerformanceData,
    __AllianceWarPerformanceData,
    __AllianceMockLeaderboard,
    __AllianceTopEventsMock,
};
