import { createSelector } from 'reselect';
import { TAvatarOffers, TShopData } from 'src/@types';

import { RootState } from '../configureStore';

const beepCoinShopSelector = (state: RootState) => state.beepCoinShop;

const changeActiveTabSelector = createSelector(
    [beepCoinShopSelector],
    ({ activeTab }) => activeTab,
);

const isLoadingSelector = createSelector(
    [beepCoinShopSelector],
    ({ isLoading }: { isLoading: boolean }): boolean => isLoading,
);
const beepcoinOfferProcessingIdSelector = createSelector(
    [beepCoinShopSelector],
    ({ beepcoinOfferProcessingId }: { beepcoinOfferProcessingId: string }) =>
        beepcoinOfferProcessingId,
);

const processingAvatarOffersIdSelector = createSelector(
    [beepCoinShopSelector],
    ({ processingAvatarOffersId }: { processingAvatarOffersId: string }) =>
        processingAvatarOffersId,
);
const beepCoinShopDataSelector = createSelector(
    [beepCoinShopSelector],
    ({ shopData }: { shopData: TShopData | null }) => shopData,
);
const avatarOffersSelector = createSelector(
    [beepCoinShopSelector],
    ({ avatarOffers }: { avatarOffers: TAvatarOffers | null }) => avatarOffers,
);

export const beepCoinShopSel = {
    isLoadingSelector,
    beepCoinShopDataSelector,
    beepcoinOfferProcessingIdSelector,
    avatarOffersSelector,
    processingAvatarOffersIdSelector,
    changeActiveTabSelector,
};
