import { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { globalSel } from '../../../store/global';
import { specialOffersSel } from '../../../store/specialOffers';

import { getStorageItem } from '../../../helpers/localStorage';
import { offersTabParser } from '../../../helpers/offersDataFormatter';
import { formatDaysLeftRRule } from '../../../helpers/rrule';
import { generateProgressDateInPercents } from '../../../helpers/utils';

const getOffersDataFiltered = (offersData, isUserLoggedIn) => {
    const offersDataFiltered = offersData?.length
        ? isUserLoggedIn
            ? offersData.filter((i) => JSON.stringify(i[0]) !== JSON.stringify({})).map((i) => i[0])
            : offersData.filter((i) => {
                  const { recurrenceRule, startedDuration } = i;
                  const { progressEndDate, progressStartDate } = formatDaysLeftRRule(
                      recurrenceRule,
                      startedDuration,
                  );
                  const progress = generateProgressDateInPercents(
                      progressStartDate,
                      progressEndDate,
                  );

                  return progress > 0 && progress < 100;
              })
        : [];

    offersDataFiltered.sort((a, b) => {
        const skuOfferMap = a?.skuOfferMap
            ? Object.values(a.skuOfferMap)?.[0]?.[1]?.[0]?.[1]
            : null;
        const weight = skuOfferMap?.shopElement?.weight;

        const skuOfferMap1 = b?.skuOfferMap
            ? Object.values(b.skuOfferMap)?.[0]?.[1]?.[0]?.[1]
            : null;
        const weight1 = skuOfferMap1?.shopElement?.weight;

        return weight - weight1;
    });

    return offersDataFiltered;
};

const useOffersContent = () => {
    const currentUser = useSelector(globalSel.currentUserSelector);
    const authenticated = useSelector(globalSel.isAuthSelector);

    const shopItems = useSelector(specialOffersSel.shopItemsSelector);

    const promoRef = useRef(null);
    const featuredRef = useRef(null);
    const defaultOfferRef = useRef(null);
    const essentialOfferRef = useRef(null);
    const specialOfferRef = useRef(null);
    const eventOfferRef = useRef(null);

    const { t } = useTranslation();

    const accessToken = getStorageItem('accessToken');

    const isUserLoggedIn = !!(currentUser && authenticated && accessToken);

    const refs = useMemo(
        () => ({
            promoRef,
            featuredRef,
            defaultOfferRef,
            essentialOfferRef,
            specialOfferRef,
            eventOfferRef,
        }),

        [],
    );

    const offersData = shopItems?.storeOfferLiveOpsEvents
        ? Object.values(shopItems.storeOfferLiveOpsEvents)
        : [];

    const offersDataFiltered = getOffersDataFiltered(offersData, isUserLoggedIn);

    const {
        promoCodeOffers,
        specialOffers,
        essentialOffers,
        featuredOffers,
        defaultOffers,
        eventOffers,
    } = offersTabParser(offersDataFiltered, false);

    const offers = useMemo(
        () => ({
            promoCodeOffers,
            featuredOffers,
            specialOffers,
            eventOffers,
            essentialOffers,
            defaultOffers,
        }),
        [
            promoCodeOffers,
            featuredOffers,
            defaultOffers,
            essentialOffers,
            specialOffers,
            eventOffers,
        ],
    );

    const offersFiltered = useMemo(
        () =>
            Object.values(offers).reduce((acc, loc) => {
                acc.push(...(loc || []));
                return acc;
            }, []),
        [offers],
    );

    const offerBannerId =
        offersFiltered?.reduce((acc, loc) => {
            const weightAcc =
                acc?.skuOfferMap?.[Object.keys(acc?.skuOfferMap)[0]]?.[1]?.[0]?.[1]?.shopElement
                    ?.weight;

            const weightLoc =
                loc?.skuOfferMap?.[Object.keys(loc?.skuOfferMap)[0]]?.[1]?.[0]?.[1]?.shopElement
                    ?.weight;

            return weightAcc < weightLoc ? acc : loc;
        }, null)?.identifier || '';

    const offerBanner = isUserLoggedIn
        ? shopItems?.storeOfferLiveOpsEvents?.[offerBannerId]?.[0]
        : shopItems?.storeOfferLiveOpsEvents?.[offerBannerId];

    const filters = useMemo(
        () => [
            {
                title: t('special.offer.SpecialOffers.filter.button.promo'),
                icon: 'icon-promo',
                content: 'promo',
                ref: promoRef,
                isShow: !!promoCodeOffers?.length,
            },
            {
                title: t('special.offer.SpecialOffers.filter.button.featured'),
                icon: 'icon-crypto-currency-bitcoin-ethereum',
                content: 'featured',
                ref: featuredRef,
                isShow: !!featuredOffers?.length,
            },
            {
                title: t('special.offer.SpecialOffers.filter.button.specials'),
                icon: 'icon-social-medias-rewards-rating-1',
                content: 'specials',
                ref: specialOfferRef,
                isShow: !!specialOffers?.length,
            },
            {
                title: t('special.offer.SpecialOffers.filter.button.events'),
                icon: 'icon-Calendar-empty',
                content: 'events',
                ref: eventOfferRef,
                isShow: !!eventOffers?.length,
            },
            {
                title: t('special.offer.SpecialOffers.filter.button.essentials'),
                icon: 'icon-Essential',
                content: 'essentials',
                ref: essentialOfferRef,
                isShow: !!essentialOffers?.length,
            },
            {
                title: t('special.offer.SpecialOffers.filter.button.offers'),
                icon: 'icon-interface-essential-clock',
                content: 'offers',
                ref: defaultOfferRef,
                isShow: !!defaultOffers?.length,
            },
        ],
        [
            t,
            promoCodeOffers,
            featuredOffers,
            specialOffers,
            essentialOffers,
            defaultOffers,
            eventOffers,
        ],
    );

    return useMemo(
        () => ({
            offers,
            refs,
            offersFiltered,
            offerBannerId,
            offerBanner,
            filters,
        }),
        [offers, refs, offersFiltered, offerBannerId, offerBanner, filters],
    );
};

export default useOffersContent;
