import styled from 'styled-components';

import avatarBg from '../../../assets/images/avatar_background.png';
import keyAvatars from '../../../assets/images/keyAvatars.png';

export const AvatarModalStyled = styled.div`
    .WP-AvatarModal-modal-left {
        background: url(${avatarBg});
        background-size: 100% 100%;
    }

    .WP-AvatarModal-avatars-root {
        background: ${({ theme }) => theme.palette.background[50]};
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            display: block;
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background-color: ${({ theme }) => theme.palette.grey[400]};
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${({ theme }) => theme.palette.grey[600]};
            border-radius: 10px;
        }
    }

    .WP-AvatarModal-remove-frame {
        text-decoration: underline;
    }

    .WP-AvatarModal-available-avatars,
    .WP-AvatarModal-locked-avatars,
    .WP-AvatarModal-btn-root {
        border-bottom: 1px solid ${({ theme }) => theme.palette.grey[400]};
    }

    .WP-AvatarModal-avatar-btn,
    .WP-AvatarModal-frame-btn {
        width: 102px;
        -webkit-transition-property: none;
        -moz-transition-property: none;
        -o-transition-property: none;
        transition-property: none;
        &:hover {
            color: ${({ theme }) => theme.palette.text[50]};
            background-color: ${({ theme }) => theme.palette.background[300]};

            span {
                -webkit-transition-property: none;
                -moz-transition-property: none;
                -o-transition-property: none;
                transition-property: none;
            }
        }
    }
    .currentTab {
        color: ${({ theme }) => theme.palette.text[50]};
        background-color: ${({ theme }) => theme.palette.background[300]};
    }

    .WP-AvatarModal-active-avatar {
        background: #fff5e3;
        border: 1px solid rgba(255, 255, 255, 0.4);
        box-sizing: border-box;
        box-shadow: 0 0 6px rgba(255, 255, 255, 0.25);
    }

    .WP-AvatarModal-locked-item {
        background: rgba(243, 247, 252, 0.66);
        :after {
            content: '';
            position: absolute;
            top: 1px;
            right: 1px;
            bottom: -1px;
            left: 1px;
            background-color: rgba(var(--midnightExpressShade1), 0.5);
            background-image: url(${keyAvatars});
            background-repeat: no-repeat;
            background-position: 50%;
            border-radius: 50%;
        }
    }
    .WP-AvatarModal-container-avatar {
        ${({ theme }) => theme.breakpoints.down('sm')} {
            flex-wrap: nowrap;
            overflow-x: auto;
            justify-content: flex-start;
        }
    }
`;
