import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { INavItem } from 'src/@types';

import { useMediaQuery, Theme } from '@mui/material';

import { globalSel } from '../../../../store/global';

import { interactionEvent } from '../../../../helpers/GA4Helper';
import { getStorageItem } from '../../../../helpers/localStorage';
import { titanAnalyticsEvents } from '../../../../helpers/titanAnalytics';

import useOffersContent from '../../../pages/SpecialOffers/offersContent';

import { Box, Icon } from '../../../core-ui';

import { Label, Typography } from '../../../../stories';
import { NAVIGATION_LIST } from '../navigationList';
import { NavStyled } from './Nav.styled';

function Nav({ setOpenLeftSidebar }: { setOpenLeftSidebar: (isOpen: boolean) => void }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const maintenanceMode = useSelector(globalSel.maintenanceModeSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);
    const authenticated = useSelector(globalSel.isAuthSelector);
    const accessToken = getStorageItem('accessToken');

    const isUserLoggedIn = useMemo(
        () => !!(currentUser && authenticated && accessToken),
        [accessToken, authenticated, currentUser],
    );

    const { offersFiltered } = useOffersContent();

    const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const isActiveNavBarItem = (path: string, href: string) => {
        switch (true) {
            case path === href:
                return true;
            case path.includes('leaderboard') && href.includes('leaderboard'):
                return true;
            case path.includes('/toon-profile') && href === '/toon-guide':
                return true;
            case path.includes('/news/') && href === '/news':
                return true;
            case path.includes('/calendar/') && href === '/calendar':
                return true;
            default:
                return false;
        }
    };

    const handleNavItemClick = (navItem: INavItem) => {
        if (isMediumScreen) setOpenLeftSidebar(false);

        navigate(navItem.href);

        titanAnalyticsEvents.navigationButton({
            'sys.user_ud': currentUser?.playerProfile?.id || '',
        });

        interactionEvent(
            navItem.href,
            'left_sidebar',
            'navigation_link',
            'navigation_panel',
            'button',
        );
    };

    const checkIfPrivatePageAvailable = (navItem: INavItem) =>
        !(navItem.isPrivate && !isUserLoggedIn);

    const isPageAvailable = (navItem: INavItem) => {
        return (
            maintenanceMode[navItem?.maintenanceModeName ?? ''] &&
            checkIfPrivatePageAvailable(navItem)
        );
    };

    const isBadgeAvailable = (navItem: INavItem) => {
        return navItem.hasBadge;
    };

    return (
        <Box component={NavStyled} mt={10} px={3} mb={61}>
            {NAVIGATION_LIST.map((navItem: INavItem) => {
                return isPageAvailable(navItem) ? (
                    <Box
                        key={navItem.id}
                        className="nav-link cursor-pointer"
                        data-testid={navItem.maintenanceModeName + '-link'}
                        onClick={() => handleNavItemClick(navItem)}
                    >
                        <Box
                            className={
                                isActiveNavBarItem(location.pathname, navItem.href)
                                    ? 'active-link'
                                    : ''
                            }
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent={navItem.hasBadge ? 'space-between' : ''}
                                p={3}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    width={1}
                                >
                                    <Icon className={navItem.iconClassname} size={24} />

                                    <Box width={1}>
                                        <Typography variant="p14" fWeight="medium">
                                            {t(navItem.name)}
                                        </Typography>
                                    </Box>
                                </Box>

                                {isBadgeAvailable(navItem) ? (
                                    <Box ml={0} display="flex">
                                        <Label
                                            size="small"
                                            variant="orange"
                                            className="WP-Nav-new-label"
                                            rounded
                                            label={t('badge.new')}
                                            clickable
                                        />
                                    </Box>
                                ) : null}
                            </Box>
                        </Box>
                    </Box>
                ) : null;
            })}
        </Box>
    );
}

export default React.memo(Nav);
