// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { ISkuOfferMap, ITransactionProducts, TShopData, TStoreProductInfoList } from '../@types';

import { GAME_S3_BUCKET_URL, GAME_S3_BUCKET_URL_FOR_ICONS } from '../constants/api.constants';
import {
    avatarOfferIndex,
    dailyExchangeOfferIndex,
    defaultOfferIndex,
    energyOfferIndex,
    essentialOfferIndex,
    eventOfferIndex,
    featuredOfferIndex,
    miscOfferIndex,
    specialOfferIndex,
} from '../constants/offersTab.constants';

import { formatDaysLeftRRule } from './rrule';
import { generateProgressDate, generateProgressDateInPercents } from './utils';

interface IINITIAL_OFFER_VALUE {
    purchaseLeftCount: number;
    description: string;
    price: number;
    originalPrice: number;
    iconPath: string;
    identifier: number;
    progressText: string;
    productId: string;
    progress: number;
    endDate: string;
    backgroundImg: string;
    offerDescription: string;
    eventId: string;
    isPromoOffer: boolean;
}
const INITIAL_OFFER_VALUE: IINITIAL_OFFER_VALUE = {
    purchaseLeftCount: 0,
    description: '',
    originalPrice: 0,
    price: 0,
    iconPath: '',
    identifier: 0,
    progressText: '',
    productId: '',
    progress: 0,
    endDate: '',
    backgroundImg: '',
    offerDescription: '',
    eventId: '',
    isPromoOffer: false,
};

/**
 * beepcoinOffersDataFormatter function receives the identifier of storeOfferLiveOpsEvent object and storeSelector data
 * @param storeOffer
 * @param storeData
 */
export interface IBeepCoinData {
    processingId?: string;
    eventId?: string;
    price: number;
    originalPrice: number;
    purchaseLeftCount: number;
    progress: number;
    progressText: string;
    isExpired?: boolean;
    description?: string;
    iconPath: string;
    identifier: string;
    productId: string;
    title?: string;
    badgeText?: string;
    badgeBackgroundUrl?: string;
    endDate: string;
    backgroundImg: string;
    offerDescription: string;
    isPromoOffer: boolean;
}
export const beepcoinOffersDataFormatter = (
    storeOffer: TShopData | TShopData[],
    storeData: TShopData | null,
): IBeepCoinData | IINITIAL_OFFER_VALUE => {
    if (!storeOffer || !storeData) return INITIAL_OFFER_VALUE;

    const offer: TShopData | undefined = Array.isArray(storeOffer) ? storeOffer[0] : storeOffer;

    if (!offer) return INITIAL_OFFER_VALUE;
    const identifier = offer.identifier;
    const skuOfferMap = offer.skuOfferMap;
    const startedDuration = offer.startedDuration || 0;
    const recurrenceRule = offer.recurrenceRule || '';
    const beepCoinBackgroundUrl = offer.beepCoinBackgroundUrl;
    /**
     * Get Product info by StoreLiveOpsEvents.identifier
     */
    const productInfo: TStoreProductInfoList | undefined = storeData.storeProductInfoList?.find(
        (i: TStoreProductInfoList) => {
            return i.eventId === identifier;
        },
    );

    /**
     * Get TransactionProduct info by storeProductInfoList.productID
     */
    const productId = productInfo?.productId || 0;
    const eventId = productInfo?.eventId || '0';
    const transactionProduct = storeData?.transactionProducts?.[productId] ?? '';
    const avatar = transactionProduct?.avatar || '';
    const iconUrl = transactionProduct?.iconUrl || '';
    const localizationKeysMap = transactionProduct?.localizationKeysMap || [];
    const description = localizationKeysMap[1]?.[1] || '';
    const offerDescription = localizationKeysMap[1]?.[2] || '';
    const playerCounterValue = productInfo?.playerCounterValue || 0;

    /**
     * Get beepcoin offer card background image url
     */
    const splitUrl = beepCoinBackgroundUrl?.split('/') || [];

    const offerBgUrl =
        splitUrl.length > 1 ? splitUrl[splitUrl.length - 1] : 'shop_default_bg_flames_left.png';

    const backgroundImg = GAME_S3_BUCKET_URL + '/ShopBackgrounds/' + offerBgUrl;

    /**
     * Use iconUrl from transactionProduct array or if iconUrl === '', use assetsMap[1]
     */
    const avatarImage = avatar?.split('/') || [];

    const imageUrl = iconUrl || avatarImage[avatarImage.length - 1];

    const iconPath = iconUrl
        ? GAME_S3_BUCKET_URL_FOR_ICONS + '/' + imageUrl
        : GAME_S3_BUCKET_URL + `/ShopIcons/${imageUrl}`;
    /**
     * Get Offer Name localized direct key (shopElement => description)
     */

    const keyOfSkuOfferMap: string | undefined = skuOfferMap ? Object.keys(skuOfferMap)?.[0] : '';

    const skuOfferMapData = keyOfSkuOfferMap ? skuOfferMap?.[keyOfSkuOfferMap] : null;

    const skuOfferData = skuOfferMapData?.[1]?.[0]?.[1];
    const maxPurchases = skuOfferData?.maxPurchases ?? 0;
    /**
     * Calculate Offers remaining transaction count
     * @param maxPurchases (max count of purchases)
     * @param playerCounterValue (count of transaction per user)
     */
    const purchaseLeftCount = +maxPurchases - playerCounterValue;
    /**
     * Get Beepcoin Offer Price from paymentItemsCurve object
     */
    const paymentItemsCurve = transactionProduct?.paymentItemsCurve?.[1];

    const nextPurchasePaymentsItemsCurve = paymentItemsCurve?.[playerCounterValue + 1]
        ? paymentItemsCurve[playerCounterValue + 1]?.[1]?.[0]?.[1]
        : paymentItemsCurve?.[1]?.[1]?.[0]?.[1];

    const price = nextPurchasePaymentsItemsCurve?.itemAmount || 0;

    const { progressStartDate, progressEndDate } = formatDaysLeftRRule(
        recurrenceRule,
        startedDuration,
    );
    const progressText = generateProgressDate(progressEndDate) ?? '';

    const progress = generateProgressDateInPercents(progressStartDate, progressEndDate);
    const originalPriceReference = transactionProduct?.priceReference || null;
    const originalPriceAmount = originalPriceReference
        ? storeData?.originalPriceReferences?.originalPriceReference?.[0]
              ?.externalPriceReferencesMap?.[1]?.[3]?.[1]?.usdAmount || 0
        : 0;
    const originalPrice = originalPriceAmount ? originalPriceAmount / 100 : 0;
    return {
        price,
        originalPrice,
        purchaseLeftCount,
        description,
        iconPath,
        eventId,
        progressText,
        progress,
        identifier,
        productId,
        endDate: progressEndDate,
        backgroundImg,
        offerDescription,
    };
};

/**
 * logoutBeepcoinOffersDataFormatter function receives the identifier of storeOfferLiveOpsEvent object and storeSelector data
 * @param storeOffer
 * @param storeData
 */

export const logoutBeepcoinOffersDataFormatter = (
    storeOffer: TShopData,
    storeData: TShopData | null,
): IBeepCoinData | IINITIAL_OFFER_VALUE => {
    if (!storeOffer || !storeData) return INITIAL_OFFER_VALUE;

    const offer = Array.isArray(storeOffer) ? storeOffer[0] : storeOffer;

    if (!offer) return INITIAL_OFFER_VALUE;

    const {
        identifier,
        recurrenceRule,
        skuOfferMap,
        transactionProducts,
        startedDuration,
        beepCoinBackgroundUrl,
    }: {
        identifier: string;
        transactionProducts: Array<ITransactionProducts>;
        recurrenceRule: string;
        skuOfferMap: ISkuOfferMap;
        startedDuration: number;
        beepCoinBackgroundUrl: string;
    } = offer;
    /**
     * Get logout state beepcoin Offer card background image
     */

    const splitUrl = beepCoinBackgroundUrl?.split('/') || [];
    const offerBgUrl =
        splitUrl.length > 1 ? splitUrl[splitUrl.length - 1] : 'shop_default_bg_flames_left.png';

    const backgroundImg = GAME_S3_BUCKET_URL + '/ShopBackgrounds/' + offerBgUrl;

    const iconPath = GAME_S3_BUCKET_URL_FOR_ICONS + '/' + transactionProducts?.[0]?.iconUrl;

    const keyOfSkuOfferMap = Object.keys(skuOfferMap)?.[0] ?? '';

    const {
        progressText,
        progress,
        offerDescription,
        description,
        purchaseLeftCount,
        progressEndDate,
    } = generateOfferItems(
        skuOfferMap,
        keyOfSkuOfferMap,
        transactionProducts,
        recurrenceRule,
        startedDuration,
    );
    const price = transactionProducts?.[0]?.paymentItemsCurve?.[1]?.[1]?.[1]?.[0]?.[1]?.itemAmount;
    const transactionProduct: ITransactionProducts =
        storeData?.transactionProducts[transactionProducts?.[0]?.identifier ?? 0];
    const originalPriceReference = transactionProduct?.priceReference || null;
    const originalPriceAmount = originalPriceReference
        ? storeData?.originalPriceReferences?.[originalPriceReference]?.[0]
              ?.externalPriceReferencesMap?.[1]?.[3]?.[1]?.usdAmount || 0
        : 0;

    const originalPrice = originalPriceAmount ? originalPriceAmount / 100 : 0;
    const productInfo: TStoreProductInfoList | undefined = storeData?.storeProductInfoList?.find(
        (i: TStoreProductInfoList) => i.eventId === identifier,
    );

    const eventId = productInfo?.eventId || '0';
    return {
        purchaseLeftCount,
        description,
        price,
        originalPrice,
        iconPath,
        productId: transactionProducts?.[0]?.identifier ?? '',
        progressText,
        progress,
        identifier,
        endDate: progressEndDate,
        backgroundImg,
        offerDescription,
        eventId,
    };
};

const generateOfferItems = (
    skuOfferMap: ISkuOfferMap,
    keyOfSkuOfferMap: string,
    transactionProducts: Array<ITransactionProducts>,
    recurrenceRule: string,
    startedDuration: number,
) => {
    const skuOfferMapData = skuOfferMap?.[keyOfSkuOfferMap]?.[1]?.[0]?.[1];
    const purchaseLeftCount = skuOfferMapData?.maxPurchases;
    const description = transactionProducts[0]?.localizationKeysMap[1]?.[1];
    const offerDescription = transactionProducts[0]?.localizationKeysMap[1]?.[2];

    const { progressStartDate, progressEndDate } = formatDaysLeftRRule(
        recurrenceRule,
        startedDuration,
    );
    const progress = generateProgressDateInPercents(progressStartDate, progressEndDate);
    const progressText = generateProgressDate(progressEndDate) ?? '';

    return {
        progress,
        progressText,
        offerDescription,
        description,
        purchaseLeftCount,
        progressEndDate,
    };
};
/**
 * specialOffersDataFormatter function receives the identifier of storeOfferLiveOpsEvent object and storeSelector data
 * @param storeOffer
 * @param storeData
 */

export const specialOfferDataFormatter = (
    storeOffer: TShopData,
    storeData: TShopData | null,
): IBeepCoinData | IINITIAL_OFFER_VALUE => {
    if (!storeOffer || !storeData) return INITIAL_OFFER_VALUE;

    const offer = Array.isArray(storeOffer) ? storeOffer[0] : storeOffer;

    if (!offer) return INITIAL_OFFER_VALUE;

    const {
        identifier,
        skuOfferMap,
        recurrenceRule,
        startedDuration,
        offerBackgroundUrl,
    }: {
        identifier: string;
        recurrenceRule: string;
        skuOfferMap: ISkuOfferMap;
        startedDuration: number;
        offerBackgroundUrl: string;
    } = offer;
    /**
     * Get Product info by StoreLiveOpsEvents.identifier
     */
    const productInfo: TStoreProductInfoList | undefined = storeData?.storeProductInfoList?.find(
        (i: TStoreProductInfoList) => i.eventId === identifier,
    );

    const playerCounterValue = productInfo?.playerCounterValue || 0;
    const productId = productInfo?.productId || '';
    const eventId = productInfo?.eventId || '0';

    /**
     * Get TransactionProduct info by storeProductInfoList.productID
     */
    const transactionProduct: ITransactionProducts = storeData?.transactionProducts[productId];
    const priceReference = transactionProduct?.priceReference || '';
    const originalPriceReference = transactionProduct?.priceReference || null;
    const iconUrl = transactionProduct?.iconUrl || '';
    const avatar = transactionProduct?.avatar || '';
    const localizationKeysMap: [string, { [key: number]: string }] =
        transactionProduct?.localizationKeysMap || null;
    const description = localizationKeysMap?.[1]?.[1] || '';
    const offerDescription = localizationKeysMap?.[1]?.[2] || '';

    /**
     * Use iconUrl from transactionProduct array or if iconUrl === '', use assetsMap[1]
     */
    const imageUrl = iconUrl
        ? iconUrl
        : avatar
        ? avatar.split('/')[avatar?.split('/').length - 1]
        : '';

    const iconPath = iconUrl
        ? GAME_S3_BUCKET_URL_FOR_ICONS + '/' + imageUrl
        : GAME_S3_BUCKET_URL + `/ShopIcons/${imageUrl}`;

    /**
     * Get Offer Price from externalPriceReferences object by princeReference ID
     */
    const externalPriceReference = storeData?.externalPriceReferences[priceReference]?.[0];

    const usdAmount =
        externalPriceReference?.externalPriceReferencesMap?.[1]?.[3]?.[1]?.usdAmount || 0;
    const price = usdAmount / 100;

    /**
     * Get Offer background image
     */
    const splitUrl = offerBackgroundUrl?.split('/') || [];

    const offerBgUrl =
        splitUrl.length > 1 ? splitUrl[splitUrl.length - 1] : 'shop_default_bg_flames_left.png';

    const backgroundImg = GAME_S3_BUCKET_URL + '/ShopBackgrounds/' + offerBgUrl;

    /**
     * Get Discount Price from originalPriceReferences object by originPriceReferenceID
     */
    const originalPriceAmount = originalPriceReference
        ? storeData?.originalPriceReferences?.[originalPriceReference]?.[0]
              ?.externalPriceReferencesMap?.[1]?.[3]?.[1]?.usdAmount || 0
        : 0;

    const originalPrice = originalPriceAmount ? originalPriceAmount / 100 : 0;

    /**
     * Get Offer Name localized direct key (shopElement => description)
     */

    const keyOfSkuOfferMap = skuOfferMap ? Object.keys(skuOfferMap)?.[0] || '' : '';

    const skuOfferMapData = skuOfferMap[keyOfSkuOfferMap]?.[1]?.[0]?.[1];
    const maxPurchases = skuOfferMapData?.maxPurchases || 0;

    /**
     * Calculate Offers remaining transaction count
     * @param maxPurchases (max count of purchases)
     * @param playerCounterValue (count of transaction per user)
     */
    const purchaseLeftCount = maxPurchases - playerCounterValue;

    const { progressStartDate, progressEndDate } = formatDaysLeftRRule(
        recurrenceRule,
        startedDuration,
    );

    const progress = generateProgressDateInPercents(progressStartDate, progressEndDate);
    const progressText = generateProgressDate(progressEndDate);
    const isPromoOffer = !!offer.promoCodesFamilyId;

    return {
        purchaseLeftCount,
        description,
        price,
        iconPath,
        eventId,
        productId,
        progressText,
        progress,
        originalPrice,
        endDate: progressEndDate,
        backgroundImg,
        offerDescription,
        isPromoOffer,
    };
};

/**
 * logoutSpecialOffersDataFormatter function receives the identifier of storeOfferLiveOpsEvent object and storeSelector data
 * @param storeOffer
 * @param storeData
 */

interface ILogoutSpecialOffersDataFormatter {
    purchaseLeftCount: number;
    description: string;
    price: number;
    originalPrice: number;
    iconPath: string;
    productId: string;
    progressText: string;
    progress: number;
    identifier: string;
    endDate: string;
    backgroundImg: string;
    offerDescription: string;
    eventId: string;
    isPromoOffer: boolean;
}
export const logoutSpecialOffersDataFormatter = (
    storeOffer: TShopData,
    storeData: TShopData | null,
): IBeepCoinData | ILogoutSpecialOffersDataFormatter | IINITIAL_OFFER_VALUE => {
    if (!storeOffer || !storeData) return INITIAL_OFFER_VALUE;

    const offer = Array.isArray(storeOffer) ? storeOffer[0] : storeOffer;

    if (!offer) return INITIAL_OFFER_VALUE;

    const {
        recurrenceRule,
        skuOfferMap,
        transactionProducts,
        externalPriceReferences,
        startedDuration,
        discountPrice,
        offerBackgroundUrl,
    }: {
        recurrenceRule: string;
        skuOfferMap: ISkuOfferMap;
        transactionProducts: Array<ITransactionProducts>;
        externalPriceReferences: Array<{
            externalPriceReferencesMap: [
                string,
                { [key: number]: [string, { skuLabel: string; usdAmount: number }] },
            ];
        }>;
        startedDuration: number;
        discountPrice: number;
        offerBackgroundUrl: string;
    } = offer;

    const url = transactionProducts[0]?.iconUrl?.split('/')[1];
    const iconPath = GAME_S3_BUCKET_URL_FOR_ICONS + '/liveops/' + url;

    /**
     * Get Offer background image
     */
    const splitUrl = offerBackgroundUrl?.split('/') || [];

    const offerBgUrl =
        splitUrl.length > 1 ? splitUrl[splitUrl.length - 1] : 'shop_default_bg_flames_left.png';

    const backgroundImg = GAME_S3_BUCKET_URL + '/ShopBackgrounds/' + offerBgUrl;

    const price =
        externalPriceReferences?.[0]?.externalPriceReferencesMap?.[1]?.[3]?.[1]?.usdAmount / 100;

    const originalPrice = discountPrice ? discountPrice / 100 : 0;

    const keyOfSkuOfferMap = skuOfferMap ? Object.keys(skuOfferMap)?.[0] : '';

    const {
        progressText,
        progress,
        offerDescription,
        description,
        purchaseLeftCount,
        progressEndDate,
    } = generateOfferItems(
        skuOfferMap,
        keyOfSkuOfferMap,
        transactionProducts,
        recurrenceRule,
        startedDuration,
    );
    const productInfo: TStoreProductInfoList | undefined = storeData.storeProductInfoList?.find(
        (i: TStoreProductInfoList) => {
            return i.eventId === identifier;
        },
    );
    const eventId = productInfo?.eventId || '0';
    const isPromoOffer = !!offer.promoCodesFamilyId;

    return {
        purchaseLeftCount,
        description,
        price,
        eventId,
        iconPath,
        productId: transactionProducts[0]?.identifier,
        progressText,
        progress,
        originalPrice,
        endDate: progressEndDate,
        backgroundImg,
        offerDescription,
        isPromoOffer,
    };
};

/**
 *
 * @param offer
 * @returns {string[]|*[]}
 */

const getOfferKey = (offer: TShopData) => {
    return offer?.skuOfferMap ? Object.keys(offer.skuOfferMap) : [];
};

/**
 *
 * @param offer
 * @param isPromoOffer
 * @returns {*|boolean}
 */

const filterOffers = (offer: TShopData, isPromoOffer?: boolean) => {
    const offerKey = getOfferKey(offer);

    if (offer?.skuOfferMap && offerKey?.[0]) {
        const skuOfferMap = offer.skuOfferMap[offerKey[0]];

        if (skuOfferMap?.[1]?.[0]?.[1]) {
            const isPromo = isPromoOffer ? null : Boolean(offer.promoCodesFamilyId);
            const maxPurchases = skuOfferMap[1][0][1].maxPurchases;

            return maxPurchases && +maxPurchases > 0 && !isPromo;
        }
    }
};

/**
 * special / beepcoin offers tab manager
 * @param storeData
 * @param isBeepcoin {boolean}
 */

export const offersTabParser = (storeData: Array<TShopData>, isBeepcoin: boolean) => {
    if (isBeepcoin) {
        const avatarOffers = storeData
            ? storeData.filter((offer: TShopData) => {
                  const offerKey = getOfferKey(offer);
                  return offerKey[0] === avatarOfferIndex;
              })
            : [];

        const energyOffers = storeData
            ? storeData.filter((offer: TShopData) => {
                  const offerKey = getOfferKey(offer);
                  return offerKey[0] === energyOfferIndex;
              })
            : [];

        const dailyExchangeOffers = storeData
            ? storeData.filter((offer: TShopData) => {
                  const offerKey = getOfferKey(offer);
                  return offerKey[0] === dailyExchangeOfferIndex;
              })
            : [];

        const miscOffers = storeData
            ? storeData.filter((offer: TShopData) => {
                  const offerKey = getOfferKey(offer);
                  return offerKey[0] === miscOfferIndex;
              })
            : [];
        return {
            avatarOffers: avatarOffers?.filter((item: TShopData) => filterOffers(item)),
            energyOffers: energyOffers?.filter((item: TShopData) => filterOffers(item)),
            dailyExchangeOffers: dailyExchangeOffers?.filter((item: TShopData) =>
                filterOffers(item),
            ),
            miscOffers: miscOffers?.filter((item: TShopData) => filterOffers(item)),
        };
    } else {
        const promoCodeOffers = storeData
            ? storeData.filter((offer: TShopData) => {
                  return !!offer.promoCodesFamilyId;
              })
            : [];

        const featuredOffers = storeData
            ? storeData.filter((offer: TShopData) => {
                  const offerKey = getOfferKey(offer);
                  return offerKey[0] === featuredOfferIndex;
              })
            : [];

        const specialOffers = storeData
            ? storeData.filter((offer: TShopData) => {
                  const offerKey = getOfferKey(offer);
                  return offerKey[0] === specialOfferIndex;
              })
            : [];

        const essentialOffers = storeData
            ? storeData.filter((offer: TShopData) => {
                  const offerKey = getOfferKey(offer);
                  return offerKey[0] === essentialOfferIndex;
              })
            : [];

        const defaultOffers = storeData
            ? storeData.filter((offer: TShopData) => {
                  const offerKey = getOfferKey(offer);
                  return offerKey[0] === defaultOfferIndex;
              })
            : [];

        const eventOffers = storeData
            ? storeData.filter((offer: TShopData) => {
                  const offerKey = getOfferKey(offer);
                  return offerKey[0] === eventOfferIndex;
              })
            : [];

        return {
            promoCodeOffers: promoCodeOffers?.filter((item: TShopData) => filterOffers(item, true)),
            specialOffers: specialOffers?.filter((item: TShopData) => filterOffers(item)),
            featuredOffers: featuredOffers?.filter((item: TShopData) => filterOffers(item)),
            essentialOffers: essentialOffers?.filter((item: TShopData) => filterOffers(item)),
            defaultOffers: defaultOffers?.filter((item: TShopData) => filterOffers(item)),
            eventOffers: eventOffers?.filter((item: TShopData) => filterOffers(item)),
        };
    }
};

/**
 * Get current offer data
 * @param offer
 * @param store
 * @param isUserLoggedIn { boolean }
 * @param isBeepcoin { boolean }
 */

export const getOfferData = (
    offer: TShopData,
    store: TShopData | null,
    isUserLoggedIn: boolean,
    isBeepcoin = false,
) => {
    if (!isUserLoggedIn) {
        return isBeepcoin
            ? logoutBeepcoinOffersDataFormatter(offer, store)
            : logoutSpecialOffersDataFormatter(offer, store);
    }

    return isBeepcoin
        ? beepcoinOffersDataFormatter(offer, store)
        : specialOfferDataFormatter(offer, store);
};
