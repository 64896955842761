import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Hidden, useMediaQuery } from '@mui/material';

import { avatarPlayerSel } from '../../../store/avatarPlayer';
import { globalSel } from '../../../store/global';

import { PORTAL_S3_BUCKET_URL } from '../../../constants/api.constants';

import { titanAnalyticsEvents } from '../../../helpers/titanAnalytics';

import AppLogo from '../../components/AppLogo/AppLogo';

import { Box, Icon } from '../../core-ui';

import hamburger from '../../../assets/images/mobile-menu.png';
import UserPhoto from '../../../assets/images/UserDefaultAvatar.png';
import { Typography } from '../../../stories';
import { HeaderStyled } from './Header.styled';

export default function Header({ setOpenLeftSidebar, setOpenRightSidebar }) {
    // const [t] = useTranslation();
    const currentAvatar = useSelector(avatarPlayerSel.currentAvatarSelector);

    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const theme = useSelector(globalSel.themeSelector);
    const user = useSelector(globalSel.currentUserSelector);

    const currentImg = user?.avatar?.path
        ? !user?.avatar?.path.includes('https')
            ? `${PORTAL_S3_BUCKET_URL}${user?.avatar?.path}`
            : `${user?.avatar?.path}`
        : null;

    const currentSessionImg = currentAvatar?.path
        ? !currentAvatar?.path.includes('https')
            ? `${PORTAL_S3_BUCKET_URL}${currentAvatar?.path}`
            : `${currentAvatar?.path}`
        : null;

    const headerRef = useRef();
    const authenticated = useSelector(globalSel.isAuthSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);

    // const [showSearchbar, setShowSearchbar] = useState(false);

    // useEffect(() => {
    //     headerRef.current.style.backgroundColor = mdUp
    //         ? `rgba(250,250,250,${(1 / 120) * scrollTop})`
    //         : `${hexToRgba(theme.palette.background[200])}`;
    // }, [scrollTop]);
    if (mdUp) {
        return null;
    }

    // eslint-disable-next-line no-unreachable
    return (
        <HeaderStyled ref={headerRef}>
            <Box
                position="relative"
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
                height={1}
            >
                <Hidden mdUp>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        mr={8}
                        flexGrow={[1, 0]}
                    >
                        <Box
                            component="img"
                            mr={5}
                            width={24}
                            mb={1}
                            onClick={() => {
                                setOpenLeftSidebar(true);
                                titanAnalyticsEvents.sideBarButton('left', {
                                    'sys.user_ud': currentUser?.playerProfile?.id || '',
                                });
                            }}
                            src={hamburger}
                            className="cursor-pointer"
                            alt="hamburger"
                        />
                        <Box flexGrow={1}>
                            <AppLogo isLeftSide={false} setOpenLeftSidebar={setOpenLeftSidebar} />
                        </Box>
                    </Box>
                </Hidden>
                {/*<ClickAwayListener onClickAway={() => setShowSearchbar(false)}>*/}
                {/*    <div className="global-search-container-wrapper">*/}
                {/*        <Box*/}
                {/*            className={clsx('global-search-container', showSearchbar ? 'show' : '')}*/}
                {/*            top={10}*/}
                {/*        >*/}
                {/*            <Search*/}
                {/*                searchIcon="icon-search-2-line"*/}
                {/*                closeIcon="icon-close-fill"*/}
                {/*                closeIconColor={theme.palette.grey[600]}*/}
                {/*                placeholder={t('header.global.search.placeholder')}*/}
                {/*                theme={theme}*/}
                {/*                setShowSearchbar={setShowSearchbar}*/}
                {/*                searchFlag={searchFlag}*/}
                {/*                setSearchFlag={setSearchFlag}*/}
                {/*                openBackdrop={openBackdrop}*/}
                {/*                setOpenBackdrop={setOpenBackdrop}*/}
                {/*            />*/}
                {/*        </Box>*/}
                {/*        <Hidden smUp>*/}
                {/*            {!showSearchbar && (*/}
                {/*                <Box*/}
                {/*                    width={32}*/}
                {/*                    height={32}*/}
                {/*                    display="flex"*/}
                {/*                    alignItems="center"*/}
                {/*                    justifyContent="center"*/}
                {/*                    borderRadius="50%"*/}
                {/*                    onClick={() => setShowSearchbar(true)}*/}
                {/*                    bgcolor={theme.palette.background[100]}*/}
                {/*                >*/}
                {/*                    <Icon*/}
                {/*                        className="icon-search-2-line cursor-pointer"*/}
                {/*                        color={theme.palette.text[100]}*/}
                {/*                        size={20}*/}
                {/*                    />*/}
                {/*                </Box>*/}
                {/*            )}*/}
                {/*        </Hidden>*/}
                {/*    </div>*/}
                {/*</ClickAwayListener>*/}
                <Hidden mdUp>
                    {authenticated && currentUser ? (
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            {!mdUp && (
                                <Box display="flex" onClick={() => setOpenRightSidebar(true)}>
                                    <Box
                                        component="img"
                                        src={
                                            currentSessionImg ||
                                            currentImg ||
                                            // user?.info?.avatar ||
                                            UserPhoto
                                        }
                                        alt="user"
                                        ml={5}
                                        width={36}
                                        borderRadius="50px"
                                        my={1}
                                        mr={1}
                                    />
                                    <Hidden smDown>
                                        <Box
                                            maxWidth={200}
                                            width={1}
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="center"
                                            pr={1}
                                        >
                                            <Typography
                                                variant="p12"
                                                fWeight="semiBold"
                                                color={theme.palette.grey[900]}
                                                className="text-truncate"
                                                loaderWidth={100}
                                            >
                                                {user?.info?.userName}
                                            </Typography>

                                            <Typography
                                                variant="p12"
                                                color={theme.palette.grey[600]}
                                                loaderWidth={120}
                                            >
                                                {user?.playerProfile?.name}
                                            </Typography>
                                        </Box>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            pr={1}
                                        >
                                            <Icon className="icon-vector" size={16} />
                                        </Box>
                                    </Hidden>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            ml={[2, 6]}
                        >
                            <Icon
                                className="icon-users-1"
                                size={32}
                                onClick={() => {
                                    setOpenRightSidebar(true);
                                    titanAnalyticsEvents.sideBarButton('right', {
                                        'sys.user_ud': currentUser?.playerProfile?.id || '',
                                    });
                                }}
                            />
                        </Box>
                    )}
                </Hidden>
            </Box>
        </HeaderStyled>
    );
}

Header.propTypes = {
    setOpenLeftSidebar: PropTypes.func,
    setOpenRightSidebar: PropTypes.func,
    searchFlag: PropTypes.bool,
    setSearchFlag: PropTypes.func,
    openBackdrop: PropTypes.bool,
    setOpenBackdrop: PropTypes.func,
};
