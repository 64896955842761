import React from 'react';

import { ClickAwayListener, useTheme } from '@mui/material';

import { Box, Icon } from '../../core-ui';

import { Typography } from '../../../stories';
import { MenuStyled } from './Menu.styled';

export interface IMenuProps {
    bgcolor: string;
    menuContent: {
        content: string;
        icon: string;
        callback: () => void;
        testId: string;
    }[];
    handleClose: () => void;
    variant: 'bottom' | 'left' | 'top' | 'right';
}

export function Menu({ bgcolor, menuContent, handleClose, variant }: IMenuProps) {
    const theme = useTheme();

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <MenuStyled className={variant} bgcolor={bgcolor}>
                <Box p={2}>
                    {menuContent.map((menuItem) => {
                        const { content, icon, callback, testId } = menuItem;

                        return (
                            <Box
                                className="WP-Menu-item cursor-pointer"
                                key={content}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                position="relative"
                                width="100%"
                                height="100%"
                                onClick={callback}
                                data-testid={testId}
                                p={3}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    color={theme.palette.grey[600]}
                                >
                                    <Box display="flex" alignItems="center" pr={2.5}>
                                        <Icon className={icon} size={20} />
                                    </Box>

                                    <Typography variant="p12" fWeight="medium" loaderWidth={120}>
                                        {content}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </MenuStyled>
        </ClickAwayListener>
    );
}
