import React from 'react';

import { createGlobalStyle } from 'styled-components';

import { icons } from './icons/icons';

export interface IGlobalStyled {
    online: boolean;
    authenticated: boolean;
}

export const GlobalStyles = createGlobalStyle<IGlobalStyled>`
    ${icons};

    html {
        scrollbar-width: none;
    }

    body {
        margin: 0;
        background: ${({ theme }) => theme.palette.background[50]};
        color: ${({ theme }) => theme.palette.grey[800]};
        font-family: ${({ theme }) => theme.typography.font.inter}, sans-serif;

        iframe {
            display: ${({ authenticated, online }) =>
                !authenticated || !online ? 'none' : 'block'};
        }
    }

    * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
    }

    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
    }

    input {
        border: none;

        &:focus-visible {
            outline: none;
        }
    }

    ::-webkit-scrollbar {
        display: none;
        width: 0;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .cursor-default {
        cursor: default !important;
    }

    .text-uppercase {
        text-transform: uppercase;
    }

    .text-decoration {
        text-decoration: none;
    }

    .disable-events {
        pointer-events: none;
    }

    .text-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .text-2-line-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .text-decoration-line-through {
        text-decoration: line-through;
    }

    .text-capitalize {
        text-transform: capitalize;
    }

    .text-uppercase {
        text-transform: uppercase;
    }

    .background-cover {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    #ot-sdk-btn-floating.ot-floating-button {
        display: none;
    }

    /****** MUI DEFAULT STYLES OVERRIDE ******\

    /*** Skeleton ***/
    .MuiSkeleton {
        &-root {
            background-color: ${({ theme }) => theme.palette.grey[300]};
            backdrop-filter: blur(8px);
        }

        &-reactangular, &-text, &-rect {
            border-radius: 12px;
        }

        &-circle {
            border-radius: 50%;
        }

        &-wave {
            &:after {
                background: linear-gradient(90deg, transparent, ${({ theme }) =>
                    theme.palette.grey[400]}, transparent) !important;
            }
        }
    }

    /*** Dialog ***/
    .MuiDialog {
        &-paper {
            overflow: visible !important;
            max-width: none !important;
            border-radius: 12px !important;
            margin: 20px !important;
            padding: 8px 0;
            max-height: calc(100% - 60px) !important;
        }
    }

    .MuiDialogContent {
        &-root {
            padding: 0 !important;
        }
    }

    .MuiDialogActions {
        &-root {
            display: block !important;
            padding: 0 !important;
        }
    }

    /*** Slider ***/
    .MuiSlider {
        &-root {
            color: ${({ theme }) => theme.palette.purple[600]} !important;
            height: 12px !important;
            padding: 12px 0 !important;
        }

        &-track, &-rail {
            height: 12px !important;
            border-radius: 20px !important;
        }

        &-thumb {
            width: 24px !important;
            height: 24px !important;
            border: 6px solid ${({ theme }) => theme.palette.common.white} !important;
            box-shadow: 0 7px 20px rgba(20, 22, 68, 0.12) !important;

            &:focus, &:hover, &:active {
                box-shadow: inherit !important;
            }
        }
    }

` as React.ElementType;
