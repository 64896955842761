import { TFunction } from 'react-i18next';

import { add, startOfWeek } from 'date-fns';

import { TEvent, CalendarPeriod } from '../@types';

import { getNextMonday, getNextSunday, isBetweenDates } from './date-formatters';

/**
 *
 * @param t
 * @returns {[{text, id: number, key: string},{text, id: number, title, key: string},{text, id: number, title, key: string},{text, id: number, title, key: string},{text, id: number, title, key: string}]}
 */
export const getPeriodType = (
    t: TFunction,
): Array<{ text: string; id: number; title?: string; key: string }> => [
    { text: t('calendar.tab.show.all'), key: CalendarPeriod.ALL, id: 0 },
    {
        text: t('calendar.tab.today'),
        title: t('right.bar.today.events.title'),
        key: CalendarPeriod.TODAY,
        id: 1,
    },
    {
        text: t('calendar.tab.tomorrow'),
        title: t('right.bar.tomorrow.events.title'),
        key: CalendarPeriod.TOMORROW,
        id: 2,
    },
    {
        text: t('calendar.tab.this.week'),
        title: t('calendar.table.title.this.week'),
        key: CalendarPeriod.THIS_WEEK,
        id: 3,
    },
    {
        text: t('calendar.tab.next.week'),
        title: t('calendar.table.title.next.week'),
        key: CalendarPeriod.NEXT_WEEK,
        id: 4,
    },
];

/**
 *
 * @param dateInterval
 * @param isNext
 * @returns {boolean}
 */
export const isWeekFallsIntoInterval = (
    dateInterval: { from: Date; to: Date },
    isNext?: boolean,
): boolean => {
    const monday = isNext ? getNextMonday() : startOfWeek(new Date(), { weekStartsOn: 1 });
    const sunday = isNext ? getNextSunday() : startOfWeek(new Date());

    return (
        isBetweenDates(dateInterval.from, dateInterval.to, monday) ||
        isBetweenDates(dateInterval.from, dateInterval.to, sunday)
    );
};

/**
 *
 * @param eventsData
 * @param t
 * @param key
 * @returns {*}
 */
export const getList = (eventsData: TEvent[], t: TFunction, key?: string): TEvent[] => {
    // ToDo test if we need this once we have data for calendar
    const events = eventsData.slice();

    const calendarEvents: {
        today: TEvent[];
        tomorrow: TEvent[];
        thisWeek: TEvent[];
        nextWeek: TEvent[];
    } = {
        today: [],
        tomorrow: [],
        thisWeek: [],
        nextWeek: [],
    };

    const startDate = (e: TEvent) => {
        return new Date(e['Start date (UTC)']);
    };
    const endDate = (e: TEvent) => {
        return new Date(e['End date (UTC)']);
    };
    switch (key) {
        case CalendarPeriod.ALL: {
            const thisWeekEvents = getList(eventsData, t, CalendarPeriod.THIS_WEEK);
            const nextWeekEvents = getList(eventsData, t, CalendarPeriod.NEXT_WEEK);
            return [...thisWeekEvents, ...nextWeekEvents];
        }
        case CalendarPeriod.TODAY: {
            const data = events?.filter((e) => {
                const from = startDate(e);
                const to = endDate(e);

                return isBetweenDates(from, to, new Date());
            });

            calendarEvents.today = data.sort((a, b) => {
                return startDate(a).getTime() - startDate(b).getTime();
            });

            return calendarEvents.today;
        }
        case CalendarPeriod.TOMORROW: {
            const tomorrowDate = add(new Date(), { days: 1 });

            const data = events?.filter((e) => {
                const from = startDate(e);
                const to = endDate(e);

                return isBetweenDates(from, to, tomorrowDate);
            });
            calendarEvents.tomorrow = data.sort((a, b) => {
                return startDate(a).getTime() - startDate(b).getTime();
            });
            return calendarEvents.tomorrow;
        }
        case CalendarPeriod.THIS_WEEK: {
            const data = events?.filter((e) => {
                return isWeekFallsIntoInterval({
                    from: startDate(e),
                    to: endDate(e),
                });
            });

            calendarEvents.thisWeek = data.sort((a, b) => {
                return startDate(a).getTime() - startDate(b).getTime();
            });

            return calendarEvents.thisWeek;
        }
        case CalendarPeriod.NEXT_WEEK: {
            const data = events?.filter((e) => {
                return isWeekFallsIntoInterval(
                    {
                        from: startDate(e),
                        to: endDate(e),
                    },
                    true,
                );
            });

            calendarEvents.nextWeek = data.sort((a, b) => {
                return startDate(a).getTime() - startDate(b).getTime();
            });

            return calendarEvents.nextWeek;
        }
        default:
            return events.sort((a, b) => {
                return startDate(a).getTime() - startDate(b).getTime();
            });
    }
};
