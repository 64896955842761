import { TEvent } from 'src/@types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialCalendarEventState } from './initialState';

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState: initialCalendarEventState(),
    reducers: {
        setEvents(state, action: PayloadAction<Array<TEvent>>) {
            state.events = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
    },
});
