import styled from 'styled-components';

export const ModalContentInfoStyled = styled.div`
    max-height: 120px;
    width: 200px;
    color: ${({ theme }) => theme.palette.grey[700]};
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.palette.grey[500]}
        ${({ theme }) => theme.palette.grey[300]};

    &::-webkit-scrollbar {
        width: 4px;
        display: block;
    }

    &::-webkit-scrollbar-track {
        background: ${({ theme }) => theme.palette.grey[300]};
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.palette.grey[500]};
        border-radius: 10px;
        border: none;
    }
    ul {
        padding: 0 0 0 14px;
        margin-top: 0;
        li {
            &::marker {
                margin: 0;
                padding: 0;
            }
        }
    }
`;
