import { useEffectOnce } from './useEffectOnce';

const helpshiftScript = `
        (function() {
    var PLATFORM_ID = 'scopely_platform_20210125150414941-b8426b0afa582f6',
        DOMAIN = 'scopely',
        LANGUAGE = 'en';

    window.helpshiftConfig = {
        platformId: PLATFORM_ID,
        domain: DOMAIN,
        language: LANGUAGE,
    };
})();

!function(t, e) {
    if ('function' != typeof window.Helpshift) {
        var n = function() {
            n.q.push(arguments);
        };
        n.q = [], window.Helpshift = n;
        var i, a = t.getElementsByTagName('script')[0];
        if (t.getElementById(e)) return;
        i = t.createElement('script'), i.async = !0, i.id = e, i.src = 'https://webchat.helpshift.com/latest/webChat.js';
        var o = function() {
            window.Helpshift('init');
        };
        window.attachEvent ? i.attachEvent('onload', o) : i.addEventListener('load', o, !1), a.parentNode.insertBefore(i, a);
    } else window.Helpshift('update');
}(document, 'hs-chat');
        `;

export default function useHelpshiftLoadHook() {
    useEffectOnce(() => {
        const script = document.createElement('script');
        script.innerHTML = helpshiftScript;
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(script);
    });
}
