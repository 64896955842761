import React, { FC } from 'react';

import { Skeleton as MuiSkeleton, SkeletonProps as MuiSkeletonProps } from '@mui/material';

export type SkeletonProps = Omit<MuiSkeletonProps, 'variant'> & {
    variant?: MuiSkeletonProps['variant'] | 'rounded';
};

export const Skeleton: FC<SkeletonProps> = ({ variant, style, ...props }) => {
    return (
        <MuiSkeleton
            variant={variant === 'rounded' ? 'rectangular' : variant}
            style={{
                ...style,
                ...(variant === 'rounded' && {
                    borderRadius: '8px',
                }),
            }}
            {...props}
        />
    );
};
