import { INavItem } from 'src/@types';

export const NAVIGATION_LIST: Array<INavItem> = [
    {
        id: 1,
        name: 'left.bar.navigation.list.dashboard',
        href: '/',
        iconClassname: 'icon-home-house-line',
        maintenanceModeName: 'dashboard',
    },
    {
        id: 2,
        name: 'left.bar.navigation.list.leaderboard',
        href: '/leaderboard',
        iconClassname: 'icon-business-products',
        maintenanceModeName: 'leaderboard',
    },
    {
        id: 3,
        name: 'left.bar.navigation.list.special.offers',
        href: '/special-offers',
        iconClassname: 'icon-bag-circle-essential-interface-shopping-ui-1',
        maintenanceModeName: 'offers',
    },
    {
        id: 4,
        name: 'left.bar.navigation.list.events',
        href: '/events',
        iconClassname: 'icon-social-medias-rewards-rating-5',
        hasBadge: true,
        isPrivate: true,
        maintenanceModeName: 'events',
    },
    {
        id: 5,
        name: 'left.bar.navigation.list.beepcoin.shop',
        href: '/beepcoin-shop',
        iconClassname: 'icon-money-dollar-circle-fill',
        maintenanceModeName: 'shop',
    },
    {
        id: 6,
        name: 'left.bar.navigation.list.calendar',
        href: '/calendar',
        iconClassname: 'icon-calendar-schedule-31-1',
        maintenanceModeName: 'calendar',
    },
    {
        id: 7,
        name: 'left.bar.navigation.list.news',
        href: '/news',
        iconClassname: 'icon-menu-burger-square',
        maintenanceModeName: 'news',
    },
    {
        id: 8,
        name: 'left.bar.navigation.list.toon.guide',
        href: '/toon-guide',
        iconClassname: 'icon-interface-essential-book',
        maintenanceModeName: 'toonGuide',
    },
];
