import React, { memo } from 'react';
import { LazyLoadImage as LazyLoadComponent } from 'react-lazy-load-image-component';

import { Box } from '../../../views/core-ui';

export interface ILazyLoadImageProps {
    src: string;
    alt: string;
    width?: string;
    height?: string | number;
    borderRadius?: string;
}

export interface ILazyLoadImageSpecificProps {
    draggable?: boolean;
    threshold?: number;
    delayMethod?: string;
    effect?: string;
    wrapperClassName?: string;
}

const LazyImage = ({
    src,
    alt = '',
    width = 'auto',
    height = 'auto',
    draggable = true,
    borderRadius = '0px',
}: ILazyLoadImageProps & ILazyLoadImageSpecificProps) => {
    /**
     * You can check the react-lazy-load-image-component documentation here:
     * https://www.npmjs.com/package/react-lazy-load-image-component
     */
    return (
        <Box
            component={LazyLoadComponent}
            borderRadius={borderRadius}
            alt={alt}
            src={src}
            width={width}
            height={height}
            draggable={draggable}
            threshold={12}
            delayMethod="debounce"
            effect="blur"
            wrapperClassName="wrapperClassName"
        />
    );
};

export default memo(LazyImage);
