import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import i18n from 'i18next';
import platform from 'platform';
import { ThemeProvider } from 'styled-components';

import { ThemeProvider as ThemeProviderMUI } from '@mui/material';

import { GlobalStyles } from './theme/global.styled';
import './theme/styles.scss';
import { theme } from './theme/themeMUI';

import { beepCoinShopOp, beepCoinShopSel } from './store/beepCoinShop';
import { calendarOp } from './store/calendar';
import { globalOp, globalSel } from './store/global';
import { leaderboardsOp } from './store/leaderboards';
import { localizationOp, localizationSel } from './store/localization';
import { newsOp } from './store/news';
import { specialOffersOp, specialOffersSel } from './store/specialOffers';

import { notAvailableRouters, pageMaintenanceModeRenderer } from './helpers/appHelpers';
import { getStorageItem } from './helpers/localStorage';
import { useAttributionHook } from './helpers/useAttribution.hook';

import { useEffectOnce } from './hooks/useEffectOnce';

import Layout from './views/layout/Layout';

import Maintenance from './views/pages/Maintenance/Maintenance';
import NotFoundPage from './views/pages/NotFound/NotFound';

import BrowserSupport from './views/components/BrowserSupport/BrowserSupport';
import ConnectionLost from './views/components/ConnectionLost/ConnectionLost';
import Modal from './views/components/Modal/Modal';
import SnackBar from './views/components/SnackBar/SnackBar';

import { APP_ROUTES } from './AppRoutes';
import './i18n';

const App: React.FC = () => {
    const dispatch = useDispatch();

    const [online, setOnline] = useState(true);

    const currentUser = useSelector(globalSel.currentUserSelector);
    const authenticated = useSelector(globalSel.isAuthSelector);
    const maintenanceMode = useSelector(globalSel.maintenanceModeSelector);
    const globalConfig = useSelector(globalSel.globalConfigSelector);
    const localization = useSelector(localizationSel.localizationSelector);
    const isLoginFaceBookLoading = useSelector(globalSel.isLoadingFacebookSelector);
    const isLoginScopelyLoading = useSelector(globalSel.isLoadingScopelySelector);
    const specialOffers = useSelector(specialOffersSel.shopItemsSelector);
    const beepcoinOffers = useSelector(beepCoinShopSel.beepCoinShopDataSelector);

    const accessToken = getStorageItem('accessToken');
    const refreshToken = getStorageItem('refreshToken');

    const userDetailsForTitan = {
        'sys.user_id': currentUser?.playerProfile?.id || '',
        player_name: currentUser?.playerProfile?.name || '',
    };

    useAttributionHook(userDetailsForTitan);

    const isFacebookBrowser = useMemo(() => {
        const ua = navigator.userAgent || navigator.vendor;

        return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
    }, []);

    useEffectOnce(() => {
        window.addEventListener('online', () => setOnline(navigator.onLine));
        window.addEventListener('offline', () => setOnline(navigator.onLine));

        return () => {
            window.removeEventListener('online', () => setOnline(navigator.onLine));
            window.removeEventListener('offline', () => setOnline(navigator.onLine));
        };
    });

    useEffectOnce(() => {
        dispatch(localizationOp.getLocalization('en'));
        dispatch(calendarOp.getEvents());
        dispatch(globalOp.getDashboardBannerConfigs());
        dispatch(globalOp.getGlobalConfigs());
        dispatch(leaderboardsOp.getGlobalLeaderboardData());
        dispatch(newsOp.getNewsData(1));
    });

    useEffect(() => {
        if (!beepcoinOffers && !isLoginFaceBookLoading && !isLoginScopelyLoading) {
            if (!accessToken) {
                dispatch(beepCoinShopOp.getBeepCoinOffersLoggedOut());
                dispatch(beepCoinShopOp.getAvatarOffersLoggedOut());
            } else if (accessToken && currentUser) {
                dispatch(beepCoinShopOp.getBeepCoinOffers());
                dispatch(beepCoinShopOp.getAvatarOffers());
            }
        }
    }, [
        accessToken,
        dispatch,
        beepcoinOffers,
        isLoginFaceBookLoading,
        currentUser,
        isLoginScopelyLoading,
    ]);

    useEffect(() => {
        if (localization) {
            i18n.use(initReactI18next).init({
                resources: {
                    en: {
                        translation: localization,
                    },
                },
                lng: 'en',
                keySeparator: false,
                interpolation: {
                    escapeValue: false,
                },
            });
        }
    }, [localization]);

    useEffect(() => {
        if (accessToken && !authenticated && !currentUser) {
            dispatch(globalOp.authMe(accessToken, refreshToken));
        }
    }, [authenticated, accessToken, dispatch, refreshToken, currentUser]);

    useEffect(() => {
        if (!specialOffers && !isLoginFaceBookLoading && !isLoginScopelyLoading) {
            if (!accessToken) {
                dispatch(specialOffersOp.getSpecialOffersLoggedOut());
            } else if (accessToken && currentUser) {
                dispatch(specialOffersOp.getSpecialOffers());
            }
        }
    }, [
        accessToken,
        dispatch,
        isLoginFaceBookLoading,
        isLoginScopelyLoading,
        currentUser,
        specialOffers,
    ]);

    useAttributionHook(userDetailsForTitan);

    return (
        <ThemeProviderMUI theme={theme}>
            <ThemeProvider theme={theme}>
                <>
                    {platform?.description?.includes('Opera') || isFacebookBrowser ? (
                        <BrowserSupport />
                    ) : (
                        <Router>
                            {!online ? (
                                <ConnectionLost />
                            ) : (
                                globalConfig &&
                                (!maintenanceMode.global ||
                                (typeof globalConfig['global'] !== 'undefined' &&
                                    !globalConfig['global']) ? (
                                    <Routes>
                                        <Route path="/" element={<Maintenance />} />
                                    </Routes>
                                ) : (
                                    <Layout>
                                        <Suspense fallback={null}>
                                            <Routes>
                                                {APP_ROUTES.map((route) => (
                                                    <Route
                                                        key={route.path}
                                                        path={route.path}
                                                        element={
                                                            pageMaintenanceModeRenderer(
                                                                route.path,
                                                                maintenanceMode,
                                                            ) ||
                                                            notAvailableRouters(
                                                                globalConfig,
                                                            ).includes(route.path) ? (
                                                                <Maintenance />
                                                            ) : (
                                                                <route.component />
                                                            )
                                                        }
                                                    />
                                                ))}

                                                <Route
                                                    path="/shop"
                                                    element={
                                                        <Navigate to="/special-offers" replace />
                                                    }
                                                />

                                                {/*<Route path="/player">*/}
                                                {/*    <Redirect to={`player/${currentUser?.id}`} />*/}
                                                {/*</Route>*/}
                                                {/*<Route path="/alliance">*/}
                                                {/*    <Redirect to={`alliance/${currentUser?.id}`} />*/}
                                                {/*</Route>*/}

                                                <Route path="*" element={<NotFoundPage />} />
                                            </Routes>
                                        </Suspense>
                                    </Layout>
                                ))
                            )}

                            <Modal />
                            <SnackBar />
                        </Router>
                    )}

                    <GlobalStyles authenticated={authenticated && currentUser} online={online} />
                </>
            </ThemeProvider>
        </ThemeProviderMUI>
    );
};

export default App;
