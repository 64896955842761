import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';

import { Box } from '../../../core-ui';

import { Typography } from '../../../../stories';

const OfferNotAvailableModal: FC = () => {
    const [t] = useTranslation();
    const theme = useTheme();
    return (
        <Box width={[1, 537]} pt={[10, 15]} px={4}>
            <Box
                className="WP-Modal-Icon"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border={1}
                    borderRadius="50%"
                    borderColor={theme.palette.purple[100]}
                    width={120}
                    height={120}
                >
                    <Box
                        width={500}
                        height={500}
                        className="WP-Modal-Offer-Not-Available WP-Modal-Icon-Background"
                    />
                </Box>

                <Box pt={6} pb={3}>
                    <Typography fWeight="bold" variant="h5" color={theme.palette.text[300]}>
                        {t('modal.offer.not.available.title')}
                    </Typography>
                </Box>
                <Box pb={[10, 25]} px={4} textAlign="center">
                    <Typography variant="p16" color={theme.palette.text[100]}>
                        {t('modal.offer.not.available.description')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default OfferNotAvailableModal;
