import React, { memo } from 'react';

import { useTheme } from '@mui/material';

import { Box } from '../../../core-ui';

import { Typography } from '../../../../stories';
import { ModalContentInfoStyled } from './ModalContentInfo.styled';

interface IModalContentInfoProps {
    data: Array<string>;
}
function ModalContentInfo({ data }: IModalContentInfoProps) {
    const theme = useTheme();

    return (
        <ModalContentInfoStyled>
            <ul>
                {data?.map((i, index) => {
                    return (
                        <Box mb={3} key={index}>
                            <Typography
                                variant="p12"
                                loaderWidth={100}
                                fWeight="regular"
                                color={theme.palette.grey[700]}
                            >
                                <li>{i}</li>
                            </Typography>
                        </Box>
                    );
                })}
            </ul>
        </ModalContentInfoStyled>
    );
}

export default memo(ModalContentInfo);
