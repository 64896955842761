import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextField, useTheme } from '@mui/material';

import { specialOffersOp, specialOffersSel } from '../../../../store/specialOffers';

import { Box } from '../../../core-ui';

import ConfirmModalBackGroundImage from '../../../../assets/images/modals/ConfirmModalBackgrount.png';
import { Button, LazyImage, Typography } from '../../../../stories';

const PromoModal = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const offerModalData = useSelector(specialOffersSel.offerModalDataSelector);
    const iconPath = offerModalData?.iconPath || '';
    const eventId = offerModalData?.eventId || '';

    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e) return;

        if (error) {
            setError(false);
        }

        setValue(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleGetOffer();
        }
    };

    const handleGetOffer = () => {
        if (!value) return;

        return dispatch(specialOffersOp.promoCodePurchase(value, eventId, setError));
    };

    React.useEffect(() => {
        return () => {
            dispatch(specialOffersOp.setProcessingId(''));
            setError(false);
        };
    }, [dispatch]);

    const PromoModalImage = React.useMemo(() => {
        return (
            <Box width={1} display="flex" flexDirection="column" justifyContent="center" mb={6}>
                <Box width={1} display="flex" justifyContent="center">
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        bordercolor={theme.palette.purple[600]}
                        border={2}
                        borderRadius="50%"
                        width={120}
                        height={120}
                        py={1}
                        px={1}
                    >
                        <Box
                            bgimage={ConfirmModalBackGroundImage}
                            width="100px"
                            height="100px"
                            className="background-cover"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <LazyImage
                                    src={iconPath}
                                    alt="offer-icon"
                                    height="80px"
                                    draggable={false}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }, [theme.palette.purple, iconPath]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width={[1, '537px']}
            pt={13}
            pb={8}
            px={[6, 9]}
            textAlign="center"
        >
            {PromoModalImage}

            <Box mb={[3, 6]}>
                <Typography variant="h5" color={theme.palette.grey[800]} fWeight="bold">
                    Purchase offer with Promo Code
                </Typography>
            </Box>

            <Box mb={[7, 8]}>
                <Typography variant="p16" color={theme.palette.grey[600]}>
                    Please fill in your Promo Code to get the offer
                </Typography>
            </Box>

            <Box mb={5}>
                <TextField
                    error={error}
                    label="Promo Code"
                    helperText={error && 'Invalid promo code. Please try again.'}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    autoFocus
                />
            </Box>

            {!error ? <Box width={1} height={[10, 20]} /> : null}

            <Box width="179px">
                <Button
                    variant="primary"
                    label="Get Offer"
                    icon="icon-promo"
                    onClick={handleGetOffer}
                    isDisabled={Boolean(!value)}
                    fullWidth
                />
            </Box>
        </Box>
    );
};

export default PromoModal;
