import React from 'react';
import { NavLink } from 'react-router-dom';

import { format } from 'date-fns';

import { useTheme } from '@mui/material/styles';

import { TEvent } from '../../../../@types';

import { sliceNewsFrameLink } from '../../../../helpers/sliceNewsFrameLink';

import { Box, Icon } from '../../../core-ui';

import { Typography } from '../../../../stories';
import { EventItemStyled } from './EventItem.styled';

interface IEventItemProps {
    event: TEvent;
}

export default function EventItem({ event }: IEventItemProps) {
    const theme = useTheme();

    const endingTime = new Date(event['End date (UTC)']);
    const endingTimeHours = format(endingTime, 'hh:mm');
    const endingTimeDay = format(endingTime, 'MMM dd, yyyy');

    return (
        <EventItemStyled>
            <Box py={5} display="flex" borderBottom={1} bordercolor={theme.palette.grey[300]}>
                <Box
                    bgcolor={theme.palette.blue[100]}
                    borderRadius="4px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={32}
                    width={32}
                >
                    <Icon
                        className="icon-social-medias-rewards-rating-2"
                        size={24}
                        color={theme.palette.blue[600]}
                    />
                </Box>

                <Box className="card-content">
                    {event['Hyperlink'] ? (
                        <Box flex={4} pr={[0, 2]}>
                            <Typography fWeight="bold" loaderWidth={100} variant="p14">
                                <NavLink
                                    to={`/calendar/${sliceNewsFrameLink(event['Hyperlink'])}`}
                                    className="WP-RightSideMenu-hyperLinkStyle text-decoration"
                                >
                                    {event['Event name']}
                                </NavLink>
                            </Typography>
                        </Box>
                    ) : (
                        <Typography
                            variant="p12"
                            fWeight="regular"
                            loaderWidth={120}
                            color={theme.palette.grey[600]}
                        >
                            {event['Event name'] || ''}
                        </Typography>
                    )}
                    <Typography
                        variant="p10"
                        fWeight="regular"
                        color={theme.palette.grey[600]}
                        loaderWidth={160}
                    >
                        {`Ends on ${endingTimeDay} at ${endingTimeHours}`}
                    </Typography>
                </Box>
            </Box>
        </EventItemStyled>
    );
}
