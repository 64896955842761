import { TAvatar } from 'src/@types';

const avatarSelector = ({ avatar: { avatars } }: { avatar: TAvatar }) => avatars;
const currentAvatarSelector = ({ avatar: { currentAvatar } }: { avatar: TAvatar }) => currentAvatar;
const avatarLoaderSelector = ({ avatar: { avatarLoader } }: { avatar: TAvatar }) => avatarLoader;

export const avatarPlayerSel = {
    avatarSelector,
    currentAvatarSelector,
    avatarLoaderSelector,
};
