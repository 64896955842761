import { TTypography } from 'src/@types';

export const typography: TTypography = {
    size: {
        h1: { fontSize: '48px', lineHeight: '72px' },
        h2: { fontSize: '40px', lineHeight: '56px' },
        h3: { fontSize: '32px', lineHeight: '44px' },
        h4: { fontSize: '28px', lineHeight: '40px' },
        h5: { fontSize: '24px', lineHeight: '32px' },
        h6: { fontSize: '20px', lineHeight: '28px' },
        p20: { fontSize: '20px', lineHeight: '28px' },
        p18: { fontSize: '18px', lineHeight: '24px' },
        p16: { fontSize: '16px', lineHeight: '24px' },
        p14: { fontSize: '14px', lineHeight: '24px' },
        p12: { fontSize: '12px', lineHeight: '20px' },
        p10: { fontSize: '10px', lineHeight: '16px' },
        p8: { fontSize: '8px', lineHeight: '12px' },
        p: { fontSize: '8px', lineHeight: '12px' },
    },
    weight: {
        bold: 700,
        semiBold: 600,
        medium: 500,
        regular: 400,
    },

    font: {
        rubik: 'Rubik',
        inter: 'Inter',
        riffic: 'Riffic',
    },
};
