import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Skeleton from '@mui/material/Skeleton';

import { localizationSel } from '../../../store/localization';

import { Box } from '../../../views/core-ui';

import usePalette from '../../palette';
import { LabelStyled } from './Label.styled';

export interface TLabel {
    variant: 'purple' | 'grey' | 'green' | 'orange' | 'red' | 'blue' | 'solid' | 'transparent';
    navigationLink?: string;
    label?: string;
    bordered?: boolean;
    rounded?: boolean;
    clickable?: boolean;
    size?: 'small' | 'medium' | 'large';
    className?: string;
    isDisabled?: boolean;
    isStory?: boolean;
}

function Label({
    variant = 'orange',
    navigationLink,
    label = '',
    bordered = false,
    rounded = false,
    clickable = false,
    size = 'medium',
    className = '',
    isStory,
    ...props
}: TLabel) {
    const { labels } = usePalette();
    const navigate = useNavigate();
    const localizationLoading = useSelector(localizationSel.loadingSelector);

    const changeRoute = () => {
        if (navigationLink) {
            navigate(navigationLink);
        }
    };

    return (
        <LabelStyled
            variant={labels[variant] ?? labels['primary']}
            bordered={bordered}
            rounded={rounded}
            size={size}
            clickable={clickable}
            onClick={changeRoute}
            className={className}
            {...props}
        >
            {localizationLoading && !isStory ? (
                <Box
                    component={Skeleton}
                    variant="text"
                    animation="wave"
                    height={16}
                    width="100%"
                    minWidth={24}
                />
            ) : (
                label
            )}
        </LabelStyled>
    );
}

export default memo(Label);
