import styled from 'styled-components';

export const IconStyled = styled.i`
    color: ${({ color }) => color};
    font-size: ${({ size }) => size}px;

    &.with-transition {
        transform: rotate(180deg);
        transition: transform 0.2s ease-out;
    }
`;
