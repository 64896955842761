import { sub, format } from 'date-fns';
import { TTop10Players, TTop500Leaderboard } from 'src/@types';

import { services } from '../../services/fetch.service';
import { AppDispatch } from '../configureStore';
import { leaderboardsSlice } from './leaderboardsSlice';

/**
 * Retry Counts initializer
 * @type {number}: 0 - if not retried yet, 1 - if retried
 */
let globalRetryCount = 0;
let topPlayersRetryCount = 0;
let topAlliancesRetryCount = 0;
let topEventsRetryCount = 0;
type TTop500RetryCount = {
    user_tcp: number;
    newuser_tcp: number;
    gain_tcp: number;
    arena_pos1: number;
    alliance_tcp: number;
    newalliance_tcp: number;
    bomb_won: number;
    bomb_lost: number;
    alliance_ldb: number;
    user_ldb: number;
};
type TTop500RetryCountUnion =
    | 'user_tcp'
    | 'newuser_tcp'
    | 'gain_tcp'
    | 'arena_pos1'
    | 'alliance_tcp'
    | 'newalliance_tcp'
    | 'bomb_won'
    | 'bomb_lost'
    | 'alliance_ldb'
    | 'user_ldb';

const top500RetryCount: TTop500RetryCount = {
    user_tcp: 0,
    newuser_tcp: 0,
    gain_tcp: 0,
    arena_pos1: 0,
    alliance_tcp: 0,
    newalliance_tcp: 0,
    bomb_won: 0,
    bomb_lost: 0,
    alliance_ldb: 0,
    user_ldb: 0,
};

const today = new Date();
/**
 ** HOMEPAGE LEADERBOARDS
 */
const getGlobalLeaderboardData = (daysDiff = 1) => {
    const date = format(sub(today, { days: daysDiff }), 'yyyyMMdd');

    const widgetsObjectId = '1';
    const playersObjectId = '2';
    const allianceObjectId = '3';

    const {
        setGlobalAllianceLeaderboard,
        setGlobalLoading,
        setGlobalPlayerLeaderboard,
        setWidgetsLeaderboard,
    } = leaderboardsSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setGlobalLoading(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v2/leaderboards/analytics/top10?date=${date}`,
                method: 'GET',
            });

            const topPlayers = data?.filter((item: TTop10Players) => {
                return item.objectId === playersObjectId;
            });

            const topAlliances = data?.filter((item: TTop10Players) => {
                return item.objectId === allianceObjectId;
            });

            const widgetsTop5Event = data?.filter((item: TTop10Players) => {
                return item.objectId === widgetsObjectId;
            });

            await dispatch(setWidgetsLeaderboard(widgetsTop5Event));
            await dispatch(setGlobalPlayerLeaderboard(topPlayers));
            await dispatch(setGlobalAllianceLeaderboard(topAlliances));
            await dispatch(setGlobalLoading(false));
        } catch (e) {
            if (globalRetryCount === 0) {
                globalRetryCount += 1;

                dispatch(getGlobalLeaderboardData(2));
            } else {
                // eslint-disable-next-line no-console
                console.error(e, 'error on global leaderboard data');
                dispatch(setGlobalLoading(false));
            }
        }
    };
};

/**
 ** LEADERBOARDS PAGE
 */
const getLeaderboardTopPlayers = (daysDiff = 1) => {
    const limit = 3;

    const date = sub(today, { days: daysDiff });
    const formattedDate = format(date, 'yyyyMMdd');
    const formattedDateToStore = format(date, 'dd/MM/yyyy');

    const { setTopPlayersLeaderboardPageLoading, setTopPlayersDate, setTopPlayersLeaderboardPage } =
        leaderboardsSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setTopPlayersLeaderboardPageLoading(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v2/leaderboards/analytics/top-players?date=${formattedDate}&limit=${limit}`,
                method: 'GET',
            });

            await dispatch(setTopPlayersLeaderboardPage(data));
            await dispatch(setTopPlayersDate(formattedDateToStore));
            await dispatch(setTopPlayersLeaderboardPageLoading(false));
        } catch (err) {
            if (topPlayersRetryCount === 0) {
                topPlayersRetryCount += 1;

                dispatch(getLeaderboardTopPlayers(2));
            } else {
                // eslint-disable-next-line no-console
                console.error(err, 'error on top players leaderboard data');
                dispatch(setTopPlayersLeaderboardPageLoading(false));
            }
        }
    };
};

const getLeaderboardTopAlliances = (daysDiff = 1) => {
    const limit = 3;
    const date = sub(today, { days: daysDiff });
    const formattedDate = format(date, 'yyyyMMdd');
    const formattedDateToStore = format(date, 'dd/MM/yyyy');

    const {
        setTopAllianceLeaderboardPageLoading,
        setTopAllianceLeaderboardPage,
        setTopAllianceDate,
    } = leaderboardsSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setTopAllianceLeaderboardPageLoading(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v2/leaderboards/analytics/top-factions?date=${formattedDate}&limit=${limit}`,
                method: 'GET',
            });

            await dispatch(setTopAllianceLeaderboardPage(data));
            await dispatch(setTopAllianceDate(formattedDateToStore));
            await dispatch(setTopAllianceLeaderboardPageLoading(false));
        } catch (err) {
            if (topAlliancesRetryCount === 0) {
                topAlliancesRetryCount += 1;

                await dispatch(getLeaderboardTopAlliances(2));
            } else {
                // eslint-disable-next-line no-console
                console.error(err, 'error on top alliances leaderboard get');
                dispatch(setTopAllianceLeaderboardPageLoading(false));
            }
        }
    };
};

const getLeaderboardTopEvents = (daysDiff = 1) => {
    const limit = 10;

    const date = sub(today, { days: daysDiff });
    const formattedDate = format(date, 'yyyyMMdd');
    const formattedDateToStore = format(date, 'dd/MM/yyyy');

    const { setTopEventsLeaderboardPageLoading, setTopEventsLeaderboardPage, setTopEventsDate } =
        leaderboardsSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setTopEventsLeaderboardPageLoading(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v2/leaderboards/analytics/top-events?date=${formattedDate}&limit=${limit}`,
                method: 'GET',
            });

            dispatch(setTopEventsLeaderboardPage(data));
            dispatch(setTopEventsDate(formattedDateToStore));
            dispatch(setTopEventsLeaderboardPageLoading(false));
        } catch (err) {
            if (topEventsRetryCount === 0) {
                topEventsRetryCount += 1;

                await dispatch(getLeaderboardTopEvents(2));
            } else {
                // eslint-disable-next-line no-console
                console.error(err, 'error on leaderboard top events getting');
                dispatch(setTopEventsLeaderboardPageLoading(false));
            }
        }
    };
};

/**
 ** LEADERBOARDS TOP 500
 */
const getLeaderboardTop500 = (type: TTop500RetryCountUnion, daysDiff = 1) => {
    const date = format(sub(today, { days: daysDiff }), 'yyyyMMdd');
    const { setTop500Leaderboard, setTop500LeaderboardLoading } = leaderboardsSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setTop500LeaderboardLoading(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v2/leaderboards/analytics/${type}?date=${date}`,
                method: 'GET',
            });

            await dispatch(setTop500Leaderboard(data));
            await dispatch(setTop500LeaderboardLoading(false));
            top500RetryCount[type] = 0;
        } catch (err) {
            if (top500RetryCount[type] === 0) {
                top500RetryCount[type] += 1;

                await dispatch(getLeaderboardTop500(type, 2));
            } else {
                dispatch(setTop500LeaderboardLoading(false));
                // eslint-disable-next-line no-console
                console.error(err, 'error on leaderboard top 500');
            }
        }
    };
};

const setLeaderboardTop500 = (data: Array<TTop500Leaderboard>) => async (dispatch: AppDispatch) => {
    const { setTop500Leaderboard } = leaderboardsSlice.actions;

    dispatch(setTop500Leaderboard(data));
};

export const leaderboardsOp = {
    getGlobalLeaderboardData,
    getLeaderboardTopPlayers,
    getLeaderboardTopAlliances,
    getLeaderboardTop500,
    setLeaderboardTop500,
    getLeaderboardTopEvents,
};
