import React from 'react';
import { useSelector } from 'react-redux';

import { calendarSel } from '../../../../../store/calendar';

import { TEvent } from '../../../../../@types';

import EventsList from './EventsList';
import EventsListSkeleton from './EventsListSkeleton';

export interface IEventsListWrapperProps {
    data: TEvent[];
    title: string;
}

function EventsListWrapper({ data, title }: IEventsListWrapperProps) {
    const isLoading = useSelector(calendarSel.loaderSelector);

    return isLoading ? <EventsListSkeleton /> : <EventsList data={data} title={title} />;
}

export default EventsListWrapper;
