import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '../../../../core-ui';

import { Label, Typography } from '../../../../../stories';
import EventItem from '../../EventItem/EventItem';
import { IEventsListWrapperProps } from './EventsListWrapper';

function EventsList({ data, title }: IEventsListWrapperProps) {
    const [t] = useTranslation();

    return (
        <Box my={5} px={5} display="grid">
            <Box display="flex" justifyContent="space-between" pb={2} width={1}>
                <Typography loaderWidth={100} variant="p14" fWeight="regular" fontFamily="rubik">
                    {title}
                </Typography>

                <Label variant="orange" size="medium" rounded label={`${data.length}`} />
            </Box>

            <Box>
                {data.length ? (
                    data.map((eventItem, index) => {
                        return <EventItem event={eventItem} key={index} />;
                    })
                ) : (
                    <Typography variant="p10" fWeight="medium" loaderWidth={80} fontFamily="rubik">
                        {t('right.bar.empty.events.message')}
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

export default EventsList;
