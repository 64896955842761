import React from 'react';
import { useSelector } from 'react-redux';

import clsx from 'clsx';

import { useTheme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { localizationSel } from '../../../store/localization';

import { FontWeight, sizeToHeading } from '../../../constants/typography.constants';

import { Box } from '../../../views/core-ui';

import { TypographyStyled } from './Typography.styled';

export interface ITypography {
    variant?:
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'p'
        | 'p8'
        | 'p10'
        | 'p12'
        | 'p14'
        | 'p16'
        | 'p18'
        | 'p20';
    fWeight?: 'regular' | 'medium' | 'semiBold' | 'bold';
    color?: string;
    fontFamily?: 'rubik' | 'inter' | 'riffic';
    fontSize?: string;
    textDecoration?: string;
    children?: React.ReactNode;
    className?: string;
    loaderWidth?: number | string;
    lineHeight?: string;
    textAlign?: string;
    style?: object;
    isStory?: boolean;
}

function Typography({
    variant = 'p',
    fWeight = 'regular',
    color = '',
    fontFamily = 'inter',
    fontSize,
    textDecoration,
    children,
    className,
    lineHeight,
    loaderWidth,
    textAlign = '',
    isStory = false,
    style,
}: ITypography) {
    const theme = useTheme();
    const isLoading = useSelector(localizationSel.loadingSelector);

    return (
        <TypographyStyled
            as={sizeToHeading[variant]}
            variant={variant}
            fontSize={fontSize ?? theme?.typography?.size[variant]?.fontSize}
            fontFamily={fontFamily}
            fWeight={FontWeight[fWeight]}
            lineHeight={lineHeight ?? theme.typography.size[variant]?.lineHeight}
            color={color}
            className={clsx(variant, fWeight, fontFamily, textDecoration, className)}
            textAlign={textAlign}
            style={style}
        >
            {isLoading && !isStory ? (
                <Box
                    component="span"
                    display="flex"
                    justifyContent="center"
                    height={variant ? theme.typography.size[variant]?.lineHeight : 16}
                    width={loaderWidth}
                >
                    <Skeleton variant="text" animation="wave" height="100%" width="100%" />
                </Box>
            ) : (
                <>{children}</>
            )}
        </TypographyStyled>
    );
}

export default Typography;
