import styled, { keyframes } from 'styled-components';

import { sizes } from '../../constants/constants';

export interface IStyle {
    button?: { [key: string]: number | string };
    icon?: { [key: string]: number | string };
}

export type TLoaderStyled = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    style?: any;
    className?: string;
};

export type TIconStyledProps = {
    size?: string;
    isDisabled?: boolean;
    isActive?: boolean;
    style?: IStyle;
    className?: string;
};
export type TIconButtonStyledProps = {
    as?: string;
    type?: 'button' | 'submit' | 'reset';
    href?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    variant: any;
    size?: 'small' | 'medium' | 'large';
    className?: string;
    icon?: string;
    circle?: boolean;
    onClick?: () => void;
    isDisabled?: boolean;
    isActive?: boolean;
    isLoading?: boolean;
    style?: IStyle;
};

const loader = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const IconStyled = styled.span<TIconStyledProps>`
    font-size: ${({ size }) => (size === sizes.SMALL ? 12 : size === sizes.MEDIUM ? 14 : 16)}px;
    transition: 0.15s linear;
`;

export const LoaderStyled = styled(IconStyled)<TLoaderStyled>`
    animation: ${loader} 1s infinite linear;
`;

export const ButtonStyled = styled.button<TIconButtonStyledProps>`
    width: ${({ size }) => (size === sizes.SMALL ? 32 : size === sizes.MEDIUM ? 36 : 40)}px;
    border-radius: ${({ circle }) => (circle ? '50%' : '6px')};
    height: ${({ size }) => (size === sizes.SMALL ? 32 : size === sizes.MEDIUM ? 36 : 40)}px;
    box-shadow: ${({ variant }) => variant?.shadow || 'none'};
    color: ${({ variant }) => variant.text};
    background-color: ${({ variant, isActive }) =>
        variant?.border ? '#fff' : isActive ? variant.active : variant.main};
    border: ${({ variant, isActive }) =>
        !variant?.border
            ? 'none'
            : isActive
            ? `1px solid ${variant.active}`
            : `1px solid ${variant.main}`};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.15s linear;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        background-color: ${({ variant, isActive }) =>
            variant?.border ? '#fff' : isActive ? variant.active : variant.hover};
        border-color: ${({ variant, isActive }) => (isActive ? variant.active : variant.hover)};
        color: ${({ variant }) => variant.textHover};
    }
    &:disabled {
        cursor: default;
        color: ${({ variant }) => variant.textDisable};
        background-color: ${({ variant }) => (variant?.border ? '#fff' : variant.disable)};
        border-color: ${({ variant }) => variant.disable};
    }
`;
