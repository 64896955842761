import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { calendarSel } from '../../../store/calendar';

import { CalendarPeriod } from '../../../@types';

import { getList } from '../../../helpers/calendar';

import MailChimp from '../../components/MailChimp/MailChimp';

import { RightSidebarStyled } from './RightSidebar.styled';
import RightSideCalendarEvents from './RightSideCalendarEvents/RightSideCalendarEvents';
import UserWrapper from './UserWrapper/UserWrapper';

export interface IRightSidebarProps {
    openRightSidebar?: boolean;
    setOpenRightSidebar: (open?: boolean) => void;
}

export default function RightSidebar({
    openRightSidebar,
    setOpenRightSidebar,
}: IRightSidebarProps) {
    const location = useLocation();
    const [t] = useTranslation();

    const rightSidebarRef = useRef<HTMLElement | null>(null);
    const events = useSelector(calendarSel.eventSelector);

    useEffect(() => {
        const rightsidebarRefClone = rightSidebarRef.current;

        return () => {
            if (rightsidebarRefClone) {
                rightsidebarRefClone?.scrollTo({ top: 0, behavior: 'auto' });
            }
        };
    }, [location]);

    useEffect(() => {
        if (!openRightSidebar && rightSidebarRef) {
            rightSidebarRef.current?.scrollTo({ top: 0, behavior: 'auto' });
        }
    }, [openRightSidebar]);

    return (
        <RightSidebarStyled className={openRightSidebar ? 'show' : ''} ref={rightSidebarRef}>
            <UserWrapper
                openRightSidebar={openRightSidebar}
                setOpenRightSidebar={setOpenRightSidebar}
            />

            {getList(events, t, CalendarPeriod.ALL) ? (
                <RightSideCalendarEvents
                    openRightSidebar={openRightSidebar}
                    setOpenRightSidebar={setOpenRightSidebar}
                />
            ) : null}

            <MailChimp />
        </RightSidebarStyled>
    );
}
