import { useMemo } from 'react';

import { useTheme } from '@mui/material/styles';

import { labelVariants, variants } from './constants/constants';

function usePalette() {
    const { palette: lightPalette } = useTheme();

    const palette = useMemo(
        () => ({
            [variants.PRIMARY]: {
                main: lightPalette.purple[600],
                hover: lightPalette.purple[500],
                active: lightPalette.purple[700],
                disable: lightPalette.purple[200],
                text: lightPalette.common.white,
            },
            [variants.SECONDARY]: {
                main: lightPalette.grey[100],
                hover: lightPalette.grey[200],
                active: lightPalette.grey[300],
                disable: lightPalette.grey[100],
                text: lightPalette.grey[800],
                textDisable: lightPalette.grey[500],
            },
            [variants.DANGER]: {
                main: lightPalette.red[700],
                hover: lightPalette.red[600],
                active: lightPalette.red[800],
                disable: lightPalette.red[200],
                text: lightPalette.common.white,
            },
            [variants.SOLID]: {
                main: 'transparent',
                hover: 'transparent',
                active: 'transparent',
                disable: 'transparent',
                text: lightPalette.grey[700],
                textDisable: lightPalette.grey[500],
                textHover: lightPalette.grey[600],
                textActive: lightPalette.grey[800],
                border: {
                    default: lightPalette.grey[300],
                    hover: lightPalette.grey[400],
                    active: lightPalette.grey[500],
                    disabled: lightPalette.grey[300],
                },
            },
            [variants.WHITE]: {
                main: lightPalette.common.white,
                hover: lightPalette.common.white,
                active: lightPalette.common.white,
                disable: lightPalette.common.white,
                text: lightPalette.grey[700],
                textDisable: lightPalette.grey[500],
                textHover: lightPalette.grey[600],
                textActive: lightPalette.purple[600],
                border: {
                    default: lightPalette.grey[300],
                    hover: lightPalette.grey[400],
                    active: lightPalette.grey[500],
                    disabled: lightPalette.grey[300],
                },
            },
        }),
        [lightPalette],
    );

    const labels = useMemo(
        () => ({
            [labelVariants.PURPLE]: {
                color: lightPalette.purple[600],
                background: lightPalette.purple[100],
                colorHover: lightPalette.purple[900],
                backgroundHover: lightPalette.purple[200],
            },
            [labelVariants.GREY]: {
                color: lightPalette.grey[800],
                background: lightPalette.grey[50],
                colorHover: lightPalette.grey[800],
                backgroundHover: lightPalette.grey[300],
            },
            [labelVariants.RED]: {
                color: lightPalette.red[600],
                background: lightPalette.red[100],
                colorHover: lightPalette.red[900],
                backgroundHover: lightPalette.red[200],
            },
            [labelVariants.ORANGE]: {
                color: lightPalette.orange[900],
                background: lightPalette.orange[200],
                colorHover: lightPalette.orange[900],
                backgroundHover: lightPalette.orange[300],
            },
            [labelVariants.GREEN]: {
                color: lightPalette.green[700],
                background: lightPalette.green[100],
                colorHover: lightPalette.green[900],
                backgroundHover: lightPalette.green[200],
            },
            [labelVariants.BLUE]: {
                color: lightPalette.blue[700],
                background: lightPalette.blue[100],
                colorHover: lightPalette.blue[900],
                backgroundHover: lightPalette.blue[200],
            },
            [labelVariants.SOLID]: {
                color: lightPalette.grey[800],
                background: lightPalette.grey[300],
                colorHover: lightPalette.grey[800],
                backgroundHover: lightPalette.grey[400],
            },
            [labelVariants.TRANSPARENT]: {
                color: lightPalette.common.white,
                background: lightPalette.background[10],
                colorHover: lightPalette.common.white,
                backgroundHover: lightPalette.background[10],
            },
        }),
        [lightPalette],
    );

    return useMemo(
        () => ({
            palette,
            labels,
        }),
        [palette, labels],
    );
}

export default usePalette;
