import styled from 'styled-components';

export const CustomCheckboxStyled = styled.div`
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: ${({ theme }) => theme.palette.grey[600]};

    .styled-checkbox {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it


        & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
            font-size: 14px;
            font-weight: 400;
            color: ${({ theme }) => theme.palette.grey[600]};
        }

        // Box.
        & + label:before {
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 22px;
            height: 22px;
            background: transparent;
            border: 1px solid #A6ACDCFF;
            border-radius: 6px;
        }

        // Box checked
        &:checked + label:before {
            background: #0DD39CFF;
            border: 1px solid #0DD39CFF;
        }

        // Checkmark. Could be replaced with an image
        &:checked + label:after {
            content: '';
            position: absolute;
            left: 6px;
            top: 10px;
            background: white;
            width: 3px;
            height: 3px;
            box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
                4px -8px 0 white;
            transform: rotate(45deg);
        }
        .checkbox {
        font-weight: bold;
            font-size: 12px;
    }
`;
