import { createSelector } from 'reselect';
import { TToonGuideState } from 'src/@types';

import { RootState } from '../configureStore';

const toonGuidesSelector = (state: RootState) => state.toonGuide;

const isLoadingSelector = createSelector(
    [toonGuidesSelector],
    (toonGuide: TToonGuideState) => toonGuide.isToonGuidesLoading,
);

const toonGuideSelector = createSelector([toonGuidesSelector], (toonGuide) => toonGuide.toonGuide);

const toonGuideProfileLoaderSelector = createSelector(
    [toonGuidesSelector],
    (toonGuide: TToonGuideState) => toonGuide.toonGuideProfileLoader,
);

const toonLevelUpsLoaderSelector = createSelector(
    [toonGuidesSelector],
    (toonGuide: TToonGuideState) => toonGuide.isToonLevelUpsLoading,
);

const tuneup45LoaderSelector = createSelector(
    [toonGuidesSelector],
    (toonGuide: TToonGuideState) => toonGuide.isTuneup45Loading,
);

const toonProfileSelector = createSelector(
    [toonGuidesSelector],
    (toonGuide: TToonGuideState) => toonGuide.toonProfile,
);

const toonLevelUpsSelector = createSelector(
    [toonGuidesSelector],
    (toonGuide: TToonGuideState) => toonGuide.toonLevelUps,
);

const tuneup45Selector = createSelector(
    [toonGuidesSelector],
    (toonGuide: TToonGuideState) => toonGuide.tuneup45,
);

const filterItemLoadingSelector = createSelector(
    [toonGuidesSelector],
    (toonGuide: TToonGuideState) => toonGuide.isFilterItemLoading,
);

const filterItemsSelector = createSelector(
    [toonGuidesSelector],
    (toonGuide: TToonGuideState) => toonGuide.filterItems,
);

const characterStatsSelector = createSelector(
    [toonGuidesSelector],
    (toonGuide: TToonGuideState) => toonGuide.characterStats,
);

const calculationSelector = createSelector(
    [toonGuidesSelector],
    (toonGuide: TToonGuideState) => toonGuide.calculation,
);

const skillsSelector = createSelector(
    [toonGuidesSelector],
    ({ skills }: { skills: any }) => skills,
);

export const toonGuideSel = {
    isLoadingSelector,
    toonGuideSelector,
    toonGuideProfileLoaderSelector,
    tuneup45LoaderSelector,
    tuneup45Selector,
    filterItemsSelector,
    filterItemLoadingSelector,
    toonProfileSelector,
    toonLevelUpsLoaderSelector,
    toonLevelUpsSelector,
    characterStatsSelector,
    calculationSelector,
    skillsSelector,
};
