import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useMediaQuery, useTheme } from '@mui/material';

import { globalOp, globalSel } from '../../../../store/global';

import { IFaceBookResponse } from '../../../../@types';

import { APP_REDIRECT_URL, FB_APP_ID, LOGIN_TYPES } from '../../../../constants/api.constants';

import { tracker } from '../../../../configs/titan.config';

import { getStorageItem, setStorageItem } from '../../../../helpers/localStorage';

import { Box, Icon } from '../../../core-ui';

import { Typography } from '../../../../stories';
import LoadAnimateButton from '../../LoadAnimateButton/LoadAnimateButton';
import { LoginWithFacebookStyled } from './LoginWithFacebook.styled';

const LoginWithFacebook = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();

    const isLoading = useSelector(globalSel.isLoadingFacebookSelector);
    const mobileQuery = useMediaQuery(theme.breakpoints.down('sm'));
    const loginProcessing = getStorageItem('loginProcessing');
    const isLoadingScopely = useSelector(globalSel.isLoadingScopelySelector);
    const loggedInType = getStorageItem('loginProcessing');

    const handleFacebookResponse = (response: IFaceBookResponse) => {
        setStorageItem('loginProcessing', LOGIN_TYPES.facebook);

        if (response?.userID && response?.accessToken && !response.error) {
            dispatch(
                globalOp.loginAsync(
                    {
                        type: LOGIN_TYPES.facebook,
                        accessToken: response.accessToken,
                        deviceId: tracker?.getDeviceToken() || '',
                        facebookId: response?.userID,
                        fbAccessToken: response.accessToken,
                        email: response?.email || `${response?.id}@facebook.com`,
                        avatar: response?.picture?.data?.url,
                        userName: response?.name,
                    },
                    tracker?.getDeviceToken() || '',
                ),
            );
        }
    };

    return (
        <FacebookLogin
            appId={FB_APP_ID ?? ''}
            fields="name,email,picture"
            disableMobileRedirect
            callback={handleFacebookResponse}
            redirectUri={loginProcessing === LOGIN_TYPES.facebook ? APP_REDIRECT_URL : ''}
            render={(renderProps: {
                onClick: () => void;
                isDisabled: boolean;
                isProcessing: boolean;
                isSdkLoaded: boolean;
            }) => {
                return (
                    <LoginWithFacebookStyled data-testid="FB-btn">
                        <Box
                            component="button"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            className={`WP-LoginWithFacebook-button cursor-pointer ${
                                isLoadingScopely && loggedInType === LOGIN_TYPES.scopely
                                    ? 'disable-events'
                                    : ''
                            }`}
                            onClick={
                                !isLoading
                                    ? renderProps.onClick
                                    : () => {
                                          return;
                                      }
                            }
                            bgcolor={theme.palette.facebook.primary}
                            border="none"
                            color={theme.palette.common.white}
                            height={48}
                            width="100%"
                        >
                            {isLoading && loggedInType === LOGIN_TYPES.facebook ? (
                                <LoadAnimateButton
                                    hasText
                                    bodySize={mobileQuery ? 'small' : 'medium'}
                                />
                            ) : (
                                <Box
                                    width={1}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Icon className="icon-fb-logo-new" size={24} />
                                    <Box pl={2}>
                                        <Typography
                                            loaderWidth={128}
                                            variant="p12"
                                            fWeight="semiBold"
                                        >
                                            {t('facebook.login.button')}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </LoginWithFacebookStyled>
                );
            }}
        />
    );
};

export default LoginWithFacebook;
