import React, { memo } from 'react';

import { DialogContent, useMediaQuery } from '@mui/material';

import { IActiveMilestone } from '../../../@types';

import MilestoneInfoModalContent from './components/MilestoneInfoModalContent';
import MilestoneInfoModalFooter from './components/MilestoneInfoModalFooter';
import MilestoneInfoModalHeader from './components/MilestoneInfoModalHeader';

import { Box } from '../../core-ui';

import { Theme } from '@mui/system';

import modalBackground from '../../../assets/images/Events/Modal_header_bgc.png';
import { Dialog } from '../../../stories';

export interface IMilestoneInfoModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    milestone: IActiveMilestone;
    isStory?: boolean;
}

function MilestoneInfoModal({
    isModalOpen,
    onClose,
    milestone,
    isStory,
}: IMilestoneInfoModalProps) {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return milestone ? (
        <Dialog
            open={isModalOpen}
            onClose={onClose}
            showCloseButton
            paperStyles={{
                width: isMobile ? '100%' : 'auto',
                minWidth: '280px',
                padding: '30px 20px',
            }}
        >
            <DialogContent>
                <Box width="auto" display="flex" flexDirection="column">
                    <MilestoneInfoModalHeader
                        milestone={milestone}
                        backgroundImg={modalBackground}
                        isStory={isStory}
                    />
                    <Box width={1} pt={[3, 4]}>
                        <MilestoneInfoModalContent rewards={milestone.rewards} isStory={isStory} />
                    </Box>
                </Box>
            </DialogContent>

            <MilestoneInfoModalFooter button={milestone.button} />
        </Dialog>
    ) : null;
}

export default memo(MilestoneInfoModal);
