import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { calendarSel } from '../../../../store/calendar';
import { localizationSel } from '../../../../store/localization';

import { CalendarPeriod } from '../../../../@types';

import { getList } from '../../../../helpers/calendar';

import { Box } from '../../../core-ui';

import { Button } from '../../../../stories';
import { IRightSidebarProps } from '../RightSidebar';
import EventsListWrapper from './component/EventsListWrapper';

function RightSideCalendarEvents({ openRightSidebar, setOpenRightSidebar }: IRightSidebarProps) {
    const [t] = useTranslation();
    const navigate = useNavigate();

    const events = useSelector(calendarSel.eventSelector);
    const isLoading = useSelector(localizationSel.loadingSelector);

    const goToCalendarPage = () => {
        if (openRightSidebar) setOpenRightSidebar(false);
        navigate('/calendar');
    };

    const AllCalendarEvents = getList(events, t, CalendarPeriod.ALL);

    return (
        <>
            <EventsListWrapper
                title={t('right.bar.today.events.title')}
                data={getList(events, t, CalendarPeriod.TODAY)}
            />
            <EventsListWrapper
                title={t('right.bar.tomorrow.events.title')}
                data={getList(events, t, CalendarPeriod.TOMORROW)}
            />
            {AllCalendarEvents?.length ? (
                <Box px={4} py={3}>
                    <Button
                        onClick={goToCalendarPage}
                        label={t('right.bar.see.more.events.button')}
                        variant="primary"
                        size="large"
                        isLoading={isLoading}
                        fullWidth
                    />
                </Box>
            ) : null}
        </>
    );
}

export default memo(RightSideCalendarEvents);
