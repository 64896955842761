import React, { memo } from 'react';

import { ThemeProvider } from 'styled-components';

import { Dialog as MuiDialog, useTheme } from '@mui/material';

import { Icon, Box } from '../../../views/core-ui';

import { CloseButtonStyled } from './Dialog.styled';

export interface IDialogProps {
    open: boolean;
    onClose: () => void;
    showCloseButton?: boolean;
    paperStyles?: { [key: string]: string };
    children?: React.ReactNode;
}

function Dialog({
    open,
    onClose,
    showCloseButton = false,
    paperStyles = {},
    children,
}: IDialogProps) {
    const theme = useTheme();

    return (
        <ThemeProvider theme={{ ...theme }}>
            <MuiDialog open={open} onClose={onClose} PaperProps={{ style: paperStyles }}>
                {showCloseButton ? (
                    <CloseButtonStyled
                        onClick={onClose}
                        className="cursor-pointer"
                        data-testid="modal-close-button"
                    >
                        <Icon color={theme.palette.grey[600]} className="icon-close-fill" />
                    </CloseButtonStyled>
                ) : null}
                <Box data-testid="modal" overflow="auto">
                    {children}
                </Box>
            </MuiDialog>
        </ThemeProvider>
    );
}

export default memo(Dialog);
