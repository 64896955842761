import styled from 'styled-components';

export const MailChimpStyled = styled.div`
    .WP-MailChimp-input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${({ theme }) => theme.palette.grey[500]};
        opacity: 1; /* Firefox */
    }

    .WP-MailChimp-input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({ theme }) => theme.palette.grey[500]};
    }

    .WP-MailChimp-input::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({ theme }) => theme.palette.grey[500]};
    }
    .sub-btn {
        color: ${({ theme }) => theme.palette.grey[800]};
    }
    .disable-sub-btn {
        cursor: default;
        color: ${({ theme }) => theme.palette.grey[800]};
        opacity: 0.5;
    }
    a {
        text-decoration: none;
        color: ${({ theme }) => theme.palette.grey[600]};
    }
`;
