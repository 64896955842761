import styled from 'styled-components';

import { sizes } from '../../constants/constants';

interface TLabelStyled {
    variant?: {
        [key: string]: string;
    };
    size?: string;
    bordered?: boolean;
    rounded?: boolean;
    clickable?: boolean;
    onClick?: () => void;
}

export const LabelStyled = styled.div<TLabelStyled>`
    width: fit-content;
    height: ${({ size }) => (size === sizes.SMALL ? 24 : size === sizes.MEDIUM ? 26 : 32)}px;
    padding: ${({ size }) =>
        size === sizes.SMALL ? '3px 20px' : size === sizes.MEDIUM ? '3px 24px' : '3px 32px'};
    border-radius: ${({ rounded }) => (rounded ? 60 : 6)}px;
    background-color: ${({ variant, bordered }) =>
        bordered ? 'transparent' : variant?.background};
    border: ${({ variant, bordered }) => (bordered ? `1px solid ${variant?.color}` : 'none')};
    color: ${({ variant }) => variant?.color};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    outline: none;
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
    text-decoration: none;
    transition: 0.15s linear;

    &:hover {
        background-color: ${({ variant, bordered }) =>
            bordered ? 'transparent' : variant?.backgroundHover};
        border-color: ${({ variant }) => variant?.backgroundHover};
        color: ${({ variant }) => variant?.colorHover};
    }

    &:disabled {
        &:hover {
            background-color: ${({ variant }) => variant?.background};
            border-color: ${({ variant }) => variant?.color};
            color: ${({ variant }) => variant?.color};
        }
    }
`;
