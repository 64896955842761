import React, { memo } from 'react';

import clsx from 'clsx';

import { IProgressBarProps } from '../../../@types';

import { Box } from '../../../views/core-ui';

import { ProgressBarStyled, ProgressBarWrapperStyled } from './ProgressBar.styled';

const ProgressBar = ({
    variant = 'dashed',
    height,
    bgColor,
    fillerColor,
    progress,
    isMax,
    progressText,
    textColor,
    isDisabled,
    className,
}: IProgressBarProps) => {
    return (
        <ProgressBarWrapperStyled className={className} color={textColor}>
            {isMax ? null : variant === 'dashed' ? (
                <Box
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    right={[0, 8]}
                    top={0}
                    bottom={0}
                    zIndex={9}
                >
                    <Box className="progress-text-dashed dashed-variant">{progressText}</Box>
                </Box>
            ) : (
                <Box
                    display="flex"
                    alignItems="center"
                    className="progress-text-doted lined-variant"
                >
                    {progressText}
                </Box>
            )}

            {progress ? (
                <Box className="progress-wrapper" mt={2}>
                    <ProgressBarStyled
                        height={height}
                        color={fillerColor}
                        isMax={isMax}
                        isDisabled={isDisabled}
                    >
                        <Box bgcolor={bgColor} borderRadius="10px">
                            <Box
                                position="relative"
                                width={isMax ? '100%' : progress + '%'}
                                className={clsx('WP-ProgressBar-filler', variant)}
                                display="flex"
                                alignItems="center"
                            >
                                {isMax ? (
                                    <Box
                                        position="absolute"
                                        right={14}
                                        className="WP-ProgressBar-Max"
                                    >
                                        MAX
                                    </Box>
                                ) : null}
                            </Box>
                        </Box>
                    </ProgressBarStyled>
                </Box>
            ) : null}
        </ProgressBarWrapperStyled>
    );
};

export default memo(ProgressBar);
