import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';

import { globalOp, globalSel } from '../../../store/global';

import { Box, Icon } from '../../core-ui';

import errorIcon from '../../../assets/images/error-icon.svg';
import successIcon from '../../../assets/images/success-icon.svg';
import { Typography } from '../../../stories';
import { SnackBarStyled } from './SnackBar.styled';

export default function SnackBar() {
    const dispatch = useDispatch();
    const snackbar = useSelector(globalSel.snackbarSelector);
    const theme = useSelector(globalSel.themeSelector);
    const { open, message, variant } = snackbar;

    const renderIcon = () => {
        switch (variant) {
            case 'success':
                return (
                    <Box
                        component="img"
                        src={successIcon}
                        alt="snackbar-icon"
                        width={44}
                        height={44}
                    />
                );
            case 'error':
                return (
                    <Box
                        component="img"
                        src={errorIcon}
                        alt="snackbar-icon"
                        width={44}
                        height={44}
                    />
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        let isMounted = true;

        setTimeout(() => {
            if (isMounted && open) {
                dispatch(globalOp.handleCloseSnackbar());
            }
        }, 4000);

        return () => {
            isMounted = false;
        };
    }, [dispatch, open]);

    return (
        <>
            {open ? (
                <Box
                    component={SnackBarStyled}
                    open={snackbar.open}
                    display={snackbar.open ? 'block' : 'none'}
                >
                    <Box
                        position="relative"
                        bgcolor={theme.palette.background[200]}
                        p={2}
                        pr={6}
                        border={1}
                        bordercolor={theme.palette.background[200]}
                        borderRadius="100px"
                        boxShadow={theme.elevation[100]}
                    >
                        <Box position="relative" zIndex={3} display="flex" alignItems="center">
                            <Box display="flex" alignItems="center" justifyContent="center">
                                {renderIcon()}
                            </Box>

                            <Box width={1} px={6}>
                                <Typography variant="p14" fWeight="medium">
                                    {message}
                                </Typography>
                            </Box>

                            <Box
                                component={Icon}
                                className={clsx('icon-close-fill', 'cursor-pointer')}
                                color={theme.palette.background[800]}
                                onClick={() => dispatch(globalOp.handleCloseSnackbar())}
                                size={24}
                            />
                        </Box>
                    </Box>
                </Box>
            ) : null}
        </>
    );
}
