import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';

import { Box } from '../../core-ui';

import MaintenanceImage from '../../../assets/images/MaintenancePage/MainteanceImage.png';
import { MaintenanceStyled } from './Maintenance.styled';

function Maintenance() {
    const [t] = useTranslation();
    const theme = useTheme();

    return (
        <Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                position="relative"
                height={[267, 452, 452, 500, 600]}
            >
                <Box
                    component={MaintenanceStyled}
                    className="WP-MaintenanceComponent-bg"
                    height={1}
                >
                    <Box
                        position="relative"
                        display="flex"
                        justifyContent="center"
                        alignItems="flex-end"
                        height="100%"
                    >
                        <Box
                            component="img"
                            src={MaintenanceImage}
                            maxWidth={[500, 800]}
                            minWidth={250}
                            width={[255, 522, 522, 625]}
                            alt="Maintenance Image"
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                textAlign="center"
            >
                <Box
                    fontSize={[
                        theme.typography.size.h3.fontSize,
                        theme.typography.size.h2.fontSize,
                    ]}
                    color={theme.palette.text[200]}
                    fontWeight={theme.typography.weight.bold}
                    mb={3}
                >
                    {t('maintenance.page.title')}
                </Box>
                <Box
                    fontSize={[
                        theme.typography.size.p16.fontSize,
                        theme.typography.size.h6.fontSize,
                    ]}
                    color={theme.palette.text[500]}
                    fontWeight={theme.typography.weight.medium}
                >
                    {t('maintenance.page.description')}
                </Box>
            </Box>
        </Box>
    );
}

export default Maintenance;
