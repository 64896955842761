import React, { memo, useCallback } from 'react';

import Skeleton from '@mui/material/Skeleton';

import { IButtonProps, IButtonVariant, TButtonVariant } from '../../../@types';

import { variants } from '../../constants/constants';

import usePalette from '../../palette';
import {
    ButtonStyled,
    IconImageStyled,
    IconStyled,
    LabelStyled,
    LoaderStyled,
} from './Button.styled';

function Button({
    variant = 'primary',
    fullWidth = false,
    size = 'medium',
    label,
    loaderLabel = '',
    icon = '',
    imageUrl = '',
    iconPosition = 'start',
    onClick = () => null,
    isDisabled = false,
    isActive = false,
    isProcessing = false,
    isLoading = false,
    className = '',
    color = '',
    isBeepcoin = false,
    ...props
}: IButtonProps) {
    const { palette } = usePalette();
    const elementProps = { onClick, disabled: isDisabled };

    const getButtonVariant = useCallback(
        (variant: TButtonVariant): IButtonVariant => {
            switch (variant) {
                case 'primary':
                    return palette[variants['PRIMARY']] as IButtonVariant;
                case 'secondary':
                    return palette[variants['SECONDARY']] as IButtonVariant;
                case 'danger':
                    return palette[variants['DANGER']] as IButtonVariant;
                case 'warning':
                    return palette[variants['WARNING']] as IButtonVariant;
                case 'success':
                    return palette[variants['SUCCESS']] as IButtonVariant;
                case 'info':
                    return palette[variants['INFO']] as IButtonVariant;
                case 'solid':
                    return palette[variants['SOLID']] as IButtonVariant;
                case 'white':
                    return palette[variants['WHITE']] as IButtonVariant;
                default:
                    return palette[variants['PRIMARY']] as IButtonVariant;
            }
        },
        [palette],
    );

    return (
        <ButtonStyled
            variant={getButtonVariant(variant)}
            iconPosition={iconPosition}
            size={size}
            fullWidth={fullWidth}
            isActive={isActive}
            className={className}
            color={color}
            isBeepcoin={isBeepcoin}
            {...elementProps}
            {...props}
        >
            {isProcessing ? (
                <LoaderStyled
                    variant={variant}
                    size={size}
                    iconPosition={iconPosition}
                    isDisabled={isDisabled}
                    isActive={isActive}
                    className="icon-download-arrows-loading"
                />
            ) : imageUrl ? (
                <IconImageStyled
                    src={imageUrl}
                    iconPosition={iconPosition}
                    size={size}
                    alt="Icon"
                    draggable={false}
                />
            ) : icon ? (
                <IconStyled
                    variant={variant}
                    size={size}
                    iconPosition={iconPosition}
                    isDisabled={isDisabled}
                    isActive={isActive}
                    className={icon}
                />
            ) : null}

            {isLoading ? (
                <Skeleton variant="text" animation="wave" height={16} width="100%" />
            ) : (
                <LabelStyled
                    variant={variant}
                    size={size}
                    isDisabled={isDisabled}
                    isActive={isActive}
                    isBeepcoin={isBeepcoin}
                >
                    {isProcessing && loaderLabel ? loaderLabel : label}
                </LabelStyled>
            )}
        </ButtonStyled>
    );
}

export default memo(Button);
