import { createSelector } from 'reselect';
import { TGlobalInitialState } from 'src/@types';

import { RootState } from '../configureStore';

const globalSelector = (state: RootState) => state.global;

/**
 * USER SPECIFIC SELECTORS
 */
const isAuthSelector = createSelector(
    [globalSelector],
    (global: TGlobalInitialState) => global.authenticated,
);
const currentUserSelector = createSelector(
    [globalSelector],
    (global: TGlobalInitialState) => global.currentUser,
);
const userBalanceSelector = createSelector(
    [globalSelector],
    (global: TGlobalInitialState) => global.userBalance,
);
const userCollectionsSelector = createSelector(
    [globalSelector],
    (global: TGlobalInitialState) => global.userCollections,
);

/**
 * GLOBAL SELECTORS
 */
const snackbarSelector = createSelector(
    [globalSelector],
    (global: TGlobalInitialState) => global.snackbar,
);
const themeSelector = createSelector(
    [globalSelector],
    (global: TGlobalInitialState) => global.theme,
);
const modalSelector = createSelector(
    [globalSelector],
    (global: TGlobalInitialState) => global.modal,
);
const maintenanceModeSelector = createSelector(
    [globalSelector],
    (global: TGlobalInitialState) => global.maintenance,
);
const globalConfigSelector = createSelector(
    [globalSelector],
    (global: TGlobalInitialState) => global.globalConfig,
);
const dashboardBannerSelector = createSelector(
    [globalSelector],
    ({ dashboardBanner }) => dashboardBanner,
);
const globalLeaderboardsSelector = createSelector(
    [globalSelector],
    ({ globalLeaderboards }) => globalLeaderboards,
);

/**
 * LOADING SELECTORS
 */
const isLoadingFacebookSelector = createSelector(
    [globalSelector],
    ({ isLoadingFacebook }: { isLoadingFacebook: boolean }) => isLoadingFacebook,
);
const isLoadingScopelySelector = createSelector(
    [globalSelector],
    ({ isLoadingScopely }: { isLoadingScopely: boolean }) => isLoadingScopely,
);

const isLeaderboardsLoadingSelector = createSelector(
    [globalSelector],
    ({ isLeaderboardsLoading }: { isLeaderboardsLoading: boolean }) => isLeaderboardsLoading,
);

const dashboardBannerLoadingSelector = createSelector(
    [globalSelector],
    ({ dashboardBannerLoading }: { dashboardBannerLoading: boolean }) => dashboardBannerLoading,
);

export const globalSel = {
    globalSelector,
    isAuthSelector,
    currentUserSelector,
    themeSelector,
    snackbarSelector,
    modalSelector,
    isLoadingFacebookSelector,
    isLoadingScopelySelector,
    globalLeaderboardsSelector,
    isLeaderboardsLoadingSelector,
    userCollectionsSelector,
    maintenanceModeSelector,
    globalConfigSelector,
    userBalanceSelector,
    dashboardBannerSelector,
    dashboardBannerLoadingSelector,
};
