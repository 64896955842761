import styled from 'styled-components';

import { Theme } from '@mui/material';

interface ILoginWithFacebookStyled {
    theme: Theme;
}

export const LoginWithScopelyIdStyled = styled.div<ILoginWithFacebookStyled>`
    transition: background-color 200ms ease-out;
    font-size: 13px;
    background-color: ${({ theme }) => theme.palette.scopely.primary};
    .WP-LoginWithScopelyID-button:hover {
        background-color: ${({ theme }) => theme.palette.scopely.hover};
    }
`;
