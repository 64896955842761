import { IDataDogKeys } from '../@types';

export const APP_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URI;
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
export const API_ENV = process.env.REACT_APP_ENVIRONMENT;
export const API_URL = `${process.env.REACT_APP_API_URL}`;

export const MAILCHIMP_API = {
    MAILCHIMP_URL: `https://scopely.us3.list-manage.com/subscribe/post?u=5aa6ec292c0385e42c99583e6&amp;id=76f28d0b4d`,
    CHECKBOX_ID: 'gdpr_31881',
    CHECKBOX_NAME: 'gdpr[31881]',
};

export const TITAN_API_KEY = process.env.REACT_APP_TITAN_API_KEY;
export const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY;

export const VOLUUM_ORGANIC_CAMPAIGN_ID = process.env.REACT_APP_VOLUUM_ORGANIC_CAMPAIGN_ID;
export const VOLUUM_OFFER_ID = process.env.REACT_APP_VOLUUM_OFFER_ID;

export const GAME_S3_BUCKET_URL = process.env.REACT_APP_GAME_S3_BUCKET_URL;
export const GAME_S3_BUCKET_URL_FOR_ICONS = process.env.REACT_APP_GAME_S3_BUCKET_URL_FOR_ICONS;
export const PORTAL_S3_BUCKET_URL = process.env.REACT_APP_PORTAL_S3_BUCKET_URL;

export const XSOLLA_URL = process.env.REACT_APP_XSOLLA_URL;
export const GAME_DOWNLOAD_URL = process.env.REACT_APP_GAME_DOWNLOAD_URL;

export const BANNED_ACCOUNT_STATUS_CODE = 1209;
export const NETWORK_UNHANDLED_EXCEPTION = 1200;
export const NOT_LINKED_ACCOUNT_STATUS_CODE = 13305;

const dataDogKeys: IDataDogKeys = Object.freeze({
    dev: {
        applicationId: 'b07d29d0-256a-4843-9da2-2bd218da703f',
        clientToken: 'pub4b2934315f13fb7f0da91fbb4a2f7904',
        service: 'looney-portal-client-dev',
        env: 'looney-dev',
    },
    staging: {
        applicationId: 'ada221cb-e435-421a-b271-5148f1ba6417',
        clientToken: 'pub8a94f25ad128b8692c31b127b2535024',
        service: 'looney-portal-client-staging',
        env: 'looney-staging',
    },
    prod: {
        applicationId: '315b41be-872d-4ea0-bc81-8b2d08625bd4',
        clientToken: 'pubc56adbd2b0588a251cf13c30f7edb8a9',
        service: 'looney-portal-client',
        env: 'looney-prod',
    },
});

export const DatDogEnvKeys = API_ENV ? dataDogKeys[API_ENV] : dataDogKeys['dev'];

export const LOGIN_TYPES = { facebook: 'facebook', scopely: 'scopely_id' };

export const IDENTITY_PROVIDER_URL = process.env.REACT_APP_IDENTITY_PROVIDER_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
