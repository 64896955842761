import React, { memo, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ILeftSidebar } from 'src/@types';

import AppLogo from '../../components/AppLogo/AppLogo';
import PlayGameButton from '../../components/CustomButtons/PlayGameButton/PlayGameButton';

import { Box } from '../../core-ui';

import { LeftSidebarStyled } from './LeftSidebar.styled';
import LeftSidebarFooter from './LeftSidebarFooter/LeftSidebarFooter';
import Nav from './Nav/Nav';

function LeftSidebar({ openLeftSidebar, setOpenLeftSidebar }: ILeftSidebar) {
    const navigate = useNavigate();
    const leftSidebarRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const leftsidebarRefClone = leftSidebarRef.current;
        return () => {
            if (leftsidebarRefClone) {
                leftsidebarRefClone?.scrollTo({ top: 0, behavior: 'auto' });
            }
        };
    }, [navigate, openLeftSidebar]);

    useEffect(() => {
        if (!openLeftSidebar && leftSidebarRef.current) {
            return leftSidebarRef.current?.scrollTo({ top: 0, behavior: 'auto' });
        }
    }, [openLeftSidebar]);

    return (
        <LeftSidebarStyled className={openLeftSidebar ? 'show' : ''} ref={leftSidebarRef}>
            <AppLogo isLeftSide setOpenLeftSidebar={setOpenLeftSidebar} />

            <Box className="WP-LeftBar-Game-Btn">
                <PlayGameButton
                    setOpenLeftSidebar={setOpenLeftSidebar}
                    openLeftSidebar={openLeftSidebar}
                />
            </Box>

            <Nav setOpenLeftSidebar={setOpenLeftSidebar} />

            <LeftSidebarFooter />
        </LeftSidebarStyled>
    );
}

export default memo(LeftSidebar);
