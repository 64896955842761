import { createSelector } from 'reselect';
import { TNewsState } from 'src/@types';

import { RootState } from '../configureStore';

const newsSelector = (state: RootState) => state.news;

const isLoadingSelector = createSelector([newsSelector], (news: TNewsState) => news.isLoading);

const newsDataSelector = createSelector([newsSelector], (news: TNewsState) => news.news);

const newsPageSelector = createSelector([newsSelector], (news: TNewsState) => news.page);

const loadMoreLoadingSelector = createSelector(
    [newsSelector],
    (news: TNewsState) => news.loadMoreLoading,
);

const showNewsLoadMoreSelector = createSelector(
    [newsSelector],
    (news: TNewsState) => news.loadMore,
);

const activeNewsSelector = createSelector([newsSelector], (news: TNewsState) => news.activeNews);

export const newsSel = {
    isLoadingSelector,
    newsDataSelector,
    newsPageSelector,
    loadMoreLoadingSelector,
    showNewsLoadMoreSelector,
    activeNewsSelector,
};
