import {
    TBannerInfoList,
    TSpecialOffersState,
    TOfferModalData,
    TShopData,
    IShopItemInfo,
} from 'src/@types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialSpecialOffersState } from './initialState';

export const specialOffers = createSlice({
    name: 'specialOffers',
    initialState: initialSpecialOffersState(),
    reducers: {
        setActiveTab(state: TSpecialOffersState, action: PayloadAction<string>) {
            state.activeTab = action.payload;
        },
        setSpecialOffers(state: TSpecialOffersState, action: PayloadAction<string>) {
            state.specialOffers = action.payload;
        },
        setLoading(state: TSpecialOffersState, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setProcessingId(state: TSpecialOffersState, action: PayloadAction<string>) {
            state.offerProcessingId = action.payload;
        },
        setStore(state: TSpecialOffersState, action: PayloadAction<TShopData[] | null>) {
            const data = action.payload;

            let dataToSet: TShopData = {
                identifier: '',
                recurrenceRule: '',
                externalPriceReferences: {},
                transactionProducts: {},
                storeProductInfoList: [],
                storeState: {},
                storeOfferLiveOpsEvents: {},
                originalPriceReferences: {},
            };

            !!data &&
                Array.isArray(data) &&
                data.forEach((item) => {
                    dataToSet = {
                        ...item,
                        externalPriceReferences: item?.externalPriceReferences
                            ? {
                                  ...dataToSet.externalPriceReferences,
                                  ...item?.externalPriceReferences,
                              }
                            : dataToSet.externalPriceReferences,

                        storeOfferLiveOpsEvents: {
                            ...dataToSet.storeOfferLiveOpsEvents,
                            ...(item?.storeOfferLiveOpsEvents
                                ? item?.storeOfferLiveOpsEvents
                                : { [item?.identifier]: item }),
                        },
                        storeState: item?.storeState
                            ? { ...dataToSet.storeState, ...item.storeState }
                            : {},
                        transactionProducts: {
                            ...dataToSet.transactionProducts,
                            ...item?.transactionProducts,
                        },
                        storeProductInfoList: item?.storeProductInfoList
                            ? [
                                  ...(dataToSet?.storeProductInfoList ?? []),
                                  ...item.storeProductInfoList,
                              ]
                            : [],
                        originalPriceReferences: item?.originalPriceReferences
                            ? {
                                  ...dataToSet.originalPriceReferences,
                                  ...item.originalPriceReferences,
                              }
                            : {},
                    };

                    return item;
                });

            state.shopItems = data ? dataToSet : null;
        },

        setOfferInfoLoader(state: TSpecialOffersState, action: PayloadAction<boolean>) {
            state.shopItemInfoLoader = action.payload;
        },

        setOfferInfoData(state: TSpecialOffersState, action: PayloadAction<IShopItemInfo>) {
            state.shopItemInfo = action.payload;
        },
        setProductInfoListLoader(state: TSpecialOffersState, action: PayloadAction<boolean>) {
            state.bannerInfoListLoader = action.payload;
        },
        setProductInfoList(state: TSpecialOffersState, action: PayloadAction<TBannerInfoList>) {
            state.bannerInfoList = action.payload;
        },
        setOfferModalData(
            state: TSpecialOffersState,
            action: PayloadAction<TOfferModalData | null>,
        ) {
            state.offerModalData = action.payload;
        },
    },
});
