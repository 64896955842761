import React from 'react';

import PropTypes from 'prop-types';

import usePalette from '../../palette';
import {
    ButtonStyled,
    IconStyled,
    LoaderStyled,
    TIconButtonStyledProps,
} from './IconButton.styled';

function IconButton({
    as = 'button',
    type = 'button',
    href = '/',
    variant,
    size = 'medium',
    style,
    icon = '',
    circle = false,
    onClick = undefined,
    isDisabled = false,
    isActive = false,
    isLoading = false,
    ...props
}: TIconButtonStyledProps) {
    const { palette } = usePalette();

    const elementProps = as === 'button' ? { type, onClick, disabled: isDisabled } : { href };

    return (
        <ButtonStyled
            as={as === 'link' ? 'a' : 'button'}
            variant={palette[variant]}
            size={size}
            isActive={isActive}
            style={style?.button}
            circle={circle}
            {...elementProps}
            {...props}
        >
            {isLoading ? (
                <LoaderStyled style={style?.icon} className="icon-download-arrows-loading" />
            ) : icon ? (
                <IconStyled
                    size={size}
                    isDisabled={isDisabled}
                    isActive={isActive}
                    style={style?.icon}
                    className={icon}
                />
            ) : null}
        </ButtonStyled>
    );
}

IconButton.propTypes = {
    as: PropTypes.oneOf(['button', 'link']),
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    href: PropTypes.string,
    variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'solid', 'white']).isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    style: PropTypes.shape({
        button: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        icon: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    }),
    icon: PropTypes.string.isRequired,
    circle: PropTypes.bool,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    isActive: PropTypes.bool,
    isLoading: PropTypes.bool,
};

export default IconButton;
