import { TLocalizationState, TLocalization } from 'src/@types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialSettingsState } from './initialState';

export const localizationSlice = createSlice({
    name: 'localization',
    initialState: initialSettingsState,
    reducers: {
        setLoading(state: TLocalizationState, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setError(state: TLocalizationState, action: PayloadAction<object>) {
            state.error = action.payload;
        },
        setLocalization(state: TLocalizationState, action: PayloadAction<TLocalization>) {
            state.localization = action.payload;
        },
    },
});
