import styled from 'styled-components';

const BUTTON_STYLES = {
    desktop: {
        width: 44,
        height: 44,
    },
    mobile: {
        width: 36,
        height: 36,
    },
};

export const CloseButtonStyled = styled.button`
    position: absolute;
    top: -${BUTTON_STYLES.desktop.height / 2}px;
    right: -${BUTTON_STYLES.desktop.width / 2}px;
    z-index: 100;
    width: ${BUTTON_STYLES.desktop.width}px;
    height: ${BUTTON_STYLES.desktop.height}px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.common.white};
    box-shadow: ${({ theme }) => theme.elevation[400]};

    ${({ theme }) => theme.breakpoints.down('sm')} {
        top: -${BUTTON_STYLES.mobile.height / 3}px;
        right: -${BUTTON_STYLES.mobile.width / 3}px;
        width: ${BUTTON_STYLES.mobile.width}px;
        height: ${BUTTON_STYLES.mobile.height}px;
    }
`;
