import { attributionHook } from '../configs/titan.config';

import { useEffectOnce } from '../hooks/useEffectOnce';

import { titanAnalyticsEvents } from './titanAnalytics';

export function useAttributionHook(user) {
    useEffectOnce(() => {
        attributionHook.appOpen();
        attributionHook.userLanding();
        titanAnalyticsEvents.appOpen(user);
    });
}
