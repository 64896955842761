import { createSelector } from 'reselect';
import {
    TWidgetsLeaderboard,
    ITopPlayersAllianceLeaderboard,
    ITopEventsLeaderboard,
    TTop500Leaderboard,
    IPlayerAllianceLeaderboard,
} from 'src/@types';

import { RootState } from '../configureStore';

const leaderboadsStateSelector = (state: RootState) => state.leaderboards;

/**
 ** LEADERBOARDS PAGE
 */
const loadingGlobalSelector = createSelector(
    [leaderboadsStateSelector],
    ({ isGlobalLoading }: { isGlobalLoading: boolean }) => isGlobalLoading,
);

const globalPlayerLeaderboardSelector = createSelector(
    [leaderboadsStateSelector],
    ({ globalPlayerLeaderboard }: { globalPlayerLeaderboard: Array<IPlayerAllianceLeaderboard> }) =>
        globalPlayerLeaderboard,
);

const globalAllianceLeaderboardSelector = createSelector(
    [leaderboadsStateSelector],
    ({
        globalAllianceLeaderboard,
    }: {
        globalAllianceLeaderboard: Array<IPlayerAllianceLeaderboard>;
    }) => globalAllianceLeaderboard,
);

const widgetsLeaderboardLeaderboardSelector = createSelector(
    [leaderboadsStateSelector],
    ({ widgetsLeaderboard }: { widgetsLeaderboard: Array<TWidgetsLeaderboard> }) =>
        widgetsLeaderboard,
);

/**
 ** LEADERBOARDS PAGE
 */

const topPlayersLeaderboardSelector = createSelector(
    [leaderboadsStateSelector],
    ({
        topPlayersLeaderboard,
    }: {
        topPlayersLeaderboard: Array<ITopPlayersAllianceLeaderboard> | null;
    }) => topPlayersLeaderboard,
);

const topPlayersLeaderboardLoadingSelector = createSelector(
    [leaderboadsStateSelector],
    ({ isTopPlayersLoading }: { isTopPlayersLoading: boolean }) => isTopPlayersLoading,
);

const topAlliancesLeaderboardSelector = createSelector(
    [leaderboadsStateSelector],
    ({
        topAllianceLeaderboard,
    }: {
        topAllianceLeaderboard: Array<ITopPlayersAllianceLeaderboard> | null;
    }) => topAllianceLeaderboard,
);

const topAlliancesLeaderboardLoadingSelector = createSelector(
    [leaderboadsStateSelector],
    ({ isTopAllianceLoading }: { isTopAllianceLoading: boolean }) => isTopAllianceLoading,
);

const topEventsLeaderboardSelector = createSelector(
    [leaderboadsStateSelector],
    ({ topEventsLeaderboard }: { topEventsLeaderboard: Array<ITopEventsLeaderboard> | null }) =>
        topEventsLeaderboard,
);

const topEventsLeaderboardLoadingSelector = createSelector(
    [leaderboadsStateSelector],
    ({ isTopEventsLoading }: { isTopEventsLoading: boolean }) => isTopEventsLoading,
);

const topPlayersDateSelector = createSelector(
    [leaderboadsStateSelector],
    ({ topPlayersDate }: { topPlayersDate: string }) => topPlayersDate,
);

const topAllianceDateSelector = createSelector(
    [leaderboadsStateSelector],
    ({ topAllianceDate }: { topAllianceDate: string }) => topAllianceDate,
);

const topEventsDateSelector = createSelector(
    [leaderboadsStateSelector],
    ({ topEventsDate }: { topEventsDate: string }) => topEventsDate,
);

/**
 ** LEADERBOARDS TOP 500
 */

const top500LeaderboardSelector = createSelector(
    [leaderboadsStateSelector],
    ({ top500Leaderboard }: { top500Leaderboard: Array<TTop500Leaderboard> }) => top500Leaderboard,
);

const top500LeaderboardLoadingSelector = createSelector(
    [leaderboadsStateSelector],
    ({ top500LeaderboardLoading }: { top500LeaderboardLoading: boolean }) =>
        top500LeaderboardLoading,
);

export const leaderboardsSel = {
    loadingGlobalSelector,
    globalPlayerLeaderboardSelector,
    globalAllianceLeaderboardSelector,
    widgetsLeaderboardLeaderboardSelector,
    topPlayersLeaderboardSelector,
    topPlayersLeaderboardLoadingSelector,
    topAlliancesLeaderboardLoadingSelector,
    topAlliancesLeaderboardSelector,
    topEventsLeaderboardSelector,
    topEventsLeaderboardLoadingSelector,
    top500LeaderboardSelector,
    top500LeaderboardLoadingSelector,
    topPlayersDateSelector,
    topAllianceDateSelector,
    topEventsDateSelector,
};
