import { TAvatarPlayer } from 'src/@types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialAvatarProfileState } from './initialState';

export const avatarPlayerSlice = createSlice({
    name: 'avatarPlayer',
    initialState: initialAvatarProfileState(),
    reducers: {
        setAvatars(state, action) {
            state.avatars = action.payload;
        },
        setAvatarsLoading(state, action: PayloadAction<boolean>) {
            state.avatarLoader = action.payload;
        },
        setCurrentAvatars(state, action: PayloadAction<TAvatarPlayer>) {
            state.currentAvatar = action.payload;
        },
    },
});
