import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Box } from '../../core-ui';

export default function Main({ children }) {
    const location = useLocation();
    useEffect(() => {
        return () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };
    }, [location]);

    return (
        <Box
            pt={[16, 16, 0]}
            ml={[0, 0, 60]}
            mr={[0, 0, 70]}
            width={[1, 1, 'calc(100% - 520px)']}
            position="relative"
            flexGrow={1}
        >
            <Box px={[5, 8]}>{children}</Box>
        </Box>
    );
}

Main.propTypes = {
    children: PropTypes.node,
};
