import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { globalSel } from '../../../../store/global';

import { BEEPCOIN_TYPE, GEM_TYPE } from '../../../../constants/currencies';

import { thousandsFormatterWithCommas } from '../../../../helpers/utils';

import PlayerAvatar from './components/PlayerAvatar';
import PlayerInfo from './components/PlayerInfo';

import { Box, Icon } from '../../../core-ui';

import beepCoin from '../../../../assets/images/currency_beepcoin.png';
import gems from '../../../../assets/images/gems.png';
import playerLevel from '../../../../assets/images/playerLevel.png';
import playerPower from '../../../../assets/images/playerPower.png';
import { IRightSidebarProps } from '../RightSidebar';

function UserProfile({ setOpenRightSidebar, openRightSidebar }: IRightSidebarProps) {
    const [t] = useTranslation();
    const theme = useSelector(globalSel.themeSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);
    const userBalance = useSelector(globalSel.userBalanceSelector);
    const userCollections = useSelector(globalSel.userCollectionsSelector);

    const power = userCollections?.battleCharacters?.reduce(
        (acc, b: { power: number }) => acc + +b?.power,
        0,
    );

    const beepCoinsBalance =
        (userBalance.length &&
            userBalance.find((item) => item.typeName === BEEPCOIN_TYPE)?.quantity) ||
        0;

    const gemBalance =
        (userBalance.length && userBalance.find((item) => item.typeName === GEM_TYPE)?.quantity) ||
        0;
    // const handleNavigateTo = (labelType) => () => {
    //     if (!history.location.pathname.includes('special-offers')) {
    //         history.push('special-offers');
    //     }
    //
    //     openRightSidebar && setOpenRightSidebar(false);
    //     dispatch(specialOffersOp.setActiveTab(labelType));
    // };

    return (
        <Box mt={5} mb={10}>
            {openRightSidebar && (
                <Box
                    pl={5}
                    pr={3}
                    mb={-2}
                    display="flex"
                    alignItems="center"
                    color={theme.palette.grey[600]}
                    onClick={() => {
                        setOpenRightSidebar(false);
                    }}
                >
                    <Icon className="icon-close-fill" size={24} />
                </Box>
            )}

            <Box display="block" textAlign="center" justifyContent="center">
                <PlayerAvatar
                    setOpenRightSidebar={setOpenRightSidebar}
                    openRightSidebar={openRightSidebar}
                />

                <Box
                    bgcolor={theme.palette.background[100]}
                    height={64}
                    borderRadius="8px"
                    display="flex"
                    mx={5}
                    mt={7}
                    mb={3}
                    justifyContent="space-between"
                    pr={7}
                    top={1}
                >
                    <PlayerInfo
                        value={currentUser?.playerProfile?.level}
                        title={t('level')}
                        image={playerLevel}
                        hasAmount
                    />

                    <PlayerInfo
                        value={thousandsFormatterWithCommas(power ?? 0)}
                        title={t('power')}
                        image={playerPower}
                    />
                </Box>

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bgcolor={theme.palette.background[100]}
                    mx={5}
                    mb={2}
                    height={56}
                    borderRadius="8px"
                >
                    <PlayerInfo
                        value={thousandsFormatterWithCommas(`${beepCoinsBalance}`)}
                        title={t('right.bar.user.profile.beepcoins')}
                        image={beepCoin}
                        isBeepcoin
                    />
                    {/*<Box pr={3}>*/}
                    {/*    <AddBalance handleClick={handleNavigateTo('beep-coin')} />*/}
                    {/*</Box>*/}
                </Box>

                <Box
                    mx={5}
                    bgcolor={theme.palette.background[100]}
                    height={56}
                    borderRadius="8px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <PlayerInfo
                        value={thousandsFormatterWithCommas(`${gemBalance}`)}
                        title={t('right.bar.user.profile.gems')}
                        image={gems}
                    />
                    <Box pr={3}>
                        {/*<AddBalance handleClick={handleNavigateTo('free-offers')} />*/}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default UserProfile;
