import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

import ReduxThunk from 'redux-thunk';

import { IS_DEVELOPMENT } from '../constants/environment.constant';

import { AnyAction, configureStore, Dispatch, Middleware } from '@reduxjs/toolkit';

import { reducers } from './reducers';

const middleware = [ReduxThunk] as Array<Middleware>;

const store = configureStore({
    reducer: reducers(),
    middleware: middleware,
    devTools: IS_DEVELOPMENT,
    enhancers: [],
});

export type RootState = ReturnType<typeof store.getState>;

// Todo: check any
export type CustomDispatchAction = AnyAction | any;
export type AppDispatch = Dispatch<CustomDispatchAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
