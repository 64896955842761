import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Hidden, useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';

import { Box, Icon } from '../../core-ui';

import logo from '../../../assets/images/logo.png';
import LoonyTunes from '../../../assets/images/LOONEY TUNES.png';
import Mayhem from '../../../assets/images/MAYHEM.png';
import MobileMenuText from '../../../assets/images/mobile-menu-text.png';
import WorldOf from '../../../assets/images/WORLD OF.png';

interface IAppLogoProps {
    isLeftSide: boolean;
    setOpenLeftSidebar: (open: boolean) => void;
}

const AppLogo: React.FC<IAppLogoProps> = ({ isLeftSide, setOpenLeftSidebar }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const customXS = useMediaQuery('(max-width:375px)');

    return (
        <Box display={isLeftSide ? 'block' : 'inline-block'}>
            <Box
                onClick={() => {
                    navigate('/');
                    setOpenLeftSidebar(false);
                }}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    mb={isLeftSide ? 10 : [0, 0, 10]}
                    mt={isLeftSide ? 5 : 0}
                    mx={isLeftSide ? 5 : 0}
                >
                    <Box
                        component="img"
                        src={logo}
                        width={isLeftSide ? 50 : [30, 32]}
                        className="cursor-pointer"
                        alt="Looney tunes logo"
                        borderRadius="8px"
                    />
                    {!isLeftSide && customXS ? null : (
                        <Box ml={3} fontSize={0} className="cursor-pointer">
                            <Box height={20} display="flex" alignItems="center">
                                <img src={LoonyTunes} width="100%" alt="Looney tunes" />
                            </Box>
                            {isLeftSide ? (
                                <>
                                    <Box height={16}>
                                        <Box component="img" src={WorldOf} alt="World of" />
                                    </Box>
                                    <Box component="img" height={18} src={Mayhem} alt="Mayhem" />
                                </>
                            ) : (
                                <Box height={16} display="flex">
                                    <img src={MobileMenuText} alt="World of Mayhem" height={10} />
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>

            {!isLeftSide && customXS ? null : (
                <Box ml={3} className="cursor-pointer">
                    {isLeftSide && (
                        <Hidden mdUp>
                            <Box
                                position="absolute"
                                right={8}
                                top={20}
                                bottom={0}
                                color={theme.palette.grey[600]}
                                onClick={() => setOpenLeftSidebar(false)}
                            >
                                <Icon className="icon-close-fill" />
                            </Box>
                        </Hidden>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default memo(AppLogo);
