import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material';

import { globalSel } from '../../../../store/global';

import { IOfferReward } from '../../../../@types';

import { getRewardItemName, getRewardsCount, getRewardsIcons } from '../../../../helpers/rewards';
import {
    getBeepcoinCount,
    getGuaranteedOneOfTheseItemsDescription,
    REWARD_TYPES,
} from '../../../../helpers/utils';

import { Box, Icon } from '../../../core-ui';

import beepCoinLogo from '../../../../assets/images/currency_beepcoin.png';
import { LazyImage, Typography } from '../../../../stories';
import InfoTooltip from './InfoTooltip';
import ModalContentInfo from './ModalContentInfo';
import { ModalContentItemStyled } from './ModalContentItem.styled';

interface IModalContentItemProps {
    title: string;
    data: Array<IOfferReward>;
    type: string;
    isBeepCoin: boolean;
    price: number;
}
function ModalContentItem({ title, data, type, isBeepCoin, price }: IModalContentItemProps) {
    const theme = useTheme();
    const [t] = useTranslation();

    const dashboardBannerSettings = useSelector(globalSel.dashboardBannerSelector);

    const [isOpen, setIsOpen] = useState(false);

    const showConversionRewards = useMemo(() => {
        return (
            !isBeepCoin &&
            dashboardBannerSettings?.beepcoinConversionRate &&
            type === REWARD_TYPES.guaranteed
        );
    }, [dashboardBannerSettings?.beepcoinConversionRate, isBeepCoin, type]);

    return (
        <ModalContentItemStyled>
            <Box width={1} display="flex" alignItems="center">
                <Box pr={2}>
                    <Typography variant="p14" fWeight="semiBold" color={theme.palette.grey[900]}>
                        {t(title)}
                    </Typography>
                </Box>

                {type !== REWARD_TYPES.guaranteed ? (
                    <InfoTooltip
                        arrow
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        placement="top"
                        title={
                            <ModalContentInfo
                                data={data?.map((i) =>
                                    getGuaranteedOneOfTheseItemsDescription(i, t),
                                )}
                            />
                        }
                    >
                        <div onMouseOver={() => setIsOpen(true)}>
                            <Box
                                component={Icon}
                                display="flex"
                                onClick={() => setIsOpen(true)}
                                alignItems="center"
                                justifyContent="center"
                                className="icon-info cursor-pointer"
                                bgcolor={theme.palette.grey[200]}
                                color={theme.palette.grey[500]}
                                borderRadius="50%"
                                width={18}
                                height={18}
                                size={8}
                            />
                        </div>
                    </InfoTooltip>
                ) : null}
            </Box>
            <Box
                width={1}
                mt={2}
                bgcolor={theme.palette.grey[100]}
                borderRadius="10px"
                pt={4}
                pl={5}
                pr={2}
            >
                <Box width={1} height={148} pr={2} pb={1} className="modal-item-content">
                    {data.length ? (
                        <>
                            {data.map((item) => {
                                const icon = getRewardsIcons(item);
                                const name = getRewardItemName(item, t);
                                const amount = getRewardsCount(item);

                                return (
                                    <Box
                                        key={item.gameItemId}
                                        width={1}
                                        pb={3}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <>
                                            <Box mr={3} minWidth={36}>
                                                {icon ? (
                                                    <LazyImage
                                                        height="32px"
                                                        src={icon}
                                                        alt="offer-reward-icon"
                                                        draggable={false}
                                                    />
                                                ) : null}
                                            </Box>

                                            <Typography
                                                variant="p12"
                                                fWeight="semiBold"
                                                color={theme.palette.grey[800]}
                                            >
                                                {`${amount} ${name}`}
                                            </Typography>
                                        </>
                                    </Box>
                                );
                            })}

                            {showConversionRewards && price ? (
                                <Box width={1} pb={3} display="flex" alignItems="center">
                                    <Box mr={3} minWidth={36}>
                                        <LazyImage
                                            height="32px"
                                            src={beepCoinLogo}
                                            alt="offer-reward-icon"
                                            draggable={false}
                                        />
                                    </Box>
                                    <Typography
                                        variant="p12"
                                        fWeight="semiBold"
                                        color={theme.palette.grey[800]}
                                    >
                                        {`x${getBeepcoinCount(price)} Beepcoin`}
                                    </Typography>
                                </Box>
                            ) : null}
                        </>
                    ) : (
                        <Box textAlign="center" py={12}>
                            <Typography
                                variant="p12"
                                loaderWidth={100}
                                color={theme.palette.grey[900]}
                                fWeight="semiBold"
                            >
                                {t('offer.info.error.message')}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </ModalContentItemStyled>
    );
}

export default ModalContentItem;
