import React from 'react';

import { useTheme } from '@mui/material';

import { Box, Skeleton } from '../../../views/core-ui';

function MilestoneCardSkeleton() {
    const theme = useTheme();

    return (
        <Box
            height={356}
            bgcolor={theme.palette.common.white}
            borderRadius="12px"
            p={4}
            data-testid="milestone-card-skeleton"
        >
            <Box height={220} borderRadius="12px" overflow="hidden">
                <Skeleton />
            </Box>

            <Box mt={4} height={24} borderRadius="6px" overflow="hidden">
                <Skeleton />
            </Box>

            <Box mt={1} height={24} borderRadius="6px" overflow="hidden">
                <Skeleton />
            </Box>

            <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box width={1} height={32} borderRadius="6px" overflow="hidden">
                    <Skeleton />
                </Box>

                <Box
                    minWidth={32}
                    height={32}
                    ml={2}
                    borderRadius="6px"
                    className="WP-OffersSubTab-info-icon cursor-pointer"
                    overflow="hidden"
                >
                    <Skeleton />
                </Box>
            </Box>
        </Box>
    );
}

export default MilestoneCardSkeleton;
