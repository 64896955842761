import React from 'react';
import { useSelector } from 'react-redux';

import { Grid, useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { globalSel } from '../../../store/global';

import { Box } from '../../core-ui';

import shine from '../../../assets/images/shines.png';
import { AvatarModalStyled } from './AvatarModal.styled';

function AvatarModalSkeleton() {
    const theme = useSelector(globalSel.themeSelector);
    const mobileQuery = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Box component={AvatarModalStyled}>
                <Box
                    width={['calc(100vw - 32px)', 720, 850]}
                    position="relative"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    className="WP-AvatarModal-root"
                >
                    <Box
                        display="flex"
                        width={1}
                        height={1}
                        overflow="auto"
                        flexDirection={['column', 'row']}
                    >
                        <Box ml={5} mr={[5, 0]} mt={5}>
                            <Box
                                mb={[5, 4, 4, 4, 9]}
                                className="WP-AvatarModal-modal-left"
                                position="relative"
                                width={[1, 265]}
                                height={[230, 321]}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                overflow="hidden"
                            >
                                <Box component="img" src={shine} alt="shine" width="130%" />
                            </Box>
                            <Box mb={[2, 5]}>
                                <Box px={8}>
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        height={20}
                                        width="100%"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            maxWidth="max-content"
                            maxHeight={mobileQuery ? 100 : 440}
                            borderRadius="10px"
                            m={5}
                            p={5}
                            className="WP-AvatarModal-avatars-root"
                        >
                            <Box display="flex" pb={5} className="WP-AvatarModal-btn-root">
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    height={20}
                                    width={100}
                                />
                            </Box>
                            <Box className="WP-AvatarModal-available-avatars" pt={5} pb={2}>
                                <Skeleton variant="text" animation="wave" height={20} width={100} />
                            </Box>
                            <Box pt={4}>
                                <Grid
                                    container
                                    spacing={5}
                                    className="WP-AvatarModal-container-avatar"
                                    justifyContent={['center', 'right']}
                                >
                                    {[1, 2, 3, 4, 5, 6, 7, 8]?.map((item) => {
                                        return (
                                            <Grid
                                                key={item}
                                                item
                                                lg={2}
                                                sm={3}
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                position="relative"
                                            >
                                                <Box
                                                    width={[60, 65, 65, 65, 80]}
                                                    height={[60, 65, 65, 65, 80]}
                                                    zIndex={2}
                                                >
                                                    <Skeleton
                                                        variant="circular"
                                                        animation="wave"
                                                        height="100%"
                                                        width="100%"
                                                    />
                                                </Box>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

export default AvatarModalSkeleton;
