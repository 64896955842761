import { combineReducers } from '@reduxjs/toolkit';

import allianceProfileSlice from './allianceProfile';
import { avatarPlayerSlice } from './avatarPlayer/avatarPlayerSlice';
import beepCoinShopSlice from './beepCoinShop';
import calendarSlice from './calendar';
import eventsRBESlice from './eventsRBE';
import globalSlice from './global';
import leaderboardsSlice from './leaderboards';
import localizationSlice from './localization';
import newsSlice from './news';
import playerProfileSlice from './playerProfile';
import specialOffersSlice from './specialOffers';
import toonGuidesSlice from './toonGuide';

export const reducers = () =>
    combineReducers({
        global: globalSlice.reducer,
        toonGuide: toonGuidesSlice.reducer,
        calendar: calendarSlice.reducer,
        beepCoinShop: beepCoinShopSlice.reducer,
        playerProfile: playerProfileSlice.reducer,
        news: newsSlice.reducer,
        alliance: allianceProfileSlice.reducer,
        specialOffers: specialOffersSlice.reducer,
        localization: localizationSlice.reducer,
        leaderboards: leaderboardsSlice.reducer,
        avatar: avatarPlayerSlice.reducer,
        eventsRBE: eventsRBESlice.reducer,
    });
