import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material';

import { eventsRBEOp, eventsRBESel } from '../../../store/eventsRBE';
import { localizationSel } from '../../../store/localization';

import { IMilestoneReward } from '../../../@types';

import { getMilestoneCardType } from '../../../helpers/milestones';

import MilestoneInfoModal from '../../../views/components/MilestoneInfoModal/MilestoneInfoModal';

import { Box, Icon } from '../../../views/core-ui';

import mock_milestone_image from '../../../assets/images/freeClaim-image.png';
import milestone_shine from '../../../assets/images/Milestones/milestone_shine.png';
import { LazyImage } from '../../index';
import Button from '../Button';
import Typography from '../Typography';
import MilestoneCardSkeleton from './MilestoneCardSkeleton';

export interface IMilestoneCardProps {
    isLoading: boolean;
    isStory?: boolean;
    order: number;
    currentProgress: number;
    requiredPoints: number;
    title: string;
    status: string;
    rewards: IMilestoneReward[];
    image?: string | null;
    id: string;
}

export const MilestoneCard: React.FC<IMilestoneCardProps> = ({
    isLoading,
    order,
    currentProgress,
    requiredPoints,
    isStory,
    title,
    status,
    rewards,
    image,
    id,
}) => {
    const [t] = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const isClaimLoading = useSelector(eventsRBESel.isClaimLoadingSelector);
    const isLocalizationLoading = useSelector(localizationSel.loadingSelector);

    const cardType = useMemo(
        () => getMilestoneCardType(status, currentProgress, requiredPoints),
        [currentProgress, requiredPoints, status],
    );

    const onClaim = useCallback(() => {
        if (isModalOpen) setIsModalOpen(false);

        dispatch(
            eventsRBEOp.claimMilestone(id, {
                title: t(title),
                rewards,
                image,
            }),
        );
    }, [isModalOpen, dispatch, id, t, title, rewards, image]);

    const openMilestonePopUp = () => {
        setIsModalOpen(true);
    };

    const milestoneButton = useMemo(
        () => (
            <Button
                fullWidth
                label={t(cardType.title)}
                variant="primary"
                size="small"
                isLoading={!isStory && isLocalizationLoading}
                isProcessing={isClaimLoading === id}
                isDisabled={isClaimLoading === id || cardType.disabled}
                onClick={onClaim}
                data-testid="claim-button"
            />
        ),
        [
            cardType.disabled,
            cardType.title,
            isClaimLoading,
            isLocalizationLoading,
            isStory,
            onClaim,
            id,
            t,
        ],
    );

    return isLoading ? (
        <MilestoneCardSkeleton />
    ) : (
        <>
            <Box
                height={356}
                bgcolor={theme.palette.common.white}
                borderRadius="12px"
                p={4}
                data-testid="milestone-card"
            >
                <Box
                    width={1}
                    height={220}
                    className="background-cover"
                    bgimage={milestone_shine}
                    bgcolor={theme.palette.grey[300]}
                    borderRadius="12px"
                    p={4}
                    position="relative"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box
                        width={40}
                        height={40}
                        bgcolor={theme.palette.grey[100]}
                        border={1}
                        borderColor={theme.palette.grey[400]}
                        borderRadius="50%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="absolute"
                        top={12}
                        left={12}
                    >
                        <Typography
                            variant="p"
                            fontSize="14px"
                            fWeight="bold"
                            color={theme.palette.grey[600]}
                            loaderWidth={12}
                            isStory={isStory}
                        >
                            {order}
                        </Typography>
                    </Box>

                    {cardType.claimed && (
                        <Box
                            width={40}
                            height={40}
                            bgcolor={theme.palette.green[100]}
                            borderRadius="50%"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            position="absolute"
                            top={12}
                            right={12}
                            data-testid="claimed-badge-icon"
                        >
                            <Icon
                                className="icon-verified"
                                size={20}
                                color={theme.palette.green[600]}
                            />
                        </Box>
                    )}

                    <LazyImage
                        src={image || mock_milestone_image}
                        alt="milestone-item-icon"
                        width="178px"
                        height="150px"
                        draggable={false}
                    />
                </Box>

                <Box pt={4}>
                    <Typography
                        fontSize="14px"
                        fWeight="semiBold"
                        color={theme.palette.common.black}
                        isStory={isStory}
                    >
                        {t(title)}
                    </Typography>
                </Box>

                <Box pt={1}>
                    <Typography fontSize="12px" color={theme.palette.grey[600]} isStory={isStory}>
                        {t('events.milestone.card.required.points')}: {requiredPoints}
                    </Typography>
                </Box>

                <Box pt={2} display="flex" alignItems="center" justifyContent="space-between">
                    {milestoneButton}

                    <Box
                        p={2}
                        ml={2}
                        bgcolor={theme.palette.grey['200']}
                        borderRadius="6px"
                        className="WP-OffersSubTab-info-icon cursor-pointer"
                        onClick={openMilestonePopUp}
                    >
                        <Box
                            width={16}
                            height={16}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            bgcolor={theme.palette.grey['600']}
                            borderRadius="50%"
                            className="cursor-pointer"
                        >
                            <Icon
                                color={theme.palette.common.white}
                                className="icon-info"
                                size={6}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            {isModalOpen ? (
                <MilestoneInfoModal
                    isModalOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    isStory={isStory}
                    milestone={{
                        title: t(title),
                        iconPath: image || mock_milestone_image,
                        requiredPoints,
                        button: milestoneButton,
                        rewards,
                    }}
                />
            ) : null}
        </>
    );
};
