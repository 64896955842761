const setStorageItem = <T>(key: string, value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const getStorageItem = (key: string) => {
    const name = localStorage.getItem(key);
    if (name) {
        return JSON.parse(name);
    }
};

const removeStorageItem = (key: string) => {
    localStorage.removeItem(key);
};

const setSessionStorage = <T>(key: string, value: T) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

const getSessionStorage = (key: string) => {
    const name = sessionStorage.getItem(key);
    if (name) {
        return JSON.parse(name);
    }
};

const removeSessionStorage = (key: string) => {
    sessionStorage.removeItem(key);
};

const removeSWCacheStorage = () => {
    caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
            caches.delete(cacheName).then((name) => {
                // eslint-disable-next-line no-console
                console.log(name, 'cache-name');
            });
        });
    });
};

export {
    setStorageItem,
    getStorageItem,
    removeStorageItem,
    setSessionStorage,
    getSessionStorage,
    removeSessionStorage,
    removeSWCacheStorage,
};
