import {
    TLeaderboardsInitialState,
    TWidgetsLeaderboard,
    ITopPlayersAllianceLeaderboard,
    TGlobalPlayerLeaderboardAction,
    TTopEventsLeaderboardAction,
    TTop500Leaderboard,
    ITopAlliancePlayerFields,
} from 'src/@types';

import { PORTAL_S3_BUCKET_URL } from '../../constants/api.constants';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialSettingsState } from './initialState';

export const leaderboardsSlice = createSlice({
    name: 'leaderboards',
    initialState: initialSettingsState(),
    reducers: {
        /**
         ** DASHBOARD LEADERBOARDS
         */
        setGlobalLoading(state: TLeaderboardsInitialState, action: PayloadAction<boolean>) {
            state.isGlobalLoading = action.payload;
        },
        setGlobalPlayerLeaderboard(
            state: TLeaderboardsInitialState,
            action: PayloadAction<Array<TGlobalPlayerLeaderboardAction>>,
        ) {
            state.globalPlayerLeaderboard = action.payload.map((item) => {
                return {
                    id: item.userId,
                    rank: item.playerRank,
                    name: item.playerName,
                    alliance: item.factionName,
                    allianceId: item.factionId,
                    leaderboardId: item.leaderboardId,
                    points: Math.round(item.playerPoints),
                    image: item.selectedAvatar
                        ? `${PORTAL_S3_BUCKET_URL}${item.selectedAvatar.path}`
                        : item.userInfo
                        ? item.userInfo.avatar
                        : null,
                };
            });
        },
        setGlobalAllianceLeaderboard(
            state: TLeaderboardsInitialState,
            action: PayloadAction<Array<TTopEventsLeaderboardAction>>,
        ) {
            state.globalAllianceLeaderboard = action.payload.map((item) => {
                return {
                    id: item.factionId,
                    name: item.factionName,
                    rank: item.playerRank,
                    points: Math.round(item.playerPoints),
                    alliancePalette: item.alliancePalette,
                    allianceSource: item.allianceSource,
                    image: item.selectedAvatar
                        ? `${PORTAL_S3_BUCKET_URL}${item.selectedAvatar.path}`
                        : item.userInfo
                        ? item.userInfo.avatar
                        : null,
                };
            });
        },

        setWidgetsLeaderboard(
            state: TLeaderboardsInitialState,
            action: PayloadAction<Array<TWidgetsLeaderboard>>,
        ) {
            state.widgetsLeaderboard = action.payload;
        },

        /**
         ** LEADERBOARDS PAGE
         */

        setTopPlayersLeaderboardPageLoading(
            state: TLeaderboardsInitialState,
            action: PayloadAction<boolean>,
        ) {
            state.isTopPlayersLoading = action.payload;
        },

        setTopPlayersLeaderboardPage(
            state: TLeaderboardsInitialState,
            action: PayloadAction<ITopPlayersAllianceLeaderboard>,
        ) {
            const topPlayers = [];

            for (const [key, value] of Object.entries(action.payload)) {
                if (value.length) {
                    topPlayers.push({
                        name: key,
                        data: value,
                    });
                }
            }

            state.topPlayersLeaderboard = topPlayers;
        },

        setTopAllianceLeaderboardPageLoading(
            state: TLeaderboardsInitialState,
            action: PayloadAction<boolean>,
        ) {
            state.isTopAllianceLoading = action.payload;
        },

        setTopAllianceLeaderboardPage(
            state: TLeaderboardsInitialState,
            action: PayloadAction<ITopPlayersAllianceLeaderboard>,
        ) {
            const topAlliances: { name: string; data: ITopAlliancePlayerFields[] }[] = [];

            for (const [key, value] of Object.entries(action.payload)) {
                if (value.length) {
                    topAlliances.push({
                        name: key,
                        data: value,
                    });
                }
            }

            state.topAllianceLeaderboard = topAlliances;
        },

        setTopEventsLeaderboardPageLoading(
            state: TLeaderboardsInitialState,
            action: PayloadAction<boolean>,
        ) {
            state.isTopEventsLoading = action.payload;
        },

        setTopEventsLeaderboardPage(
            state: TLeaderboardsInitialState,
            action: PayloadAction<Array<TTopEventsLeaderboardAction[]>>,
        ) {
            const events = [];

            for (const [key, value] of Object.entries(action.payload)) {
                const isPlayers = key === 'userLdb';

                if (value.length) {
                    events.push({
                        name: key,
                        data: isPlayers
                            ? value.map((item: TTopEventsLeaderboardAction) => {
                                  return {
                                      id: item.userId,
                                      rank: item.playerRank,
                                      name: item.playerName,
                                      alliance: item.factionName,
                                      allianceId: item.factionId,
                                      points: Math.round(item.playerPoints),
                                      leaderboardId: item.leaderboardId,
                                      image: item.selectedAvatar
                                          ? `${PORTAL_S3_BUCKET_URL}${item.selectedAvatar.path}`
                                          : item.userInfo
                                          ? item.userInfo.avatar
                                          : null,
                                  };
                              })
                            : value.map((item: TTopEventsLeaderboardAction) => {
                                  return {
                                      id: item.id || item.factionId,
                                      name: item.name || item.factionName,
                                      rank: item.playerRank,
                                      leaderboardId: item.leaderboardId,
                                      points: Math.round(item.playerPoints || item.numBombs),
                                      alliancePalette: item.alliancePalette,
                                      allianceSource: item.allianceSource,
                                      image: item.selectedAvatar
                                          ? `${PORTAL_S3_BUCKET_URL}${item.selectedAvatar.path}`
                                          : item.userInfo
                                          ? item.userInfo.avatar
                                          : null,
                                  };
                              }),
                    });
                }
            }

            state.topEventsLeaderboard = events;
        },

        /**
         ** LEADERBOARDS TOP 500
         */
        setTop500Leaderboard(
            state: TLeaderboardsInitialState,
            action: PayloadAction<Array<TTop500Leaderboard>>,
        ) {
            state.top500Leaderboard = action.payload;
        },

        setTop500LeaderboardLoading(
            state: TLeaderboardsInitialState,
            action: PayloadAction<boolean>,
        ) {
            state.top500LeaderboardLoading = action.payload;
        },

        setTopPlayersDate(state: TLeaderboardsInitialState, action: PayloadAction<string>) {
            state.topPlayersDate = action.payload;
        },

        setTopAllianceDate(state: TLeaderboardsInitialState, action: PayloadAction<string>) {
            state.topAllianceDate = action.payload;
        },
        setTopEventsDate(state: TLeaderboardsInitialState, action: PayloadAction<string>) {
            state.topEventsDate = action.payload;
        },
    },
});
