import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { Box } from '../../../core-ui';

function ModalContentSkeleton() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box width={1}>
            {isMobile ? null : (
                <Box
                    width={460}
                    height={24}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box width={180} height={1}>
                        <Skeleton variant="text" animation="wave" width="100%" height="100%" />
                    </Box>
                </Box>
            )}

            <Box width={1} height={164} mt={[0, 2]}>
                <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
            </Box>
        </Box>
    );
}

export default ModalContentSkeleton;
