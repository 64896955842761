import styled from 'styled-components';

import { hexToRgba } from '../../../helpers/utils';

import { IMenuProps } from './Menu';

export const MenuStyled = styled.div<Pick<IMenuProps, 'bgcolor'>>`
    background-color: ${({ bgcolor }) => bgcolor};
    box-shadow: 0 0 20px 30px ${({ theme }) => hexToRgba(theme.palette.purple['A200'], 0.06)};
    border-radius: 12px;

    z-index: 1200;

    &.bottom:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: -7px;
        left: 50%;
        transform: translate(-50%, -50%);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 15px solid ${({ theme }) => theme.palette.common.white};
    }

    &.top:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: -20px;
        left: 50%;
        transform: translate(-50%, -50%);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 15px solid ${({ theme }) => theme.palette.common.white};
    }

    &.left:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        left: -6px;
        top: 50%;
        transform: translate(-50%, -50%);
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 15px solid ${({ theme }) => theme.palette.common.white};
    }

    &.right:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        right: -20px;
        top: 50%;
        transform: translate(-50%, -50%);
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid ${({ theme }) => theme.palette.common.white};
    }

    .WP-Menu-item {
        &:hover {
            background-color: ${({ theme }) => theme.palette.grey[100]};
            border-radius: 6px;
        }
    }
`;
