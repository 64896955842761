import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';

import { IMilestoneReward } from '../../../../@types';

import MilestoneInfoModalContent from '../../MilestoneInfoModal/components/MilestoneInfoModalContent';

import { Box } from '../../../core-ui';

import ConfirmModalBackGroundImage from '../../../../assets/images/modals/ConfirmModalBackgrount.png';
import { Typography, LazyImage } from '../../../../stories';

export interface ISuccessModalProps {
    milestone: {
        title: string;
        image?: string | null;
        rewards: IMilestoneReward[];
    };
}

const MilestoneSuccessModal: FC<ISuccessModalProps> = ({ milestone }) => {
    const theme = useTheme();
    const [t] = useTranslation();

    return (
        <Box
            pt={[6, 15]}
            pb={[6, 8]}
            px={[6, 8]}
            width={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Box
                className="WP-Modal-Icon"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box pb={3}>
                    <Box display="flex" justifyContent="center" alignItems="center" mb={1}>
                        <Box width={1} display="flex" justifyContent="center">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                bordercolor={theme.palette.purple[600]}
                                border={2}
                                borderRadius="50%"
                                width={120}
                                height={120}
                                py={1}
                                px={1}
                            >
                                <Box
                                    bgimage={ConfirmModalBackGroundImage}
                                    width="100px"
                                    height="100px"
                                    className="background-cover"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <LazyImage
                                            src={milestone.image || ''}
                                            alt="milestone"
                                            height="80px"
                                            draggable={false}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box textAlign="center" data-testid="success-payment-modal">
                    <Typography fWeight="bold" variant="h5" color={theme.palette.text[300]}>
                        {t('modal.successful.milestone.claimed')}
                    </Typography>
                </Box>
            </Box>

            <Box textAlign="center" pt={3} px={[0, 2, 6]} maxWidth={[452, 436]}>
                <Typography fWeight="medium" variant="p16" color={theme.palette.text[100]}>
                    {t('modal.successful.claim.close.successful')}
                </Typography>
            </Box>

            <Box py={8} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="p14" fWeight="semiBold" color={theme.palette.grey[900]}>
                    {t(milestone.title)}
                </Typography>
            </Box>

            <MilestoneInfoModalContent rewards={milestone.rewards} />
        </Box>
    );
};

export default MilestoneSuccessModal;
