import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Grid, Hidden, useMediaQuery } from '@mui/material';

import { avatarPLayerOp, avatarPlayerSel } from '../../../store/avatarPlayer';
import { globalSel } from '../../../store/global';

import { PORTAL_S3_BUCKET_URL } from '../../../constants/api.constants';

import { Box, Icon } from '../../core-ui';

import chickAvatar from '../../../assets/images/checkAvatar.svg';
import shine from '../../../assets/images/shines.png';
import userPhoto from '../../../assets/images/UserDefaultAvatar.png';
import { Dialog, Button, Typography } from '../../../stories';
import { AvatarModalStyled } from './AvatarModal.styled';
import AvatarModalSkeleton from './AvatarModalSkeleton';

function AvatarModal({ open, handleModal }) {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const theme = useSelector(globalSel.themeSelector);
    const mobileQuery = useMediaQuery(theme.breakpoints.down('sm'));
    const user = useSelector(globalSel.currentUserSelector);
    const avatars = useSelector(avatarPlayerSel.avatarSelector);
    const avatarLoader = useSelector(avatarPlayerSel.avatarLoaderSelector);

    const [activeAvatar, setActiveAvatar] = useState('');
    const [sessionImageUuid, setSessionImageUuid] = useState('');
    const [activeItemIndex, setActiveItemIndex] = useState(
        avatars?.findIndex((p) => p.uuid === user?.avatar?.uuid),
    );

    const defImg = {
        uuid: 'reset',
        path: userPhoto,
    };

    const handleActiveAvatar = (img, uuid) => {
        setSessionImageUuid(uuid);
        setActiveAvatar(img);
        setActiveItemIndex(avatars?.findIndex((p) => p.uuid === uuid));
    };

    const handleAvatarApply = () => {
        if (sessionImageUuid) {
            if (activeItemIndex === -1) {
                dispatch(avatarPLayerOp.setAvatarImage(defImg));
            } else dispatch(avatarPLayerOp.setAvatarImage(avatars?.[activeItemIndex]));
        }
        closeModal();
    };

    const availableAvatars = avatars?.filter((e) => e?.statusType === 'default' || e.ownByUser);
    const lockedAvatars = avatars?.filter((e) => e?.statusType === 'protected' && !e.ownByUser);

    const isCheckedAvatar = (index, item) => {
        if (item.uuid === user?.avatar?.uuid && !sessionImageUuid.length) {
            return true;
        }
        return availableAvatars?.[index].uuid === sessionImageUuid;
    };

    const getImg = () => {
        if (sessionImageUuid === 'reset') {
            // return initialImg;
            return userPhoto;
        }
        const img = avatars?.filter((e) => e.uuid === sessionImageUuid);
        return `${PORTAL_S3_BUCKET_URL}${img?.[0]?.path}`;
    };

    const closeModal = () => {
        setActiveAvatar('');
        setActiveItemIndex(avatars?.findIndex((p) => p.uuid === user?.avatar?.uuid));
        setSessionImageUuid('');
        handleModal(false);
    };

    useEffect(() => {
        const currentAvatarObj = avatars?.find((obj) => obj.uuid === user?.avatar?.uuid);
        if (open) {
            setActiveAvatar(`${PORTAL_S3_BUCKET_URL}${currentAvatarObj?.path}`);
        }
    }, [dispatch, open, setActiveAvatar, avatars, user, activeAvatar]);

    useEffect(() => {
        const index = avatars?.findIndex((p) => p.uuid === user?.avatar?.uuid);
        setActiveAvatar(`${PORTAL_S3_BUCKET_URL}${avatars?.[index]?.path}`);
        setActiveItemIndex(index);
    }, [avatars, user]);

    return (
        <Dialog
            maxWidth="xl"
            open={open}
            disableEnforceFocus
            PaperProps={{ borderRadius: '12px', padding: '30px 20px' }}
            onClose={closeModal}
            aria-labelledby="responsive-dialog-title"
        >
            {avatarLoader ? (
                <AvatarModalSkeleton />
            ) : (
                <Box component={AvatarModalStyled}>
                    <Box
                        width={['calc(100vw - 32px)', 720, 850]}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        className="WP-AvatarModal-root"
                    >
                        <Box
                            position="absolute"
                            width={44}
                            height={44}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            bgcolor={theme.palette.common.white}
                            top={0}
                            right={0}
                            mt={-4}
                            mr={-4}
                            borderRadius="50%"
                            className="cursor-pointer"
                            onClick={closeModal}
                        >
                            <Box
                                component={Icon}
                                size={15}
                                className="icon-close-fill cursor-pointer"
                                borderRadius="50%"
                            />
                        </Box>
                        <Box
                            display="flex"
                            width={1}
                            height={1}
                            overflow="auto"
                            flexDirection={['column', 'row']}
                        >
                            <Box ml={5} mr={[5, 0]} mt={5}>
                                <Box
                                    mb={[5, 4, 4, 4, 9]}
                                    className="WP-AvatarModal-modal-left"
                                    position="relative"
                                    width={[1, 265]}
                                    height={[230, 321]}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    overflow="hidden"
                                >
                                    <Box component="img" src={shine} alt="shine" width="130%" />
                                    <Box
                                        component="img"
                                        src={
                                            sessionImageUuid
                                                ? getImg()
                                                : !activeAvatar.includes('undef')
                                                ? activeAvatar
                                                : userPhoto
                                            // : initialImg
                                        }
                                        alt="avatar"
                                        style={{ borderRadius: '50%' }}
                                        width={172}
                                        position="absolute"
                                    />
                                </Box>
                                <Hidden smDown>
                                    <Box mb={[2, 5]}>
                                        <Box px={8}>
                                            <Button
                                                variant="primary"
                                                label={t('avatar.modal.apply')}
                                                size="large"
                                                onClick={handleAvatarApply}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Hidden>
                            </Box>

                            <Box
                                maxWidth={1}
                                maxHeight={mobileQuery ? 100 : 440}
                                borderRadius="10px"
                                m={5}
                                p={5}
                                className="WP-AvatarModal-avatars-root"
                            >
                                <Box display="flex" pb={5} className="WP-AvatarModal-btn-root">
                                    <Button
                                        variant="white"
                                        size="small"
                                        label={t('avatar')}
                                        className="white WP-AvatarModal-avatar-btn cursor-pointer"
                                    />
                                </Box>
                                <Box pt={5} pb={2}>
                                    <Typography
                                        className="WP-AvatarModal-available-avatars"
                                        variant="p12"
                                        loaderWidth={120}
                                        color={theme.palette.text[100]}
                                    >
                                        {t('available.avatars')}
                                    </Typography>
                                </Box>
                                <Box pt={4}>
                                    <Box
                                        component={Grid}
                                        container
                                        spacing={5}
                                        className="WP-AvatarModal-container-avatar"
                                    >
                                        <Box
                                            // key={fbImg.uuid}
                                            key={defImg.uuid}
                                            component={Grid}
                                            item
                                            lg={2}
                                            sm={3}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            position="relative"
                                        >
                                            <Box
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    handleActiveAvatar(defImg.path, defImg.uuid);
                                                }}
                                                component="img"
                                                borderRadius="50%"
                                                width={[60, 65, 65, 65, 80]}
                                                src={defImg.path}
                                                zIndex={2}
                                            />
                                            {activeItemIndex === -1 ? (
                                                <>
                                                    <Box
                                                        width={[72, 80, 80, 80, 100]}
                                                        height={[72, 80, 80, 80, 100]}
                                                        zIndex={1}
                                                        position="absolute"
                                                        className="WP-AvatarModal-active-avatar"
                                                        borderRadius="50%"
                                                    />
                                                    <Box
                                                        component="img"
                                                        width={[16, 20]}
                                                        bottom={7}
                                                        right={[15, 45, 45, 15]}
                                                        src={chickAvatar}
                                                        alt="check"
                                                        position="absolute"
                                                        zIndex={3}
                                                    />
                                                </>
                                            ) : null}
                                        </Box>

                                        {availableAvatars?.map((item, index) => {
                                            return (
                                                <Box
                                                    key={item.uuid}
                                                    component={Grid}
                                                    item
                                                    lg={2}
                                                    sm={3}
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    position="relative"
                                                >
                                                    <Box
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            handleActiveAvatar(
                                                                `${PORTAL_S3_BUCKET_URL}${item.path}`,
                                                                item.uuid,
                                                            );
                                                        }}
                                                        component="img"
                                                        width={[60, 65, 65, 65, 80]}
                                                        src={`${PORTAL_S3_BUCKET_URL}${item.path}`}
                                                        zIndex={2}
                                                    />

                                                    {isCheckedAvatar(index, item) && (
                                                        <>
                                                            <Box
                                                                width={[72, 80, 80, 80, 100]}
                                                                height={[72, 80, 80, 80, 100]}
                                                                zIndex={1}
                                                                position="absolute"
                                                                className="WP-AvatarModal-active-avatar"
                                                                borderRadius="50%"
                                                            />
                                                            <Box
                                                                component="img"
                                                                width={[16, 20]}
                                                                bottom={7}
                                                                right={[15, 45, 45, 15]}
                                                                src={chickAvatar}
                                                                alt="check"
                                                                position="absolute"
                                                                zIndex={3}
                                                            />
                                                        </>
                                                    )}
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>

                                {lockedAvatars?.length ? (
                                    <>
                                        <Box pt={5} pb={2}>
                                            <Typography
                                                className="WP-AvatarModal-locked-avatars"
                                                variant="p12"
                                                color={theme.palette.text[100]}
                                            >
                                                {t('locked.avatars')}
                                            </Typography>
                                        </Box>
                                        <Box pt={4}>
                                            <Box
                                                component={Grid}
                                                container
                                                className="WP-AvatarModal-container-avatar"
                                                spacing={5}
                                            >
                                                {lockedAvatars.map((item) => {
                                                    return (
                                                        <Box
                                                            key={item.uuid}
                                                            component={Grid}
                                                            item
                                                            lg={2}
                                                            sm={3}
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            position="relative"
                                                        >
                                                            <Box
                                                                component="img"
                                                                width={65}
                                                                src={`${PORTAL_S3_BUCKET_URL}${item.path}`}
                                                            />
                                                            <Box
                                                                width={65}
                                                                height={67}
                                                                position="absolute"
                                                                className="WP-AvatarModal-locked-item"
                                                            />
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                        </Box>
                                    </>
                                ) : null}
                            </Box>
                            <Hidden smUp>
                                <Box mb={[2, 5]}>
                                    <Box px={8}>
                                        <Button
                                            variant="primary"
                                            size="large"
                                            label={t('avatar.modal.apply')}
                                            onClick={handleAvatarApply}
                                            fullWidth
                                        />
                                    </Box>
                                </Box>
                            </Hidden>
                        </Box>
                    </Box>
                </Box>
            )}
        </Dialog>
    );
}

AvatarModal.propTypes = {
    open: PropTypes.bool,
    handleModal: PropTypes.func,
};

export default memo(AvatarModal);
