import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DialogContent } from '@mui/material';

import { globalOp, globalSel } from '../../../store/global';

import {
    REWARD_PROCESS,
    PAYMENT_PROCESS,
    REWARD_SUCCESS,
    PAYMENT_SUCCESS,
    NOT_ENOUGH_COIN,
    NO_LINKED_ACCOUNT,
    BANNED_ACCOUNT,
    OFFER_NOT_AVAILABLE,
    DOWNLOAD_GAME,
    PAYMENT_PROCESS_BEEPCOIN,
    AVATAR_PAYMENT_SUCCESS,
    CLAIM_BEEPCOIN_SUCCESS,
    MILESTONE_REWARD_SUCCESS,
    LOGIN,
    PROMO_CODE_MODAL,
} from '../../../constants/purchaseVariants';

import { useScrollBlock } from '../../../hooks/useScrollBlock.hook';

import AvatarPaymentSuccessModal from './components/AvatarPaymentSuccess';
import BannedAccountModal from './components/BannedAccountModal.';
import DownloadGameModal from './components/DownloadGameModal';
import LoginModal from './components/LoginModal';
import MilestoneSuccessModal from './components/MilestoneSuccesModal';
import NoLinkedAccountModal from './components/NoLinkedAccountModal';
import NoneEnoughModal from './components/NoneEnoughModal';
import OfferNotAvailableModal from './components/OfferNotAvailableModal';
import ProcessingModal from './components/ProcessingModal';
import PromoModal from './components/PromoModal';
import SuccessfulModal from './components/SuccessfulModal';

import { Dialog } from '../../../stories';
import { ModalStyled } from './Modal.styled';

function Modal() {
    const dispatch = useDispatch();

    const modal = useSelector(globalSel.modalSelector);

    const [blockScroll, allowScroll] = useScrollBlock();

    // Type any because this function needs to be flexible and work with any modal
    const modalGenerator = (variant: string, content?: any) => {
        switch (variant) {
            case LOGIN:
                return <LoginModal />;
            case REWARD_PROCESS:
                return <ProcessingModal isClaim />;
            case PAYMENT_PROCESS:
                return <ProcessingModal isEnoughCoins />;
            case REWARD_SUCCESS:
                return <SuccessfulModal />;
            case MILESTONE_REWARD_SUCCESS:
                return <MilestoneSuccessModal milestone={modal.content} />;
            case PAYMENT_SUCCESS:
                return <SuccessfulModal />;
            case PAYMENT_PROCESS_BEEPCOIN:
                return <SuccessfulModal isBeepcoin />;
            case CLAIM_BEEPCOIN_SUCCESS:
                return <SuccessfulModal isBeepcoin />;
            case NOT_ENOUGH_COIN:
                return <NoneEnoughModal />;
            case BANNED_ACCOUNT:
                return <BannedAccountModal />;
            case OFFER_NOT_AVAILABLE:
                return <OfferNotAvailableModal />;
            case NO_LINKED_ACCOUNT:
                return <NoLinkedAccountModal />;
            case AVATAR_PAYMENT_SUCCESS:
                return <AvatarPaymentSuccessModal content={content} />;
            case DOWNLOAD_GAME:
                return <DownloadGameModal />;
            case PROMO_CODE_MODAL:
                return <PromoModal />;
            default:
                return null;
        }
    };

    const handleCloseModal = () => {
        if (!modal) return;

        if (modal.variant === PAYMENT_PROCESS || modal.variant === REWARD_PROCESS) return;

        dispatch(globalOp.handleCloseModal());
    };

    useEffect(() => {
        if (modal?.open) return blockScroll?.();

        allowScroll?.();
    }, [allowScroll, blockScroll, modal?.open]);

    return modal.open ? (
        <Dialog
            open={modal.open}
            onClose={handleCloseModal}
            showCloseButton={modal.variant !== PAYMENT_PROCESS && modal.variant !== REWARD_PROCESS}
            paperStyles={{
                minWidth: '300px',
                padding: '8px 0',
            }}
        >
            <DialogContent>
                <ModalStyled>{modalGenerator(modal.variant, modal.content)}</ModalStyled>
            </DialogContent>
        </Dialog>
    ) : null;
}

export default Modal;
