import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { globalSel } from '../../../../store/global';

import { Box } from '../../../core-ui';

import { Typography } from '../../../../stories';

function ModalContentError() {
    const [t] = useTranslation();
    const theme = useSelector(globalSel.themeSelector);

    return (
        <Box width={1} bgcolor={theme.palette.grey[100]} borderRadius="10px" py={20} px={41}>
            <Typography
                variant="p12"
                fWeight="semiBold"
                textAlign="center"
                color={theme.palette.grey[900]}
            >
                {t('offer.info.error.message')}
            </Typography>
        </Box>
    );
}

export default ModalContentError;
