import { TGlobalInitialState } from 'src/@types';

import { theme } from '../../theme/themeMUI';

export function initialGlobalState(): TGlobalInitialState {
    return {
        theme: { ...theme },
        modal: {
            open: false,
            variant: '',
            content: null,
        },
        snackbar: {
            open: false,
            variant: '',
        },
        dashboardBanner: null,
        globalConfig: {},
        maintenance: {
            dashboard: true,
            global: true,
            shop: true,
            calendar: true,
            news: true,
            events: true,
            playerProfile: true,
            toonGuide: true,
            offers: true,
            alliance: true,
            leaderboard: true,
            toonProfile: true,
        },
        dashboardBannerLoading: true,
        isLoadingFacebook: false,
        isLoadingScopely: false,
        authenticated: false,
        userBalance: [],
        userCollections: null,
        currentUser: null,
        isLeaderboardsLoading: false,
        globalLeaderboards: {},
        scopelyLoading: false,
    };
}
