import styled from 'styled-components';

import { TTextButtonProps } from '../../../@types';

export const LabelStyled = styled.span`
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
    transition: 0.15s linear;
`;

export const IconStyled = styled.span<Pick<TTextButtonProps, 'iconPosition'>>`
    font-size: 16px;
    margin-right: ${({ iconPosition }) => (iconPosition === 'start' ? 8 : 0)}px;
    margin-left: ${({ iconPosition }) => (iconPosition === 'end' ? 8 : 0)}px;
    transition: 0.15s linear;
`;

export const TextButtonStyled = styled.button<Pick<TTextButtonProps, 'iconPosition'>>`
    background-color: transparent;
    border: none;
    padding: 4px 0;
    height: 32px;
    color: ${({ theme }) => theme.palette.grey[500]};
    width: fit-content;
    display: flex;
    flex-direction: ${({ iconPosition }) => (iconPosition === 'end' ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: center;
    transition: 0.15s linear;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        color: ${({ theme }) => theme.palette.purple[600]}};
    }
    &:active {
        color:  ${({ theme }) => theme.palette.purple[800]};
    }
    &:disabled {
        cursor: default;
        color: ${({ theme }) => theme.palette.grey[500]};
    }
`;
