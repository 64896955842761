import styled from 'styled-components';

export const EventItemStyled = styled.div`
    .card-content {
        margin-left: 14px;
    }
    Box:nth-child(1) {
        border-bottom: 1px solid;
    }
    .WP-RightSideMenu-hyperLinkStyle {
        color: ${({ theme }) => theme.palette.grey[900]};
    }
`;
