import { createSelector } from 'reselect';

const playerProfilesSelector = (state) => state.playerProfile;

const isLoadingSelector = createSelector(
    [playerProfilesSelector],
    (playerProfile) => playerProfile.isLoadingSelector,
);

const playerProfileSelector = createSelector(
    [playerProfilesSelector],
    (playerProfile) => playerProfile.playerProfile,
);

export const playerProfileSel = {
    isLoadingSelector,
    playerProfileSelector,
};
