import { TSpecialOffersState } from 'src/@types';

export function initialSpecialOffersState(): TSpecialOffersState {
    return {
        activeTab: '',
        isLoading: true,
        specialOffers: null,
        shopItems: null,
        shopItemInfo: null,
        shopItemInfoLoader: true,
        bannerInfoListLoader: true,
        offerProcessingId: '',
        offerModalData: null,
        bannerInfoList: null,
        currencies: [
            {
                name: 'beepcoin',
                rate: 3,
            },
            {
                name: 'USD',
                rate: 5,
            },
        ],
    };
}
