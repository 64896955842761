import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material';

import { globalOp, globalSel } from '../../../../store/global';

import { getStorageItem, removeStorageItem } from '../../../../helpers/localStorage';
import { titanAnalyticsEvents } from '../../../../helpers/titanAnalytics';

import { useEffectOnce } from '../../../../hooks/useEffectOnce';

import { Box } from '../../../core-ui';

import login_icon from '../../../../assets/images/modals/login_icon.svg';
import { Typography } from '../../../../stories';
import LoginWithFacebook from '../../CustomButtons/LoginWithFacebookButton/LoginWithFacebook';
import LoginWithScopelyId from '../../CustomButtons/LoginWithScopelyButton/LoginWithScopelyId';

const LoginModal: FC = () => {
    const theme = useTheme();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const authenticated = useSelector(globalSel.isAuthSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);

    useEffectOnce(() => {
        titanAnalyticsEvents.authModal();
    });

    useEffect(() => {
        const pageToRedirect = getStorageItem('loggedOutRedirectUrl');

        if (authenticated && currentUser) {
            if (pageToRedirect && window) {
                window.location = pageToRedirect;
                removeStorageItem('loggedOutRedirectUrl');
            }
            dispatch(globalOp.handleCloseModal());
        }
    }, [authenticated, currentUser, dispatch]);

    return (
        <>
            <Box
                className="WP-Modal-Icon"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                pt={15}
                px={4}
                pb={10}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border={1}
                    borderRadius="50%"
                    bordercolor={theme.palette.purple[100]}
                    width={120}
                    height={120}
                    mb={6}
                >
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor={theme.palette.purple[100]}
                        border={1}
                        borderRadius="50%"
                        bordercolor={theme.palette.purple[100]}
                        width={100}
                        height={100}
                    >
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Box
                                component="img"
                                src={login_icon}
                                alt="login-icon"
                                width={60}
                                height={60}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" justifyContent="center" textAlign="center">
                    <Typography fWeight="bold" variant="h5" color={theme.palette.grey[800]}>
                        {t('login.to.make.purchase')}
                    </Typography>
                </Box>
            </Box>
            <Box
                mb={10}
                width={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                px={[0, 25]}
            >
                <Box width={240}>
                    <LoginWithScopelyId />
                </Box>

                <Box width={240} mt={3}>
                    <LoginWithFacebook />
                </Box>
            </Box>
        </>
    );
};

export default LoginModal;
