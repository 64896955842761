import { createSelector } from 'reselect';

import { RootState } from '../configureStore';

const eventsSelector = (state: RootState) => state.eventsRBE;

const isClaimLoadingSelector = createSelector(
    [eventsSelector],
    ({ isClaimLoading }) => isClaimLoading,
);

const isEventsLoadingSelector = createSelector([eventsSelector], ({ isEventsLoading }) => {
    return isEventsLoading;
});

const activeEventsSelector = createSelector([eventsSelector], ({ activeEvents }) => activeEvents);

export const eventsRBESel = {
    isClaimLoadingSelector,
    isEventsLoadingSelector,
    activeEventsSelector,
};
