import styled from 'styled-components';

export const SnackBarStyled = styled.div`
    position: fixed;
    top: 85px;
    left: 50%;
    width: 340px;
    transform: translateX(-50%);
    z-index: 1600;
    opacity: 1;
    animation: fade 4s linear;

    @keyframes fade {
        0%,
        100% {
            opacity: 0;
        }
        15%,
        50%,
        85% {
            opacity: 1;
        }
    }
`;
