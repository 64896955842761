import React from 'react';

import PropTypes from 'prop-types';

import { LoadAnimateButtonStyled } from './LoadAnimateButton.styled';

export default function LoadAnimateButton({ bodySize, hasText = true }) {
    return (
        <LoadAnimateButtonStyled>
            <span className="animation-load-button animation-load-button-single" />
            <span className="animation-load-button animation-load-button-double" />
            <span className="animation-load-button animation-load-button-single" />
            <span className="animation-load-button animation-load-button-double" />
            {hasText && (
                <span className={`${bodySize || 'body-12'} pl--5 font-weight-medium`}>
                    Processing
                </span>
            )}
        </LoadAnimateButtonStyled>
    );
}

LoadAnimateButton.propTypes = {
    bodySize: PropTypes.string,
    hasText: PropTypes.bool,
};
