import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import { globalOp, globalSel } from '../../store/global';
import { globalSlice } from '../../store/global/globalSlice';

import { GA_ID } from '../../constants/analytic.constants';
import { LOGIN_TYPES } from '../../constants/api.constants';

import { scopelyId } from '../../configs/titan.config';
import { tracker } from '../../configs/titan.config';

import {
    getStorageItem,
    getSessionStorage,
    removeSessionStorage,
} from '../../helpers/localStorage';
import { titanAnalyticsEvents } from '../../helpers/titanAnalytics';

import { useEffectOnce } from '../../hooks/useEffectOnce';
import useHelpshiftLoadHook from '../../hooks/useHelpshiftLoadHook';
import { useLogoutHook } from '../../hooks/useLogout.hook';
import { useScrollBlock } from '../../hooks/useScrollBlock.hook';
import { useXsollaLoadHook } from '../../hooks/useXsollaLoad.hook';

import Main from '../components/Main/Main';

import { Backdrop, Box } from '../core-ui';

import Header from './Header/Header';
import LeftSidebar from './LeftSidebar/LeftSidebar';
import RightSidebar from './RightSidebar/RightSidebar';

export default function Layout({ children }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [blockScroll, allowScroll] = useScrollBlock();

    const currentUser = useSelector(globalSel.currentUserSelector);

    const [openLeftSidebar, setOpenLeftSidebar] = useState(false);
    const [openRightSidebar, setOpenRightSidebar] = useState(false);
    const [searchFlag, setSearchFlag] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const loggedInType = getStorageItem('loginProcessing');
    const { setModal, setSnackbar } = globalSlice.actions;

    const handleBackdropClose = () => {
        setOpenBackdrop(false);
        setSearchFlag(false);
    };

    const checkPageLoadDuration = useCallback(() => {
        const entriesValues = performance.getEntriesByType('navigation')[0];

        if (!entriesValues) {
            return null;
        } else {
            const pageLoadDuration = Math.floor(entriesValues.duration / 1000);

            return (
                pageLoadDuration >= 20 &&
                window?.DD_RUM?.addError(`page-load-${window.location.pathname.replace('/', '')}`, {
                    host: process.env.REACT_APP_REDIRECT_URI,
                    path: pathname,
                })
            );
        }
    }, [pathname]);

    useEffectOnce(() => {
        const currentLocation = window.location.href;

        if (scopelyId.isAuthorizationUri(currentLocation) && loggedInType === LOGIN_TYPES.scopely) {
            scopelyId.authorizationResult(currentLocation).then((authResult) => {
                const previousLocation = scopelyId.previousLocation();

                if (previousLocation) {
                    navigate(previousLocation.pathname);
                }
                if (authResult.isSuccessful()) {
                    const accessToken = authResult.getResponse().getAccessToken();

                    dispatch(
                        globalOp.loginAsync(
                            {
                                type: LOGIN_TYPES.scopely,
                                accessToken,
                                deviceId: tracker?.getDeviceToken() || '',
                            },
                            tracker?.getDeviceToken() || '',
                        ),
                    );
                }
            });
        }
    });

    useEffectOnce(() => {
        if (!window.gtag) return;

        window.gtag('config', GA_ID, {
            page_title: pathname,
            page_path: pathname,
            user_id: currentUser?.playerProfile?.id,
        });
    });

    useEffect(() => {
        const data = getSessionStorage('loginErrorHandler');
        if (data?.variant === 'error') {
            dispatch(
                setSnackbar({
                    open: true,
                    variant: 'error',
                    message: 'Network Error',
                }),
            );
            removeSessionStorage('loginErrorHandler');

            return;
        }
        if (data?.variant) {
            dispatch(
                setModal({
                    open: true,
                    variant: data.variant,
                }),
            );
            removeSessionStorage('loginErrorHandler');

            return;
        }
        removeSessionStorage('loginErrorHandler');
    }, [dispatch, setModal, setSnackbar]);

    useEffect(() => {
        if (openLeftSidebar || openRightSidebar) {
            blockScroll();
        } else {
            allowScroll();
        }
    }, [allowScroll, blockScroll, openLeftSidebar, openRightSidebar]);

    useEffectOnce(() => {
        titanAnalyticsEvents.generatePageLoadEvent(pathname, {
            'sys.user_id': currentUser?.playerProfile?.id,
        });
    });

    useEffect(() => {
        return () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            dispatch(globalOp.getGlobalConfigs());

            titanAnalyticsEvents.generatePageLoadEvent(pathname, {
                'sys.user_id': currentUser?.playerProfile?.id,
            });
        };
    }, [currentUser?.playerProfile?.id, dispatch, pathname]);

    useEffect(() => {
        checkPageLoadDuration();
    }, [checkPageLoadDuration]);

    useHelpshiftLoadHook();
    useXsollaLoadHook();
    useLogoutHook();

    return (
        <Box overFlow="hidden">
            <Header
                searchFlag={searchFlag}
                setSearchFlag={setSearchFlag}
                openBackdrop={openBackdrop}
                setOpenBackdrop={setOpenBackdrop}
                openLeftSidebar={openLeftSidebar}
                setOpenLeftSidebar={setOpenLeftSidebar}
                openRightSidebar={openRightSidebar}
                setOpenRightSidebar={setOpenRightSidebar}
            />

            <Box height="100%" display="flex" onClick={handleBackdropClose}>
                <Backdrop
                    open={openLeftSidebar}
                    zIndex={1150}
                    onClick={() => setOpenLeftSidebar(false)}
                />
                <LeftSidebar
                    openLeftSidebar={openLeftSidebar}
                    setOpenLeftSidebar={setOpenLeftSidebar}
                />

                <Main>{children}</Main>

                <Backdrop
                    open={openRightSidebar}
                    zIndex={1150}
                    onClick={() => setOpenRightSidebar(false)}
                />

                <RightSidebar
                    openRightSidebar={openRightSidebar}
                    setOpenRightSidebar={setOpenRightSidebar}
                />
            </Box>
        </Box>
    );
}

Layout.propTypes = {
    children: PropTypes.node,
};
