import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { BackdropStyled } from './Backdrop.styled';

export const Backdrop = ({ open, color, zIndex, className, children, onClick }) => {
    return open ? (
        <BackdropStyled color={color} zIndex={zIndex}>
            <div className="overlay" onClick={onClick} />
            {children && <div className={clsx('backdrop-content', className)}>{children}</div>}
        </BackdropStyled>
    ) : null;
};

Backdrop.defaultProps = {
    open: false,
    className: '',
};

Backdrop.propTypes = {
    open: PropTypes.bool,
    color: PropTypes.string,
    zIndex: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node,
};
