import styled, { keyframes } from 'styled-components';

import { IButtonProps, IButtonPaletteVariant } from '../../../@types';

import { sizes } from '../../constants/constants';

const loader = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const LabelStyled = styled.span<
    Pick<IButtonProps, 'variant' | 'size' | 'isActive' | 'isDisabled' | 'isBeepcoin'>
>`
    font-size: ${({ size, isBeepcoin }) => (isBeepcoin ? 12 : size === sizes.SMALL ? 12 : 14)}px;
    line-height: ${({ size }) => (size === sizes.SMALL ? 20 : 24)}px;
    font-weight: 600;
    text-align: center;
    transition: 0.15s linear;
    white-space: nowrap;
`;

export const IconStyled = styled.span<
    Pick<
        IButtonProps,
        'iconPosition' | 'size' | 'variant' | 'isDisabled' | 'isActive' | 'className'
    >
>`
    font-size: ${({ size }) => (size === sizes.SMALL ? 16 : size === sizes.MEDIUM ? 20 : 24)}px;
    margin-right: ${({ iconPosition, size }) =>
        iconPosition === 'start' ? (size === sizes.SMALL ? 8 : 12) : 0}px;
    margin-left: ${({ iconPosition, size }) =>
        iconPosition === 'end' ? (size === sizes.SMALL ? 8 : 12) : 0}px;
    transition: 0.15s linear;
`;

export const IconImageStyled = styled.img<
    Pick<IButtonProps, 'iconPosition' | 'size' | 'src' | 'alt' | 'draggable'>
>`
    width: ${({ size }) => (size === sizes.SMALL ? 12 : size === sizes.MEDIUM ? 14 : 16)}px;
    margin-right: ${({ iconPosition, size }) =>
        iconPosition === 'start' ? (size === sizes.SMALL ? 8 : 12) : 0}px;
    margin-left: ${({ iconPosition, size }) =>
        iconPosition === 'end' ? (size === sizes.SMALL ? 8 : 12) : 0}px;
`;

export const LoaderStyled = styled(IconStyled)<
    Pick<
        IButtonProps,
        'iconPosition' | 'size' | 'variant' | 'isDisabled' | 'isActive' | 'className'
    >
>`
    animation: ${loader} 1s infinite linear;
`;

export const ButtonStyled = styled.button<
    Pick<
        IButtonProps,
        'iconPosition' | 'size' | 'fullWidth' | 'isActive' | 'className' | 'color' | 'isBeepcoin'
    > &
        IButtonPaletteVariant
>`
    border-radius: ${({ size, isBeepcoin }) => (isBeepcoin ? 6 : size === sizes.SMALL ? 6 : 12)}px;
    padding: ${({ size }) => (size === sizes.SMALL ? '4px 16px' : '4px 20px')};
    height: ${({ size, isBeepcoin }) =>
        isBeepcoin ? 32 : size === sizes.SMALL ? 32 : size === sizes.MEDIUM ? 40 : 48}px;
    color: ${({ variant, color }) => (color ? color : variant?.text)};
    background-color: ${({ variant, isActive, theme }) =>
        variant?.border
            ? theme.palette.common.white
            : isActive && variant
            ? variant.active
            : variant.main};
    border: ${({ variant }) => (!variant?.border ? 'none' : `1px solid ${variant.border.default}`)};
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
    display: flex;
    flex-direction: ${({ iconPosition }) => (iconPosition === 'end' ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: center;
    transition: 0.15s linear;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        background-color: ${({ variant, isActive }) =>
            variant?.border ? 'transparent' : isActive && variant ? variant.active : variant.hover};
        border-color: ${({ variant }) => (variant?.border ? variant?.border.hover : null)};
        color: ${({ variant, isActive }) => (isActive ? variant?.textActive : variant?.textHover)};
    }
    &:active {
        background-color: ${({ variant }) => variant?.active};
        border-color: ${({ variant }) => (variant?.border ? variant.border.active : 'transparent')};
        color: ${({ variant }) => variant?.textActive};
    }
    &:disabled {
        cursor: default;
        color: ${({ variant }) => variant?.textDisable};
        background-color: ${({ variant }) => (variant?.border ? 'transparent' : variant?.disable)};
        border-color: ${({ variant }) => variant?.border?.disabled || 'transparent'};
    }
`;
