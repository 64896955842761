import styled from 'styled-components';

import maintenance_effect from '../../../assets/images/MaintenancePage/MainteanceEffect.png';

export const MaintenanceStyled = styled.div`
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 0;

            background-image: url(${maintenance_effect});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top;
            background-color: ${({ theme }) => theme.palette.purple[700]};

            mix-blend-mode: multiply;

            display: flex;
            justify-content: center;
            width: 110%;

            &::before {
                width: 100%;
                height: 10%;

                position: absolute;
                bottom: -1px;
                left: 0;
                content: '';

                display: block;
                border-radius: 100% 100% 0 0;
                background-color: ${({ theme }) => theme.palette.background[200]};
            }
        }
`;
