import { TAvatarOffers, TShopData } from 'src/@types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialToonGuideState } from './initialState';

export const beepCoinShopSlice = createSlice({
    name: 'beepCoinShop',
    initialState: initialToonGuideState(),
    reducers: {
        setActiveTab(state, action: PayloadAction<string>) {
            state.activeTab = action.payload;
        },
        setBeepCoinShopLoader(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },

        setBeepcoinProcessingId(state, action: PayloadAction<string>) {
            state.beepcoinOfferProcessingId = action.payload;
        },

        setAvatarOffers(state, action: PayloadAction<TAvatarOffers>) {
            state.avatarOffers = action.payload;
        },

        setAvatarOffersLoading(state, action: PayloadAction<boolean>) {
            state.avatarOffersLoading = action.payload;
        },

        setProcessingAvatarOffersId(state, action: PayloadAction<string>) {
            state.processingAvatarOffersId = action.payload;
        },

        setBeepCoinShopData(state, action: PayloadAction<TShopData[] | null>) {
            const data = action.payload;

            let dataToSet: TShopData = {
                identifier: '',
                recurrenceRule: '',
                externalPriceReferences: {},
                transactionProducts: {},
                storeProductInfoList: [],
                storeState: null,
                storeOfferLiveOpsEvents: {},
            };

            !!data &&
                action?.payload?.forEach((item: TShopData | null) => {
                    if (item) {
                        dataToSet = {
                            ...item,
                            externalPriceReferences: item?.externalPriceReferences
                                ? {
                                      ...dataToSet.externalPriceReferences,
                                      ...item.externalPriceReferences,
                                  }
                                : {},

                            storeOfferLiveOpsEvents: {
                                ...dataToSet.storeOfferLiveOpsEvents,
                                ...(item?.storeOfferLiveOpsEvents
                                    ? item?.storeOfferLiveOpsEvents
                                    : { [item?.identifier]: item }),
                            },
                            storeState: item?.storeState
                                ? { ...dataToSet.storeState, ...item.storeState }
                                : null,
                            transactionProducts: {
                                ...dataToSet.transactionProducts,
                                ...item.transactionProducts,
                            },
                            storeProductInfoList: item?.storeProductInfoList
                                ? [
                                      ...(dataToSet?.storeProductInfoList ?? []),
                                      ...item.storeProductInfoList,
                                  ]
                                : [],
                        };
                    }

                    return item;
                });

            state.shopData = data ? dataToSet : null;
        },
    },
});
