export const notAvailableRouters = (config: { [key: string]: boolean }) => {
    return Object.keys(config)
        ?.filter((key) => !config[key])
        ?.map((configKey) => {
            switch (configKey) {
                case 'dashboard':
                    return ['/'];
                case 'shop':
                    return ['/beepcoin-shop'];
                case 'calendar':
                    return ['/calendar'];
                case 'playerProfile':
                    return ['/player/:playerId'];
                case 'toonProfile':
                    return ['/toon-profile/:toonId'];
                case 'toonGuide':
                    return ['/toon-guide'];
                case 'news':
                    return ['/news'];
                case 'events':
                    return ['/events'];
                case 'leaderboard':
                    return ['/leaderboard'];
                case 'alliance':
                    return ['/alliance/:allianceId'];
                case 'offers':
                    return ['/special-offers'];
                default:
                    return [];
            }
        })
        ?.flat();
};

export const pageMaintenanceModeRenderer = (
    page: string,
    maintenanceMode: { [key: string]: boolean },
) => {
    switch (page) {
        case '/':
            return !maintenanceMode.dashboard;
        case '/calendar':
            return !maintenanceMode.calendar;
        case '/events':
            return !maintenanceMode.events;
        case '/news':
            return !maintenanceMode.news;
        case '/player/:playerId':
            return !maintenanceMode.playerProfile;
        case '/toon-guide':
            return !maintenanceMode.toonGuide;
        case '/special-offers':
            return !maintenanceMode.offers;
        case '/beepcoin-shop':
            return !maintenanceMode.shop;
        case '/alliance/:allianceId':
            return !maintenanceMode.alliance;
        case '/leaderboard':
            return !maintenanceMode.leaderboard;
        case '/toon-profile/:toonId':
            return !maintenanceMode.toonProfile;
        default:
            return false;
    }
};
