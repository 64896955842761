import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { globalSel } from '../../../../store/global';

import { IActiveMilestone } from '../../../../@types';

import { Box } from '../../../core-ui';

import shineEffect from '../../../../assets/images/shineEffectRedesign.png';
import { Typography, LazyImage } from '../../../../stories';

export interface IMilestoneInfoModalHeaderProp {
    milestone: IActiveMilestone;
    backgroundImg: string;
    isStory?: boolean;
}

function MilestoneInfoModalHeader({
    milestone,
    backgroundImg,
    isStory,
}: IMilestoneInfoModalHeaderProp) {
    const [t] = useTranslation();
    const theme = useSelector(globalSel.themeSelector);

    const { iconPath, title, requiredPoints } = milestone;

    return (
        <>
            <Box
                width={1}
                height={150}
                borderRadius="10px"
                position="relative"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor={theme.palette.grey[300]}
                bgimage={backgroundImg || shineEffect}
                className="background-cover"
            >
                <Box
                    width={160}
                    height={36}
                    position="absolute"
                    left={12}
                    top={12}
                    border="1px solid #F4F6FA"
                    borderRadius={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color={theme.palette.text[100]}
                >
                    <Typography
                        variant="p"
                        fontSize="12px"
                        fWeight="regular"
                        color={theme.palette.grey[600]}
                        lineHeight="18px"
                        isStory={isStory}
                    >
                        {t('events.milestone.card.required.points')}: {requiredPoints}
                    </Typography>
                </Box>

                <LazyImage
                    width="108px"
                    src={iconPath || ''}
                    alt="milestone-image"
                    draggable={false}
                />
            </Box>

            <Box width={1} pt={3}>
                <Typography
                    variant="p"
                    fontSize="14px"
                    fWeight="semiBold"
                    color={theme.palette.common.black}
                    isStory={isStory}
                >
                    {title}
                </Typography>
            </Box>
        </>
    );
}

export default memo(MilestoneInfoModalHeader);
