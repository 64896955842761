import { AVATAR_PAYMENT_SUCCESS } from '../../constants/purchaseVariants';

import { services } from '../../services/fetch.service';
import { AppDispatch } from '../configureStore';
import { globalOp } from '../global';
import { beepCoinShopSlice } from './beepCoinShopSlice';

const getBeepCoinOffers = () => {
    const { setBeepCoinShopData, setBeepCoinShopLoader } = beepCoinShopSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setBeepCoinShopLoader(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v1/beepcoins/store-offers`,
                method: 'GET',
            });

            dispatch(setBeepCoinShopData(data));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on beepcoin shop getting');
        }

        dispatch(setBeepCoinShopLoader(false));
    };
};

const getBeepCoinOffersLoggedOut = () => {
    const { setBeepCoinShopData, setBeepCoinShopLoader } = beepCoinShopSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setBeepCoinShopLoader(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v1/beepcoins/store-offers/public`,
                method: 'GET',
            });

            dispatch(setBeepCoinShopData(data));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on beepcoin shop getting');
        }

        dispatch(setBeepCoinShopLoader(false));
    };
};

const getAvatarOffers = () => {
    const { setAvatarOffers, setAvatarOffersLoading } = beepCoinShopSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setAvatarOffersLoading(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v2/avatars/store`,
                method: 'GET',
            });

            dispatch(setAvatarOffers(data));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on avatars offers getting');
        }

        dispatch(setAvatarOffersLoading(false));
    };
};

const getAvatarOffersLoggedOut = () => {
    const { setAvatarOffers, setAvatarOffersLoading } = beepCoinShopSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setAvatarOffersLoading(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v2/avatars/store/public`,
                method: 'GET',
            });

            dispatch(setAvatarOffers(data));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on avatars offers getting');
        }

        dispatch(setAvatarOffersLoading(false));
    };
};

const purchaseAvatarOffers = (uuid: string, img: string, name: string) => {
    const { getUserBalance, handleOpenModal } = globalOp;
    const { setProcessingAvatarOffersId } = beepCoinShopSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setProcessingAvatarOffersId(uuid));

        try {
            const { status } = await services.fetch({
                endPoint: `/v2/avatars/purchase/${uuid}`,
                method: 'GET',
            });

            if (status === 200) {
                dispatch(
                    handleOpenModal({
                        open: true,
                        variant: AVATAR_PAYMENT_SUCCESS,
                        content: { img, name },
                    }),
                );
                dispatch(getAvatarOffers());
                dispatch(getUserBalance());
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on avatars offers purchase');
        }
        dispatch(setProcessingAvatarOffersId(''));
    };
};

const purchaseBeepcoinOffer = (conversionProductId: number, deviceToken: string) => {
    return services.fetch({
        endPoint: `/v1/stores/purchase-conversion-product`,
        method: 'POST',
        body: {
            conversionProductId,
        },
        headers: {
            'device-token': deviceToken,
        },
    });
};

const setActiveTab = (position: string) => {
    const { setActiveTab } = beepCoinShopSlice.actions;

    return (dispatch: AppDispatch) => dispatch(setActiveTab(position));
};

const setBeepcoinProcessingId = (eventId: string) => {
    const { setBeepcoinProcessingId } = beepCoinShopSlice.actions;

    return (dispatch: AppDispatch) => dispatch(setBeepcoinProcessingId(eventId));
};

export const beepCoinShopOp = {
    getBeepCoinOffers,
    setActiveTab,
    getBeepCoinOffersLoggedOut,
    setBeepcoinProcessingId,
    purchaseBeepcoinOffer,
    getAvatarOffers,
    getAvatarOffersLoggedOut,
    purchaseAvatarOffers,
};
