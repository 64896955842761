import styled from 'styled-components';

export const RightSidebarStyled = styled.aside`
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 280px;
    overflow: auto;
    z-index: ${({ theme }) => theme.zIndex.drawer};
    transition: transform 200ms ease-out;
    background: ${({ theme }) => theme.palette.common.white};

    ${({ theme }) => theme.breakpoints.down('md')} {
        transform: translateX(280px);
        background-color: ${({ theme }) => theme.palette.background[200]}!important;
        &.show {
            transform: translateX(0);
        }
    }

    .app-logo {
        p {
            color: ${({ theme }) => theme.palette.grey[800]};
        }
    }
`;
