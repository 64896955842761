import React from 'react';
import { useSelector } from 'react-redux';

import { specialOffersSel } from '../../../../store/specialOffers';

import { IShopItemInfo } from '../../../../@types';

import { REWARD_TYPES } from '../../../../helpers/utils';

import { Box } from '../../../core-ui';

import ModalContentItem from './ModalContentItem';

interface IModalContentProps {
    isBeepCoin: boolean;
    price: number;
    successModal?: boolean;
}
function ModalContent({ isBeepCoin, price, successModal }: IModalContentProps) {
    const offerInfo: IShopItemInfo | null = useSelector(specialOffersSel.shopItemInfoSelector);

    return (
        <Box
            width={1}
            minWidth={!successModal ? 460 : 'auto'}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
        >
            {offerInfo?.info?.guaranteedItems?.length ? (
                <Box
                    width={
                        offerInfo?.info?.guaranteedOneOfTheseItems?.length ||
                        offerInfo?.info?.possibleItems?.length
                            ? [0, 220, 260]
                            : '100%'
                    }
                >
                    <ModalContentItem
                        title="offers.card.guaranteedItems"
                        data={offerInfo?.info?.guaranteedItems}
                        type={REWARD_TYPES.guaranteed}
                        isBeepCoin={isBeepCoin}
                        price={price}
                    />
                </Box>
            ) : null}
            {offerInfo?.info?.possibleItems?.length ? (
                <Box
                    width={
                        offerInfo?.info?.guaranteedItems?.length ||
                        offerInfo?.info?.guaranteedOneOfTheseItems?.length
                            ? [0, 220, 260]
                            : '100%'
                    }
                    ml={
                        offerInfo?.info?.guaranteedOneOfTheseItems?.length ||
                        offerInfo?.info?.possibleItems?.length
                            ? 3
                            : 0
                    }
                >
                    <ModalContentItem
                        title="offers.card.possibleItems"
                        data={offerInfo?.info?.possibleItems}
                        type={REWARD_TYPES.possible}
                        isBeepCoin={isBeepCoin}
                        price={price}
                    />
                </Box>
            ) : null}

            {offerInfo?.info?.guaranteedOneOfTheseItems?.length ? (
                <Box
                    width={
                        offerInfo?.info?.guaranteedItems?.length ||
                        offerInfo?.info?.possibleItems?.length
                            ? [0, 220, 260]
                            : '100%'
                    }
                    ml={
                        offerInfo?.info?.possibleItems?.length ||
                        offerInfo?.info?.guaranteedItems?.length
                            ? 3
                            : 0
                    }
                >
                    <ModalContentItem
                        title="offers.card.guaranteedOneOfTheseItems"
                        data={offerInfo?.info?.guaranteedOneOfTheseItems}
                        type={REWARD_TYPES.guaranteedOneOfThese}
                        isBeepCoin={isBeepCoin}
                        price={price}
                    />
                </Box>
            ) : null}
        </Box>
    );
}

export default ModalContent;
