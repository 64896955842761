import { TInitialState } from 'src/@types';

export function initialToonGuideState(): TInitialState {
    return {
        activeTab: '',
        isLoading: false,
        avatarOffersLoading: false,
        shopData: null,
        avatarOffers: null,
        beepcoinOfferProcessingId: '',
        processingAvatarOffersId: '',
    };
}
