import styled from 'styled-components';

export const PlayGameButtonStyled = styled.div`
    position: sticky;
    top: 0;
    min-width: 212px;
    .WP-PlayGameButton-root {
        margin: 0 auto;
    }
    .WP-PlayGameButton-root-Banner {
        margin: 0 auto;

        ${({ theme }) => theme.breakpoints.down('375px')} {
            height: 40px;
        }
    }
`;
