import { TNews } from 'src/@types';

import { sliceNewsFrameLink, slicePortalNewsLink } from '../../helpers/sliceNewsFrameLink';

import { services } from '../../services/fetch.service';
import { AppDispatch } from '../configureStore';
import { newsSlice } from './newsSlice';

const getNewsData = (page: number) => {
    const {
        setNewsData,
        setNewsLoader,
        setNewsPage,
        setLoadMoreLoader,
        setLoadMoreNews,
        setActiveNews,
    } = newsSlice.actions;

    return async (dispatch: AppDispatch) => {
        dispatch(setLoadMoreLoader(true));

        try {
            const { data } = await services.fetch({
                endPoint: `/v2/news`,
                method: 'GET',
                body: { page },
            });

            if (data) {
                const slicedLink = slicePortalNewsLink(window.location.pathname);

                const activeNewsItem = data.data.find(
                    (news: TNews) => sliceNewsFrameLink(news.link) === slicedLink,
                );

                dispatch(setActiveNews(activeNewsItem));
                dispatch(setNewsData(data.data));
                dispatch(setNewsPage(1));

                if (data.currentPage >= data.pages) {
                    dispatch(setLoadMoreNews(false));
                }
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err, 'error on news getting');
        }

        dispatch(setNewsLoader(false));
        dispatch(setLoadMoreLoader(false));
    };
};

export const newsOp = {
    getNewsData,
};
