import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';

import { Box } from '../../../core-ui';

import { Typography, LazyImage } from '../../../../stories';

interface IProps {
    content?: {
        img: string;
        name: string;
    } | null;
}

const AvatarPaymentSuccessModal: FC<IProps> = ({ content }) => {
    const [t] = useTranslation();
    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            pt={15}
            px={6}
            pb={8}
            width={[296, 446]}
        >
            <Box width={1} display="flex" justifyContent="center">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    border={2}
                    bordercolor={theme.palette.purple[600]}
                    borderRadius="50%"
                    width={112}
                    pt={1}
                    px={1}
                >
                    <LazyImage
                        src={content?.img ?? ''}
                        alt="avatar"
                        width="100px"
                        height="100px"
                        draggable={false}
                    />
                </Box>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                pt={3}
                pb={8}
            >
                <Box pb={3}>
                    <Typography
                        loaderWidth={100}
                        variant="h5"
                        color={theme.palette.grey[800]}
                        fWeight="bold"
                    >
                        {t('modal.successful.payment.successful')}
                    </Typography>
                </Box>

                <Typography
                    variant="p16"
                    color={theme.palette.grey[600]}
                    fWeight="regular"
                    loaderWidth={160}
                >
                    {t('avatar.purchase.success.text')}
                </Typography>
            </Box>

            <Typography variant="p14" fWeight="semiBold" loaderWidth={80}>
                {content?.name}
            </Typography>
        </Box>
    );
};

export default AvatarPaymentSuccessModal;
