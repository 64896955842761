import React from 'react';
import { useNavigate } from 'react-router-dom';

import Skeleton from '@mui/material/Skeleton';

import { TTextButtonProps } from '../../../@types';

import { TextButtonStyled, IconStyled, LabelStyled } from './TextButton.styled';

function TextButton({
    navigationLink,
    label,
    icon,
    iconPosition = 'start',
    isLoading = false,
    className,
    ...props
}: TTextButtonProps) {
    const navigate = useNavigate();

    const changeRoute = () => {
        if (navigationLink) {
            navigate(navigationLink);
        }
    };

    return (
        <TextButtonStyled iconPosition={iconPosition} className={className} {...props}>
            {icon ? <IconStyled iconPosition={iconPosition} className={icon} /> : null}

            {isLoading ? (
                <Skeleton variant="text" animation="wave" height={16} width="100px" />
            ) : (
                <LabelStyled onClick={changeRoute}>{label}</LabelStyled>
            )}
        </TextButtonStyled>
    );
}

export default TextButton;
