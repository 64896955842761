import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { globalSel } from '../../../../store/global';

import Login from '../../../components/Login/Login';

import { Box, Icon } from '../../../core-ui';

import { Typography } from '../../../../stories';
import { IRightSidebarProps } from '../RightSidebar';
import UserProfile from '../UserProfile/UserProfile';

function UserWrapper({ openRightSidebar, setOpenRightSidebar }: IRightSidebarProps) {
    const [t] = useTranslation();
    const theme = useSelector(globalSel.themeSelector);
    const authenticated = useSelector(globalSel.isAuthSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);

    return (
        <>
            {openRightSidebar && !(authenticated && currentUser) && (
                <Box
                    display="flex"
                    alignItems="center"
                    height={60}
                    borderBottom={1}
                    bordercolor={theme.palette.border[50]}
                >
                    <Box
                        pl={5}
                        pr={3}
                        display="flex"
                        alignItems="center"
                        color={theme.palette.grey[600]}
                        onClick={() => {
                            setOpenRightSidebar(false);
                        }}
                    >
                        <Icon className="icon-close-fill" size={24} />
                    </Box>

                    <Typography
                        variant="p16"
                        loaderWidth={120}
                        fWeight="semiBold"
                        color={theme.palette.text[200]}
                    >
                        {t('right.bar.profile.events')}
                    </Typography>
                </Box>
            )}

            {authenticated && currentUser ? (
                <UserProfile
                    openRightSidebar={openRightSidebar}
                    setOpenRightSidebar={setOpenRightSidebar}
                />
            ) : (
                <Login />
            )}
        </>
    );
}

UserWrapper.propTypes = {
    openRightSidebar: PropTypes.bool,
    setOpenRightSidebar: PropTypes.func,
};

export default memo(UserWrapper);
