import React from 'react';

import PropTypes from 'prop-types';

import { IconStyled } from './Icon.styled';

export const Icon = ({ size, color, className, children, onClick }) => {
    return (
        <IconStyled size={size} color={color} className={className} onClick={onClick}>
            {children}
        </IconStyled>
    );
};

Icon.defaultProps = {
    size: 24,
};

Icon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string.isRequired,
    children: PropTypes.node,
    onClick: PropTypes.func,
};
