export interface IActiveMilestone {
    title?: string;
    iconPath?: string;
    requiredPoints?: number;
    button?: JSX.Element | null;
    rewards: IMilestoneReward[];
}

export enum MilestonesEnum {
    CLAIMABLE = 'in_progress',
    NOT_READY = 'not_ready',
    COMPLETED = 'done',
}

export interface IMilestoneReward {
    amount: number;
    descriptionPath: string;
    iconPath: string;
}
