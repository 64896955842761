import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@mui/material';

import { IMilestoneReward } from '../../../../@types';

import { GAME_S3_BUCKET_URL } from '../../../../constants/api.constants';

import { convertPriceUsingK } from '../../Alliance/AllianceTopPlayers/PlayerCard/helpers';

import { Box } from '../../../core-ui';

import { Typography, LazyImage } from '../../../../stories';
import { MilestoneInfoModalContentItemStyled } from './MilestoneInfoModalContentItem.styled';

export interface MilestoneInfoModalContentItemProp {
    title: string;
    rewards: IMilestoneReward[];
    isStory?: boolean;
}

function MilestoneInfoModalContentItem({
    title,
    rewards,
    isStory,
}: MilestoneInfoModalContentItemProp) {
    const theme = useTheme();
    const [t] = useTranslation();

    const getMilestoneIcon = (iconUri: string) => {
        const splitIconUrl = iconUri.split('/');
        const rewardIconUrl = splitIconUrl[splitIconUrl.length - 1];

        return `${GAME_S3_BUCKET_URL}/MaterialIcon/${rewardIconUrl}`;
    };

    return (
        <MilestoneInfoModalContentItemStyled>
            <Box width={1} display="flex" alignItems="center">
                <Box pr={2}>
                    <Typography
                        variant="p14"
                        fWeight="semiBold"
                        color={theme.palette.grey[900]}
                        isStory={isStory}
                    >
                        {title}
                    </Typography>
                </Box>
            </Box>
            <Box
                width={1}
                mt={2}
                bgcolor={theme.palette.grey[100]}
                borderRadius="10px"
                pt={4}
                pl={5}
                pr={2}
            >
                <Box width={1} height={148} pr={2} pb={1} className="modal-item-content">
                    {rewards.map((reward, index) => {
                        if (!reward) return null;

                        const icon = reward.iconPath ? getMilestoneIcon(reward.iconPath) : '';

                        const description = reward.descriptionPath ? t(reward.descriptionPath) : '';

                        const amount = reward.amount ? convertPriceUsingK(reward.amount) : 0;

                        return (
                            <Box
                                key={description + index}
                                width={1}
                                pb={3}
                                display="flex"
                                alignItems="center"
                            >
                                <>
                                    <Box
                                        mr={3}
                                        width="32px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        {icon ? (
                                            <LazyImage
                                                height="32px"
                                                src={icon}
                                                alt="offer-reward-icon"
                                                draggable={false}
                                            />
                                        ) : null}
                                    </Box>
                                    <Box maxWidth={280}>
                                        <Typography
                                            variant="p12"
                                            fWeight="semiBold"
                                            color={theme.palette.grey[800]}
                                            isStory={isStory}
                                        >
                                            {`x${amount} ${description}`}
                                        </Typography>
                                    </Box>
                                </>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </MilestoneInfoModalContentItemStyled>
    );
}

export default memo(MilestoneInfoModalContentItem);
