export const sizeToHeading: Record<string, React.ElementType> = {
    p: 'p',
    p8: 'p',
    p10: 'p',
    p12: 'p',
    p14: 'p',
    p16: 'p',
    p18: 'p',
    p20: 'p',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
};
export const FontWeight: { [key: string | number]: string | number } = {
    semiBold: 600,
    regular: 400,
    medium: 500,
    bold: 'bold',
    normal: 'normal',
    thin: 300,
    100: 100,
    200: 200,
    300: 300,
    400: 400,
    500: 500,
    600: 600,
    700: 700,
    800: 800,
    900: 900,
};
