import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Link, useTheme } from '@mui/material';

import { DOWNLOAD_LINKS, SCOPELY_URLS } from '../../../../constants/links.constants';

import { interactionEvent } from '../../../../helpers/GA4Helper';

import { Box, Icon } from '../../../core-ui';

import AppStore from '../../../../assets/images/LeftSidebarFooter/AppStoreImg.png';
import PlayMarket from '../../../../assets/images/LeftSidebarFooter/PlayMarketImg.png';
import { Typography } from '../../../../stories';
import { LeftSidebarFooterStyled } from './LeftSidebarFooter.styled';

declare global {
    interface Window {
        OneTrust: any;
        reloadOTBanner: () => void;
    }
}

function LeftSidebarFooter() {
    const [t] = useTranslation();
    const theme = useTheme();
    const { pathname } = useLocation();

    const handleLinkClick = (linkType: string) => {
        interactionEvent(pathname, 'left_sidebar', linkType, 'social_links', 'link');
    };

    const handlePrivacyPolicyClick = (type: string) => {
        interactionEvent(pathname, 'left_sidebar', type, 'privacy_policy_link', 'link');
    };

    const handleStoreLinkClick = (type: string) => {
        interactionEvent(pathname, 'left_sidebar', 'install', type, 'link');
    };

    React.useEffect(() => {
        const maxRetries = 10; // Define the maximum number of retries
        let attemptCount = 0; // Initialize the retry counter

        const checkAndReloadBanner = () => {
            if (window.OneTrust && window.OneTrust?.Init && window.reloadOTBanner) {
                window.reloadOTBanner(); // Call reloadOTBanner when OneTrust is fully loaded
            } else if (attemptCount < maxRetries) {
                attemptCount++; // Increment the retry counter
                setTimeout(checkAndReloadBanner, 30000); // Retry after a short delay
            }
        };

        checkAndReloadBanner();
    }, []);

    return (
        <LeftSidebarFooterStyled>
            <Box
                py={5}
                pr={10}
                mx={5}
                display="grid"
                className="WP-LeftSideBarFooter-download-links"
            >
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Link href={DOWNLOAD_LINKS.APP_STORE} underline="none" target="_blank">
                        <Box
                            component="img"
                            src={AppStore}
                            alt="AppStore"
                            height="100%"
                            width="158px"
                            onClick={() => handleStoreLinkClick('IOS_store_button')}
                        />
                    </Link>
                    <Link href={DOWNLOAD_LINKS.PLAY_STORE} underline="none" target="_blank">
                        <Box
                            component="img"
                            src={PlayMarket}
                            alt="PlayMarket"
                            height="100%"
                            width="158px"
                            onClick={() => handleStoreLinkClick('google_play_button')}
                        />
                    </Link>
                </Box>
            </Box>

            <Box pt={16} pb={8} px={5}>
                <Link href={SCOPELY_URLS.TERMS} target="_blank" underline="none">
                    <Box onClick={() => handlePrivacyPolicyClick('terms_of_service_link')}>
                        <Typography
                            color={theme.palette.grey[600]}
                            variant="p14"
                            className="cursor-pointer"
                        >
                            {t('left.bar.footer.terms.of.service')}
                        </Typography>
                    </Box>
                </Link>

                <Box mt={5}>
                    <Link href={SCOPELY_URLS.PRIVACY_POLICY} target="_blank" underline="none">
                        <Box onClick={() => handlePrivacyPolicyClick('privacy_policy_link')}>
                            <Typography
                                color={theme.palette.grey[600]}
                                variant="p14"
                                fWeight="regular"
                                className="cursor-pointer"
                            >
                                {t('left.bar.footer.privacy.policy')}
                            </Typography>
                        </Box>
                    </Link>
                </Box>

                <Box mt={5}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a id="ot-sdk-link" className="ot-sdk-show-settings" href="#">
                        Cookies Settings
                    </a>
                </Box>
            </Box>

            <Box
                display="flex"
                alignItems="center"
                px={5}
                mb={15}
                className="WP-LeftSideBarFooter-social-links"
            >
                <Link
                    href="https://www.facebook.com/LooneyTunesWOM/"
                    mr={3}
                    underline="none"
                    className="WP-LeftSideBarFooter-social-link"
                    target="_blank"
                >
                    <Icon
                        className="icon-facebook-black"
                        size={24}
                        onClick={() => handleLinkClick('facebook')}
                    />
                </Link>
                <Link
                    href="https://www.youtube.com/channel/UCWuVAZDgzG83DJOzSFUEGDA"
                    underline="none"
                    className="WP-LeftSideBarFooter-social-link"
                    target="_blank"
                >
                    <Icon
                        className="icon-youtube"
                        size={24}
                        onClick={() => handleLinkClick('youtube')}
                    />
                </Link>
                <Link
                    href="https://twitter.com/LooneyTunesWoM?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                    underline="none"
                    className="WP-LeftSideBarFooter-social-link"
                    target="_blank"
                >
                    <Icon
                        className="icon-twitter"
                        size={24}
                        onClick={() => handleLinkClick('twitter')}
                    />
                </Link>
                <Link
                    href="https://www.instagram.com/looneytuneswom/?hl=en"
                    underline="none"
                    className="WP-LeftSideBarFooter-social-link"
                    target="_blank"
                >
                    <Icon
                        className="icon-instagram"
                        size={24}
                        onClick={() => handleLinkClick('instagram')}
                    />
                </Link>
                <Link
                    href="https://discord.com/channels/481394840010227713/481461206473768970"
                    underline="none"
                    className="WP-LeftSideBarFooter-social-link"
                    target="_blank"
                >
                    <Icon
                        className="icon-discord"
                        size={24}
                        onClick={() => handleLinkClick('discord')}
                    />
                </Link>
            </Box>

            <Box display="flex" alignItems="start" flexDirection="column" px={5} mb={20}>
                <Typography color={theme.palette.grey[500]} variant="p12" fWeight="medium">
                    Looney Tunes © 2022 WBEI
                </Typography>
                <Typography color={theme.palette.grey[500]} variant="p12" fWeight="medium">
                    Looney Tunes TM and © WBEI;
                </Typography>
                <Typography color={theme.palette.grey[500]} variant="p12" fWeight="medium">
                    © Scopely 2022
                </Typography>
            </Box>
        </LeftSidebarFooterStyled>
    );
}

export default memo(LeftSidebarFooter);
