import { TRewardCategory } from '../@types';

export const REWARD_CATEGORIES: TRewardCategory[] = [
    'characterPiece',
    'token',
    'material',
    'gadgetDrop',
    'gameEnergy',
    'currency',
    'potion',
];
