import { TNews, TNewsState } from 'src/@types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialNewsState } from './initialState';

export const newsSlice = createSlice({
    name: 'news',
    initialState: initialNewsState(),
    reducers: {
        setNewsLoader(state: TNewsState, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setLoadMoreLoader(state: TNewsState, action: PayloadAction<boolean>) {
            state.loadMoreLoading = action.payload;
        },
        setNewsData(state: TNewsState, action: PayloadAction<Array<TNews>>) {
            state.news = state.news.concat(action.payload);
        },
        setNewsPage(state: TNewsState, action: PayloadAction<number>) {
            state.page = state.page + action.payload;
        },
        setLoadMoreNews(state: TNewsState, action: PayloadAction<boolean>) {
            state.loadMore = action.payload;
        },
        setActiveNews(state: TNewsState, action: PayloadAction<any>) {
            state.activeNews = action.payload;
        },
    },
});
