import React, { memo } from 'react';

import { DialogActions } from '@mui/material';

import { Box } from '../../../core-ui';

function MilestoneInfoModalFooter({ button }: { button?: JSX.Element | null }) {
    return button ? (
        <DialogActions>
            <Box data-testid="WP-Modal-purchase-btn" pt={5}>
                {button}
            </Box>
        </DialogActions>
    ) : null;
}

export default memo(MilestoneInfoModalFooter);
