export interface IPeriodType {
    text: string;
    title?: string;
    key: string;
    id: number;
}

export type TEventInitialState = {
    events: Array<TEvent>;
    isLoading: boolean;
};

export type TEvent = {
    Art: string;
    'End date (UTC)': string;
    'Event ID (admin tool)': string;
    'Event name': string;
    Hyperlink: string;
    'Live/Future/Ended': string;
    'Start date (UTC)': string;
};

export interface IEventWinnerProps {
    allianceName: string;
    playerName: string;
    playerPlaceNumber: string;
    score: string;
}

export enum CalendarPeriod {
    TODAY = 'today',
    TOMORROW = 'tomorrow',
    THIS_WEEK = 'this_week',
    NEXT_WEEK = 'next_week',
    ALL = 'All',
}
