import React, { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import clsx from 'clsx';

import { useTheme } from '@mui/material';
import { CircularProgress } from '@mui/material';

import { globalOp } from '../../../../store/global';

import { Box } from '../../../core-ui';

import { Typography } from '../../../../stories';

interface IProps {
    isClaim?: boolean;
    isEnoughCoins?: boolean;
}

const ProcessingModal: FC<IProps> = ({ isClaim, isEnoughCoins }) => {
    const [t] = useTranslation();
    const [progress, setProgress] = useState(0);
    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress: number): number => {
                if (prevProgress >= 100) {
                    if (isClaim) {
                        dispatch(
                            globalOp.handleOpenModal({
                                open: true,
                                variant: 'payment-success-claim',
                            }),
                        );
                    } else if (isEnoughCoins) {
                        dispatch(
                            globalOp.handleOpenModal({
                                open: true,
                                variant: 'payment-success',
                            }),
                        );
                    } else {
                        dispatch(
                            globalOp.handleOpenModal({
                                open: true,
                                variant: 'not-enough-coins',
                            }),
                        );
                    }
                    return prevProgress;
                } else {
                    return prevProgress + 5;
                }
            });
        }, 200);

        return () => clearInterval(timer);
    }, [dispatch, isClaim, isEnoughCoins]);

    return (
        <>
            <Box
                pt={15}
                className="WP-Modal-Icon"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box mb={6}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress
                            size={120}
                            className="WP-Modal-loading"
                            variant="determinate"
                            value={progress}
                        />
                        <Box
                            width={76}
                            height={76}
                            position="absolute"
                            className={clsx(
                                isClaim ? 'WP-Modal-Reward-Process' : 'WP-Modal-Payment-Process',
                                'WP-Modal-Icon-Background',
                            )}
                        />
                    </Box>
                </Box>
                <Box px={[5, 11]} pb={3} textAlign="center">
                    <Typography fWeight="bold" variant="h5" color={theme.palette.text[300]}>
                        {isClaim
                            ? t('modal.processing.claiming.reward')
                            : t('modal.processing.payment.processing')}
                    </Typography>
                </Box>
            </Box>
            <Box px={[5, 11]} pb={16} textAlign="center">
                <Typography fWeight="medium" variant="p16" color={theme.palette.text[100]}>
                    {t('modal.processing.description')}
                </Typography>
            </Box>
        </>
    );
};

export default ProcessingModal;
